<!-- <button *ngIf="!isRecording" class="button" (click)="startRecording()">Start Recording</button>
<button *ngIf="isRecording" class="button" (click)="stopRecording()">Stop Recording</button> -->

<!-- <div *ngIf="isLocked">
    <a [href]="capture.url">Télécharger votre enregistrement</a>
</div>
*ngIf="!isLocked"  -->

<div class="centered-stack" id="microphone">

    <div *ngIf="isCountdownInProgress" class="centered-stack" style="margin-bottom:2em;">
        <div *ngIf="element.setTimer && time > 0">
            <!--<div style="color: red;font-weight: bold;">
                Recording Starts In {{time}} Seconds
            </div>-->
            <div class="progress-bar" [ngStyle]="getProgressBarBorder()">
                <div class="progress-bar-fill" [ngStyle]="getProgressBarFillEm()">
                </div>
            </div>
        </div>
        <div *ngIf="!isCountdownEarlyEnd" style="margin-top:1em;">
            <button 
                (click)="isCountdownEarlyEnd = true" 
                class="button is-large has-icon"
            >
                <span class=" recorder-circle"></span>
                <span>Enregistrer maintenant</span>
            </button>
        </div>
    </div>

    <div *ngIf="!isCountdownInProgress || isCountdownEarlyEnd" >
        <div *ngIf="isDeviceError" class="notification is-danger">
            L'évaluation est incapable d'accèder à votre microphone. Veuillez vérifier ce problème avec un technicien.
        </div>
        <div style="display: flex; flex-direction: row; align-items: center;">
            <div *ngIf="element.isPractice" (click)="resetRecording()" class="undo-button" [class.is-active]="isRecorded">
                <div style="margin-bottom:0.5em;">Refaire</div>
                <i class="fas fa-undo"></i>
            </div>
            <table>
                <tr>
                    <td style="width:5em;background-color:#333;"></td>
                    <td class="header-cell is-cell-record">Enregistrer</td>
                    <td class="header-cell is-cell-stop">Arrêter</td>
                    <td class="header-cell is-cell-save">Envoyer</td>
                </tr>
                <tr>
                    <!-- <td style="background-color:#000"> <img *ngIf="isRecordingSim" style="width:2em;" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/Feb-02-2021 15-55-10/1612299541888/Feb-02-2021 15-55-10.gif" /></td> -->
                    <td style="background-color:#ccc; overflow:hidden;" [ngSwitch]="!!isChrome()"> 
                        <div *ngSwitchCase="true" id="waveform" style="overflow:hidden"  [class.is-transparent]="!isRecordingSim"></div> 
                        <div *ngSwitchCase="false" [class.is-hidden]="!isRecordingSim" >
                            <div *ngIf="isRecorderInitializing" style="font-size:0.7em;">Initialization...</div>
                            <img *ngIf="!isRecorderInitializing" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/voice/1613601522306/voice.gif">
                        </div>
                    </td>
                    <td (click)="startRec()" [class.is-disabled]="isRecorded" [class.is-active]="isRecordingSim" class="btn-cell is-cell-record"> <i class="fas fa-circle"></i></td>
                    <td (click)="stopRec()"  [class.is-disabled]="isRecorded" class="btn-cell is-cell-stop"> <i class="fas fa-square"></i></td>
                    <td (click)="saveRec()"  [class.is-disabled]="capture.isFilled" [class.is-active]="isUploading" class="btn-cell is-cell-save"> <i class="fas fa-cloud-upload-alt"></i> </td>
                </tr>
                <tr>
                    <td colspan="4" style="background-color:#FFFD4C">
                        <div class="space-between">
                            <span> {{msg}} <span *ngIf="isUploading">(Téléversement)</span> </span>
                            <span>
                                <span *ngIf="isRecordingSim">{{currentRecordingTime}}</span>
                                <!-- <button *ngIf="element.isPractice" (click)="restartState()">Recommencé</button> -->
                            </span>
                        </div>
                    </td>
                </tr>
            </table>
            <div (click)="playRecording()" class="play-button" [class.is-active]="isRecorded">
                <div style="margin-bottom:0.5em;">Écouter</div>
                <i class="fas fa-play-circle"></i>
            </div>
        </div>

        <div *ngIf="!isChrome()" class="padded-audio-display">
            <mictest 
                [startRec]="triggerStartRec"
                [stopRec]="triggerStopRec"
                (onUrlGenerated)="onUrlGenerated($event)"
            ></mictest>
        </div>
            
        <div *ngIf="isRecorded" >
            <render-audio 
                [hasControls]="false" 
                [trigger]="audioTrigger" 
                [forceNativePlayer]="true"
                [url]="capture.url"
                (end)=" msg = 'Terminé' "
            ></render-audio>
        </div>

    </div>
</div>

<render-audio 
    [url]="warningForAuto.url"
    [hasControls]="false" 
    [trigger]="warningForAutoTrigger" 
></render-audio>
<render-audio 
    [url]="standardBeep.url"
    [hasControls]="false" 
    [trigger]="standardBeepTrigger" 
></render-audio>


<!-- <div [ngSwitch]="!!isLocked" style="opacity:0">
    <capture-voice *ngSwitchCase="false"
        [element]="capture" 
        [isResponse]="true"
        [recordEndCallback]="stopRecording"
        [startNow]="element.setTimer && !(time>0)"
        (notifyRecordingStart)="voiceCaptureStarted()"
    ></capture-voice>
    
</div> -->


 