<div class="element-type-grouping">
  <div
    #elPosContainer
    class="pos-container element-render-grouping"
    [style.width.em]="element.groupWidth ? element.groupWidth : '' "
    [class.is-dragging]="isDragging"
  >
    <div
            cdkDropListGroup
            class="pos-container-sub"
            [class.is-targets-above]="element.isTargetsAbove"
    >
      <div >
        <div
                cdkDropList
                class="drag-drop-list drag-drop-source mb-3"
                [class.is-empty]="draggables.length == 0 "
                [cdkDropListData]="draggables"
                cdkDropListSortingDisabled
                (cdkDropListDropped)="drop($event, null, true); isDragging=false"
                (cdkDropListEntered)="isDragging=true"
        >
          <div
                  *ngFor="let elPos of draggables"
                  cdkDrag [cdkDragDisabled]="isLocked"
                  (mousedown)="dragStarted(elPos)"
                  class="drag-el"
                  [ngStyle]="getDraggableStyle(elPos, true)"
                  [class.is-centered]="elPos.isTargetsContentsCentred"
          >
            <element-render
                    *ngIf="elPos.ref.element"
                    [contentElement]="elPos.ref.element"
                    [isLocked]="true"
                    [questionState]="{}"
            ></element-render>
            <render-audio
                    [url]="getElementAudio(elPos.ref.voiceover)"
                    [trigger]="getDragTrigger(elPos)"
                    [isTriggerDisabled]="!isVoiceoverEnabled()"
            ></render-audio>
          </div>
        </div>
      </div>
      <div *ngIf="!element.isInstructionsDisabled" class="question-block-label mb-3" style="text-align: center; font-size:0.8em;"  >
        <tra-md slug="txt_default_drag_instr"></tra-md>
      </div>
      <div>
        <div class="group-container" [class.is-vertical]="element.isVerticalLayout" [class.is-side-by-side]="isSideBySide()">
          <div
                  class="drag-drop-container"
                  *ngFor="let target of targets; index as idx"
                  [style.backgroundColor]="getTargetColor(target)"
                  [style.margin]="getTargetMargins()"
                  [style.position]="getPosition()"
                  [style.min-width.em]="this.element.customTargetDim ? this.element.targetWidth : '0'"
                  [style.min-height.em]="this.element.customTargetDim ? this.element.targetHeight: '0'"
                  [style.left.em]="getLeft(target.targetContext)"
                  [style.top.em]="getTop(target.targetContext)"
          >
            <div class="group-title"
                 [style.color]="this.element.targetColourScheme.textColor"
            >
              <element-render
                      *ngIf="target.targetContext.element"
                      [contentElement]="target.targetContext.element"
                      [isLocked]="true"
                      [questionState]="{}"
              ></element-render>
              <render-audio
                      [url]="getElementAudio(target.targetContext.voiceover)"
                      [trigger]="getDropTrigger(target.targetContext)"
                      [isTriggerDisabled]="!isVoiceoverEnabled()"
              ></render-audio>
            </div>

            <div
                    cdkDropList
                    [cdkDropListData]="target.contents"
                    class="drag-drop-list"
                    style="flex-wrap: nowrap; flex-direction: column;"
                    (cdkDropListDropped)="drop($event, target.targetContext, false); isDragging=false"
                    (cdkDropListEntered)="isDragging=true"
                    [ngStyle]="getStyleForTarget(target.targetContext)"
            >
              <div class="hover-indicator"></div>
              <div
                      *ngFor="let assignedElement of target.contents"
                      cdkDrag
                      (mousedown)="dragStarted(assignedElement)"
                      class="drag-el"
                      [cdkDragDisabled]="isLocked"
                      [class.is-centered]="assignedElement.isTargetsContentsCentred"
                      [ngStyle]="getDraggableStyle(assignedElement)"
              >
                <div>{{assignedElement.isTargetsContentsCentred}}</div>
                <element-render
                        *ngIf="assignedElement.ref.element"
                        [contentElement]="assignedElement.ref.element"
                        [isLocked]="true"
                        [questionState]="{}"
                ></element-render>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>