import { Component, OnInit, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { QuestionState, IContentElement, IEntryStateInsertion, IContentElementInsertion, IContentElementDndTarget, ScoringTypes, IContentElementDndSub, IContentElementDndDraggable, getElementWeight, ElementType, IContentElementText } from '../models';
import { IElementPos, renderDndElementStyle } from '../../ui-item-maker/element-config-insertion/element-config-insertion.component';
import {CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem} from '@angular/cdk/drag-drop';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { StyleprofileService, processText } from '../../core/styleprofile.service';
import { LangService } from '../../core/lang.service';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import {TextToSpeechService} from "../text-to-speech.service";



@Component({
  selector: 'element-render-insertion',
  templateUrl: './element-render-insertion.component.html',
  styleUrls: ['./element-render-insertion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ElementRenderInsertionComponent implements OnInit {

    @Input() element:IContentElementInsertion;
    @Input() isLocked: boolean;
    @Input() questionState: QuestionState;
    @Input() changeCounter: number;
    @Input() questionPubSub?: QuestionPubSub;

    draggables: IElementPos[] = [];
    dragTriggers: Map<IElementPos, Subject<boolean>> = new Map();
    // textBlocks: {textBlockContext: IContentElementDndTarget, contents?:IElementPos[]}[] = [];
    blocks: {isTarget?: boolean, isPunctNext?:boolean, contents?:any[], isSpaceAfter?:boolean, str?:any, ctx?:IContentElementDndDraggable,}[]

    constructor(
        private lang:LangService, 
        private profile:StyleprofileService,
        public textToSpeech:TextToSpeechService
    ) { }

    ngOnInit(): void {
      this.loadState();
      this.ensureState();
    }
  
    ngOnChanges(changes: SimpleChanges) {
      if (changes.changeCounter){
        if (this.element.textBlocks!=undefined){ 
          this.updateDisplayEls();
        }
      }
    }

    isDragBetweenWords(){
      return this.element && this.element.isDragBetweenWords;
    }

    ensureState(){
      if (this.questionState) {
        if (!this.questionState[this.element.entryId]) {
          console.log("initializing state");
          let entryState: IEntryStateInsertion = {
            type: ElementType.INSERTION,
            isCorrect: false,
            isStarted: false,
            isFilled: false,
            score: 0, //this.targets.find((target:any) => target.groups.length) ? getElementWeight(this.element) : 0,
            weight: getElementWeight(this.element),
            scoring_type: ScoringTypes.AUTO,
            draggables: this.draggables,
            targets: this.blocks
          };
          //console.log("initializing state")
          this.questionState[this.element.entryId] = entryState;
        }
        
      }
    }

    loadState() {
      if (this.questionState && this.questionState[this.element.entryId]) {
        console.log("loading prior state")
        this.draggables = this.questionState[this.element.entryId].draggables;
        this.blocks = this.questionState[this.element.entryId].targets;
      } else {
        console.log("updating display els")
        this.updateDisplayEls();
      }
    }

    updateDisplayEls() {
      console.log("updateDisplay")
      this.draggables = [];
      if (!this.element.draggables || !this.element.textBlocks) return;
      this.element.draggables.forEach(element => this.addElementToList(element, this.draggables));

      this.blocks = [];
      this.element.textBlocks.forEach(ctx => {
        if (ctx.element && ctx.element.elementType === ElementType.TEXT){
          const textElement = <IContentElementText> ctx.element;
          (textElement.caption || '').split(/(?=[\s,\.!\?":-])/).forEach((str,i) => {
            if ((/(?=[\s,\.!\?":-])/).test(str) && this.blocks.length>0 && str.length==1) {
              const lastIndex = this.blocks.length-1;
              this.blocks[lastIndex].isPunctNext = true;
            }
            this.blocks.push({str, isSpaceAfter:!this.isDragBetweenWords()})
            if (this.isDragBetweenWords()){
              //console.log(textElement.caption)
              this.blocks.push({isTarget:true, contents:[]})
            }
          });
        }
        else {
          const last_i = this.blocks.length -1;
          const lastBlock = this.blocks[last_i];
          if (lastBlock) {
            if (lastBlock.isTarget && !lastBlock.ctx){
              this.blocks.splice(last_i, 1);
            }
            else {
              lastBlock.isSpaceAfter = false;
            }
          }
          
          //console.log(lastBlock.caption)
          this.blocks.push({isTarget:true, contents:[], ctx })
        }
      })
      // const caption = `Aunt Cathie had arrived, and was resting in her bedroom at Brayton before dressing for dinner. Lucia was coming up for a chat later, but Aunt Cathie was glad to be alone for a little, and recover from the excitement and strangeness of it all.`;

    }
  
    addElementToList(element: IContentElementDndSub, elementsToPosition: IElementPos[], isTarget: boolean= false) {
      let hasElement  = false;
      if ((<IContentElementDndDraggable> element).element) {
        hasElement = true;
      }
      elementsToPosition.push({
        ref: element,
        originalX: element.x,
        originalY: element.y,
        isTarget,
        //style: renderDndElementStyle(element, hasElement, isTarget && this.element.customTargetDim, this.element.defaultTargetStyle),
        style: {}
      });
      return
    }
  
    refreshState(){
      
      if (this.questionState) {
        const entryId = this.element.entryId;
        const weight = getElementWeight(this.element);
        let score = weight;
        let missed = 0;
        let correct = 0;
        let isFilled = true;
        let isStarted = false;
        if (!this.element.isDragBetweenWords) {
          this.blocks.forEach((block)=>{
            if (block.isTarget) {
              if (block.contents && block.contents.length>0) {
                const droppedObj = block.contents[0].ref;
                if (!block.ctx || droppedObj.id != block.ctx.id) {
                  missed++;
                } else {
                  correct++;
                }
                isStarted = true;
              } else {
                missed++;
                isFilled=false;
              }
            }
          })
        } else {
            this.blocks.forEach((block)=>{
              if (!block.isTarget) return;
              if (block.contents.length>0) {
                if (!block.ctx) missed++;
                else {
                  const droppedObj = block.contents[0].ref;
                  if (droppedObj.id != block.ctx.id) missed++;
                  else correct++;
                }
                isStarted = true;
              } else {
                if (block.isTarget && block.ctx) {
                  missed++;
                  isFilled=false;
                }
              }
            })
        }

        if (this.element.enableProportionalScoring) {
          const ratio = correct/(missed+correct);
          score = ratio*weight;
        } else if (missed>0) {
          score = 0;
        } else {
          score = weight;
        }
        //console.log(score);
        let entryState: IEntryStateInsertion = {
          type: ElementType.INSERTION,
          isCorrect: score==weight,
          isStarted: isStarted, //this.targets.find((target:any) => target.groups.length) ? true : false,
          isFilled: isFilled, //this.targets.find((target:any) => target.groups.length) ? true : false,
          score: score, //this.targets.find((target:any) => target.groups.length) ? getElementWeight(this.element) : 0,
          weight: getElementWeight(this.element),
          scoring_type: ScoringTypes.AUTO,
          draggables: this.draggables,
          targets: this.blocks, // this.textBlocks
        };
        if (this.questionState){
          this.questionState[this.element.entryId] = entryState;
        }
      }
    }
  
    drop(event: CdkDragDrop<string[]>, isHomeDest:boolean=false) {
      const isComingFromHome = ((<any[]>event.previousContainer.data) === this.draggables);
      const isDestToHome = ((<any[]>event.container.data) === this.draggables);
      if (this.isDragBetweenWords()){
        
        // console.log({isComingFromHome, isDestToHome});
        if (isDestToHome){
          if (!isComingFromHome){
            transferArrayItem(event.previousContainer.data,
              [],
              event.previousIndex,
              0);
          }
        }
        else{
          if (isComingFromHome){
            // transferArrayItem(event.previousContainer.data, [], event.previousIndex, 0);
            copyArrayItem(event.previousContainer.data,
              event.container.data,
              event.previousIndex,
              event.currentIndex);
            
          } else {
            transferArrayItem(event.previousContainer.data,
              event.container.data,
              event.previousIndex,
              event.currentIndex);
          }
        }
      }
      else{
        let allowTransfer = true;
        if (!isComingFromHome && !isDestToHome) {
          if (event.container.data.length>0) {
            transferArrayItem(event.container.data,
              event.previousContainer.data,
              event.currentIndex,
              event.previousIndex+1);
          }
          transferArrayItem(event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex);
        } else if (!isComingFromHome && isDestToHome) {
          if (!this.element.isRepeatableOptions) {
            transferArrayItem(event.previousContainer.data,
              event.container.data,
              event.previousIndex,
              event.currentIndex);
          } else {
            transferArrayItem(event.previousContainer.data,
              [],
              event.previousIndex,
              0);
          }
        } else if (isComingFromHome && !isDestToHome) {
          if (!this.element.isRepeatableOptions) {
            if (event.container.data.length>0) {
              transferArrayItem(event.container.data,
                event.previousContainer.data,
                event.currentIndex,
                event.previousIndex+1);
            }
            transferArrayItem(event.previousContainer.data,
              event.container.data,
              event.previousIndex,
              event.currentIndex);
          } else {
            if (event.container.data.length>0) {
              transferArrayItem(event.container.data,
                [],
                event.currentIndex,
                0);
            }
            copyArrayItem(event.previousContainer.data,
              event.container.data,
              event.previousIndex,
              event.currentIndex);
          }
          
        }
      }
      // console.log(event, this.targets);
      this.refreshState();
      // this.rearrangeByOrder();
      this.removeSelection();
    }
  
    removeSelection() {
      if (window.getSelection()) {
        window.getSelection().removeAllRanges()
      } else if (document.getSelection()) {
        document.getSelection().removeAllRanges();
      }
    }

    // rearrangeByOrder() {
    //   const thisOpts = this.draggables;
    //   const configOpts = this.element.draggables;
    //   const newThisOpts = []
    //   configOpts.forEach((opt)=>{
    //     thisOpts.forEach((currOpt)=>{
    //       if (currOpt.ref == opt) {
    //         newThisOpts.push(currOpt)
    //       }
    //     })
    //   })
    //   this.draggables = newThisOpts;
    // }

    updateRender(str:string){
      const lang = this.lang.c();
      return processText(str, this.profile.getStyleProfile()[lang].renderStyling.plainText.transforms);
    }

  isVoiceoverEnabled() {
    return this.textToSpeech.isActive;
  }

  getElementAudio(voiceover: any): string {
    return (voiceover && voiceover.url) ? voiceover.url : '';
  }

  getDragTrigger(element: IElementPos){
    let trigger = this.dragTriggers.get(element);
    if (!trigger){
      trigger = new Subject();
      this.dragTriggers.set(element, trigger);
    }
    return trigger;
  }

  dragStarted(element: IElementPos) {
    const voiceover = element.ref.voiceover;
    if (voiceover && voiceover.url) {
      this.getDragTrigger(element).next(true);
    }
  }
  
}