<div>

  <div *ngIf="!isFormatTypeTextLong()">
    <div style="display:inline-block; vertical-align:middle; margin-right:0.2em;" *ngIf="element.prefix">
      {{element.prefix}}
    </div>
    <div style="display:inline-block; vertical-align:middle; ">
      <div *ngIf="isFormatTypeNumber()">
        <input [readonly]="this.isLocked" type="text" [formControl]="numberInput" class="input" style="width:5em; text-align:center" >
      </div>
      <div *ngIf="isFormatTypeFraction()">
        <div style="display:inline-block; vertical-align:middle;">
          <div *ngIf="element.isMixedNumber" style="display:inline-block; vertical-align:middle; margin-right:0.2em;">
            <input [readonly]="this.isLocked" type="text" [formControl]="fractionWholeInput" class="input input-fraction"  >
          </div>
          <div style="display:inline-block; vertical-align:middle;">
            <input [readonly]="this.isLocked" type="text" [formControl]="fractionNumeInput" class="input  input-fraction is-small"  >
            <div style="margin-bottom:6px; border:1px solid #333;"></div>
            <input [readonly]="this.isLocked" type="text" [formControl]="fractionDenoInput" class="input  input-fraction is-small"  >
          </div>
        </div>
      </div>
      <div *ngIf="isFormatTypeTextShort()">
        <input 
          type="text" 
          class="input" 
          [formControl]="textInput" 
          [ngStyle]="{'width.em':element.maxChars*1 + 2}"
          [readonly]="this.isLocked"
        />
      </div>
      <div *ngIf="isFormatTypeRatio()">
        <span *ngFor="let listEntry of ratioTerms; let listIndex = index;">
          <span *ngIf="listIndex !== 0" style="margin-left:0.3em; margin-right:0.3em;">:</span>
          <input [readonly]="this.isLocked" type="text" class="input" [formControl]="listEntry" style="width:40px; vertical-align:middle; text-align:center">
        </span>
      </div>
      <div *ngIf="isFormatTypeAlgebra()" style="min-width:12em">
        <capture-math 
          [obj]="latexCapture" 
          prop="latexInput"
          [isLocked]="isLocked"
          [isManualKeyboard]="true"
          (onChange)="updateState()"
        ></capture-math>
      </div>
    </div>
    <div style="display:inline-block; vertical-align:middle; margin-left:0.2em;" *ngIf="element.suffix">
      {{element.suffix}}
    </div>
  </div>
  
  <div 
    *ngIf="isFormatTypeTextLong()"
  >
    <div *ngIf="isPlainTextArea()">
      <textarea 
        #textArea
        class="textarea is-fullwidth" 
        style="width: 24em;"
        [formControl]="textInput"
        [readonly]="this.isLocked"
      ></textarea>
    </div>
    <div
      *ngIf="!isPlainTextArea()"
      style="position:relative;" 
      [class.hide-toolbar-ckeditor]="element.isToolbarAutoHide && !isInFocus"
      [class.fixed-height-ckeditor]="element.isFixedHeight"
    >
      <ckeditor 
        [editor]="Editor" 
        [config]="ckEditorConfig" 
        (focus)="!!isInFocus" 
        (blur)="!isInFocus" 
        (ready)="onCkEditorReady($event)" 
        (change)="onCkEditorUpdate($event)"
        [disabled]="isLocked"
      ></ckeditor>
      <div 
        *ngIf="isBlankText() && element.defaultText && !isInFocus" 
        class="default-text-container"
        [class.is-in-fixed-height]="element.isFixedHeight"
      >
        <div class="default-text">{{element.defaultText}}</div>
      </div>
    </div>
    
    <div class="keyboard-container" *ngIf="element.isShowingFrenchKeys">
      <div class="keyboard">
        <div>
          <button class="button is-small character " style="width: 5em;" [class.is-warning]="!isShowingCapitals" [class.is-dark]="isShowingCapitals" (click)="isShowingCapitals=!isShowingCapitals">
            Maj.
          </button>
        </div>
        <ng-container *ngIf="!isShowingCapitals">
          <div *ngFor="let letter of frenchAccent">
            <button class="button is-small character" (click)="addFrenchCharacter(letter, $event.target, $event);">
              {{letter}}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="isShowingCapitals">
          <div *ngFor="let letter of frenchAccentCapital">
            <button class="button is-small character" (click)="addFrenchCharacter(letter, $event.target, $event);">
              {{letter}}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
      <!-- (ready)="onReady($event)"
      -->
    <div *ngIf="isLimitByWords() == true" [ngSwitch]="!!isCountdownMode()"> 
      <ng-container *ngSwitchCase="true">
        <small *ngIf="this.element.maxWords && getRemainingWords()>=0" class="remaining-chars" [class.is-complete]="getRemainingWords() == 0" >
          <tra slug="open_response_rem_words"></tra>{{getRemainingWords()}}
        </small>
        <small *ngIf="this.element.maxWords && getRemainingWords()<0" class="remaining-chars is-overflow" >
          <tra slug="open_response_rem_words"></tra> {{-1*getRemainingWords()}}
        </small>
      </ng-container>
      <ng-container  *ngSwitchCase="false">
        <small *ngIf="element.maxWords" class="remaining-chars" [class.is-complete]="getRemainingWords() == 0" [class.is-overflow]="getWords() > element.maxWords" >
          <tra slug="open_response_words"></tra>{{getWords()}}
        </small>
      </ng-container>
    </div>

  </div>

  <div *ngIf="isFormatTypeForm()">
    <div *ngFor="let element of element.formElements">
      <element-render 
        [contentElement]="element" 
        [isLocked]="isLocked" 
        [questionState]="questionState"
        [questionPubSub]="questionPubSub"
      ></element-render>
    </div>
  </div>
</div>

