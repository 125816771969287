import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbsService } from '../../core/breadcrumbs.service';
import { FormGroup, FormControl } from '@angular/forms';
import { ScrollService } from '../../core/scroll.service';
import { AuthService, F_ERR_MSG__INVALID_LOGIN } from '../../api/auth.service';
import { LangService } from '../../core/lang.service';
import { Subscription } from 'rxjs';
import { AccountType } from '../../constants/account-types';
import { LoginValidationService } from 'src/app/core/login-validation.service';
import { FormFail, LoginCtrl } from '../../core/login-ctrl';

interface ILoginFormErrors {
  isBlank?:boolean,
  isInvalid?: boolean,
}

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit, OnDestroy {

  @Input() accountType:AccountType;
  @Input() prefilledEmail:string;
  @Output() onLoginAttempt = new EventEmitter();

  public breadcrumb = [];
  public isFormValidated:boolean;
  public isFormSent:boolean;
  public isFormFailed:boolean;
  public formFailReason:FormFail;
  public routeSub:Subscription;
  public loginAttemptsSub:Subscription;
  public formGroupEmailAddress = new FormControl();
  public formGroupPassword = new FormControl();
  public formGroup = new FormGroup({
    emailAddress: this.formGroupEmailAddress,
    password: this.formGroupPassword,
  })
  public FormFail = FormFail;
  private formSubs:Subscription[] = [];
  public loginCtrl:LoginCtrl

  loginErrorTroubleshootMsg;
  
  constructor(
    public lang: LangService,
    private auth: AuthService,
    private breadcrumbsService: BreadcrumbsService,
    private router:Router,
    private route:ActivatedRoute,
    private scrollService:ScrollService,
    private loginValidatorService: LoginValidationService
  ) {
 
  }
  
  ngOnInit() {
    this.loginCtrl = this.loginValidatorService.initCtrl()
    const onFormValueChange = (r) => this.isFormSent = false;
    this.getLoginAttempts();
    this.formSubs = this.formSubs.concat([
      (<FormControl>this.loginCtrl.formGroup.controls.emailAddress).valueChanges.subscribe(onFormValueChange),
      (<FormControl>this.loginCtrl.formGroup.controls.password).valueChanges.subscribe(onFormValueChange)
    ])
    if (this.prefilledEmail){
      this.formGroup.controls.emailAddress.setValue(this.prefilledEmail);
    }

    this.route.queryParams.subscribe((queryParams) => {
      if(queryParams['key'] && queryParams['key'].length > 0) {
        console.log("GOT LOGIN KEY:" + queryParams['key']);

        this.submitLoginKey(queryParams['key']);
      }
    });

  }


  ngOnDestroy() {
    this.formSubs.forEach(sub => sub.unsubscribe() );
    this.loginAttemptsSub.unsubscribe();
  }

  getMainHeader(){
    switch(this.accountType){
      case AccountType.CERT_BODY: return 'page_login_h1_cb';
      case AccountType.TEST_ADMIN: return 'page_login_h1_ta';
      case AccountType.TEST_CTRL: return 'page_login_h1_tc';
      case AccountType.TEST_TAKER: return 'page_login_h1_tt';
      default: return 'page_login_h1'
    }
  }

  getMainInstruction(){
    switch(this.accountType){
      case AccountType.CERT_BODY: return 'instr_login_cb';
      case AccountType.TEST_ADMIN: return 'instr_login_test_admin';
      case AccountType.TEST_CTRL: return 'instr_login_tc';
      case AccountType.TEST_TAKER: return 'instr_login_tt';
      default: return ''
    }
  }

  getCurrentAccountType(){
    return this.accountType || AccountType.TEST_TAKER;
  }

  getForgotPasswordRoute(){
    return `/${this.lang.c()}/${this.getCurrentAccountType()}/forgot-password`
  }
  
  getAccountCreationRoute(){
    return `/${this.lang.c()}/${this.getCurrentAccountType()}/create-account`
  }


  isInputBlank(control:FormControl){
    return control.untouched && control.hasError('isBlank');
  }

  isInputInvalid(control:FormControl){
    return control.untouched && control.hasError('isInvalid');
  }

  onApiError(e:{message:string}){
    this.formGroup.enable();
    this.isFormValidated = false;
    this.isFormFailed = true;
    if (e.message == 'NOT_VERIFIED'){
      this.formFailReason = FormFail.NOT_VERIFIED;
    }
    else if (e.message == F_ERR_MSG__INVALID_LOGIN){
      this.formFailReason = FormFail.NOT_FOUND;
    }
    else{
      this.formFailReason = FormFail.UNKNOWN;
    }

    // easier troubleshooting
    try {
      this.loginErrorTroubleshootMsg = JSON.stringify(e, null, '  ');
    }
    catch(e){
      this.loginErrorTroubleshootMsg = e.code +' '+ e.message;
    }
    // console.log('formFailReason', this.formFailReason)
  }

  private _onApiError(){
    this.formGroup.enable();
    this.isFormValidated = false;
  }




  submitLoginKey(key) {
    this.onLoginAttempt.emit()
    this.isFormSent = true;
    this.auth
        .loginWithKey(key)
        .then(success => {
          if (!success){
            this._onApiError();
            this.clearForm();
          }
        })
        .catch(e => {
          this.onApiError(e);
          this.formGroup.controls.password.reset();
          this.auth.apiCreate('public/log', {
            slug: 'FAILED_LOGIN',
            data: {
              key, // password, // todo: remove this
              errorMessage: e.message
            }
          })
        })
  }

  submitForm(){
    this.loginCtrl.submitForm();
  }
  
  getLoginAttempts(){
    this.loginAttemptsSub = this.loginCtrl.isLoginAttemptedEvent.subscribe( attempt =>{  
      if (attempt){
        this.onLoginAttempt.emit();
      }
    }

    )
  }

  clearForm(){
    this.formGroup.controls.emailAddress.reset();
    this.formGroup.controls.password.reset();
  }

}
