import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'results-summary-sr',
  templateUrl: './results-summary-sr.component.html',
  styleUrls: ['./results-summary-sr.component.scss']
})
export class ResultsSummarySrComponent implements OnInit {
  
  @Input() numCorrectSRQuestions: number;
  @Input() numSRQuestions: number;
  @Input() correctSRScore: number = 0;
  @Input() totalSRScore: number = 0;
  @Input() isExportPage: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
  }

  getNumIncorrectSRQuestions() {
    return this.numSRQuestions - this.numCorrectSRQuestions;
  }

  getSRPercentage() {
    if (this.totalSRScore === 0)
      return "-";
    
      return Math.round((this.correctSRScore / this.totalSRScore)*100);
  }

  getCorrectSRScore() {
    return Math.round(this.correctSRScore*100)/100; //2 decimal places
  }

  getStyle() {
    if(this.isExportPage) {
      return {
        'width.em': 30,
        'margin-bottom.em': 0.5
      }
    } else {
      return {};
    }
  }

}
