import {Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { WhitelabelService } from './domain/whitelabel.service';
import { Router, NavigationError, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { LoginGuardService } from './api/login-guard.service';
import { LangService } from './core/lang.service';
import { Subscription } from 'rxjs';
import { SidepanelService } from './core/sidepanel.service';
import { ChatpanelService } from './core/chatpanel.service';
import { DataGuardService } from './core/data-guard.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  subscription = new Subscription();
  isEducator:boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginGuard: LoginGuardService,
    private lang: LangService,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    public whiteLabel: WhitelabelService,
    private dataGuard: DataGuardService
  ) {
    this.setupRouterDefault();
    this.setupConnectionOverlaySubs();
  }
  ngOnInit(){
    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // )
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  title = 'app';
  isShowingDevNetFailOverlay: boolean;
  isShowingApiNetFailOverlay: boolean;
  confirmationProceedCaption: string;
  confirmationCancelCaption: string;
  currentConfirmation: {caption: string, confirm: () => any, hideCancel?: boolean, width?:string};

  getLoginReq     = () => this.loginGuard.getLoginReq();
  getDevNetFail   = () => this.loginGuard.getDevNetFail();
  getApiNetFail   = () => this.loginGuard.getApiNetFail();
  getSupportReq   = () => this.loginGuard.getSupportReq();
  getAutoLogout   = () => this.loginGuard.getAutoLogout();
  getAutoLogoutWarning   = () => this.loginGuard.getAutoLogoutWarning();
  refreshLogin = () => this.loginGuard.refreshLogin();

  setupConnectionOverlaySubs() {
    this.subscription.add(this.getDevNetFail().subscribe(v => this.isShowingDevNetFailOverlay = v ));
    this.subscription.add(this.getApiNetFail().subscribe(v => this.isShowingApiNetFailOverlay = v ));
    this.subscription.add(
      this.loginGuard.getConfirmationReq().subscribe(v => {
        this.currentConfirmation = <any> v;
        if (v) {
          if (this.router.url.indexOf('/osslt-tool-exploration') > -1 || this.router.url.indexOf('/tools') > -1) {
            this.currentConfirmation.caption = this.lang.tra('msg_finish_tool_exploration');
            this.confirmationCancelCaption = this.lang.tra('osslt_tools_message');
            this.confirmationProceedCaption = this.lang.tra(v.btnProceedCaption) || 'OK';
          } else{
            this.confirmationProceedCaption = this.lang.tra(v.btnProceedCaption) || 'OK';
            this.confirmationCancelCaption = this.lang.tra(v.btnCancelCaption) || this.lang.tra('btn_cancel');
          }
         
        }
      })
    );
  }
  isAuthActive() {
    return this.loginGuard.isAuthActive();
  }
  closeModal() {
    this.loginGuard.confirmationReqDeactivate();
  }
  confirmModal() {
    this.currentConfirmation.confirm();
    this.loginGuard.confirmationReqDeactivate();
  }
  getCurrentConfirmationCaption() {
    if (this.currentConfirmation) {
      return this.lang.tra(this.currentConfirmation.caption);
    }
  }
  showCancel() {
    if (this.currentConfirmation) {
      return this.currentConfirmation.hideCancel;
    }
  }
  setCustomWidth(){
    const defaultWidth = '30em';
    if(this.currentConfirmation && this.currentConfirmation.width){
      console.log(this.currentConfirmation)
      return this.currentConfirmation.width
    }
    return defaultWidth;
  }

  dismissSupportPopup() {
    this.loginGuard.supportReqDeactivate();
  }

  gotoHomeScreen() {
    this.router.navigate([
      this.whiteLabel.getHomepageRoute()
    ]);
  }

  setupRouterDefault() {
    // this.route.params.subscribe(params => {
    //   console.log(params, params)
    // });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && event.url) {
        this.lang.updateCurrentUrl(event.url);
        const routePath = event.url.split('/');
        this.lang.setCurrentLanguage(routePath[1]);
      }
      if (event instanceof NavigationError) {
        console.warn('NavigationError', event);
        setTimeout(() => {
          this.gotoHomeScreen();
        }, 100);
      }
    });
  }
  isSidePanelExpanded(){
    return this.sidePanel.getExpandedStatus();
  }
  isSidePanelVisible(){
    return this.sidePanel.getVisibilityStatus() && this.sidePanel.isAccountLoaded;
  }
  isMobileLeftPanel(){
    return this.sidePanel.getMobileVisibilityStatus();
  }
  openMobileLeftPanel(){
    this.sidePanel.activateMobile();
  }
  closeMobileLeftPanel(){
    this.sidePanel.deactivateMobile();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: BeforeUnloadEvent) {
    if (this.dataGuard.isActive()) {
      $event.returnValue = true;
    }
  }

}
