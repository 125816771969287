import { Component, OnInit, Input } from '@angular/core';
import { IContentElementColorPanel } from '../../ui-testrunner/models/index';

@Component({
  selector: 'color-panel',
  templateUrl: './color-panel.component.html',
  styleUrls: ['./color-panel.component.scss']
})
export class ColorPanel implements OnInit {

    @Input() element?:IContentElementColorPanel;

    width;
    maxLength;

    constructor() {
        this.width = 5;
        this.maxLength = 7;
    }

    ngOnInit() {
        if (!this.element) this.element = {backgroundColor: "#FFFFFF", textColor: "#000000"};
        if (!this.element.backgroundColor) this.element.backgroundColor="#FFFFFF";
        if (!this.element.textColor) this.element.textColor="#000000";
    }
}  