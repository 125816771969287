import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { IContentElement, QuestionState, IEntryStateCamera, ScoringTypes, getElementWeight } from '../models';
import { AuthService } from '../../api/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';



const SCORING_TYPE = ScoringTypes.MANUAL;
@Component({
  selector: 'element-render-camera',
  templateUrl: './element-render-camera.component.html',
  styleUrls: ['./element-render-camera.component.scss']
})
export class ElementRenderCameraComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('video') videoEl:ElementRef;
  @ViewChild('canvas') canvasEl:ElementRef;

  @Input() element:IContentElement;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  @Input() questionPubSub?: QuestionPubSub;

  constructor(
    private auth: AuthService,
    private sanitizer: DomSanitizer
  ) { }

  isVideoOpen:boolean;
  isSnapTaken:boolean;
  lastTrackedQuestionState;

  ngOnInit() {
    this.ensureState();
  }


  ngOnDestroy(){

  }

  ngAfterViewInit(){

  }

  ensureState(){
    if (this.questionState){
      const entryId = this.element.entryId;
      if (!this.questionState[entryId]){
        let entryState:IEntryStateCamera = {
          type: 'camera',
          isCorrect: false,
          isStarted: false,
          isFilled: false,
          score: 0,
          weight: getElementWeight(this.element),
          scoring_type: SCORING_TYPE, 
        }
        this.questionState[entryId] = entryState;
      }
      else{
        if(this.questionState[entryId].url){          
          this.isSnapTaken = true;
          this.isVideoOpen = false;     
          let image = new Image();
          image.onload=()=>{
            let context = this.canvasEl.nativeElement.getContext('2d');
            context.drawImage(image,0, 0, 640, 480);
          };
          image.src= this.questionState[entryId].url;
        }
      }
    }
  }

  photoStart(){
    this.isVideoOpen = true;
    this.isSnapTaken = false;
    let video = this.videoEl.nativeElement;
    if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function(stream) {
          video.srcObject = stream;
          video.play();
        });
    }
  }

  photoSnap(){
    var video = this.videoEl.nativeElement;
    var context = this.canvasEl.nativeElement.getContext('2d');
    context.drawImage(video, 0, 0, 640, 480);
    this.isSnapTaken = true;
    this.isVideoOpen = false;   
    
    let img = this.canvasEl.nativeElement.toDataURL("image/png");
    let binary = atob(img.split(',')[1]);
    let array = []
    let i = 0
    while (i < binary.length){
      array.push(binary.charCodeAt(i))
      i++
    }
    let img_blob = new Blob([ new Uint8Array(array) ], {type: 'image/png'})

    this.auth
    .uploadFile(img_blob, 'camera_capture_' + Date.now()+'.png', 'authoring', true)
    .then(res => {
      if (this.questionState){
        const entryState:IEntryStateCamera = this.questionState[this.element.entryId];
        if (entryState){
          entryState.url = res.url;
          entryState.isFilled = true;
          entryState.isStarted = true;
        }
      }
    })
  }

}
