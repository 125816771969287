import { API_ADDRESS_LOCAL } from "../../api/api-addr/l";
import { IContextData } from "./common";

const footer = [
  [
    {"route":"/contact", "caption": "title_contactus"},
    // {"route":"/news", "caption": "title_news"},
  ],
  [
    {"route":"/privacy", "caption": "title_privacy"},
  ],
  [
    {"route":"/legal", "caption": "title_legal"}
  ],
]

const apiAddress = (hostname:string) => {
  switch(hostname){
    case 'localhost':
      return 'https://eqao-api.vretta.com';
      return API_ADDRESS_LOCAL;
    // return 'https://apiqc.mathproficiencytest.ca'
      // return 'https://api-eassessment.vretta.com';
    case 'eqao-xqc.vretta.com':
      return 'https://api-eqao-xqc.vretta.com';
    case 'eqao-main-qc.vretta.com':
      return 'https://apiqc.mathproficiencytest.ca';
    case 'eqao.vretta.com':
    case 'private.eqao.vretta.com':
    case 'eqao-lit.vretta.com':
      return 'https://eqao-api.vretta.com';
    case 'd24a8vriqswfok.cloudfront.net':
      return 'https://apiqc.mathproficiencytest.ca';
    case 'd1w433s63hot3w.cloudfront.net':
    case 'd2r9nuffi6xllr.cloudfront.net':
    case 'qc1.mathproficiencytest.ca':
      // return 'https://eqao-api.vretta.com';
      return 'https://apiqc.mathproficiencytest.ca'
    case 'qc4.mathproficiencytest.ca':
    case 'drkpaddt3nk24.cloudfront.net':
      return 'https://apiqc.mathproficiencytest.ca';
    default:
      return 'https://api.mathproficiencytest.ca';
  }
}
  
export const EQAO:IContextData = {
    id: 'EQAO',
    homepageRoute: '/en/login-router-st',
    brandName: 'EQAO E-Assessment',
    defaultTimezone: 'America/Toronto',
    logo: 'eqao_context_logo',
    footer: null,
    apiAddress,
    siteFlags: {
      BREADCRUMB_MENU: false,
      BOOKING_POLICY_STATS: true,
      IS_EQAO: true,
      EQAO_LOGIN: true,
      'INST_MNG_TT': true,
      'TEST_RUNNER_WIDTH_CONSTRAINT': true
    },
    siteText: {
        copyright: 'title_copyright',
        supportEmail: 'support_email',
        supportPhone: '1-888-887-3882',
        login_admins: 'teacher_administrator',
        my_inst: 'My School',
        my_inst_admin: 'You are the **administrator** of this school.',
        my_inst_manage_acct: 'Manage Teachers+',
        student_ident: 'oen',
        asmt_logo_en: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/EQAO_Logo_SVG/1609173725758/EQAO_Logo_SVG.svg',
        asmt_logo_fr: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/OQRE_Logo_SVG/1609173780871/OQRE_Logo_SVG.svg',
        header_logo_en: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/g18/1602729003686/g18.png',
        header_logo_fr: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/OQRE_Logo_White_sml%201/1602729029336/OQRE_Logo_White_sml%201.png',
        marking_chat:'Scoring Chat',
        marking:'scoring',
        mark:'score',
        marker:'scorer',
        markers:'scorers',
        marks:'scores',
        marked:'scored',
        reliability:'validity',
        exemplar:'validity response',
        exemplars:'validity responses',
        TnR:'T&V'
    },
    siteData: {
      'SCORING_SUPPORT': {
        text: 'lbl_technical_support_scor',
        email: 'support_email',
        phone: '1-888-887-3882',
      }
    },
    hosts: [
        'localhost:4200',
        'd12o445rx6gi3b.cloudfront.net',
        'd1w433s63hot3w.cloudfront.net',
        'd24a8vriqswfok.cloudfront.net',
        'eqao.vretta.com',
        'eqao-lit.vretta.com',
        'eqao-lit-qc1.vretta.com',
        'eqao-xqc.vretta.com',
        'qc4.mathproficiencytest.ca',
        'd1c1qqn86e6v14.cloudfront.net',
    ]
}
