import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {
  ElementType,
  getElementWeight,
  GroupingArrangementType,
  IContentElementDndDraggable,
  IContentElementDndSub,
  IContentElementGroup,
  IContentElementGroupTarget,
  IEntryStateGroup,
  QuestionState,
  ScoringTypes
} from '../models';
import {
  IElementPos,
  renderDndElementStyle
} from '../../ui-item-maker/element-config-grouping/element-config-grouping.component';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {Subject} from 'rxjs';
// import {IContentElementGroup} from '../../ui-item-maker/element-config-grouping/element-config-grouping.component';
import {QuestionPubSub} from '../question-runner/pubsub/question-pubsub';
import {TextToSpeechService} from "../text-to-speech.service";


const SCORING_TYPE = ScoringTypes.AUTO;
@Component({
  selector: 'element-render-grouping',
  templateUrl: './element-render-grouping.component.html',
  styleUrls: ['./element-render-grouping.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ElementRenderGroupingComponent implements OnInit, OnChanges {
  
  @Input() element:IContentElementGroup;
  @Input() isLocked: boolean;
  @Input() questionState: QuestionState;
  @Input() changeCounter: number;
  @Input() questionPubSub?: QuestionPubSub;

  draggables: IElementPos[] = [];
  targets: {targetContext: IContentElementGroupTarget, contents:IElementPos[]}[] = [];
  initialNumOptions;
  isDragging:boolean;
  dragTriggers:Map<IElementPos, Subject<boolean>> = new Map();
  dropTriggers:Map<IContentElementGroupTarget, Subject<boolean>> = new Map();

  constructor(
      public textToSpeech:TextToSpeechService,
  ) { }

  ngOnInit(): void {
    if (!this.element.targetColourScheme) {
      this.element.targetColourScheme = ({backgroundColor: "#FFFFFF", textColor: "#000000"});
    }
    if (!this.element.draggableColourScheme) {
      this.element.draggableColourScheme = ({backgroundColor: "#FFFFFF", textColor: "#000000"});
    }
    //console.log(this.element.customTargetDim);
    this.ensureState();
    this.loadState();
  }

  isVoiceoverEnabled() {
    return this.textToSpeech.isActive;
  }
  
  dragStarted(element:IElementPos) {
    const voiceover = element.ref.voiceover; 
    if (voiceover && voiceover.url) {
      this.getDragTrigger(element).next(true);  
    }
  }

  /*getElementAudio(element: IElementPos): string {
    return (element.ref && element.ref.voiceover) ? element.ref.voiceover.url : '';
  }*/

  getElementAudio(voiceover: any): string {
    return voiceover ? voiceover.url : '';
  }
  
  getDragTrigger(element: IElementPos){
    let trigger = this.dragTriggers.get(element);
    if (!trigger){
      trigger = new Subject();
      this.dragTriggers.set(element, trigger);
    }
    return trigger;
  }

  getDropTrigger(targetContext: IContentElementGroupTarget){
    let trigger = this.dropTriggers.get(targetContext);
    if (!trigger){
      trigger = new Subject();
      this.dropTriggers.set(targetContext, trigger);
    }
    return trigger;
  }  
  
  ensureState(){
    if (this.questionState) {
      if (!this.questionState[this.element.entryId]) {
        let entryState: IEntryStateGroup = {
          type: ElementType.GROUPING,
          isCorrect: false,
          isStarted: false,
          isFilled: false,
          score: 0, //this.targets.find((target:any) => target.groups.length) ? getElementWeight(this.element) : 0,
          weight: getElementWeight(this.element),
          scoring_type: SCORING_TYPE,
          draggables: this.draggables,
          targets: this.targets
        };
        //console.log("initializing state")
        this.questionState[this.element.entryId] = entryState;
      }
      
    }
  }

  loadState(){
    if (this.questionState) {
      if (this.questionState[this.element.entryId]) {
        //console.log(this.questionState[this.element.entryId])
        this.draggables = this.questionState[this.element.entryId].draggables;
        this.targets = this.questionState[this.element.entryId].targets;
      }
    }
  }

  getDraggableStyle(dragEl:any, includePosition:boolean=false){
    const style:any = {
      color: this.element.draggableColourScheme.textColor,
      backgroundColor: this.element.draggableColourScheme.backgroundColor,
    };
    if (includePosition){ 
      style.position = this.getPosition();
      style['left.em'] = this.getLeft(dragEl.ref)
      style['top.em'] = this.getTop(dragEl.ref)
    }
    if (this.element.draggableWidth) { style['width.em'] = this.element.draggableWidth; }
    if (this.element.draggableHeight) { style['height.em'] = this.element.draggableHeight; }
    return style;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.changeCounter){
      //console.log("in ngonchanges")
      this.updateDisplayEls();
      //this.updateState();
    }
  }

  updateDisplayEls() {
    this.draggables = [];
    this.targets = [];

    this.targets = this.element.targets.map(target => {
      return {
        targetContext: target,
        contents: [],
      }
    });
    this.element.draggables.forEach(element => this.addElementToList(element, this.draggables));
    this.initialNumOptions = this.draggables.length;
    // console.log('this.targets', this.targets)
  }

  addElementToList(element: IContentElementDndSub, elementsToPosition: IElementPos[], isTarget: boolean= false) {
    let hasElement  = false;
    if ((<IContentElementDndDraggable> element).element) {
      hasElement = true;
    }
    elementsToPosition.push({
      ref: element,
      originalX: element.x,
      originalY: element.y,
      isTarget,
      style: renderDndElementStyle(element, hasElement, isTarget && this.element.customTargetDim, this.element.defaultTargetStyle),
    });
    return
  }

  updateState(){
    const weight = getElementWeight(this.element);
    let score = 0; //this.initialNumOptions;
    let numMatched = 0;
    let isAnyFilled = false;
    let isAllFilled = true;
    let numFilled = 0;
    let numPlaced = 0;
    let isCorrect = false;
    const numDraggables = this.element.draggables.length;
    // are any of the targets filled? are they all filled?
    this.targets.forEach(target=>{
      numPlaced += target.contents.length;
      if (target.contents.length > 0){
        isAnyFilled = true;
        numFilled ++;
      }
    })
    if (numPlaced < numDraggables){
      isAllFilled = false;
    }
    // identify all placements into known targets (map to target's ID)
    const knownDraggablePlacements = new Map();
    this.targets.forEach(target=>{
      const targetId = target.targetContext.id;
      target.contents.forEach(draggableEl => {
        const draggableRef = draggableEl.ref;
        knownDraggablePlacements.set(draggableRef, targetId)
      })
    })
    // count up the elements that are in the correct position (assumes that all elements should have a target position, this can be updated later to allow for draggables that do not have an intended target)
    this.element.draggables.forEach(draggableRef => {
      if (draggableRef.id === knownDraggablePlacements.get(draggableRef)){
        numMatched ++;
      }
    })
    numMatched = Math.min(numMatched, numDraggables);


    // finalize score
    isCorrect = (numMatched === numDraggables);
    if (this.element.enableProportionalScoring) {
      score = weight * numMatched/numDraggables;
    }
    else{
      score = isCorrect ? weight : 0;
    }

    // console.log({
    //   prop: this.element.enableProportionalScoring,
    //   score,
    //   isCorrect,
    //   numMatched,
    //   numFilled,
    //   numDraggables,
    // })

    // conclude
    const isStarted = isAnyFilled;
    const isFilled = isAllFilled;

    let entryState: IEntryStateGroup = {
      type: ElementType.GROUPING,
      isCorrect,
      isStarted,
      isFilled: isFilled,
      score, //this.targets.find((target:any) => target.groups.length) ? getElementWeight(this.element) : 0,
      weight,
      scoring_type: SCORING_TYPE,
      draggables: this.draggables,
      targets: this.targets
    };
    if (this.questionState){
      this.questionState[this.element.entryId] = entryState;
    }
  }

  getTargetColor(target){
    if (target.targetContext.bgColor){
      return target.targetContext.bgColor
    }
    else {
      const targetColourScheme = this.element.targetColourScheme
      if (targetColourScheme && targetColourScheme.backgroundColor){
        return targetColourScheme.backgroundColor;
      }
    }
  }

  drop(event: CdkDragDrop<string[]>, targetContext: IContentElementGroupTarget, isHomeDest:boolean=false) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } 
    else {
      let allowTransfer;
      if (this.element.isMatchingMode){
        allowTransfer = false;
        if (isHomeDest){
          allowTransfer = true;
        }
        else if (event.container.data.length === 0) { 
          allowTransfer = true;
        }
      }
      else{
        allowTransfer = true;
      }

      if (allowTransfer){
        transferArrayItem(event.previousContainer.data,
                          event.container.data,
                          event.previousIndex,
                          event.currentIndex);
      }
    }
    
    if (targetContext) {
      const voiceover = targetContext.voiceover;
      if (voiceover && voiceover.url) {
        this.getDropTrigger(targetContext).next(true);
      }
    }
    
    this.updateState();
    // this.rearrangeByOrder();
    this.removeSelection();
  }

  removeSelection() {
    if (window.getSelection()) {
      window.getSelection().removeAllRanges()
    } else if (document.getSelection()) {
      document.getSelection().removeAllRanges();
    }
  }

  // rearrangeByOrder() {
  //   const thisOpts = this.draggables;
  //   const configOpts = this.element.draggables;
  //   const newThisOpts = []
  //   configOpts.forEach((opt)=>{
  //     thisOpts.forEach((currOpt)=>{
  //       if (currOpt.ref == opt) {
  //         newThisOpts.push(currOpt)
  //       }
  //     })
  //   })
  //   this.draggables = newThisOpts;
  // }

  getTargetMargins() {
    if (this.element.arrangementType == GroupingArrangementType.NORMAL) {
      return "0 25px 25px 0";
    } else {
      return "0 0 0 0"
    }
  }

  getPosition() {
    if (this.element.arrangementType != GroupingArrangementType.NORMAL) {
      return "relative"
    } else {
      return "static"
    }
  }

  getLeft(el) {
    if (this.element.arrangementType == GroupingArrangementType.MANUAL) {
      console.log(el.x)
      return el.x;
    }
    return 0;
  }

  getTop(el) {
    if (this.element.arrangementType == GroupingArrangementType.MANUAL) return el.y;
    return 0;
  }

  getFlexFlowForGroup() {
    if (this.element.arrangementType == GroupingArrangementType.SIDE_BY_SIDE) {
      return "row wrap";
    }
  }

  isSideBySide() {
    return this.element.arrangementType==GroupingArrangementType.SIDE_BY_SIDE;
  }

  getStyleForTarget(el:IContentElementGroupTarget) {
    const style:any = {};
    if (el.backgroundImg && el.backgroundImg.url) {
      style["background-image"]="url(\'"+el.backgroundImg.url+"\')"
      style["background-size"]="100% 100%"
    }
    return style;
  }

  getStyleForGroupContainer() {
    const style:any = {}
    if (this.element.arrangementType == GroupingArrangementType.SIDE_BY_SIDE) {
      style["display"]="flex"
      style["wrap"]="nowrap"
    }
    return style;
  }
}
