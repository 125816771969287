import { Component, OnInit, Input } from '@angular/core';
import { IContentElementText, TextParagraphStyle, QuestionState, TextParagraph } from '../models';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { WhitelabelService } from '../../domain/whitelabel.service';
@Component({
  selector: 'element-render-text',
  templateUrl: './element-render-text.component.html',
  styleUrls: ['./element-render-text.component.scss']
})
export class ElementRenderTextComponent implements OnInit {

  @Input() element:IContentElementText;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() questionPubSub?: QuestionPubSub;

  constructor(
    private whitelabel: WhitelabelService,
  ) { }


  ngOnInit() {

  }

  isParagraphs(){
    return this.element.paragraphStyle === TextParagraphStyle.PARAGRAPHS;
  }
  isHeading(){
    return this.element.paragraphStyle === TextParagraphStyle.HEADLINE;
  }
  isHeadingSmall(){
    return this.element.paragraphStyle === TextParagraphStyle.HEADLINE_SMALL;
  }
  isBody(){
    return this.element.paragraphStyle === TextParagraphStyle.REGULAR;
  }
  isSmall(){
    return this.element.paragraphStyle === TextParagraphStyle.SMALL;
  }
  isBulletList(){
    return this.element.paragraphStyle === TextParagraphStyle.BULLET;
  }
  isNumberedList(){
    return this.element.paragraphStyle === TextParagraphStyle.NUMBERED;
  }

  isSimpleText(){
    if (this.element.paragraphStyle === TextParagraphStyle.HEADLINE){ return true }
    if (this.element.paragraphStyle === TextParagraphStyle.HEADLINE_SMALL){ return true }
    if (this.element.paragraphStyle === TextParagraphStyle.REGULAR){ return true }
    if (this.element.paragraphStyle === TextParagraphStyle.SMALL){ return true }
    return false;
  }

  isLink(){
    return this.element.paragraphStyle === TextParagraphStyle.LINK;
  }

  isListText(){
    if (this.element.paragraphStyle === TextParagraphStyle.BULLET){ return true }
    if (this.element.paragraphStyle === TextParagraphStyle.NUMBERED){ return true }
    return false;
  }

  isAdvancedInlineText(){
    if (this.element.paragraphStyle === TextParagraphStyle.ADVANCED_INLINE){ return true }
    return false;
  }

  isAnnotation() {
    return this.element.paragraphStyle === TextParagraphStyle.ANNOTATION;
  }

  getFontFamily() {
    if (!this.whitelabel.getSiteFlag('IS_EQAO')){
      return this.element.font;
    }
    return 'inherit';
  }

  

  getRotation() {
    if (this.element.rotation) return "rotate("+this.element.rotation+"deg)";
    return '';
  }

  getFontSize(){
    if (this.element.fontSize){
      return `${this.element.fontSize}em`;
    }
    return 'inherit';
  }

  getLineHeight() {
    if (this.element.lineHeight){
      return `${this.element.lineHeight}em`;
    }
    return 'inherit';
    // return '1.6em';
  }

  renderParaNum(index:number, paragraph:TextParagraph){
    if (paragraph.numberingOverride){
      return paragraph.numberingOverride;
    }
    let start = this.element.paragraphNumberingStart || 1;
    return (+start) + index;
  }

  renderParaNumPadding(){
    return (1-(this.element.paragraphNumberingScale || 1)) / 4;
  }

  getTextStyle() {
    const style:any = {}
    style["font-family"]=this.getFontFamily();
    style["text-align"]=this.element.alignment;
    style["font-size"]=this.getFontSize();
    style["transform"]=this.getRotation();
    style["color"]= this.element.colour;
    if (!this.isAdvancedInlineText()){
      style["line-height"]= this.getLineHeight()
    }
    // style["user-select"]='none';
    return style;
  }

  minWidth = 10;
  getWidth() {
    if(this.isParagraphs()){
      return this.element.paragraphWidth
    }
    if (this.element.advancedInlineWidth && this.element.advancedInlineWidth >= this.minWidth) {
      return this.element.advancedInlineWidth;
    }
    return 'initial';
  }
}