import { Component, Input, OnInit } from '@angular/core';
import { LangService } from 'src/app/core/lang.service';

@Component({
  selector: 'results-summary-cr',
  templateUrl: './results-summary-cr.component.html',
  styleUrls: ['./results-summary-cr.component.scss']
})
export class ResultsSummaryCrComponent implements OnInit {

  @Input() totalCRScore: number;
  @Input() numCRQuestions: number;
  @Input() isExportPage: boolean = false;
  
  constructor(public lang: LangService) { }

  ngOnInit(): void {
  }

  getStyle() {
    if(this.isExportPage) {
      return {
        'width.em': 30
      }
    } else {
      return {};
    }
  }

}
