<div
    class="mouse-over"
    [class.is-perm-tooltip]="element.isPermanentTooltip"
>
    <div [style.font-size.em]="element.buttonScale">
        <render-audio 
            #renderAudio
            [audioID]="'audio'+element.entryId"
            (play)="play()" 
            (onPlay)="onPlay()" 
            (end)="playEnd()"
            [hasControls]="hasControls()" 
            [startTime]="getStartTime()"
            [preventOverload]="true"
            [trigger]="trigger" 
            [isDisabled]="!isEnabled()" 
            *ngIf="element" 
            (timeUpdate)="onTimeUpdate()"
            [url]="element.url"
            (canStartPlaying)="canStartPlaying()"
        ></render-audio>
    </div>
    <div 
        *ngIf="!element.isHidden" 
        class="on-mouse-over" 
        [class.is-align-top]="element.toolTipPosition==ToolTipPositions.TOP || !element.toolTipPosition"
        [class.is-align-bottom]="element.toolTipPosition==ToolTipPositions.BOTTOM"
        [class.is-align-right]="element.toolTipPosition==ToolTipPositions.RIGHT"
        [class.is-align-left]="element.toolTipPosition==ToolTipPositions.LEFT"
    >
        <div class="on-mouse-over-offset" >
            <div>
                <div style="background-color: #fff; padding:0.5em; padding-bottom:0em; " *ngIf="resetter && element.numAudioPlays && element.isLimitAudioPlays && !element.isHidden">
                    <tra-md [isCondensed]="true" slug="lbl_num_plays_remaining" [props]="{NUM_REM_PLAYS: element.numAudioPlays-getNumViews()}"></tra-md>
                </div>
                <div  
                    style="font-size: 0.94em; padding: 0.5em;     white-space: nowrap; background-color: #f1f3f0;"
                > 
                    {{getSecRemainingStr()}}
                </div>
            </div>
        </div>
    </div>
</div>