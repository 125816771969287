import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import {UrlLoaderService} from '../url-loader.service';
import {AudioBufferService, VoiceSound} from '../audio-buffer.service';
import {Subject, Subscription} from 'rxjs';
import { memo } from '../element-render-video/element-render-video.component';
import { WhitelabelService } from '../../domain/whitelabel.service';

@Component({
  selector: 'render-audio',
  templateUrl: './render-audio.component.html',
  styleUrls: ['./render-audio.component.scss']
})
export class RenderAudioComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() url:string;
  @Input() audioID?:string;
  @Input() preventOverload:boolean = false;
  @Input() isDisabled:boolean = false;
  @Input() hasControls:boolean = false;
  @Input() trigger:Subject<boolean>;
  @Input() isTriggerDisabled:boolean;
  @Input() startTime:number;
  @Input() forceNativePlayer:boolean;
  @Output() play = new EventEmitter();
  @Output() onPlay = new EventEmitter();
  @Output() progress = new EventEmitter();
  @Output() end = new EventEmitter();
  @Output() timeUpdate = new EventEmitter();
  @Output() canStartPlaying = new EventEmitter();

  @ViewChild('audioPlayer') audioPlayer: ElementRef

  sound:VoiceSound;
  audioSources = new Map();
  public isPlaying: boolean = false;
  
  __componentId = Math.random();

  constructor(
    private urlLoader: UrlLoaderService,
    private audioBuffer: AudioBufferService,
    private whiteLabel: WhitelabelService
  ) { }

  ngOnInit(): void { }

  triggerSub:Subscription;
  ngAfterViewInit(){
    // if (!this.controlsVisible()){
      if (this.triggerSub){
        this.triggerSub.unsubscribe();
      }
      if (this.trigger){
        this.triggerSub = this.trigger.subscribe(this.onTriggerPlay)
      }
      if (this.sound){
        this.sound.destroy();
      }

      this.sound = new VoiceSound(this.audioPlayer.nativeElement, this.audioBuffer);
      this.sound.getElement().addEventListener('pause', this.donePlaying);
      //this.sound.getElement().addEventListener('ended', this.onEnded);
    // }
  }
  ngOnDestroy() {
    if (this.triggerSub){
      this.triggerSub.unsubscribe();
    }
    if (this.sound){
      this.sound.removeFromBuffer()
      //this.sound.getElement().removeEventListener('ended', this.onEnded);
    }
    if (this.audioBuffer.activeSound === this.sound){
      this.audioBuffer.activeSound = null;
    }
  }
  onEnded = () => this.end.emit();

  onTriggerPlay = (isActive:boolean) => {
    if (this.preventOverload){
      if (this.audioBuffer.activeSound){
        return
      }
    }
    if (!this.sound){
      return;
    }
    if (this.isDisabled){
      return;
    }
    if (isActive && !this.isTriggerDisabled){
      this.onPlay.emit();
      this.sound.play();
      this.isPlaying = true;
    }
  }

  emitPlay() {
    // console.log("in emit play", this.__componentId)
    this.play.emit('')
    this.isPlaying = true;
  }

  emitCanStartPlaying() {
    this.canStartPlaying.emit('')
  }

  emitEnd() {
    this.end.emit('')
  }

  donePlaying = () => {
    // console.log('end')
    this.isPlaying = false;
    if (this.emitEnd) this.emitEnd();
  }

  sanitizeUrl(url:string){
    return this.urlLoader.sanitize(this.url);
  }

  getAudioSources(){
    // setTimeout(()=>{
    //   console.log(this.audioSources);
    // }, 500)
    return memo(this.audioSources, this.url, url => {
      const sanitized = this.urlLoader.sanitize(url)
      console.log(url);
      return [{ src: url, type: 'audio/mp3', }]
    });
  }

  controlsVisible() {
    return this.hasControls && !this.isOneButton();
  }

  isOneButton() {
    return !this.forceNativePlayer && this.whiteLabel.getSiteFlag('IS_BCED');
  }

  onTimeUpdate() {
    this.timeUpdate.emit('');
  }
}
