<table class="results-table" [ngStyle]="getStyle()">
    <tr>
        <th colspan="3"><tra slug="tr_results_cr_questions"></tra></th>
    </tr>
    <tr>
        <td>
            <tra slug="tr_results_score"></tra>
        </td>
        <td>
        </td>
        <td>
        </td>
    </tr>
    <tr>
        <td>
            <tra slug="tr_results_cr_questions"></tra><tra slug="txt_colon"></tra>
        </td>
        <td>
            {{numCRQuestions}}
        </td>
        <td>
        </td>
    </tr>
</table>