import * as _ from 'lodash';
import { Component, OnInit, Input, Output } from '@angular/core';
import { IContentElementInsertion, IContentElementDndSub, IContentElementDndDraggable, IContentElementText} from '../../ui-testrunner/models';
import { CdkDragRelease, CdkDragStart, CdkDragEnd, moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { generateDefaultElementMath, generateDefaultElementText } from '../item-set-editor/models/';
import { indexOf } from '../services/util';

export const renderDndElementStyle = (element: IContentElementDndSub, hasContent: boolean= false, isCustomDim: boolean= false, defaultTargetStyle: IContentElementDndSub= null) => {
    const style: {[key: string]: string | number} = {
      'transform': `translate3d(${element.x}px, ${element.y}px, 0px)`,
    };
    if (!hasContent) { // using as a proxy for targets right now...
      const dims = ['width', 'height'];
      dims.forEach(dim => {
        let val;
        if (isCustomDim) {
          val = element[dim];
        }
        if (!val && defaultTargetStyle) {
          val = defaultTargetStyle[dim];
        }
        if (!val) {
          val = 30;
        }
        style[dim + '.px'] = val;
      });
    }
    return style;
};

export interface IElementPos {
    ref: IContentElementDndSub;
    originalX: number;
    originalY: number;
    isTarget?: boolean;
    isDraggable?: boolean;
    style: {
      [key: string]: string | number
    };
}

@Component({
  selector: 'element-config-insertion',
  templateUrl: './element-config-insertion.component.html',
  styleUrls: ['./element-config-insertion.component.scss']
})
export class ElementConfigInsertionComponent implements OnInit {

    @Input() element:IContentElementInsertion;

    selectedPos;
    elementsToPosition: IElementPos[] = [];

    ngOnInit() {
        if (!this.element.textBlocks) this.element.textBlocks= [];
        if (!this.element.draggables) this.element.draggables = [];
        if (!this.element.draggableCounter) { this.element.draggableCounter = 0; }
        if (!this.element.textBlocksCounter) { this.element.textBlocksCounter = 0; }
        if (!this.element.scoreWeight) this.element.scoreWeight = 1;
        if (this.element.isShowInstructions == undefined) this.element.isShowInstructions = true;
        if (this.element.isTargetsAbove == undefined) this.element.isTargetsAbove = false;
        if (this.element.isRepeatableOptions == undefined) this.element.isRepeatableOptions = false;

        this.element.draggables.forEach((drag)=>{
          if (!drag.voiceover) drag.voiceover = {};
        })
    }

    drop(draggables, event) {
        moveItemInArray(draggables, event.previousIndex, event.currentIndex);
        this.updateDisplayEls();
    }

    insertDraggableElement() {
        this.element.draggableCounter = this.incrementCounter(this.element.draggableCounter, this.element.draggables);
        const newEl = this.newDefaultDndElementConfig(false, "", this.element.draggableCounter);
        newEl.voiceover = {};
        this.element.draggables.push(newEl);
        //this.recordLastTouch(newEl);
        this.updateDisplayEls();
    }

    insertTextBlock() {
        const newEl = this.newDefaultDndElementConfig(false, "", undefined);
        this.element.textBlocks.push(newEl);
        //this.recordLastTouch(newEl);
        this.updateDisplayEls();
    }

    insertBlank() {
        this.element.textBlocksCounter = this.incrementCounter(this.element.textBlocksCounter, this.element.textBlocks);
        const newEl = this.newDefaultDndElementConfig(true, "", this.element.textBlocksCounter)
        this.element.textBlocks.push(newEl);
        //this.recordLastTouch(newEl);
        this.updateDisplayEls();
    }

    newDefaultDndElementConfig (isBlank: boolean, caption: string, id:number): IContentElementDndDraggable {
        let el;
        if (!isBlank) {
          el = generateDefaultElementText("text");
          el.isAdvancedOptionsDisabled = true;
          el.caption = caption;
        } else {
          el = {elementType: ""};
        }
        return  {
            id: id,
            element: el,
            x: 0,
            y: 0,
        };
    }

    incrementCounter(counter: number, subs: IContentElementDndSub[]) {
        let isFirst = true;
        let isMatchFound = false;
        while (isFirst || isMatchFound) {
          counter ++;
          isFirst = false;
          isMatchFound = false;
          subs.forEach(sub => {
            if ( ('' + sub.id) === ('' + counter) ) {
              isMatchFound = true;
            }
          });
        }
        return counter;
      }

    isSelectedPosRef(el:IContentElementDndSub){
        if (this.selectedPos){
            return (el === this.selectedPos.ref);
        }
    }
        
    selectPosElByRef(el:IContentElementDndSub){
        this.elementsToPosition.forEach(pos =>{
            if (pos.ref === el){
            this.selectedPos = pos;
            }
        })
    }

    removeElement(arr: any[], t: any) {
        if (window.confirm('Remove this option?')) {
          let i = indexOf(arr, t);
          if (i !== -1) {
            arr.splice(i, 1);
          }
        }
        this.updateDisplayEls();
    }

    updateDisplayEls() {
        this.elementsToPosition = [];
        this.element.textBlocks.forEach(element => this.addElementToList(element, this.elementsToPosition, true));
        this.element.draggables.forEach(element => this.addElementToList(element, this.elementsToPosition, false, true));
        this.updateChangeCounter();
    }
    
    addElementToList(element: IContentElementDndSub, elementsToPosition: IElementPos[], isTarget: boolean= false, isDraggable: boolean= false) {
        let hasElement = false;
        if ((<IContentElementDndDraggable> element).element) {
          hasElement = true;
        }
        elementsToPosition.push({
          ref: element,
          originalX: element.x,
          originalY: element.y,
          isTarget,
          isDraggable,
          //style: renderDndElementStyle(element, hasElement, isTarget && this.element.customTargetDim, this.element.defaultTargetStyle),
          style: {}
        });
    }

    onInnerTextChange(){
      this.updateChangeCounter();
    }

    updateChangeCounter() {
      if (!this.element._changeCounter) {
        this.element._changeCounter = 0;
      }
      this.element._changeCounter ++;
    }

    throttledUpdate = _.throttle(() => {
        this.updateDisplayEls();
    }, 500);
}