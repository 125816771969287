import {ElementRef, Injectable} from '@angular/core';
import {chatDataByUid, genCoordinatorData, genLeaderData} from './data/chatData/chatData';
import {AuthService} from '../api/auth.service';
import Sockette from "sockette";
import {DATETIME} from '../api/models/db-types';
import * as moment from 'moment-timezone';

// Lambda/Dynamo specifics.
enum ChatEventType {
  CHAT_DATA = 'CHAT_DATA',
  ACTIVE_UPDATE = 'ACTIVE_UPDATE',
  NEW_MESSAGE = 'NEW_MESSAGE',
  MESSAGE_SENT = 'MESSAGE_SENT',
}
enum ChatEndpoint {
  USER_CONNECT = 'userConnect',
  SEND_MESSAGE = 'sendMessage',
  SEND_BROADCAST = 'sendBroadcast',
  CLEAR_UNREAD = 'clearUnread'
}

interface ILambdaQuery {
  Count: number;
  ScannedCount: number;
  Items: any[];
}
interface ILambdaChatData {
  eventType: ChatEventType;
  userChats: ILambdaQuery;
  userData: ILambdaQuery[];
}

// public/UI pieces
export enum ChatRowType {
  BROADCAST = 'BROADCAST',
  PERSON = 'PERSON',
  GROUP = 'GROUP',
}

export enum PersonType {
  MARKER = 'Marker',
  LEADER = 'Leader',
  COORDINATOR = 'Coordinator',
}

export enum GroupType {
  LEADERS = 'LEADERS',
  MARKERS = 'Markers',
  ALL = 'BROADCAST'
}


// UI Interfaces.
// DEPRECATED
export interface IMessage {
  senderUid: number;
  senderName: string;
  timestamp: DATETIME;

  message: string;
  isBroadcast: boolean;

  // seenBy: number[]; // array of UIDs.
}

// could be a group or person.
// DEPRECATED
interface IChat {
  id: number;
  title: string;
  chat: IMessage[];

  type: ChatRowType;
  personType?: PersonType;
  groupType?: GroupType;
}

// DEPRECATED
export interface IChatRow {
  unreadMessagesCount: number;
  activeCount: number;
  chat: IChat;
}

export interface IChatSection {
  rows: IChatRow[];
}

export interface IChatData {
  recent: IChatSection;
  groups: IChatSection;
  people: IChatSection;
  supportChat?: IChatRow;
}

// Updated interfaces
export interface INewMessage {
  senderUid: number;
  senderName: string;
  timestamp: DATETIME;

  message: string;
  isBroadcast: boolean;
  isUnread: boolean;
}

export interface INewChat {
  id: number;
  unreadCount: number;
  activeCount: number;
  theirUUID:number;
  title?: string;
  chat: INewMessage[];
  chatType: ChatRowType;

  // only when chatType === ChatRowType.BROADCAST.
  // this can be true for multiple users per broadcast chat. Indicates that it will show up in their list.
  isBroadcastOwner?: boolean;
}

export interface INewChatSection {
  rows: INewChat[];
}

export interface INewChatData {
  recent: INewChatSection;
  groups: INewChatSection;
  people: INewChatSection;
  // supportChat?: IChatRow;
}

@Injectable({
  providedIn: 'root'
})
export class MarkingChatService {
  MAX_RECENT = 5;

  private demoNames = {
    6265: 'Demo 1 Coordinator',
    6267: 'Demo 1 Leader',
    6268: 'Demo 2 Leader',
    6269: 'Demo 3 Leader',
    6270: 'Demo 1 Marker',
    6271: 'Demo 2 Marker',
    6272: 'Demo 3 Marker',
    5766: 'Demo 4 Marker' //nick added this
  };

  // private uid = 6265;

  public isLoading = true;
  public errorLoading = true;
  public hasUnread = false;
  public allChatData: INewChatData = {groups: {rows: []}, people: {rows: []}, recent: {rows: []}};
  public openChatId: number;
  public elem: ElementRef;

  private socket: any;
  private readonly uri: string = 'wss://mwgmbuiprh.execute-api.ca-central-1.amazonaws.com/production';
  private chatsPerUser: { [uid: number]: number[] } = {};
  private SOCKET_OPTIONS = {
    timeout: 10e3,
    maxAttempts: 10,
    onopen: e => {
      const data = {
        uid: this.getUid()
      };
      this.emit(ChatEndpoint.USER_CONNECT, data);
    },
    onmessage: e => {
      if(e.data == "KICK") {
        //someone logged into the account
        //this.auth.logout();
        return;
      }
      const data: ILambdaChatData = JSON.parse(e.data);
      const eventType: ChatEventType = data.eventType;

      // console.log('new message', eventType);
      switch (eventType) {
        case ChatEventType.CHAT_DATA:
          this.parseChatData(data);
          this.isLoading = false;
          break;
        case ChatEventType.ACTIVE_UPDATE:
          this.activeUpdate(data);
          break;
        case ChatEventType.NEW_MESSAGE:
          this.receiveMessage(data);
          break;
        case ChatEventType.MESSAGE_SENT:
          // console.log('message delivered');
          break;
        default:
          if (this.isChatDataEmpty()) {
            this.isLoading = false;
            this.errorLoading = true;
          }
      }

    },
    onreconnect: e => this.logConnectionEvent('Reconnecting...', e),
    onmaximum: e => this.logConnectionEvent('Stop Attempting!', e),
    onclose: e => {
      this.logConnectionEvent('Closed!', e);
      this.initSocket();
    },
    onerror: e => this.logConnectionEvent('Error:', e)
  };

  logConnectionEvent(msg:string, e:any){
    console.log(msg, e);
  }

  constructor(public auth: AuthService) {
    // need this because sometimes it fails to get uid at this point in loading.
    setTimeout(() => {
      this.initSocket();
    }, 300);
  }

  initSocket() {
    this.isLoading = true;
    this.errorLoading = false;
    this.socket = new Sockette(this.uri, this.SOCKET_OPTIONS);
  }

  private emit(action, data) {
    this.socket.json({action, data});
  }

  private getUid() {
    let uid = -1;
    let user = this.auth.user().value;
    if (!user) {
      user = this.auth.user().getValue();
    }
    if (user) {
      uid = user.uid;
    } else {
      console.error('Failed to get UID');
    }
    return uid;
  }

  private receiveMessage(data) {
    const chatId: number = data.chatId;
    const msg: INewMessage = data.msg;

    if (+msg.senderUid === +this.getUid()) {
      msg.isUnread = false;
    }

    let newChat: INewChat;
    // check people then groups for the chatId
    for (let i = 0; i < this.allChatData.people.rows.length; i++) {
      if (+this.allChatData.people.rows[i].id === +chatId) {
        this.allChatData.people.rows[i].chat.push(msg);
        /*
        if (msg.isUnread) {
          this.hasUnread = true;
          this.allChatData.people.rows[i].unreadCount += 1;
        }*/

        newChat = this.allChatData.people.rows[i];
        break;
      }
    }
    if (!newChat) {
      for (let i = 0; i < this.allChatData.groups.rows.length; i++) {
        if (+this.allChatData.groups.rows[i].id === +chatId) {
          /*
          if (msg.isUnread) {
            this.hasUnread = true;
            this.allChatData.groups.rows[i].unreadCount += 1;
          }*/
          this.allChatData.groups.rows[i].chat.push(msg);

          newChat = this.allChatData.groups.rows[i];
          break;
        }
      }
    }

    if (!newChat) {
      console.error('Chat not found.');
      return;
    }

    if (this.openChatId === +chatId) {
      this.clearUnread(newChat);
    } else if (msg.isUnread) {
      this.hasUnread = true;
      newChat.unreadCount += 1;
    }

    // then update recent with the new chat we find.
    this.scrollToBottom();
    this.updateRecent(newChat);
  }

  private activeUpdate(data) {
    const uid = data.uid;
    const isActive = data.isActive;
    const chatIds = this.chatsPerUser[uid] || [];

    let chatIdsDict = {};
    chatIds.forEach((id: number) => chatIdsDict[id] = true);

    this.allChatData.groups.rows.forEach((row: INewChat) => {
      if (!!chatIdsDict[row.id]) {
        if (isActive) {
          row.activeCount += 1;
        } else if (row.activeCount > 0) {
          row.activeCount -= 1;
        }
      }
    });
    this.allChatData.people.rows.forEach((row: INewChat) => {
      if (!!chatIdsDict[row.id]) {
        if (isActive) {
          row.activeCount = 1;
        } else {
          row.activeCount = 0;
        }
      }
    });
  }

  private parseChatData(data: ILambdaChatData) {
    let usersPerChat = {};
    let chatsPerUser = {};
    data.userData.forEach((userData: ILambdaQuery) => {
      // for each userData object, all of `Items` belongs to same chatId.
      if (userData.Count > 0) {
        const chatId: number = userData.Items[0].chatId;
        usersPerChat[chatId] = userData.Items;
        userData.Items.forEach(user => {
          if (user.uid in chatsPerUser) {
            chatsPerUser[user.uid].push(chatId);
          } else {
            chatsPerUser[user.uid] = [chatId];
          }
        });
      }
    });
    this.chatsPerUser = chatsPerUser;

    const allRows: INewChat[] = [];
    const myUid = this.getUid();
    data.userChats.Items.forEach(chat => {
      const chatId = chat.chatId;
      const chatChat = chat.chat;
      
      
      
      let theirUUID = chat.theirUUID;


      let unreadCount = 0;
      let activeCount = 0;
      usersPerChat[chatId].forEach(user => {
        if (user.isActive && +user.uid !== +myUid) {
          activeCount += 1;
        }
      });
      for (let i = chatChat.length - 1; i >= 0; i--) {
        const message: INewMessage = chatChat[i];
        if (!message.isUnread || +message.senderUid === +myUid) {
          break;
        }
        this.hasUnread = true;
        unreadCount += 1;
      }

      let title = chat.title;
      let gotTitle;
      if(title == "test") {
        gotTitle = new Promise((resolve, reject) => {
          this.auth.apiFind('/public/mrkg-lead/user', {query: {
            uid:chat.theirUUID
          }}).then(userData => {
            if(this.auth.myAccountType() == 'mrkg-mrkr' && userData.data[0].type == 'leader') {
              title = userData.data[0].firstName + " " + userData.data[0].lastName + " (Leader)"
            }
            else if(this.auth.myAccountType() == 'mrkg-mrkr' && userData.data[0].type == 'marker') {
              title = userData.data[0].firstName + " " + userData.data[0].lastName + " (Partner)"
            }
            else{
              title = userData.data[0].firstName + " " + userData.data[0].lastName + " (Marker)"
            }
            resolve(title);
          });
        });
      }
      else {
        gotTitle = new Promise((resolve, reject) => {
          resolve(title);
        })
      }

      gotTitle.then(title => {
        const newChat: INewChat = {
          id: chatId,
          unreadCount,
          activeCount,
          title: title,
          theirUUID: theirUUID,
          chat: chatChat,
          chatType: chat.chatType,
        };
        allRows.push(newChat);

        if(data.userChats.Items.length == allRows.length) {
          this.allChatData = this.chatsToData(allRows);
          this.checkUnread();
        }
      });
    });
    
  }

  private chatsToData(chats: INewChat[]): INewChatData {
    let recentRows: INewChat[] = [];
    let groupsRows: INewChat[] = [];
    let peopleRows: INewChat[] = [];
    chats.forEach((chat: INewChat) => {
      recentRows = this.insertIntoRecent(chat, recentRows);
      switch (chat.chatType) {
        case ChatRowType.BROADCAST:
        case ChatRowType.GROUP:
          groupsRows.push(chat);
          break;
        case ChatRowType.PERSON:
          peopleRows.push(chat);
          break;
      }
    });

    return {
      recent: {rows: recentRows},
      groups: {rows: groupsRows},
      people: {rows: peopleRows},
    };
  }

  private uidsToNameString(uids: number[]) {
    let s = '';
    const myUid = this.getUid();
    uids.forEach((uid: number) => {
      if (+uid !== +myUid) {
        this.uidToName(uid).then(name => {
          if (!!s) {
            s += ', ';
          }
          s += name;
        });
      }
    });
    return s;
  }
  // TODO
  private uidToName(uid: number) {
    return new Promise((resolve, reject) => {
      this.auth.apiFind('/public/mrkg-lead/user', {query:{
        uid
      }}).then(data => {
        if(data.data[0]) {
          resolve(data.data[0].firstName + " " + data.data[0].lastName);
        }
        else {
          resolve(uid.toString());
        }
      })
    });
  }

  private insertIntoRecent(chat: INewChat, recentRows: INewChat[]) {
    if (!chat.chat || chat.chat.length === 0) {
      return recentRows;
    }
    const newRecent: INewChat[] = [];
    const recentMsg: INewMessage = chat.chat[chat.chat.length - 1];
    const recentDate = moment(recentMsg.timestamp);
    let i = 0;
    let wasAdded = false;
    while (i < Math.min(this.MAX_RECENT, recentRows.length)) { // make sure no off by one error.
      const currentRecentRow = recentRows[i];
      const currentLastMessage = currentRecentRow.chat[recentRows.length - 1];

      if (!currentLastMessage) {
        // something probably broke
        break;
      }

      const checkDate = moment(currentLastMessage.timestamp);
      if (recentDate.isBefore(checkDate) && !wasAdded) {
        newRecent.push(chat);
        wasAdded = true;
      }
      newRecent.push(currentRecentRow);
      i += 1;
    }
    if (!wasAdded && newRecent.length < this.MAX_RECENT) {
      newRecent.push(chat);
    }
    return newRecent;
  }

  private checkUnread() {
    for (let i = 0; i < this.allChatData.people.rows.length; i++) {
      if (!!this.allChatData.people.rows[i].unreadCount) {
        this.hasUnread = true;
        return;
      }
    }
    for (let i = 0; i < this.allChatData.groups.rows.length; i++) {
      if (!!this.allChatData.groups.rows[i].unreadCount) {
        this.hasUnread = true;
        return;
      }
    }
  }

  private isChatDataEmpty() {
    return ((this.allChatData.groups.rows.length === 0) && (this.allChatData.people.rows.length === 0));
  }

  scrollToBottom() {
    let self = this;
    setTimeout(function() {
      if (self.elem) {
        self.elem.nativeElement.scrollTop = self.elem.nativeElement.scrollHeight;
      } else {
        console.error('chatDiv is undefined (service)');
      }
    }, 50);
  }

  getGroups() {
    return this.allChatData.groups;
  }
  getPeople() {
    return this.allChatData.people;
  }

  getTotalUnread() {
    let totalUnread = 0;
    this.allChatData.people.rows.forEach((row: INewChat) => {
      totalUnread += row.unreadCount;
    });
    this.allChatData.groups.rows.forEach((row: INewChat) => {
      totalUnread += row.unreadCount;
    });
    if (!totalUnread) { this.hasUnread = false; }
    return totalUnread;
  }

  updateRecent(chat: INewChat) {
    let newRows: INewChat[] = [chat];
    if (this.allChatData.recent.rows.length > 0) {
      for (let i = 0; i < Math.min(this.MAX_RECENT, this.allChatData.recent.rows.length); i++) {
        if (this.allChatData.recent.rows[i].id !== chat.id) {
          newRows.push(this.allChatData.recent.rows[i]);
        }
      }
    }
    this.allChatData.recent.rows = newRows;
  }

  clearUnread(row: INewChat) {
    if (row.unreadCount) { // to reduce API calls.
      this.allChatData.recent.rows.forEach((tempRow: INewChat) => {
        if (tempRow.id === row.id) {
          tempRow.unreadCount = 0;
        }
      });

      const type = row.chatType;
      if (type === ChatRowType.PERSON) {
        this.allChatData.people.rows.forEach((tempRow: INewChat) => {
          if (tempRow.id === row.id) {
            tempRow.unreadCount = 0;
          }
        });
      } else { // type === ChatRowType.GROUP
        this.allChatData.groups.rows.forEach((tempRow: INewChat) => {
          if (tempRow.id === row.id) {
            tempRow.unreadCount = 0;
          }
        });
      }
      row.unreadCount = 0;

      const data = {
        uid: this.getUid(),
        chatId: row.id,
      };
      this.emit(ChatEndpoint.CLEAR_UNREAD, data);
    }
  }

  sendMessage(chatId: number, msg: INewMessage) {
    if(msg.message == null || msg.message == "") {
      return;
    }
    msg.isUnread = true;
    const data = {
      uid: this.getUid(),
      chatId,
      msg
    };

    if (msg.isBroadcast) {
      this.emit(ChatEndpoint.SEND_BROADCAST, data);
    } else {
      this.emit(ChatEndpoint.SEND_MESSAGE, data);
    }
  }

}
