import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {
  ElementType,
  getElementWeight,
  IContentElementOrder,
  IContentElementOrderOption,
  IEntryStateOrder,
  ImageStates,
  McqDisplay,
  OrderMode,
  QuestionState,
  ScoringTypes,
  shuffle
} from '../models';
import {LangService} from '../../core/lang.service';
import {CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {processText, StyleprofileService} from '../../core/styleprofile.service';
import {Subject} from 'rxjs';
import {QuestionPubSub} from '../question-runner/pubsub/question-pubsub';
import {TextToSpeechService} from "../text-to-speech.service";


const SCORING_TYPE = ScoringTypes.AUTO;

@Component({
  selector: 'element-render-order',
  templateUrl: './element-render-order.component.html',
  styleUrls: ['./element-render-order.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ElementRenderOrderComponent implements OnInit, OnChanges {

  @Input() element:IContentElementOrder;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  @Input() questionPubSub?: QuestionPubSub;

  constructor(
    private lang:LangService,
    private profile:StyleprofileService,
    public textToSpeech:TextToSpeechService,
  ) { }

  options;
  answers;
  currentSelections = [];

  target = OrderMode.TARGET;
  reorder = OrderMode.REORDER;

  dragTriggers:Map<any, Subject<boolean>> = new Map();
  dropTriggers:Map<any, Subject<boolean>> = new Map();

  ngOnInit() {
    if (!this.questionState || !this.questionState[this.element.entryId]) {
      // console.log('initializing')
      this.resetOptions();
      this.ensureState();
    }
    else {
     // console.log(this.questionState);
      if (this.element.orderMode == OrderMode.TARGET) {
        this.answers = [].concat(this.questionState[this.element.entryId].answers);
        this.options = [].concat(this.questionState[this.element.entryId].options)
        //console.log(this.options);
      } 
      else {
        let answers = this.questionState[this.element.entryId].answers;
        this.options = [];
        answers.forEach((ans)=>{
          if (ans.length>0) this.options.push(ans[0]);
        })
      }
    //  console.log('options::ngOnInit', this.options)
    }
    if (!this.element.colourScheme) {
      this.element.colourScheme = { backgroundColor:"#FFFFFF", textColor:"#000000"};
    }
  }

  getOptionsExcludingFixed(options:IContentElementOrderOption[]) {
    let opt = [];
    options.forEach((option)=>{
      if (!option.isReadOnly) opt.push(option);
    })
    return opt;
  }

  resetOptions(){
    this.options = [].concat(this.element.options);
    this.answers = [];
    this.options.forEach((option)=>{
      const ans = [];
      if (option.isReadOnly) {
        ans.push(option);
      }
      this.answers.push(ans);
    })
    if (this.isScrambled()) {
      this.options = shuffle(this.options);
      this.element.scrambledOptions = [].concat(this.options);
    }
    else {
      this.options = [].concat(this.element.scrambledOptions);
    }
    let thisOptions = this.options;
    let originalOptions = this.element.options;
    if (this.isTargetMode()) {
      thisOptions = this.getOptionsExcludingFixed(this.options);
      originalOptions = this.getOptionsExcludingFixed(this.element.options);
    }
	
    let isCorrectOrder = true;
    thisOptions.forEach((option, index)=>{
      if (originalOptions[index].optionId!=option.optionId) {
        isCorrectOrder = false;
      }
    })
    
    if (isCorrectOrder && thisOptions.length>2) {
      this.resetOptions();
    }
    //  console.log('options::resetOptions', this.options)
     // this.options = [];
  }

  ngOnChanges(changes:SimpleChanges){
    if (changes.changeCounter){
      // console.log("Resetting options", this.questionState[this.element.entryId]);
      this.resetOptions();
    }
  }

  getAnswers() {
    let answers = this.answers;
    if (this.element.orderMode == OrderMode.REORDER) {
      answers = [];
      for (let i = 0;i<this.options.length;i++) {
        let opt = this.options[i];
        let ans = [];
        ans.push(opt);
        answers.push(ans);
      }
    }
    return answers;
  }

  ensureState() {
    let score = 0;
    let weight = 0;

    let answers = this.getAnswers();
    if (this.questionState) {
      if (!this.questionState[this.element.entryId]) {
        const isCorrect = false;
        const isStarted = false;
        const isFilled = false;
        
        let entryState:IEntryStateOrder = {
          type: ElementType.ORDER,
          isCorrect,
          isStarted,
          isFilled,
          answers,
          options: this.options,
          score,
          weight,
          scoring_type: ScoringTypes.AUTO, 
        }
        //console.log(entryState);
        if (this.questionState){
            this.questionState[this.element.entryId] = entryState;
        }
      }
    }
  }

  private getOptionWeight(): number {
    const weight = getElementWeight(this.element);
    return +((weight / this.element.options.filter(option => !option.isReadOnly).length).toFixed(2));
  }
  
  updateState() {
    let isStarted = false;
    let isCorrect = true;
    let isFilled = true;
    let weight = getElementWeight(this.element);
    const optionWeight = this.getOptionWeight();  
    let score = weight;
    
    let answers = this.getAnswers();

    answers.forEach((option, index)=>{
      if (this.element.options[index].isReadOnly) return;

      if (option.length==0) {
        isCorrect = false;
        score = score - optionWeight;
        isFilled = false;
        return;
      }
      isStarted = true;
      const currAnswer = option[0];
      
      if (currAnswer == undefined || currAnswer.optionId!=this.element.options[index].optionId) {
        isCorrect = false;
        score = score - optionWeight;
      }
    })
    
    score = +score.toFixed(2);

    // console.log('answers', answers, isFilled)
    // console.log('options', this.options)

    //console.log(answers);
    if (score<0){
      score=0;
    }
    //console.log(score);
    //console.log(isFilled);
    let entryState:IEntryStateOrder = {
      type: ElementType.ORDER,
      isCorrect,
      isStarted,
      isFilled,
      answers,
      options: this.options,
      score,
      weight,
      scoring_type: ScoringTypes.AUTO, 
    }
    //console.log(entryState);

    if (this.questionState){
        this.questionState[this.element.entryId] = entryState;
    }

    
  }

  isVertical(){
    return (this.element.displayStyle===McqDisplay.VERTICAL)
  }
  isHorizontal(){
    // return (this.element.displayStyle==='vertical')
    return !this.isVertical()
  }

  drop(arr:any, event: CdkDragDrop<string[]>) {
    // console.log('drop', arr)
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
    this.updateState();
    this.removeSelection();
  }

  dropTargetMode(event: CdkDragDrop<string[]>, targetElement: any, isHomeDest: boolean) {
    // console.log('dropTargetMode>>event.container', event.container)
    if (event.container.data.length>0 && !isHomeDest) {
      transferArrayItem(
        event.container.data,
        event.previousContainer.data,
        event.currentIndex,
        event.previousIndex+1
      );
    } 
    transferArrayItem(event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex);
    
    this.updateState();
    this.removeSelection();
    // this.rearrangeByOrder()

    if (targetElement) {
      const voiceover = targetElement.label_voiceover;
      if (voiceover && voiceover.url) {
        this.getDropTrigger(targetElement).next(true);
      }
    }
  }

  removeSelection() {
    if (window.getSelection()) {
      window.getSelection().removeAllRanges()
    } else if (document.getSelection()) {
      document.getSelection().removeAllRanges();
    }
  }

  // rearrangeByOrder() {
  //   if (!this.isTargetMode()) return;
  //   const thisOpts = this.options;
  //   let configOpts;
  //   if (this.isScrambled()) {
  //     configOpts = this.element.options
  //   }
  //   else {
  //     configOpts = this.element.scrambledOptions
  //   }
  //   const newThisOpts = []

  //   configOpts.forEach((opt)=>{
  //     if (thisOpts.includes(opt)) {
  //       newThisOpts.push(opt)
  //     }
  //   })
  //   this.options = newThisOpts;
  //   console.log('options::rearrangeByOrder', this.options)
  // }

  isScrambled() {
    if (this.element.isScrambled || !this.element.scrambledOptions) {
      return true;
    }
    return false;
  }

  canBeDropped(optionElement:IContentElementOrderOption[]) {
    if (optionElement.length>0 && optionElement[0].isReadOnly) return false;
    return true;
  }

  canBeDroppedPredicate(drag : CdkDrag, list:CdkDropList) {
    if (list.data.length>0 && list.data[0].isReadOnly) return false;
    return true;
  }

  isTargetMode() {
    return this.element.orderMode == OrderMode.TARGET;
  }

  processThisText(str: string) {
    const lang = this.lang.c();
    return processText(str, this.profile.getStyleProfile()[lang].renderStyling.plainText.transforms);
  }

  getDims(pad: boolean) {
    const style = {}
    style["height.em"] = this.element.targetHeight ? this.element.targetHeight : 3;
    style["width.em"] = this.element.targetWidth ? this.element.targetWidth : 3;
    style["max-height.em"]=this.element.targetHeight ? this.element.targetHeight : 3
    style["max-width.em"]=this.element.targetWidth ? this.element.targetWidth : 3
    if (pad) {
      style["height.em"] += 0.3;
      style["width.em"] += 0.3;
      style["max-height.em"] += 0.3;
      style["max-width.em"] += 0.3;
    }
    return style;
  }

  hasDefaultImage(img:any) {
    return (img && img.images && img.images[ImageStates.DEFAULT] && img.images[ImageStates.DEFAULT].image && img.images[ImageStates.DEFAULT].image.url);
  }

  isVoiceoverEnabled() {
    return this.textToSpeech.isActive;
  }

  getElementAudio(voiceover: any): string {
    return (voiceover && voiceover.url) ? voiceover.url : '';
  }

  getDragTrigger(element: any){
    let trigger = this.dragTriggers.get(element);
    if (!trigger){
      trigger = new Subject();
      this.dragTriggers.set(element, trigger);
    }
    return trigger;
  }

  getDropTrigger(targetContext: any){
    let trigger = this.dropTriggers.get(targetContext);
    if (!trigger){
      trigger = new Subject();
      this.dropTriggers.set(targetContext, trigger);
    }
    return trigger;
  }

  dragStarted(element:any) {
    const voiceover = element.voiceover;
    if (voiceover && voiceover.url) {
      this.getDragTrigger(element).next(true);
    }
  }

}
