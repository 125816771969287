<div style="display:flex; flex-wrap:wrap;">
    <div class="block-el" *ngFor="let block of blocks; let i = index;" (click)=toggleAnswer(block.index) [ngStyle]="getWordStyle(block, i)">
        <markdown-inline [input]="getFormattedWord(block.word)"></markdown-inline>
    </div>
</div>
<div *ngIf="this.showMaxSelectedMsg" (click)="this.showMaxSelectedMsg = false;" class="mcq-max-selected-msg">
    {{getMaxMsgText()}}
    <div class="click-dismiss-msg">
        {{getClickToDismissMsg()}}
    </div>
</div>