<table style="width: 85%; font-size:0.85em;">
  <tr>
    <td>
      Convert option to
    </td>
    <td>
      <button (click)="replaceOptionType.emit('ADVTEXT')" class="is-small button">Adv. Text</button>
      <button (click)="replaceOptionType.emit('IMAGE')" class="is-small button">Image</button>
      <button (click)="replaceOptionType.emit('MATH')" class="is-small button">Math</button>
      <button (click)="replaceOptionType.emit('TABLE')" class="is-small button">Table</button>
    </td>
  </tr>
  <tr>
    <th>Voice</th>
    <td>
      <capture-voice 
        [isDisabled]="isReadOnly()"
        [element]="mcqOption[voiceoverProp]" 
        [scriptChanges]="getMcqChange()" 
        [urlChanges]="getMcqChange()" 
        title="Option Voice-over"
        [isSmall]="true"
        [isMagicEnabled]="false"
      ></capture-voice>
    </td>
  </tr>
  <tr>
    <th [ngSwitch]="!!useDistractorAsResponseSlug">
      <span *ngSwitchCase="true">Response Slug</span>
      <span *ngSwitchCase="false">Distractor</span>
    </th>
    <td>
      <input 
        class="input is-small is-fullwidth" 
        [formControl]="content" 
        type="text" 
        placeholder="Distractor Info"
      >
    </td>
  </tr>
</table>
