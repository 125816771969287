import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'results-legend',
  templateUrl: './results-legend.component.html',
  styleUrls: ['./results-legend.component.scss']
})
export class ResultsLegendComponent implements OnInit {
  
  @Input() initExpand: boolean;
  
  constructor() { }

  public isExpanded : boolean = false;
  ngOnInit(): void {
    this.isExpanded = this.initExpand;
  }

  toggleLegendExpand() {
    this.isExpanded = !this.isExpanded;
  }

}
