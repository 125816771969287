import { Injectable } from '@angular/core';

export enum AuthScopeSetting {
  ENABLE_SCORE_WEIGHT = 'ENABLE_SCORE_WEIGHT', // should be conditionally enabled for BC (but false for EQAO MPT/Grade9)
  DISABLE_EDITING = 'DISABLE_EDITING', //based on role type
  ASSET_LIB_TAG_FILTER = 'ASSET_LIB_TAG_FILTER', // to preserve search
  APPLY_NUM_FORMATS = 'APPLY_NUM_FORMATS', // should be conditionally enabled for ENS (through the framework)
  Q_FADE_IN_ENTRANCE = 'Q_FADE_IN_ENTRANCE', // would likely be set by the assessment, with override by the item
}

@Injectable({
  providedIn: 'root'
})
export class AuthScopeSettingsService {
  
  // scope should eventually be defined by the following hierarchy
  /*

    > System Defaults
      > White Label Defaults
        > Auth Group Defaults
          > Item Bank Defaults
            > Current Item Setting

  */
  // this can be used to provide all of the display customization which might be relevant for the authoring experience

  private state:Map<AuthScopeSetting, boolean | number | any> = new Map();

  constructor() {
    this.state.set(AuthScopeSetting.Q_FADE_IN_ENTRANCE, true)
    this.state.set(AuthScopeSetting.ENABLE_SCORE_WEIGHT, true)
  }
  
  public getSetting(settingId: AuthScopeSetting) {
    return this.state.get(settingId);
  }

  public setSetting(settingId: AuthScopeSetting, val:any) {
    this.state.set(settingId, val);
  }

}
