

<div 
    class="element-render-moveable-dnd"
    [class.has-frame]="element.hasFrame"
    [class.is-dragging]="isDragging"
    [style.width.em]="element.width"
    [style.height.em]="element.height"
>
    <render-image 
        *ngIf="element.backgroundImg" 
        [element]="element.backgroundImg"
    ></render-image>
    <div class="background-el">
        <div *ngFor="let image of this.element.moveableImages">
            <element-render-image [element]="image"></element-render-image>
        </div>
    </div>
    <div  cdkDropListGroup>
        <div *ngFor="let target of this.targets"
            cdkDropList
            class="target-el"
            [cdkDropListData]="target.contents"
            cdkDropListSortingDisabled
            (cdkDropListDropped)="drop($event); isDragging=false"
            (cdkDropListEntered)="isDragging=true"
            [ngStyle]="target.targetContext ? getTargetStyle(target) : {}"
        >
            <div class="hover-indicator"></div>
            <div cdkDrag class="drag-el" [cdkDragDisabled]="isLocked"
                [class.is-transparent]="element.isDragsTransparent" 
                [style.width.em]="target.targetContext.width" 
                [style.height.em]="target.targetContext.height" 
                [style.background-color]="getDraggableColour(target.contents[0].ref)"
                *ngIf="target.contents && target.contents[0] && target.contents[0].ref && target.contents[0].ref.element"
            >
                <element-render 
                    [contentElement]="target.contents[0].ref.element"
                    [questionPubSub]="questionPubSub"
                ></element-render>
                <div class="img-drag-shield"></div>
            </div>
        </div>
    </div>
</div>
