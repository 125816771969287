import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IContentElementMcqOption, IContentElementOption } from '../../ui-testrunner/models/index';
import { FormControl } from '@angular/forms';
import { bindFormControls } from '../services/data-bind';
import { EditingDisabledService } from '../editing-disabled.service';

@Component({
  selector: 'element-config-mcq-option-info',
  templateUrl: './element-config-mcq-option-info.component.html',
  styleUrls: ['./element-config-mcq-option-info.component.scss']
})
export class ElementConfigMcqOptionInfoComponent implements OnInit {

  @Input() mcqOption:IContentElementOption;
  @Input() voiceoverProp:string;
  @Input() useDistractorAsResponseSlug:boolean;
  @Output() replaceOptionType = new EventEmitter();

  public content = new FormControl();

  constructor(private editingDisabled: EditingDisabledService) { }

  ngOnInit() {
    const DEFAULT_VOICEOVER_PROP = 'voiceover';
    this.voiceoverProp = this.voiceoverProp || DEFAULT_VOICEOVER_PROP;
    
    const formControls = [ {f: this.content, p:'info'}];
    
    this.editingDisabled.registerFormControls(formControls);
    
    bindFormControls(this.mcqOption, formControls)
    if (!this.mcqOption[this.voiceoverProp]){
      this.mcqOption[this.voiceoverProp] = {};
    }
  }

  getMcqChange(){
    if (this.mcqOption && this.mcqOption[this.voiceoverProp]){
      return this.mcqOption[this.voiceoverProp].url + this.mcqOption[this.voiceoverProp].script
    }
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }

}
