import { Injectable } from '@angular/core';
import { AuthService, getFrontendDomain } from './auth.service';

// local short hand used for defining valid api calls routes
const GET = 'get';
const POST = 'post';
const FIND = 'find';
const PATCH = 'patch';
const REMOVE = 'remove';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {

  constructor(
    private auth: AuthService,
  ) { }

  currentHost(){
    return getFrontendDomain()+'#';
    // return 'https://mathproficiencytest.ca/#'
  }

  ANALYTICS_SESSION                                    = 'public/analytics/session';
  ANALYTICS_EVENT                                      = 'public/analytics/event';
  ANON_SAMPLE_TEST_DESIGN_FORM                         = 'public/anon/sample-test-design-form';
  AUTH_RESET_PASSWORD                                  = 'public/auth/reset-password';
  AUTH_RESET_PASSWORD_REQUEST                          = 'public/auth/reset-password-request';
  AUTH_CONFIRM_EMAIL                                   = 'public/auth/confirm-email';
  AUTH_TEST_TAKER                                      = 'public/auth/test-taker';
  AUTH_TEST_ADMIN                                      = 'public/auth/test-admin';
  AUTH_TEST_CTRL                                       = 'public/auth/test-ctrl';
  AUTH_TEST_CERT                                       = 'public/auth/test-cert';
  LANDING_APPLICANT_REGISTRATION                       = 'public/landing/applicant-registration';
  LOG                                                  = 'public/log';

  DIST_ADMIN_SUMMARY                                   = 'public/dist-admin/summary';
  DIST_ADMIN_CONTACT                                   = 'public/dist-admin/contact';
  DIST_ADMIN_CHECKLIST                                 = 'public/dist-admin/tech-readi/checklist';
  DIST_ADMIN_LOCK_DOWN_INFO                            = 'public/dist-admin/tech-readi/lock-down-info';
  DIST_ADMIN_SESSIONS                                  = 'public/dist-admin/sessions';
  EDUCATOR_SCHOOL                                      = 'public/educator/school';
  EDUCATOR_STUDENTS                                    = 'public/educator/students';
  EDUCATOR_SESSION                                     = 'public/educator/session';
  EDUCATOR_SESSION_SUB                                 = 'public/educator/session-sub';
  EDUCATOR_SOFT_LOCK                                   = 'public/educator/soft-lock';

  FIELD_TESTER_SESSION                                 = 'public/field-tester/session'
  FIELD_TESTER_SESSION_QUESTION                        = 'public/field-tester/session-question';
  STUDENT_ASMT_FILTER                                  = 'public/student/asmt-filter';
  STUDENT_SESSION                                      = 'public/student/session';
  STUDENT_SESSION_QUESTION                             = 'public/student/session-question';
  STUDENT_CHECKLIST                                    = 'public/student/checklist';
  STUDENT_STAGE_SUBMISSION                             = 'public/student/stage-submission';
  STUDENT_CLASS_ID                                     = 'public/student/class-id';

  SCHOOL_ADMIN_CHECKLIST                               = 'public/school-admin/tech-readi/checklist';
  SCHOOL_ADMIN_IP                                      = 'public/school-admin/tech-readi/ip';
  SCHOOL_ADMIN_CONTACT                                 = 'public/school-admin/tech-readi/it-contact';
  SCHOOL_ADMIN_ROLES                                   = 'public/school-admin/roles';
  SCHOOL_ADMIN_SCHOOL                                  = 'public/school-admin/school';
  SCHOOL_ADMIN_REPORTS                                 = 'public/school-admin/reports';
  SCHOOL_ADMIN_LOCK_DOWN_INFO                          = 'public/school-admin/tech-readi/lock-down-info';
  SCHOOL_ADMIN_STUDENT                                 = 'public/school-admin/student';
  SCHOOL_ADMIN_SESSION                                 = 'public/school-admin/session';
  SCHOOL_ADMIN_STUDENT_ASSIGN                          = 'public/school-admin/student/assign';
  SCHOOL_ADMIN_OEN_SCHOOL                              = 'public/school-admin/oen-school';
  SCHOOL_ADMIN_CLASSES                                 = 'public/school-admin/classes';
  SCHOOL_ADMIN_TEACHERS                                = 'public/school-admin/teachers';
  SCHOOL_ADMIN_TEACHERS_INVITE                         = 'public/school-admin/teachers/invite';

  SCOR_SCOR_SUMMARY                                    = 'public/scor-scor/summary';

  SUPPORT_RESET_PROGRESS                               = 'public/support/reset-progress';
  SUPPORT_OVERRIDE_START_SESSION                       = 'public/support/test-session/date';
  SUPPORT_VALIDATE_OCT_MEMBER                          = 'public/support/validate-oct-member';
  SUPPORT_SYS_FLAGS                                    = 'public/support/sys-flags';
  SUPPORT_TT_NOTIFICATIONS                             = 'public/support/tt-notifications';
  SUPPORT_TT_REPORT_NOTIFICATIONS                      = 'public/support/tt-report-notifications';
  SUPPORT_TT_LANG_REQ                                  = 'public/support/tt-lang-req';
  SUPPORT_TT_UPCOMING_SESSIONS                         = 'public/support/tt-upcoming-sessions';
  SUPPORT_TT_BOOKING                                   = 'public/support/tt-booking';
  SUPPORT_TEST_FORM_PRINT                              = 'public/support/test-form-print';
  SUPPORT_USERS                                        = 'public/support/users';
  SUPPORT_INVITATION_CODE                              = 'public/support/u-invitation-code';
  SUPPORT_MAIL_CAMPAIGN                                = 'public/support/mail-campaign';
  SUPPORT_MAIL_CAMPAIGN_BATCH                          = 'public/support/mail-campaign-batch';
  SUPPORT_MAIL_CAMPAIGN_TEST                           = 'public/support/mail-campaign-test';


  TEST_ADMIN_INSTITUTION                               = 'public/test-admin/institution';
  TEST_ADMIN_STUDENTS_SCHOOL                           = 'public/test-admin/students/school';
  TEST_ADMIN_STUDENTS_BOOKING                          = 'public/test-admin/students/booking';
  TEST_ADMIN_STUDENTS_WALK_IN                          = 'public/test-admin/students/walk-in';
  TEST_ADMIN_SEB_DOWNLOAD_LINK                         = 'public/test-admin/seb-download-link';
  TEST_ADMIN_TEST_SESSION_SETUP_ACTIVE                 = 'public/test-admin/test-session-setup/active';
  TEST_ADMIN_TEST_SESSION_SETUP_CHECKLIST              = 'public/test-admin/test-session-setup/checklist';
  TEST_ADMIN_TEST_SESSION_SETUP_TEST_WINDOWS           = 'public/test-admin/test-session-setup/test-windows';
  TEST_ADMIN_TEST_SESSION_SETUP_LOCATIONS              = 'public/test-admin/test-session-setup/locations';
  TEST_ADMIN_TEST_SESSION_SETUP_ACCOMM_PING            = 'public/test-admin/test-session-setup/accomm-ping';
  TEST_ADMIN_TEST_SESSION_SETUP_TECH_READY             = 'public/test-admin/test-session-setup/tech-ready';
  TEST_ADMIN_TEST_SESSION_SETUP_TIME_SLOTS             = 'public/test-admin/test-session-setup/time-slots';
  TEST_ADMIN_TEST_SESSION_SETUP_TIME_SLOT_RESTRICTION  = 'public/test-admin/test-session-setup/time-slot-restriction';
  TEST_ADMIN_TEST_SESSION_SETUP_TIMEZONE               = 'public/test-admin/test-session-setup/timezone';
  TEST_ADMIN_TEST_SESSION_SETUP_SESSIONS               = 'public/test-admin/test-session-setup/sessions';
  TEST_ADMIN_TEST_SESSIONS_MY_UPCOMING                 = 'public/test-admin/test-sessions/my-upcoming';
  TEST_ADMIN_TEST_SESSIONS_MY_SINGLE                   = 'public/test-admin/test-sessions/my-single';
  TEST_ADMIN_TEST_SESSIONS_ALL                         = 'public/test-admin/test-sessions/all';
  TEST_ADMIN_TEST_SESSIONS_SINGLE                      = 'public/test-admin/test-sessions/single';
  TEST_ADMIN_TEST_SESSIONS_SUMMARY                     = 'public/test-admin/test-sessions/summary';
  TEST_ADMIN_TEST_SESSION_CANCELLATION                 = 'public/test-admin/test-session/cancellation';
  TEST_ADMIN_TEST_SESSION_RESTRICTIONS                 = 'public/test-admin/test-session/restrictions';
  TEST_ADMIN_TEST_SESSION_STATUS_UPDATE                = 'public/test-admin/test-session/status-update';
  TEST_ADMIN_TEST_SESSION_ASSIGNED_INVIGILATOR         = 'public/test-admin/test-session/assigned-invigilator';
  TEST_ADMIN_TEST_SESSION_INVITATION_CODE              = 'public/test-admin/test-session/invitation-code';
  TEST_ADMIN_TEST_SESSION_CAPACITY                     = 'public/test-admin/test-session/capacity';
  TEST_ADMIN_TEST_SESSION_BOOKINGS                     = 'public/test-admin/test-session/bookings';
  TEST_ADMIN_TEST_SESSION_WAITLISTERS                  = 'public/test-admin/test-session/waitlisters';
  TEST_ADMIN_TEST_SESSION_BOOKING_TRANSFER_REQ         = 'public/test-admin/test-session/booking-transfer-req';
  TEST_ADMIN_TEST_SESSION_WAITLISTER_TRANSFER_REQ      = 'public/test-admin/test-session/waitlister-transfer-req';
  TEST_ADMIN_TEST_SESSION_BOOKINGS_INDIC               = 'public/test-admin/test-session/bookings-indic';
  TEST_ADMIN_TEST_SESSION_WAIT_LISTINGS_INDIC          = 'public/test-admin/test-session/wait-listings-indic';
  TEST_ADMIN_ACCOUNTS_ACCESS                           = 'public/test-admin/accounts/access';
  TEST_ADMIN_ACCOUNTS_ASSIGNED_COORD                   = 'public/test-admin/accounts/assigned-coord';
  TEST_ADMIN_ACCOUNTS_ASSIGNED_SESSIONS                = 'public/test-admin/accounts/assigned-sessions';
  TEST_ADMIN_ACCOMM_PENDING_REQUESTS                   = 'public/test-admin/accomm/pending-requests';
  TEST_ADMIN_ACCOMM_REQUESTS_RESPONSES                 = 'public/test-admin/accomm/responses';
  TEST_ADMIN_ACCOMM_TRANSFER_REQ                       = 'public/test-admin/accomm/transfer-req';
  TEST_ADMIN_INVIGILATION_                             = 'public/test-admin/invigilation/';
  TEST_ADMIN_INVIGILATION_PAUSE                        = 'public/test-admin/invigilation/pause';
  TEST_ADMIN_INVIGILATION_CLOSE                        = 'public/test-admin/invigilation/close';
  TEST_ADMIN_INVIGILATION_SEND                         = 'public/test-admin/invigilation/send';
  TEST_ADMIN_INVIGILATION_GROUP_ISSUE                  = 'public/test-admin/invigilation/group-issue';
  TEST_ADMIN_INVIGILATION_INDIV_ISSUE                  = 'public/test-admin/invigilation/indiv-issue';
  TEST_ADMIN_INVIGILATION_GROUP_TIME_EXTEND            = 'public/test-admin/invigilation/group-time-extend';
  TEST_ADMIN_INVIGILATION_INDIV_TIME_EXTEND            = 'public/test-admin/invigilation/indiv-time-extend';
  TEST_ADMIN_INVIGILATION_IDENTITY_VERIF               = 'public/test-admin/invigilation/identity-verif';
  TEST_ADMIN_INVIGILATION_IDENTITY_NO                  = 'public/test-admin/invigilation/identity-no';
  TEST_ADMIN_INVIGILATION_ABSENCE_REPORT               = 'public/test-admin/invigilation/absence-report';
  TEST_ADMIN_INVIGILATION_TEMP_ACCESS                  = 'public/test-admin/invigilation/temp-access';
  TEST_AUTH_ACCOUNTS_ACCESS                            = 'public/test-auth/accounts/access';
  TEST_AUTH_ITEM_SET                                   = 'public/test-auth/item-set';
  TEST_AUTH_FRAMEWORKS                                 = 'public/test-auth/frameworks';
  TEST_AUTH_QUESTIONS                                  = 'public/test-auth/questions';
  TEST_AUTH_QUESTION_SET_LISTS                         = 'public/test-auth/question-set-lists';
  TEST_AUTH_TEXT_VOICE                                 = 'public/test-auth/text-voice';
  TEST_AUTH_QUESTION_REVISIONS                         = 'public/test-auth/question-revisions';
  TEST_AUTH_GROUPS                                     = 'public/test-auth/groups';
  TEST_AUTH_GROUP                                      = 'public/test-auth/group';
  TEST_AUTH_GROUP_MEMBERS                              = 'public/test-auth/group-members';
  TEST_AUTH_GROUP_ROLES                                = 'public/test-auth/group-roles';
  TEST_AUTH_NOTES                                      = 'public/test-auth/notes';
  TEST_AUTH_ASSET_LIBRARIES                            = 'public/test-auth/asset-libraries';
  TEST_AUTH_ASSET                                      = 'public/test-auth/assets';
  TEST_AUTH_TEST_DESIGNS                               = 'public/test-auth/test-designs';
  TEST_AUTH_TEST_DESIGNS_IS_PUBLIC                     = 'public/test-auth/test-designs/is-public';
  TEST_AUTH_NOTE_FILES                                 = 'public/test-auth/note-files';
  TEST_ADMIN_VALIDATE_SEB                              = 'public/test-admin/validate-seb';
  TEST_TAKER_TEST_SESSIONS_ALL                         = 'public/test-taker/test-sessions/all';
  TEST_TAKER_TEST_SESSIONS_INVITATION                  = 'public/test-taker/test-sessions/invitation';
  TEST_TAKER_TEST_SESSIONS_BOOKING                     = 'public/test-taker/test-sessions/booking';
  TEST_TAKER_TEST_SESSIONS_WAITLIST                    = 'public/test-taker/test-sessions/waitlist';
  TEST_TAKER_TEST_SESSIONS_PAST_ATTEMPTS               = 'public/test-taker/test-sessions/past-attempts';
  TEST_TAKER_ACCOMMODATIONS_PENDING_REQ                = 'public/test-taker/accommodations/pending-req';
  TEST_ADMIN_INVIGILATION_ONBEHALF_TEST_ATTEMPT        = 'public/test-admin/invigilation/onbehalf/test-attempt';
  TEST_ADMIN_INVIGILATION_ONBEHALF_QUESTION_RESPONSE   = 'public/test-admin/invigilation/onbehalf/question-response';
  TEST_TAKER_INVIGILATION_TEST_ATTEMPT                 = 'public/test-taker/invigilation/test-attempt';
  TEST_TAKER_INVIGILATION_TEST_ATTEMPT_ATTEST          = 'public/test-taker/invigilation/test-attempt-attest';
  TEST_TAKER_INVIGILATION_TEST_ATTEMPT_LANG            = 'public/test-taker/invigilation/test-attempt-lang';
  TEST_TAKER_INVIGILATION_QUESTION_RESPONSE            = 'public/test-taker/invigilation/question-response';
  TEST_TAKER_INVIGILATION_TEST_ATTEMPT_TIME            = 'public/test-taker/invigilation/test-attempt-time';
  TEST_TAKER_INVIGILATION_SCREEN_SESSION               = 'public/test-taker/invigilation/screen-session';
  TEST_TAKER_INVIGILATION_TEST_FORM                    = 'public/test-taker/invigilation/test-form';
  TEST_TAKER_INVIGILATION_QUESTION_CONTENT             = 'public/test-taker/invigilation/question-content';
  TEST_TAKER_INVIGILATION_SECTION_SUBMIT               = 'public/test-taker/invigilation/section-submit';
  TEST_TAKER_INVIGILATION_REPORT_ISSUE                 = 'public/test-taker/invigilation/report-issue';
  TEST_TAKER_REPORT_RESULTS                            = 'public/test-taker/report/results';
  TEST_TAKER_DATA_DOWNLOAD                             = 'public/test-taker/data/download';
  TEST_ADMIN_INVIGILATION_REPORT_APPLICANT_ISSUE       = 'public/test-admin/invigilation/report-applicant-issue';
  TEST_CTRL_DATA_FILE                                  = 'public/test-ctrl/data-file';
  TEST_CTRL_VALIDATION_ISSUE                           = 'public/test-ctrl/validation/issue';
  TEST_CTRL_VALIDATION_DATES                           = 'public/test-ctrl/validation/dates';
  TEST_CTRL_VALIDATION_ISSUE_RESOLUTION                = 'public/test-ctrl/validation/issue-resolution';
  TEST_CTRL_VALIDATION_TEST_SESSIONS                   = 'public/test-ctrl/validation/test-sessions';
  TEST_CTRL_VALIDATION_TEST_ATTEMPTS                   = 'public/test-ctrl/validation/test-attempts';
  TEST_CTRL_VALIDATION_READY                           = 'public/test-ctrl/validation/ready';
  TEST_CTRL_SUMMARY                                    = 'public/test-ctrl/summary';
  TEST_CTRL_LANG_REQ                                   = 'public/test-ctrl/lang-req';
  TEST_CTRL_SCHOOLS                                    = 'public/test-ctrl/schools/school';
  TEST_CTRL_BOARDS                                     = 'public/test-ctrl/schools/boards';
  TEST_CTRL_CLASSES                                    = 'public/test-ctrl/schools/classes';
  TEST_CTRL_TEACHERS                                    = 'public/test-ctrl/schools/teachers';
  TEST_CTRL_STUDENTS                                  = 'public/test-ctrl/schools/students';
  TEST_CTRL_TEST_WINDOW_SUMMARY                        = 'public/test-ctrl/test-window/summary';
  TEST_CTRL_TEST_WINDOW_PUBLISH                        = 'public/test-ctrl/test-window/publish';
  TEST_CTRL_TEST_WINDOW_RELEASE_NOTES                  = 'public/test-ctrl/test-window/release-notes';
  TEST_CTRL_TEST_WINDOW_RETRIEVALS                     = 'public/test-ctrl/test-window/retrievals';
  TEST_CTRL_TEST_WINDOW_TEST_SESSIONS                  = 'public/test-ctrl/test-window/test-sessions';
  TEST_CTRL_TEST_WINDOW_TEST_DESIGN                    = 'public/test-ctrl/test-window/test-design';
  TEST_CTRL_TEST_WINDOW_CONFIG                         = 'public/test-ctrl/test-window/config';
  TEST_CTRL_ACCOUNTS_INSTITUTIONS                      = 'public/test-ctrl/accounts/institutions';
  TEST_CTRL_ACCOUNTS_TEST_ADMINS                       = 'public/test-ctrl/accounts/test-admins';
  TEST_CTRL_ACCOUNTS_TEST_CERTS                        = 'public/test-ctrl/accounts/test-certs';
  TEST_CTRL_ACCOUNTS_TEST_CTRLS                        = 'public/test-ctrl/accounts/test-ctrls';
  TEST_CTRL_ACCOUNTS_TEST_ADMIN_REVOKE                 = 'public/test-ctrl/accounts/test-admin-revoke';
  TEST_CTRL_ACCOUNTS_TEST_CERT_REVOKE                  = 'public/test-ctrl/accounts/test-cert-revoke';
  TEST_CTRL_ACCOUNTS_TEST_CTRL_REVOKE                  = 'public/test-ctrl/accounts/test-ctrl-revoke';
  TEST_CTRL_ROLES                                      = 'public/test-ctrl/roles';
  TEST_CERT_ROLES                                      = 'public/test-cert/roles';
  TEST_CERT_DATA_FILE                                  = 'public/test-cert/data-file';
  TRANSLATION                                          = 'public/translation';

  MRKG_LEAD_STAGE                              = 'public/mrkg-lead/stage';
  MRKG_CTRL_PROFILE                            = 'public/mrkg-ctrl/profile';                             // get user profile
  MRKG_CTRL_MARKING_WINDOW                     = 'public/mrkg-ctrl/marking-window';                      // get all or 1 marking windows or create new one
  MRKG_CTRL_MARKING_WINDOW_NAME                = 'public/mrkg-ctrl/marking-window/name';                 // update marking window name
  MRKG_CTRL_MARKING_WINDOW_ACCOUNTS_SUPRS      = 'public/mrkg-ctrl/marking-window/accounts/suprs';       // update or get marking window supervisors
  MRKG_CTRL_MARKING_WINDOW_ACCOUNTS_MRKRS      = 'public/mrkg-ctrl/marking-window/accounts/mrkrs';       // update or get marking window markers
  MRKG_CTRL_MARKING_WINDOW_ACCOUNTS_CERT_MRKRS = 'public/mrkg-ctrl/marking-window/accounts/cert-mrkrs';  // update or get all markers who are certified for the window
  MRKG_CTRL_MARKING_WINDOW_UNIQ_ITEMS          = 'public/mrkg-ctrl/marking-window/unique-items';         // get 1/all unique items OR update 1 (q-specific-marking)
  MRKG_CTRL_MARKING_WINDOW_STATUS              = 'public/mrkg-ctrl/marking-window/status';               // get or some marking windows
  MRKG_CTRL_MARKING_WINDOW_DATE_FROM           = 'public/mrkg-ctrl/marking-window/date-from';            // update marking window start date
  MRKG_CTRL_MARKING_WINDOW_DATE_TO             = 'public/mrkg-ctrl/marking-window/date-to';              // update marking window end date
  MRKG_CTRL_MARKING_WINDOW_CERT_TESTS          = 'public/mrkg-ctrl/marking-window/cert-tests';           // update or get all cert tests for the marking window
  MRKG_CTRL_SUPRS                              = 'public/mrkg-ctrl/suprs';                               // get all supervisors
  MRKG_CTRL_MRKRS                              = 'public/mrkg-ctrl/mrkrs';                               // get all markers

  MRKG_SUPR_PROFILE         = 'public/mrkg-supr/profile'                 // get user profile
  MRKG_SUPR_Q_SCORING       = 'public/mrkg-supr/question-marking';        // get or update 1 question
  MRKG_SUPR_Q_SCORING_BATCH = 'public/mrkg-supr/question-marking-batch';  // get or update multiple questions
  MRKG_SUPR_MARKING_LOG     = 'public/mrkg-supr/marking-log';             // get the marking log
  MRKG_SUPR_ACCOUNTS_MRKRS  = 'public/mrkg-supr/accounts/mrkrs';          // get all their markers
  MRKG_SUPR_SCHEDULE        = 'public/mrkg-supr/schedule';                // get or update scheduling
  MRKG_SUPR_MODE            = 'public/mrkg-supr/mode';                    // get or update assignment mode
  MRKG_SUPR_PRIORITY        = 'public/mrkg-supr/priority';                // get or update item priority
  MRKG_SUPR_PERF            = 'public/mrkg-supr/mrkr-perf';               // get 1 marker's performance
  MRKG_SUPR_MRKR_RE_CERT    = 'public/mrkg-supr/mrkr/re-cert';            // require re-certification for 1 marker
  MRKG_SUPR_MRKR_STATUS     = 'public/mrkg-supr/mrkr/status';             // update status (pause, resume) for 1 marker

  MRKG_MRKR_PROFILE         = 'public/mrkg-mrkr/profile';                 // get user profile
  MRKG_MRKR_QUESTIONS       = 'public/mrkg-mrkr/assigned-qs';             // get assigned questions
  MRKG_MRKR_Q_SCORING       = 'public/mrkg-mrkr/question-marking';        // get or update 1 question
  MRKG_MRKR_Q_SCORING_BATCH = 'public/mrkg-mrkr/question-marking-batch';  // get or update multiple questions
  MRKG_MRKR_CERT_TEST       = 'public/mrkg-mrkr/cert-test';               // get or submit required cert test

  MRKG_CAND_PROFILE   = 'public/mrkg-cand/profile';    // get user or set profile
  MRKG_CAND_CERT_TEST = 'public/mrkg-cand/cert-test';  // get or submit required cert test

  MRKG_UPLD_PROFILE          = 'public/mrkg-upld/profile';               // get user profile
  MRKG_UPLD_RESP             = 'public/mrkg-upld/response';              // upload new response, update existing one, or get all/1
  MRKG_UPLD_RESP_TEST_WINDOW = 'public/mrkg-upld/response/test-window';  // update response test window
  MRKG_UPLD_RESP_TEST_FORM   = 'public/mrkg-upld/response/test-form';    // update response test form
  MRKG_UPLD_RESP_TEST_TAKER  = 'public/mrkg-upld/response/test-taker';   // update response test taker id
  MRKG_UPLD_RESP_DOC         = 'public/mrkg-upld/response/doc';          // update response document
}
