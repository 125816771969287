import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { OSSLT_ASSESSMENT_MODULES, OSSLT_ASSESSMENT_MODULES_FR } from '../../ui-student/osslt-page-switcher/data/dummy-session-data';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, EventEmitter, Output, OnDestroy, HostListener, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import {  LangService } from '../../core/lang.service';
import { Router, ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import { Subscription } from 'rxjs';
import { LoginGuardService } from '../../api/login-guard.service';
import { ISectionDef, ITestDef, ISectionMeta, SectionDrawingCtx } from '../sample-questions/data/sections';
import { reject } from 'q';
import { FormControl } from '@angular/forms';
import { AuthService } from '../../api/auth.service';
import { RoutesService } from '../../api/routes.service';
import { TextToSpeechService } from '../text-to-speech.service';
import { checkElementIsEntry, getElementChildren, IQuestionConfig, IQuestionRun } from '../../ui-item-maker/item-set-editor/models';
import { ElementType,IContentElement, IContentElementCanvasPage, IContentElementIframe, IContentElementTextLink, IContentElementCanvas, IEntryStateMcq, IScoredResponse, getElementWeight } from '../models';
import {ChatService} from '../../chat/chat.service';
import { HttpClient } from '@angular/common/http';
import { IMenuTabConfig } from '../../ui-partial/menu-bar/menu-bar.component';
import { TestFormConstructionMethod, IAssessmentFrameworkDetail } from '../../ui-item-maker/item-set-editor/models/assessment-framework';
import { DrawDisplayMode } from '../element-render-drawing/constants';
import { IPanelModuleDef } from '../../ui-testtaker/view-tt-test-runner/view-tt-test-runner.component';
import { randArrEntry } from '../../ui-testadmin/demo-data.service';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { HyperlinkService, ILinkRequest } from '../hyperlink.service'
import { CanvasService, PageSwitch } from '../canvas.service'
import { UrlLoaderService } from '../url-loader.service';
import { DrawingLogService } from '../drawing-log.service';
import { StudentG9ConnectionService } from '../../ui-student/student-g9-connection.service';
import { StyleprofileService } from '../../core/styleprofile.service';
import { getQuestionTitles, getQuestionTitleFromMap } from './util/question-titles';
import { QuestionScore } from '../../ui-item-maker/view-g9-sample/view-g9-sample.component';
import { AccountType } from '../../constants/account-types';
import {identifyQuestionResponseEntries} from '../../ui-item-maker/item-set-editor/models/expected-answer'
import { DataGuardService } from '../../core/data-guard.service';
import { pageNodeDef } from './util/drawings';
import { KNOWN_TEST_RUNNER_TAGS } from './tags/known-tags';
import {DevtoolsDetectService, IDevTools} from "../../core/devtools-detect.service";

export interface ITestState {
  languageCode: string;
  currentSectionIndex: number;
  currentQuestionIndex: number;
  currentReadingPassageId?: number;
  currentModuleId?: number;
  readSelItemId?: number;
  questionStates: any;
  isSubmitted?: boolean;
  notes?: string;
  currentSectionsAllowedIndex?: number;
}

interface ReaderInfo {
  readerId?: string,
  canvasId?: string,
  itemLabel?: string
}

enum ReaderTextMode {
  CLOSED = 'CLOSED',
  HALF = 'HALF',
  FULL = 'FULL',
}

enum PageMode {
  RESULTS_INTRO = 'RESULTS_INTRO',
  RESULTS_SUMMARY = 'RESULTS_SUMMARY',
  RESULTS_INSTRUCTIONS = 'RESULTS_INSTRUCTIONS',
  TEST_RUNNER = 'TEST_RUNNER'
}

export interface ICustomConfirmTestDialogData {
  text: string;
  confirmMsg: string;
  cancelMsg: string;
}

export  const parseQuestionSaveError = (msg: string) => {
  switch (msg) {
    case 'NOT_BOOKED_APPL': return 'msg_no_longer_booked_err';
    case 'ATTEMPT_CLOSED': return 'msg_attempt_closed_err';
    case 'SESSION_CLOSED': return 'msg_session_closed_err';
    case 'SESSION_ENDED': return 'msg_session_ended';
    case 'MARKED_NO_ID': return 'msg_marked_no_id';
    case 'MARKED_ABSENT': return 'msg_marked_absent';
    case 'ATTEMPT_PAUSED': return 'msg_session_pause';
    // case 'NOT_VERIFIED': return 'msg_not_verified';
    case 'NOT_VERIFIED': return 'msg_paused_err';
  }
}

export const handleSubmitTestErr = (e, loginGuard: LoginGuardService, lang: LangService) => {
  loginGuard.confirmationReqActivate({
    caption: lang.tra('msg_may_not_submit_test') + ' ' + lang.tra(parseQuestionSaveError(e.message))
  });
}

@Component({
  selector: 'test-runner',
  templateUrl: './test-runner.component.html',
  styleUrls: ['./test-runner.component.scss']
})
export class TestRunnerComponent implements OnInit,AfterViewInit, OnDestroy, OnChanges {

  @Input() asmtFmrk: IAssessmentFrameworkDetail;
  @Input() attemptKey: string;
  @Input() autoScrollOnSelect: boolean = false;
  @Input() btnReviewSubmit: string = 'btn_review_submit'; // please deprecate
  @Input() checkChat: () => Promise<any>;
  @Input() checkTime: () => Promise<any>;
  @Input() currentSession: Object;
  @Input() currentTestDesign: ITestDef;
  @Input() customConfirmTestDialogData: ICustomConfirmTestDialogData;
  @Input() dateTimeStart: moment.Moment;
  @Input() documentItems: {itemId:number, caption:string}[];
  @Input() exitResults: () => Promise<any>;
  @Input() forceQuestionsSkippable: boolean = false;
  @Input() frameWorkTags: {slug:string}[];
  @Input() goToDashboard: () => any;
  @Input() helpPageItem: number;
  @Input() instit_group_id: number;
  @Input() isChatEnabled: boolean;
  @Input() isExitEnabled: boolean;
  @Input() isPreview: boolean;
  @Input() isHelpEnabled: boolean = true;
  @Input() isIssueReportingEnabled: boolean;
  @Input() isNavFooterDisabled: boolean;
  @Input() isOsslt:boolean; // deprecated
  @Input() isOssltTools:boolean; // deprecated
  @Input() isPrintMode: boolean;
  @Input() isSectionControlsEnabled: boolean = true;
  @Input() isShowingResults:boolean = false; 
  @Input() isShowQuestionLabel: boolean;
  @Input() isText2SpeechEnabled: boolean = true;
  @Input() isTimeEnabled: boolean = true;
  @Input() isToolExploration: boolean; // is this a duplicate of isOssltTools ? please deprecate
  @Input() moduleIdInit: number;
  @Input() noTestConfirmationRequired: boolean = false;
  @Input() questionIndexInit: number;
  @Input() questionSrcDb: Map<number, IQuestionRun>;
  @Input() questionStates: {[key: string]: any};
  @Input() regularTimeRemaining: string;
  @Input() rubricDownloadLink:string;
  @Input() saveQuestion: (data: any) => Promise<any>;
  @Input() sectionIndexInit: number;
  @Input() sectionsAllowed?: number[];
  @Input() sessions: Array<Object> = [];
  @Input() showAnswers: () => Promise<any>;
  @Input() studentG9Connection: StudentG9ConnectionService
  @Input() submitTest: () => Promise<any>;
  @Input() testAttemptId: number
  @Input() testFormId: number;
  @Input() testFormType: string;
  @Input() testLang: string;
  @Input() testSessionId: number;
  @Input() testTakerName: string;
  @Input() testTakerPEN: number;
  @Output() backToMap = new EventEmitter()
  @Output() backToMenu = new EventEmitter()
  @Output() endSection = new EventEmitter();
  @Output() exit = new EventEmitter();
  @Output() questionTitles = new EventEmitter();
  @Output() studentPosition = new EventEmitter();
  @Output() calcState = new EventEmitter();
  @ViewChild('questionDisplay', { static: false }) questionDisplay: ElementRef<HTMLDivElement>;
  @ViewChild('rubricLink') rubricLinkRef : ElementRef;
  @ViewChild('topBar', { static: false }) topBar: ElementRef<HTMLDivElement>;
    
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.updateScreenShrinkFactor();
    this.updateDragZoomCorrection();
  }
 
  constructor(
    public lang: LangService,
    private loginGuard: LoginGuardService,
    private routes: RoutesService,
    private router: Router,
    public auth: AuthService,
    private textToSpeech: TextToSpeechService,
    public chatService: ChatService,
    private whitelabel: WhitelabelService,
    private safeUrl: UrlLoaderService,
    private drawLog: DrawingLogService,
    private hyperLinkService: HyperlinkService,
    private styleProfile: StyleprofileService,
    private changeDetector: ChangeDetectorRef,
    private dataGuard: DataGuardService,
    private canvasService: CanvasService,
    private devtoolsDetect: DevtoolsDetectService,
  ) {
  }

  showSolution;
  defaultZoomLevel = 1.5;
  zoomLevel = this.defaultZoomLevel;
  minZoomLevel = 0.5;
  maxZoomLevel = 3;
  zoomIncrement = 0.25;
  testState: ITestState;
  routeSub: Subscription;
  isSyncing: boolean;
  isShowingSectionInfo: boolean;
  isFormulasToggledOn: boolean;
  isCalcToggledOn: boolean;
  isHighContrast: boolean;
  isHelpOverlay: boolean;
  sectionTimeStarted: number;
  sectionTimeRemaining;
  helpScreenLayout:any;
  ticker;
  currentReadSelection:string;
  currentBookmark:string;
  itemLabel: string;
  readerInfo: ReaderInfo[];
  isSavingResponse: boolean;
  isQuietlySavingResponse: boolean;
  currentModal: any;
  isShowingReadingSelections: boolean;
  isShowingTime;
  isShowingChat;
  isShowingReport;
  questionResaveInterval;
  issueReportMessage = new FormControl();
  isLineReaderActive: boolean;
  isTestNavExpanded: boolean = true;
  isToolbarExpanded: boolean;
  activeReadingSelection: IQuestionConfig;
  // resultQs: {qId: number, sIndex: number, qIndex: number}[] = [];
  resultQs: number[] = [];
  documentViews: IMenuTabConfig<number>[];
  selectedDocumentId:number;
  drawingCtx;
  drawingPageIndexTracker = new Map();
  PageMode = PageMode;
  screenShrink = 1;
  questionScores: Map<number, QuestionScore> = new Map();
  questionPScores: Map<number, number> = new Map();
  screenWidth;
  isShowFormulaSheet = false;
  isShowDocuments = false;
  showEraser = false;
  showLine = false;
  showHighlight = false;
  transValue
  drawMode = '';
  currIEZoom = 100
  isShowOverlay = false;
  showDeactivatedMode = false;
  isNotepadEnabled = false;
  isZoomIn = false;
  isZoomOut = false;
  overlayState ={
    "off":true,
    "on":false,
    "deactivate":false
  }
  pageMode : PageMode;
  pageModeFlow = [
    {slug: PageMode.RESULTS_INTRO, caption: 'tr_results_intro_title'}, 
    {slug: PageMode.RESULTS_SUMMARY, caption: 'tr_results_summary_title', isDetail:true}, 
    {slug: PageMode.RESULTS_INSTRUCTIONS, caption: 'tr_results_instr_title', isDetail:true}, 
    {slug: PageMode.TEST_RUNNER, caption: undefined}
  ];
  pageModeIndex : number;
  iframeUrl
  element:IContentElementIframe = {
     elementType:ElementType.IFRAME,
     url:''
  }
  count = 0; //for progressbar
  totalFilledBySection = {}; //for progressBar
  public numSRQuestions : number;
  public numCorrectSRQuestions : number;
  public numCRQuestions : number;
  public correctSRScore : number;
  public totalSRScore : number;
  public totalCRScore : number;
  frameWorkTagsRef = new Map();
  isInitialized:boolean;
  helpPageState = {};
  KNOWN_TEST_RUNNER_TAGS = KNOWN_TEST_RUNNER_TAGS;
  private devtoolsSub: Subscription;

  ngOnInit() {
    const root = document.getElementsByTagName("html")[0];
    root.style.overflowX="auto";

    this.initTestDef();
    this.initTestState();
    this.initTags()
    if (this.isChatEnabled){
      this.initChatPage();
    }
    if (this.isHelpEnabled){
      this.initHelpPage();
    }
    this.initDocuments();
    this.initHyperlinkService();
    this.initCanvasService();
    this.initTicker();
    this.setDefaultZwibblerContext();
    this.initFirstQuestion();
    this.selectPageMode(this.pageModeFlow.length - 1);
    this.onResize();
    this.checkForImmediateResults().then(()=> {
      this.isInitialized = true;
    });

    if (!this.isPreview){
      this.initializeDevToolsSub()
    }

    if(this.checkIsOsslt()){
      if((this.currentSession !== this.sessions[this.count])){
        this.count++ // increase count; to start check on next Session
      } 
      if( JSON.parse(sessionStorage.getItem("totalFilledBySection")) && (this.currentSession === this.sessions[this.count])){
        this.totalFilledBySection = JSON.parse(sessionStorage.getItem("totalFilledBySection"));
      } 
    }
  }

  checkIsOsslt(){
    return this.isOsslt || this.checkTag('OSSLT'); // non-tag approach is deprecated
  }
  checkIsOssltTools(){
    return this.isOssltTools || this.checkTag('OSSLT_TOOLS'); // non-tag approach is deprecated
  }


  ngOnChanges(changes: SimpleChanges): void {
    if(this.isInitialized && changes.isShowingResults && this.isShowingResults) {
      this.showResults();
    }
  }
  initializeDevToolsSub(){
    var userAgent = window.navigator.userAgent;
    if (!userAgent.match(/iPad/i) && !userAgent.match(/iPhone/i)) {
      this.devtoolsSub = this.devtoolsDetect.sub().subscribe((devtools: IDevTools) => {
        if (devtools) {
          const { isOpen } = devtools
          if (isOpen) {
            alert('DevTools detected.')
            this.router.navigateByUrl(`${this.auth.getDashboardRoute(this.lang.c())}/main`)
          }
        }
      })
    }

  }
  initTags(){
    // console.log('frameWorkTags', this.frameWorkTags);
    if (this.frameWorkTags){
      this.frameWorkTags.forEach(tag => {
        const key = (''+tag.slug).trim();
        this.frameWorkTagsRef.set(key, true);
      })
    }
  }

  checkTag(tag:string){
    return this.frameWorkTagsRef.get(tag);
  }

  
  showResults(){
    this.selectPageMode(0); // Select first page mode
    this.resultQs = [];
    for(let section of this.testRunnerSections) {
      if(!section.disableScoring){
        for(let questionId of section.questions) {
          if(!this.questionSrcDb.get(questionId).isReadingSelectionPage) {
            // this.resultQs.push({qId: questionId, sIndex, qIndex});
            this.resultQs.push(questionId);
          }
        }
      }
    }
  }

  checkForImmediateResults(){
    return Promise.all(
      this.testRunnerSections.map((section, sectionIndex) => {
        return this.ensureSectionPathFill(sectionIndex, false, true)
      })
    )
    .then(() => {
      if(this.isShowingResults && this.isFlushNavigation()) {
        this.showResults();
        this.scoreAllQuestions();
      }
    })
  }

  initDocuments(){
    this.documentViews = [];
    if (this.documentItems){
      Promise.all(
        this.documentItems.map(document => {
          this.documentViews.push({
            id: document.itemId,
            caption: document.caption,
          })
          return this.loadDocument(document.itemId);
        })
      )
      .then(()=>{
        if (this.documentItems){
          const firstDocument = this.documentItems[0];
          if (firstDocument){
            this.selectDocumentView(firstDocument.itemId)
          }
        }
      });
    }
  }

  initCanvasService() {
    this.canvasService.canvasPageNumChanged.subscribe(this.canvasPageChanged)
  }
  
  initHyperlinkService(){
    this.isShowingReadingSelections = false;
    this.hyperLinkService.linkRequest.subscribe(this.onLinkRequest);
    this.hyperLinkService.requestForLinkUpdate.subscribe(this.onLinkRequestFromCanvas);
    this.hyperLinkService.canvasBookmarkChanged.subscribe(this.setLinkInfo);
    this.hyperLinkService.linkRequest.next({
      readerElementId: undefined,
      readerId: undefined
    })
  }

  canvasPageChanged = (data:PageSwitch) => {
    const readerId = data.canvasId
    const pageNum = data.pageNum
    const leftId = this.getActiveQuestionId()
    const rightId = this.testState.readSelItemId
    if (this.isQuestionCanvas(leftId)) {
      const canvasObj = <IContentElementCanvas>this.getActiveQuestionContent().content[0]
      if (canvasObj.readerId == readerId && readerId) {
        this.leftPageId = pageNum;
      }
    }
    if (this.isQuestionCanvas(rightId)) {
      const canvasObj = <IContentElementCanvas>this.getQuestionDef(rightId).content[0]
      if (canvasObj.readerId == readerId && readerId) {
        this.rightPageId = pageNum;
      }
    }
    console.log('canvasPageChanged', this.leftPageId, this.rightPageId)
  }

  initFirstQuestion(){
    this.scrollToQuestion()
    this.lastFillState = this.isQuestionFilled( this.getCurrentQuestionIndex() );
    this.getIframeURL()
    this.initText2Speech();
    this.setActiveReadingSelection();
    if (!this.getCurrentSectionPreambleContent()){
      this.markActiveQuestionAsStarted();
    }
    this.getReadingSelectionCanvases();
    this.reinitReadingSelection()
    this.restartQuestionResaveInterval()
    // console.log ('initFirstQuestion', this.isShowingLeft(), this.isShowingRight())
  }

  initText2Speech(){
    if (this.whitelabel.getSiteFlag('IS_BCED')){
      this.isText2SpeechEnabled = false;
    }
  }

  isProgressBarEnabled(){
    return !this.whitelabel.getSiteFlag('IS_BCED')
  }

  isInfoIconEnabled(){
    return !this.whitelabel.getSiteFlag('IS_BCED')
  }

  isFlushNavigation(){
    return this.whitelabel.getSiteFlag('IS_BCED')
  }

  isUsingTemporaryNotes(){
    return this.checkIsOsslt() || this.whitelabel.getSiteFlag('IS_BCED');
  }

  getSectionSlug(){
    if (this.whitelabel.getSiteFlag('IS_BCED')){
      return 'Part';
    }
    else{
      return 'title_stage'
    }
  }

  getFlagSlug(isUnflag?:boolean){
    if (!isUnflag){
      if (this.whitelabel.getSiteFlag('IS_BCED')){
        return 'btn_flag_question_bc'
      }
      else if (this.checkIsOsslt() || this.checkIsOssltTools()){
        return 'btn_flag_question'
      }
      else{
        return 'btn_flag_question_g9'
      }
    }
    else{
      if (this.whitelabel.getSiteFlag('IS_BCED')){
        return 'btn_unflag_question_bc'
      }
      else if (this.checkIsOsslt() || this.checkIsOssltTools()){
        return 'btn_unflag_question'
      }
      else{
        return 'btn_unflag_question_g9'
      }
    }
  }

  getOpenReadSelSlug(isOpen:boolean){
    
    if (isOpen){
      if (this.whitelabel.getSiteFlag('IS_BCED')){
        return 'btn_hide_read_sel_bc';
      }
      return 'btn_hide_read_sel';
    }
    if (this.whitelabel.getSiteFlag('IS_BCED')){
      return 'btn_view_read_sel_bc';
    }
    return 'btn_view_read_sel';

    
  }

  appendNum(num1, num2){
    return parseInt(num1.toString() + num2.toString())
  }

  setDefaultZwibblerContext() {
    let defaultStr = "zwibbler3."
    const pages = [];
    const obj = {
      id: 0,
      type: "BaseNode"
    }
    
    const addEntry2DrawingPages = (question, questDef)=>{
      if (this.isQuestionCanvas(question)) {
        const canvasContent = <IContentElementCanvas> questDef.content[0]
        // console.log(canvasContent)
        canvasContent.pages.forEach((page:IContentElementCanvasPage, pindex)=>{
          let thisId = this.appendNum(question, pindex)
          //if (pindex==0) thisId = question
          this.drawingPageIndexTracker.set(thisId, index)
          // console.log(thisId, this.drawingPageIndexTracker.get(thisId))
          pages.push(pageNodeDef(nextID))
          index++
          nextID++
        })
      } else {
        pages.push(pageNodeDef(nextID));
        // console.log(question, index)
        this.drawingPageIndexTracker.set(question, index)
        nextID++;
        index++
      }
    }

    

    pages.push(JSON.stringify(obj));
    const questions = this.getCurrentQuestions();
    let nextID = 1000000000
    this.drawingPageIndexTracker.clear();

    let index = 0;
    questions.forEach((question, index)=>{
      const questDef = <IQuestionConfig>this.getQuestionDef(question)
      addEntry2DrawingPages(question, questDef)
    })
    questions.forEach((question)=>{
      const readingSelections = this.getQuestionDef(question).readSelections;
      if (readingSelections) {
        readingSelections.forEach((readSel)=>{
          for (const [key, value] of this.questionSrcDb.entries() ) {
            const questDef = <IQuestionConfig>value
            if (questDef.label == readSel) {
              if (!this.drawingPageIndexTracker.has(key)) {
                addEntry2DrawingPages(key, questDef)
              }
            }
          }
        })
      }
    }) 
    // console.log("Default", JSON.parse("["+pages.toString()+"]"))
    defaultStr += "["+pages.toString()+"]";
    // console.log(defaultStr)
    this.drawingCtx = defaultStr;
    this.resetFlag()    
  }

  getZoomValue(){
    const ua = navigator.userAgent;
    if (ua.indexOf("iPad")!=-1 || ua.indexOf("iPhone")!=-1) {
      if (this.zoomLevel > this.defaultZoomLevel) {
        this.zoomLevel = this.defaultZoomLevel;
      }
    }
    return this.zoomLevel * this.screenShrink
  }

  saveDrawing(drawingContext:SectionDrawingCtx) {
    const drawingSection = drawingContext.section;
    const drawing = drawingContext.ctx;
    if (this.getCurrentSectionIndex() != drawingSection) {
      console.log("different section")
      return;
    }

    console.log("same section");
    if (!this.drawingCtx) {
      this.drawingCtx = drawing
      return;
    }

    const startIndex = drawing.indexOf('[');
    const objStr = drawing.substr(startIndex);
    const obj = JSON.parse(objStr);
    const oldObjStr = this.drawingCtx.substr(this.drawingCtx.indexOf('['));
    const oldObj = JSON.parse(oldObjStr)
    console.log("old obj", oldObj)
    console.log("obj", obj)
    obj.forEach(element => {
      let found = false;
      oldObj.forEach((existing, index)=>{
        if (existing["id"]==element["id"]) {
          found = true;
        }
      })
      if (found) return;
      let parentIndex = -1;
      let placed = false;
      oldObj.forEach((existing, index)=>{
        if (placed==true) return;
        if (existing["id"]==element["parent"] && parentIndex == -1) {
          parentIndex = index;
        } 
        
        if (parentIndex !=-1) {
          if (oldObj.length - 1 >index && oldObj[index+1] && oldObj[index+1]["type"]!="PageNode" ) {
            return;
          }
          let nextIndex = index+1;
          if (oldObj.length - 1 >index) {
            nextIndex--;
          }
          if (index<oldObj.length-1) oldObj.splice(index+1, 0, JSON.parse(JSON.stringify(element)))
          else oldObj.push(JSON.parse(JSON.stringify(element)))
          placed = true;
        }
      })
    });
    this.drawingCtx = "zwibbler3."+JSON.stringify(oldObj)
    // console.log("Saved", JSON.parse(this.drawingCtx.substr(this.drawingCtx.indexOf('[')))) 
  }

  loadDrawing() {
    return this.drawingCtx
  }

  resetter = true;
  resetFlag() {
    this.resetter = false;
    this.changeDetector.detectChanges();
    this.resetter = true;
    setTimeout(() => {
      this.scrollToQuestion()
    }, 100)
  }

  onLinkRequest = (data:ILinkRequest) => {
    if (!data.readerId && !data.readerElementId && !data.itemLabel) {
      this.closePassage()
    } 
    else {
      this.currentReadSelection = data.readerId;
      this.currentBookmark = data.readerElementId;
      this.itemLabel = data.itemLabel;
      if (!data.itemLabel){
        this.testState.currentReadingPassageId = this.getCurrentReadingPassageId();
      }
      else this.testState.currentReadingPassageId = this.getCurrentReadingPassageId();
      if (this.currentReadSelDisplayMode === ReaderTextMode.CLOSED){
        this.readSelViewHalf();
      }
      this.hyperLinkService.linkRequestSecond.next({
        readerElementId: this.currentBookmark,
        readerId: this.currentReadSelection,
        itemLabel: this.itemLabel
      });
      this.onResize();
    }
    
  }

  onLinkRequestFromCanvas = (data:ILinkRequest) => {
    if (this.currentBookmark != data.readerElementId || this.currentReadSelection != data.readerId) {
      this.hyperLinkService.linkRequestSecond.next({
        readerElementId: this.currentBookmark,
        readerId: this.currentReadSelection,
      })
      this.onResize();
    }
  }

  setLinkInfo = (data:ILinkRequest) => {
    // console.log("Test runner unset Bookmark")
    this.currentReadSelection = data.readerId;
    this.currentBookmark = data.readerElementId;
    this.leftPageId = 0;
    this.rightPageId = 0;
    this.itemLabel = undefined;
  }


  getCurrentReadingPassageId() {
    const questions = this.getCurrentQuestions();
    let readingPassageQuestionID = undefined;
    questions.forEach((questID)=>{
      const question = this.getQuestionDef(questID);
      const content = question.content;
      content.forEach((element)=>{
        if (element.elementType == ElementType.CANVAS ) {
          if (element["readerId"] == this.currentReadSelection) {
            readingPassageQuestionID = questID;
          }
        }
      })
    })
    return readingPassageQuestionID;
  }

  isQuestionCanvas(id) {
    const questDef = this.getQuestionDef(id);
    if (questDef && questDef.content && questDef.content.length>0) {
      if (questDef.content[0].elementType == ElementType.CANVAS && questDef.content[0]["pages"]) {
        return true;
      }
    }
    return false;
  }

  leftPageId=0;
  rightPageId=0;
  getCurrentLeftItemId() {
    if (this.isQuestionCanvas(this.getActiveQuestionId())) {
      return this.appendNum(this.getActiveQuestionId(), this.leftPageId)
    } else {
      return this.getActiveQuestionId()
    }
  }

  getCurrentRightItemId() {
    if (this.isQuestionCanvas(this.testState.readSelItemId)) {
      return this.appendNum(this.testState.readSelItemId, this.rightPageId)
    } else {
      return this.testState.readSelItemId;
    }
  }

  convertReadSelToTextLink(readerId) {
    let textLinkEl = {};
    if (readerId.canvasId) {
      textLinkEl = {
        readerId: readerId.canvasId,
        caption: readerId.caption,
        elementType: ElementType.TEXT_LINK,
        itemLabel: undefined
      } 
    } 
    else if (readerId.itemLabel) {
      textLinkEl = {
        readerId: undefined,
        caption: readerId.caption,
        elementType: ElementType.TEXT_LINK,
        itemLabel: readerId.itemLabel
      } 
    }
    
    return textLinkEl
  }

  isWidthConstrained() {
    return this.whitelabel.getSiteFlag("TEST_RUNNER_WIDTH_CONSTRAINT");
  }

  flagCurrentQuestion = () => {
    const qState = this.getActiveQuestionState()
    qState.__meta.isFlagged = !qState.__meta.isFlagged;
  }

  flagKeyPress = (event: KeyboardEvent) => {
      this.flagCurrentQuestion()
  }

  documentMap:Map<number, IQuestionConfig> = new Map();
  activeDocument
  loadDocument(itemId:number){
    return new Promise((resolve, reject) => {
      const item = <any>this.questionSrcDb.get(+itemId);
      // console.log(+itemId, item)
      this.documentMap.set(+itemId, item);
      resolve(item);
    })
  }

  selectDocumentView(itemId:number){
    this.selectedDocumentId = +itemId;
    // console.log('this.activeDocument', +itemId,  this.activeDocument)
    this.activeDocument = this.documentMap.get(+itemId);

    this.logStudentAction("STUDENT_ACCESS_DOC", this.activeDocument);
  }

  ngAfterViewInit(){

  }

  lastFillState:boolean;
  checkAutoScroll(){
    const currentFillState = this.isQuestionFilled( this.getCurrentQuestionIndex() );
    if (currentFillState !== this.lastFillState){
      if (this.autoScrollOnSelect && currentFillState){
        const el = document.getElementById('btn-submit-question');
        if (el){
          el.scrollIntoView({behavior: 'smooth', block: 'end'});
        }
      }
      this.lastFillState = currentFillState;
    }
  }

  getQuestionRunnerWidth(containerId:string){
    return 40;
  }
  getQuestionRunnerSpillover(containerId:string){
    return 0;
  }

  getIframeURL(){
    let url = window.location.protocol + "//" + window.location.host + "/assets/sci_calc/index.html"
    this.element.url = url;

  }
  setEraser(){
    this.showLine = false;
    this.showHighlight = false;
    this.toggleZwibbler();
    if(!this.overlayState.deactivate && this.showEraser){
      this.disableOverlay()
    }else{
      this.showEraser = true;
    }
    this.logTool("SHOW_ERASER", this.showEraser);
  }
  setHighlighter(){
    this.showEraser = false;
    this.showLine = false;
    this.toggleZwibbler()
    if (!this.overlayState.deactivate && this.showHighlight){
      this.disableOverlay()
    }else{
      this.showHighlight = true;
    }
    this.logTool("SHOW_HIGHLIGHT", this.showHighlight);
  }
  setLine(){
    this.showHighlight = false;
    this.showEraser = false;
    this.toggleZwibbler();
    if (!this.overlayState.deactivate && this.showLine){
      this.disableOverlay()
    }else{
      this.showLine = true;
    }
    this.logTool("SHOW_LINE", this.showLine);
  }
  toggleZwibbler(){
   if (this.overlayState.off){
    this.overlayState.off = false;
     this.overlayState.on = true;
     this.isShowOverlay = true;
     return;
   }
   if (this.overlayState.deactivate){
     this.overlayState.deactivate = false;
     this.showDeactivatedMode = false;
   }
  }
  disableOverlay(){
    this.showEraser = false;
    this.showHighlight = false;
    this.showLine = false;
    if (!this.overlayState.deactivate){
         this.overlayState.deactivate = true;
         this.showDeactivatedMode = true;
       }
  }

  getDrawingDisplayMode(){
    return DrawDisplayMode.TEST_RUNNER;
  }

  initTicker(){
    this.sectionTimeStarted = (new Date()).valueOf();
    // to do: this is not correct... it should be when they start a section and it should be persisted to the db
    this.ticker = setInterval(() => {
      const section = this.getCurrentSection();
      // console.log('section', section)
      this.checkAutoScroll(); // this has nothing to do with the timer countdown disp[lay]
      if (section && section.isTimeLimit) {
        let secondsRemaining = this.getCurrentSection().timeLimitMinutes * 60;
        let secondsSpent = ((new Date()).valueOf() - this.sectionTimeStarted) / 1000;
        secondsRemaining -= secondsSpent;
        let secondsDisplay = Math.round(secondsRemaining % 60);
        // console.log('getSectionTimeRemaining', secondsSpent)
        let minutesDisplay = Math.round((secondsRemaining - secondsDisplay) / 60);
        this.sectionTimeRemaining = this.leadingZero(minutesDisplay) + ':' + this.leadingZero(secondsDisplay);
      }
    }, 100);
    
    this.dataGuard.forceSaveSub().subscribe(req => {
      if (req){
        this._saveQuestion(true);
      }
    })
  }

  initChatPage(){
    this.chatService.isSupervisor = false;
    this.chatService.isInvigilator = false;
    this.chatService.isTestTaker = true;
    this.chatService.uid = this.auth.user().value.uid;
    this.chatService.markingPoolId = this.testSessionId;
    // this.chatService.selectedMarker = this.markerId;
    this.chatService.instit_group_id = this.instit_group_id;
    this.chatService.initSocket();
  }

  initHelpPage(){
    if (this.helpPageItem){
      this.helpScreenLayout = this.questionSrcDb.get(+this.helpPageItem);
    }
    else{
      return this.auth.apiGet(this.routes.TEST_TAKER_DATA_DOWNLOAD, 1)
        .then(helpScreenLayout =>{
          this.helpScreenLayout = <any> helpScreenLayout;
        })
    }
  }

  private clearQuestionResaveInterval(){
    if (this.questionResaveInterval){
      clearInterval(this.questionResaveInterval);
    }
  }

  private restartQuestionResaveInterval(){
    this.clearQuestionResaveInterval();
    const ITEM_RESAVE_INTERVAL = 60*1000
    this.questionResaveInterval = setInterval(() => {
      if (!this.isShowingResults){
        this._saveQuestion(true);
      }
    }, ITEM_RESAVE_INTERVAL)
  }
  
  ngOnDestroy() {
    clearInterval(this.ticker);
    this.clearQuestionResaveInterval();
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if (this.devtoolsSub) {
      this.devtoolsSub.unsubscribe()
    }
  }
  toggleTextToSpeech() {
    this.textToSpeech.toggle();
    this.logTool("TTS", this.textToSpeech.isActive);
  }
  isTextToSpeechActive() {
    return this.textToSpeech.isActive;
  }

  toggleHelpScreen() {
    if(this.isHelpOverlay){
      // document.getElementById("quest-cont").style.display = "flex"
      this.isHelpOverlay = false;
    }
    else{
      // document.getElementById("quest-cont").style.display = "none"
      this.isHelpOverlay = true;
    }

    this.logTool("HELP", this.isHelpOverlay);
  }
  getHelpScreenLayout(){
    //console.log(this.helpScreenLayout);
    return this.helpScreenLayout;
  }
  private _saveQuestion(isQuietSave:boolean=false) {
    if (this.isSavingResponse || this.isShowingResults) {
      reject();
    }
    this.isSavingResponse = true;
    this.isQuietlySavingResponse = isQuietSave;
    return new Promise( async (resolve, reject) => {
      const content = this.getActiveQuestionContent();
      const test_question_version_id = content ? content.test_question_version_id : 0;

      const saveQuestionAndHandleErrors = () => {
        this.isSavingResponse = true;
        this.isQuietlySavingResponse = isQuietSave;
        this.saveQuestion({
              test_question_id: this.getActiveQuestionId(),
              test_question_version_id,
              question_index: this.getCurrentQuestionIndex(),
              section_index: this.getCurrentSectionIndex(),
              module_id: this.getCurrentModuleId(),
              response_raw: JSON.stringify(this.getActiveQuestionState()),
              response: this.getActiveQuestionResponse(),
        }).then((res) => {
          if (res && res.activeSubSession){
  
          }
          this.isSavingResponse = false;
          this.isQuietlySavingResponse = false;
          this.logDrawing();
          resolve();
        }).catch((e) => {
          this.isSavingResponse = false;
          this.isQuietlySavingResponse = false;
          const questionSaveErr = parseQuestionSaveError(e.message);
  
          if(!questionSaveErr) {
            this.loginGuard.confirmationReqActivate({
              caption: 'msg_save_question_err',
              hideCancel: true,
              btnProceedCaption: 'btn_retry',
              confirm: () => {
                saveQuestionAndHandleErrors();
              }
            })
          } else {
            this.loginGuard.confirmationReqActivate({
              caption: this.lang.tra('msg_cannot_proceed_test') + '' + this.lang.tra(questionSaveErr)
            });
            reject(e);
          }
        });
      }

      saveQuestionAndHandleErrors();
    });
  }

  getCurrentModuleId(){
    return this.testState.currentModuleId;
  }

  getLogo(){
    let url;
    if (this.lang.c() === 'en'){
      url = this.whitelabel.getSiteText('asmt_logo_en')
    }
    else if (this.lang.c() === 'fr'){
      url = this.whitelabel.getSiteText('asmt_logo_fr')
    }
    return url;
  }

  getLogoSafe(){
    return this.safeUrl.sanitize(this.getLogo());
  }

  getActiveQuestionResponse() {
    const state = this.getActiveQuestionState();
    const responses = [];
    const entries = this.getQuestionStateEntries(state);
    entries.forEach(eRes => {
      if (eRes.selections && eRes.selections[0]) {
        const entryResponses = eRes.selections.map(s => s.i);
        responses.push(entryResponses.join(','));
      }
    });
    return responses.join(';');
  }

  // getRouteParams(routeParams: any) {
  //   this.initTestDef();
  //   this.initTestState();
  // }

  testRunnerSections: Partial<ISectionDef>[];
  testRunnerPathSections: Map<number, ISectionDef[]>;
  initTestDef() {
    // reconstruct section order (taking into account choice paths)
    this.testRunnerSections = [];
    this.testRunnerPathSections = new Map();
    let currentConditional:{itemLabel:string, pathSectionList:ISectionDef[]};
    this.currentTestDesign.sections.forEach(section => {
      if (section.isConditional){
        if (currentConditional && currentConditional.itemLabel === section.conditionOnItem){
          currentConditional.pathSectionList.push(section);
        }
        else{
          currentConditional = {
            itemLabel: section.conditionOnItem, 
            pathSectionList: [section]
          }
          const nextIndex = this.testRunnerSections.length;
          this.testRunnerSections.push({
            questions:[],
          });
          this.testRunnerPathSections.set(nextIndex, currentConditional.pathSectionList);
        }
      }
      else{
        this.testRunnerSections.push(section)
      }
    });
    // compute section meta (mostly for the progress bar)
    let qsPrec = 0;
    this.testRunnerSections.forEach(section => {
      const qs = section.questions.length;
      let meta: ISectionMeta = { qs, qsPrec, };
      section.__meta = meta;
      qsPrec += qs;
    });
    const qsTotal = qsPrec;
    // store the total number of questions
    this.currentTestDesign.__meta = {qs: qsTotal};
    // compute the position of the marker on the progress bar
    this.testRunnerSections.forEach(section => {
      const m = section.__meta;
      const qIG = m.qsPrec + m.qs;
      const proportion = qIG / qsTotal;
      const markLoc = this.renderLocProp(proportion);
      section.__meta.markLoc = markLoc;
    });
    // in case we are restoring, initialize path fills
    
  }

  updateScreenShrinkFactor(){
    this.screenWidth = window.innerWidth;
    let baseW = 900;
    const isSplitScreen = this.isShowingLeft() && this.isShowingRight();
    if (isSplitScreen){
      baseW = 1400 
    }
    baseW = baseW * this.defaultZoomLevel;
    if (this.screenWidth < baseW){
      this.screenShrink = this.screenWidth / baseW;
    }
    else if (!isSplitScreen) { // && this.isShowingRight()
      this.screenShrink = Math.min(3000, this.screenWidth) / baseW;
    }
  }

  updateDragZoomCorrection(){
    const cdkStyle:any = document.getElementById('cdk-font-size-override');
    if (cdkStyle){
      if (this.isFlushNavigation()){
        cdkStyle.innerText = `.cdk-drag-preview { font-size: ${this.zoomLevel*this.screenShrink}em; }`;
      }
      else{
        cdkStyle.innerText = `.cdk-drag-preview { font-size: ${this.zoomLevel}em; }`;
      }
    }
  }

  private renderLocProp(p: number, asNum: boolean= false) {
    return Math.round(100 * p) + (asNum ? '' : '%');
  }

  showSectionInfo() {
    if (this.getCurrentSectionPreambleContent()) {
      this.isShowingSectionInfo = true;
    }
    this.scrollToQuestion();
  }
  hideSectionInfo() {
    this.isShowingSectionInfo = false;
    this.lastFillState = this.isQuestionFilled(this.getCurrentQuestionIndex());
    this.markActiveQuestionAsStarted();
    this.scrollToQuestion();
  }

  defaultProgressContainer = {};
  getProgressContainer(){
    return OSSLT_ASSESSMENT_MODULES || this.defaultProgressContainer;
  }
  getCurrentProgressBySession(session: Object, indexStart: number){
    let qsTotal = 0;
    let totalFilled = 0;
    
    const container = this.getProgressContainer();

    if(this.count > 0){ indexStart = indexStart + container[this.count].length; }

    if(session === this.sessions[this.count]){
      const currSection = this.getCurrentSection(); 
      const currSectionIndex = this.getCurrentSectionIndex();
      const numUnfilledQInSection = this.countNumCurrentQuestionsUnfilled();
      let totalFilledInSection = currSection.questions.length - numUnfilledQInSection;
      this.totalFilledBySection[currSectionIndex] = totalFilledInSection;
      
      for(let el in this.totalFilledBySection ){
        if(this.totalFilledBySection.hasOwnProperty(el)){
          totalFilled += parseInt(this.totalFilledBySection[el]) //sum of all totalFilledBysection
        }
      }

      for( let i=indexStart; i< container[this.count].length+indexStart; i++ ){
        qsTotal += this.currentTestDesign.sections[i].questions.length;
      }
      const proportion = totalFilled / qsTotal; // sum of all totalFilledBySection divided by the total num of questions in session

    return this.renderLocProp(proportion, true);
    } 
  }


  getCurrentProgressLoc(asNum: boolean= false) {
    const section = this.getCurrentSection();
    const qIR = this.getCurrentQuestionIndex() + 1;
    const qsTotal = this.currentTestDesign.__meta.qs;
    const qIG = section.__meta.qsPrec + qIR;
    const proportion = qIG / qsTotal;
    return this.renderLocProp(proportion, asNum);
  }

  initTestState() {

    let sectionIndex = this.sectionIndexInit;
    let sectionsAllowedIndex = undefined;
    if(this.sectionsAllowed) {
      sectionsAllowedIndex = 0;
      sectionIndex = Math.max(sectionIndex, this.sectionsAllowed[sectionsAllowedIndex]);
    }
    // console.log('questionStates', this.questionStates)
    this.testState = {
      languageCode: this.testLang,
      currentSectionIndex: sectionIndex,
      currentQuestionIndex: this.questionIndexInit,
      currentModuleId: this.moduleIdInit,
      questionStates: this.questionStates,
      currentSectionsAllowedIndex: sectionsAllowedIndex
    };

    if (this.testFormType === TestFormConstructionMethod.MSCAT){
      // this.loadNextModule();
      if (this.testState.currentSectionIndex > 0){
        let targetModuleId = this.testState.currentModuleId;
        if (!targetModuleId){
          targetModuleId = this.getNextModuleId(this.testState.currentSectionIndex-1);
        }
        this.loadQuestionsForModuleId(+targetModuleId, this.testState.currentSectionIndex);
      } 
      else {
        this.testState.currentModuleId = this.getSection(this.testState.currentSectionIndex).moduleId;
      }
    }
    this.initSection();

    if (this.testState.currentQuestionIndex === 0) {
      if (this.getCurrentSectionPreambleContent()) {
        this.isShowingSectionInfo = true;
      }
    }

  }

  questionTitleMap;
  initSection(){
    this.initQuestionAllTitles();
  }
  
  initQuestionAllTitles(){
    this.questionTitleMap = getQuestionTitles(
      this.currentTestDesign.sections, 
      this.questionSrcDb, 
      this.currentTestDesign.useQuestionLabel, 
      this.getQuestionWord(),
      this.lang
    );
    this.questionTitles.emit(this.questionTitleMap);
  }

  useSectionCaptions(){
    return this.currentTestDesign.useSectionCaptions;
  }

  getCurrentSectionCaption(){
    const section = this.getCurrentSection();
    return section.caption
  }

  activateModal(caption: string, confirm: any, btnProceedCaption?:string, btnCancelCaption?:string) {
    this.loginGuard.confirmationReqActivate({
      caption,
      confirm,
      btnProceedCaption,
      btnCancelCaption,
    });
  }

  getNextPageSlug(){
    if (this.checkIsOssltTools()){
      return 'osslt_next_page'
    }
    else{
      return 'btn_next_question'
    }
  }

  getCloseDrawingSlug(){
    if(this.checkIsOsslt()){
      return 'draw_tool_exit';

    } else{
      return 'draw_tool_exit_g9';
    }
  }

  allowQuickCollapse(){
    if (this.checkTag(KNOWN_TEST_RUNNER_TAGS.NO_LEFT_COLLAPSE)){
      return false;
    }
    if (this.whitelabel.getSiteFlag('IS_EQAO')){
      if (!this.checkTag(KNOWN_TEST_RUNNER_TAGS.ALLOW_LEFT_COLLAPSE)){
        return false
      }
    }    
    return true;
  }

  areTestQuestionsSkippable(){
    return !!this.getCurrentSection().areQuestionsSkippable;
  }


  isFormulasAvailable() {
    return this.getCurrentSection().hasFormulas;
  }
  isCalcAvailable() {
    return this.getCurrentSection().hasCalculator;
  }

  getCurrentSectionIndex(): number {
    if (this.testState) {
      return this.testState.currentSectionIndex;
    }
    return -1;
  }
  getCurrentQuestionIndex(): number {
    if (this.testState) {
      return this.testState.currentQuestionIndex;
    }
    return -1;
  }
  getCurrentQuestionStates(): any {
    if (this.testState) {
      return this.testState.questionStates;
    }
    return {};
  }
  getCurrentSection(): ISectionDef {
    return this.getSection(this.getCurrentSectionIndex());
  }

  leadingZero(num) {
    if (num < 10) {
      return '0' + num;
    }
    return num;
  }
  getSectionTimeRemaining() {

  }

  getSection(i: number) {
    return this.testRunnerSections[i] || <any> {questions: []};
  }

  getCurrentQuestions(): number[] {
    if(this.isShowingResults) {
      return this.resultQs;
    } else {
      return (this.getCurrentSection()).questions || [];
    }
  }

  getResultsQuestions(): any[] {
    return this.resultQs;
  }

  getQuestionWordSlug() {
    return this.currentTestDesign.questionWordSlug ? this.currentTestDesign.questionWordSlug : "title_question";
  }

  getQuestionWord() {
    return this.lang.tra(this.getQuestionWordSlug());
  }

  getCurrentQuestionTitle(){
    return (this.getQuestionTitle(this.getCurrentQuestionIndex())) ;
  }

  getQuestionTitle(qIndex: number):string {
    const qId = this.getCurrentQuestions()[qIndex];
    return this.getQuestionTitleFromId(qId);
  }


  getQuestionTitleFromId(qId: number):string {
    return getQuestionTitleFromMap(this.questionTitleMap, qId);
  }

  getCurrentPoints(){
    const question = this.getActiveQuestionContent();
    if (question){
      return question.points;
    }
  }

  getActiveQuestionId() {
    const qId = this.getCurrentQuestions()[this.getCurrentQuestionIndex()];
    if (!qId) {
      // console.warn('Null question');
    }
    return qId;
  }

  getSectionPreambleContent(section: ISectionDef) {
    const content = this.getQuestionDef(section.preamble);
    return content;
  }
  getCurrentSectionPreambleContent() {
    // replaced getSectionInfoContent
    const section = this.getCurrentSection();
    return this.getSectionPreambleContent(section);
  }

  getActiveQuestionContent() {
    const content = this.getQuestionDef(this.getActiveQuestionId());
    // console.log('content', content)
    return content;
  }

  setActiveReadingSelection() {
    this.activeReadingSelection = undefined;
    const id = this.getActiveQuestionReadSel();
  }

  getReadingSelections() {
    const question = this.getQuestionDef(this.getActiveQuestionId());
    if (question){
      return question.readSelections;
    }
  }

  private getQuestionsByLabel(){
    const questionMap:Map<string, IQuestionConfig> = new Map()
    this.questionSrcDb.forEach((question: IQuestionConfig)=>{
      questionMap.set(question.label, question);
    });
    return questionMap
  }

  getCurrentZoomDisplay(){
    return Math.floor( 100*(this.zoomLevel/this.defaultZoomLevel) ) + '%';
  }

  getReadingSelectionCanvases() {
    const readers = [];
    const readSels = this.getReadingSelections();
    const questionMap = this.getQuestionsByLabel();
    const trackReaderLink = (question:IQuestionConfig, canvasElement?:IContentElementCanvas) => {
      let caption, canvasId;
      if (canvasElement){
        caption = canvasElement.caption
        canvasId = canvasElement.readerId
      }
      if (!caption){
        caption = question.caption || question.label;
      }
      readers.push({
        canvasId, 
        readerId: question.label, 
        caption,
        itemLabel: question.label
      })
    }
    if (readSels) {
      readSels.forEach((itemLabel)=>{
        const question = questionMap.get(itemLabel);
        let isAtLeastOneCanvasFound = false;
        if (question.isReadingSelectionPage){
          question.content.forEach((element:IContentElement)=>{
            if (element.elementType == ElementType.CANVAS) {
              const canvasElement = <IContentElementCanvas> element;
              trackReaderLink(question, canvasElement);
              isAtLeastOneCanvasFound = true;
            }
          })
        }
        if (!isAtLeastOneCanvasFound) {
          trackReaderLink(question);
        }
      })
    }
    this.readerInfo = readers;
    // console.log('readerInfo', this.readerInfo)
    return readers;
  }

  hasReadingSelections() {
    const readSels = this.getReadingSelections();
    if (readSels && readSels.length>1) return true;
    return false;
  }

  hasExactlyOneReadingSelection() {
    const readSels = this.getReadingSelections();
    if (readSels && readSels.length==1) {
      return true;
    }
    return false;
  }

  isReadingSelectionListToggle() {
    return this.getActiveQuestionContent().isReadingSelectionsAlwaysShown;
  }

  getActiveQuestionReadSel() {
    const readSelections = this.getReadingSelections();
    if (!readSelections) return undefined;
    readSelections.forEach((candReadSel)=>{
      this.questionSrcDb.forEach((question:IQuestionConfig, id:number) => {
        if (question.label===candReadSel) {
          if (question.label === this.itemLabel){
            this.testState.readSelItemId = id;
            this.activeReadingSelection = question;
          } else {
            question.content.forEach((element)=>{
              if (element.elementType==ElementType.CANVAS && element["readerId"]==this.currentReadSelection) {
                if (this.activeReadingSelection != question) {
                  this.activeReadingSelection = question;
                  this.testState.readSelItemId = id;
                }
              }
            })
          }
        }
      })
    })
    //console.log(readSel)
    if (this.activeReadingSelection) return this.activeReadingSelection.label;
    return undefined;
  }

  getCurrentQuestionId(){
    const config = this.getActiveQuestionContent();
  }

  isCurrentQuestionFilled(entryIds?:string[]) {
    return this.isQuestionFilled(this.getCurrentQuestionIndex(), entryIds);
  }

  isQuestionFlaggedSection(sIndex: number, qIndex: number) {

  }

  isQuestionFlagged(qIndex: number) {
    let qState = this.getQuestionStateByIndex(qIndex);
    if (qState){
      return qState.__meta.isFlagged;
    }
  }

  getQuestionStateEntries(qState:{[key:string]:any}){
    const entryIdsUnfiltered = Object.keys(qState);
    const entryIds = entryIdsUnfiltered.filter(str => str !== '__meta' );
    return entryIds.map(entryId => {
      qState[entryId].entryId = entryId
      return qState[entryId];
    })
  }

  isQuestionFilled(qIndex: number, entryIds?:string[]) {
    let qState = this.getQuestionStateByIndex(qIndex);
    const hasSpecReqEntries = (entryIds && entryIds.length > 0);
    if (qState) {
      try {
        let isAllFilled = true;
        let numSpecFilled = 0;
        const entries = this.getQuestionStateEntries(qState);
        if (entries.length == 0 && !hasSpecReqEntries){
          return qState.__meta.isStarted; // this means that the state has been initialized (by the user accessing the item, but there is nothing to be filled)
        }
        entries.forEach(entry => {
          if (!entry.isFilled) {
            isAllFilled = false;
          }
          if (hasSpecReqEntries && (entryIds.indexOf(''+entry.entryId) !== -1)  ){
            if (entry.isFilled){
              numSpecFilled ++
            }
          }
        });
        if (hasSpecReqEntries && numSpecFilled < entryIds.length){
          return false;
        }
        return isAllFilled;
      } 
      catch (e) {
        return false;
      }
    }
    return false;
  }

  containsWideLoad() {
    const question = this.getActiveQuestionContent();
    let isMatch = false;
    if (question) {
      question.content.forEach( element => {
        if (element.elementType === ElementType.SBS) {
          isMatch = true;
        }
      });
    }
    return isMatch;
  }

  getQuestionStateByIndex(qIndex:number){
    const questions = (this.getCurrentQuestions() || []);
    const qId = questions[qIndex];
    return this.getQuestionState(qId);
  }

  getQuestionState(qId:number){
    const states = this.getCurrentQuestionStates();
    let qState = states[qId];
    if (!qState) {
      qState = states[qId] = {};
    }
    if (!qState.__meta){
      qState.__meta = {};
    }
    return qState;
  }
  getActiveQuestionState() {
    const qId = this.getActiveQuestionId();
    return this.getQuestionState(qId)
  }

  getActiveReadingSelectionId() {
    const rsId = this.testState.readSelItemId;
    return rsId;
  }

  getReadingSelectionState() {
    const qId = this.getActiveReadingSelectionId()
    const state = this.getQuestionState(qId)
    return state;
  }

  getQuestionDef(questionId: number) {
    return <IQuestionConfig> this.questionSrcDb.get(questionId);
  }
  getQuestionLabel(questionId: number) {
    const question = this.getQuestionDef(questionId);
    if (question){
      return question.label;
    }
  }

  showCurrentQuestionLabel(){
    alert("Item Label for Lookup: " + this.getQuestionLabel(this.getActiveQuestionId()))
  }

  scrollQuestionIntoView() {
    // const el = this.questionDisplay.nativeElement;
    // el.scrollIntoView();
  }

  updatePosition(sectionIndex :number, questionIndex :number) {
    let differentSection = false;
    if (sectionIndex != this.testState.currentSectionIndex) {
      differentSection = true;
    }
    this.testState.currentSectionIndex = sectionIndex;
    const curentQuestionIndex = parseInt(this.getQuestionTitle(questionIndex).split(' ')[1]) - 1;
    if (questionIndex != curentQuestionIndex) {
      this.testState.currentQuestionIndex = curentQuestionIndex
    }
    else{
      this.testState.currentQuestionIndex = questionIndex;
    }
    this.rightPageId = 0;
    this.leftPageId = 0
    const positionData = {
      stageIndex: this.testState.currentSectionIndex,
        questionIndex: this.testState.currentQuestionIndex,
    }
    this.studentPosition.emit(positionData);
    if(this.studentG9Connection) {
      this.studentG9Connection.updateStudentPosition({
        stageIndex: this.testState.currentSectionIndex,
        questionIndex: this.testState.currentQuestionIndex,
      });
    }
    if (differentSection) {
      this.setDefaultZwibblerContext();
    }
  }
 

  selectSectionAndQuestion(sectionIndex, questionIndex) {
    this._saveQuestion().then(() => {
      if (sectionIndex !== this.testState.currentSectionIndex) {
        this.sectionTimeStarted = (new Date()).valueOf();
      }
      this.updatePosition(sectionIndex, questionIndex);
      this.scrollQuestionIntoView();
    });
  }

  logDrawing() {
    this.drawLog.commitLogAndClear(this.testState, this.testSessionId);
  }

  getLineReaderCaption(){
    if (this.checkIsOsslt() || this.checkIsOssltTools()){
      return 'btn_line_reader_osslt';
    }
    else {
      return 'btn_line_reader';
    }
  }

  clearReadingSelection() {
    this.activeReadingSelection = undefined;
    this.currentReadSelection = undefined;
    this.currentBookmark = undefined
    this.itemLabel = undefined;
    this.testState.currentReadingPassageId = undefined;
  }

  questionResetter = true;
  selectQuestion(questionIndex) {
    this.questionResetter = false;
    this.changeDetector.detectChanges();
    this.restartQuestionResaveInterval();
    this.selectPageMode(this.pageModeFlow.length - 1); //index of the TEST_RUNNER page mode
    if(questionIndex < 0) {
      return;
    }
    this.isShowingSectionInfo = false;
    this.isHelpOverlay = false;
    // document.getElementById("quest-cont").style.display = "flex"
    if(!this.showDeactivatedMode && this.isShowOverlay){
      this.disableOverlay()
    }

    const updateQuestionIndex = () => {
      this.updatePosition(this.testState.currentSectionIndex, questionIndex);
      this.testState.currentQuestionIndex = questionIndex;
      this.markActiveQuestionAsStarted();
      this.reinitReadingSelection()
      this.clearTools();
      this.lastFillState = this.isQuestionFilled(questionIndex);
      this.scrollToQuestion();
    }
    if(this.isShowingResults) {
      updateQuestionIndex();
    } 
    else {
      this._saveQuestion().then(updateQuestionIndex);
    }
    this.questionResetter = true;
    this.changeDetector.detectChanges();
    this.restartQuestionResaveInterval();
  }
  
  currentReadSelDisplayMode:ReaderTextMode;
  reinitReadingSelection(){
    const lastReadSelId = this.currentReadSelection;
    // this.hyperLinkService.linkRequest.next({
    //   readerElementId: undefined,
    //   readerId: undefined,
    // });
    this.getReadingSelectionCanvases();
    this.readerInfo && this.readerInfo.length
    const nextReadSel = this.readerInfo ? this.readerInfo[0] : null;
    const isSameReadingSelection = nextReadSel && ((lastReadSelId === nextReadSel.canvasId) || (lastReadSelId === nextReadSel.readerId))
    if ( !nextReadSel || !isSameReadingSelection){
      this.clearReadingSelection();
      this.isShowingReadingSelections = false;
      this.currentReadSelDisplayMode = ReaderTextMode.CLOSED;
      this.hyperLinkService.linkRequest.next({})
      this.resetFlag();
    }
    if (nextReadSel && this.getActiveQuestionContent().isStartHalf){
      this.currentReadSelDisplayMode = ReaderTextMode.HALF;
      if (!isSameReadingSelection){
        this.openDefaultTextLink();
        this.resetFlag();
      }
    }
    else if (isSameReadingSelection){
      this.currentReadSelDisplayMode = ReaderTextMode.HALF;
    }

    if (isSameReadingSelection){
      this.canvasService.bump()
    }
    this.onResize();
  }
  readSelViewFull(){
    this.currentReadSelDisplayMode = ReaderTextMode.FULL;
    this.onResize();
  }
  readSelViewHalf(){
    this.currentReadSelDisplayMode = ReaderTextMode.HALF;
    this.onResize();
  }

  readSelViewClose(){
    console.log('readSelViewClose')
    this.currentReadSelDisplayMode = ReaderTextMode.CLOSED;
    this.hyperLinkService.linkRequest.next({});
    this.onResize();
  }

  isShowingLeft(){
    if(this.isShowingResults) {
      return true;
    }
    if (this.getActiveQuestionReadSel()){
      switch(this.currentReadSelDisplayMode){
        case ReaderTextMode.FULL:
            return false;
        case ReaderTextMode.HALF:
        case ReaderTextMode.CLOSED:
            return true;
      }
    }
    return true;
  }
  isShowingRight(){
    if(this.isShowingResults) {
      return false;
    }
    if (this.getActiveQuestionReadSel()){
      switch(this.currentReadSelDisplayMode){
        case ReaderTextMode.CLOSED:
          return false;
        case ReaderTextMode.FULL:
        case ReaderTextMode.HALF:
            return true;
      }
    }
    return false;
  }

  private scrollElsToByClass = (ids:string, isToBott:boolean=false, positionY?:number) => {
    const elements = document.getElementsByClassName(ids);
    if (elements){
      for (let i=0; i<=elements.length; i++){
        const el = elements[i];
        if (el){
          if (positionY === undefined){
            if (isToBott){
              positionY = el.scrollHeight;
            }
            else {
              positionY = 0;
            }
          }
          el.scrollTo(0, positionY)
        }
      }
    }
  }

  questionNavScrollToEnd(){
    this.scrollElsToByClass('test-questions', true);
  }
  questionNavScrollToStart(){
    this.scrollElsToByClass('test-questions', false);
  }
  questionNavScrollToQuestion(){
    // this.scrollElsToByClass('test-questions', false);
    try {
      const containerEl = document.getElementsByClassName('test-questions')[0];
      const targetEl = document.getElementsByClassName('test-question--entry is-active')[0];
      if (containerEl && targetEl){
        var containerRect = containerEl.getBoundingClientRect();
        var targetRect = targetEl.getBoundingClientRect();
        var top = targetRect.top - containerRect.top;
        var scrollTarget = containerEl.scrollTop;
        var isReqScrollDown = (top + targetRect.height) > containerRect.height;
        var isReqScrollUp = top  < 0;
        // console.log('scroll', {
        //   scrollTarget,
        //   y: top,
        //   y2: top + targetRect.height,
        //   h: containerRect.height,
        //   isReqScrollDown, 
        //   isReqScrollUp,
        // })
        if (isReqScrollDown){
          scrollTarget += top - (containerRect.height - targetRect.height)
        }
        if (isReqScrollUp){
          scrollTarget += top
        }
        // containerEl.scrollTo(0, scrollTarget)
        

        containerEl.scrollTo({
          top: scrollTarget,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
    catch(e){}
  }

  scrollToQuestion(){
    // content
    window.scrollTo(0, 0);
    this.scrollElsToByClass('split-view-left');
    // this.scrollElsToByClass('split-view-right');
    this.scrollElsToByClass('helper-tools');
    // sidebar
    setTimeout(() => this.questionNavScrollToQuestion(), 100)
  }

  openDefaultTextLink(){
    if (this.readerInfo && this.readerInfo.length === 1){
      const readSel = this.readerInfo[0];
      this.hyperLinkService.linkRequest.next({
        readerElementId: undefined,
        readerId: readSel.readerId,
        itemLabel: readSel.itemLabel
      })
      this.onResize();
      return readSel;
    }
  }



  markActiveQuestionAsStarted(){
    const qState = this.getActiveQuestionState();
    qState.__meta.isStarted = true;
  }

  clearTools(){
    this.isFormulasToggledOn = false;
    this.isCalcToggledOn = false;
  }

  gotoPrev() {
    if(this.isShowingResults && this.pageModeIndex > 0 && this.getCurrentQuestionIndex() === 0) {
      this.gotoPrevPageMode();
      return;
    }
    if (this.blockMoveAwayFromReqQuestion()){
      return;
    }
    
    if (this.getCurrentQuestionIndex() <= 0){
      if (!this.isShowingSectionInfo && this.getSectionPreambleContent( this.getCurrentSection() )){
        this.showSectionInfo();
        return;
      }     
      this.gotoPrevSection()
      return
    }
    this.gotoPrevQuestion()
  }

  selectPageMode(index: number) {
    this.pageModeIndex = index;
    this.pageMode = this.pageModeFlow[index].slug;
  }

  gotoPrevPageMode() {
    this.selectPageMode(this.pageModeIndex - 1);
  }

  gotoNextPageMode() {
    this.selectPageMode(this.pageModeIndex + 1);
    if(this.pageModeIndex === this.pageModeFlow.length - 1){
      this.selectQuestion(0);
    }
  }

  blockMoveAwayFromReqQuestion(){
    if(!this.isShowingResults) {
      const question = this.getActiveQuestionContent();
      // console.log('blockMoveAwayFromReqQuestion', question.isReqFill)
      if (question.isReqFill){
        let entryIds = [];
        if (question.reqFillEntries){
          entryIds = question.reqFillEntries.split(',').map(str => str.trim())
        }
        if (!this.isCurrentQuestionFilled(entryIds)){
          this.loginGuard.quickPopup(question.reqFillMsg);
          return true;
        }
      }
    }
    return false;
  }

  showPrevNext(){
    if (this.isShowingResults && (this.pageMode !== PageMode.TEST_RUNNER)){
      if (this.asmtFmrk && this.asmtFmrk.isResultsDetailDisabled){
        return false;
      }
    }
    return true;
  }

  gotoNext() {
    if(this.isShowingResults && this.pageModeIndex < this.pageModeFlow.length - 1) {
      return this.gotoNextPageMode();
    }
    if (this.isHelpOverlay){
      return this.isHelpOverlay = false;
    }
    if (this.isShowingSectionInfo){
      return this.hideSectionInfo()
    }

    if(!this.isShowingResults) {
      if (this.blockMoveAwayFromReqQuestion()){
        return;
      }
    }

    if (this.isOnLastQuestion()){
      if(!this.isShowingResults) {
        return this.reviewAndSubmit();
      } 
      else {
        return this.leaveResults();
      }
    }
    this.gotoNextQuestion()
  }

  gotoNextQuestion() {
    this.selectQuestion(this.testState.currentQuestionIndex + 1);
  }

  gotoPrevQuestion() {
    this.selectQuestion(this.testState.currentQuestionIndex - 1);
  }

  isOnLastQuestion() {
    return this.testState.currentQuestionIndex >= this.getCurrentQuestions().length - 1;
  }

  countNumCurrentQuestionsUnfilled() {
    let numUnfilled = 0;
    let questionIds = this.getCurrentQuestions();
    if (!questionIds) {
      console.error('no questions');
      return 0;
    }
    questionIds.forEach((qId, qIndex) => {
      if (!this.isQuestionFilled(qIndex) && !this.questionSrcDb.get(qId).isReadingSelectionPage) {
        numUnfilled ++;
      }
    });
    return numUnfilled;
  }

  countNumberofFlaggedQuestions = () => {
    const flaggedQuestions = this.returnArrayOfFlaggedQuestions();
    return flaggedQuestions.length;
  }

  returnArrayOfUnfilledQuestions = () => {
    let array = [];
    let questionIds = this.getCurrentQuestions();
    if (!questionIds) {
      console.error('no questions');
      return [];
    }
    questionIds.forEach((qId, qIndex) => {
      if (!this.isQuestionFilled(qIndex)) {
        array.push(this.getQuestionTitle(qIndex));
      }
    });
    return array;
  }

  returnArrayOfFlaggedQuestions = () => {
    let flaggedQuestion = []
    this.getCurrentQuestions().forEach((qId, i) => {
      const qState = this.getQuestionState(qId);
      if (qState.__meta.isFlagged){
        flaggedQuestion.push(i)
      }
    })
    return flaggedQuestion;
  }

  private confirmAndSubmitTest(){
    if (this.checkTag(KNOWN_TEST_RUNNER_TAGS.NO_SUBMIT_CONF)){
      this._submitTest();
      return;
    }
    const arrayOfFlaggedQuestions = this.returnArrayOfFlaggedQuestions();
    const arrayOfUnfilledQuestions = this.returnArrayOfUnfilledQuestions();
    // const numFlagged = arrayOfFlaggedQuestions.length;
    // const numUnfilled = arrayOfUnfilledQuestions.length;
    const {numUnfilled, numFlagged} = this.getFilledAndFlagged();
    const {preMessage, cancelLabel} = this.getSubmitPreMessage()

    let dialogText: string;
    let confirmBtnMsg: string;
    let cancelBtnMsg: string;
    const customDlg = this.customConfirmTestDialogData; 
    if (customDlg) {
      dialogText = this.lang.tra(customDlg.text);
      confirmBtnMsg = this.lang.tra(customDlg.confirmMsg);
      cancelBtnMsg = this.lang.tra(customDlg.cancelMsg);
    } 
    else {
      dialogText = preMessage + this.lang.tra(this.getAlertKKSubmitTestSlug());
      confirmBtnMsg = this.goBackAndReviewYesNavigation();
      cancelBtnMsg = this.goBackAndReviewNoLabel();
    }

    if (this.isFlushNavigation()){
      dialogText = this.lang.tra('alert_bc_section_submit_final');
    }
    if (this.asmtFmrk && this.asmtFmrk.msgFinalSubmission){
      dialogText = this.asmtFmrk.msgFinalSubmission;
    }
    this.activateModal(dialogText, () => {
      this._saveQuestion()
      .then(() => {
        if (numUnfilled > 0 && numFlagged > 0) {
          this.endSection.emit({hasUnfilled: true, hasFlags: true, arrayOfFlaggedQuestions, arrayOfUnfilledQuestions})
        } 
        else if (numUnfilled > 0 && numFlagged === 0) {
          this.endSection.emit({hasUnfilled: true, hasFlags: false, arrayOfUnfilledQuestions})
        } 
        else if (numUnfilled === 0 && numFlagged > 0) {
          this.endSection.emit({hasUnfilled: false, hasFlags: true, arrayOfFlaggedQuestions})
        } else {
          this.endSection.emit({hasUnfilled: false, hasFlags: false})
        }
        this._submitTest();
      })
    }, confirmBtnMsg, cancelBtnMsg );
  }

  private _submitTest(){
    if(this.isFlushNavigation()) {
      this.scoreAllQuestions();
    }
    this.submitTest().catch((e) => {
      handleSubmitTestErr(e, this.loginGuard, this.lang);
    });
  }

  isAnyFlaggedQuestions(){
    return this.countNumberofFlaggedQuestions() > 0;
  }

  isFlaggingEnabled(){
    const section = this.getCurrentSection();
    if (this.checkTag(KNOWN_TEST_RUNNER_TAGS.NO_FLAGGING)){
      return false
    }
    if (section){
      return !section.disableFlagging;
    }
  }

  isLeftBarDisabled(){
    if (!this.isShowingResults){
      // todo: might need to add a flag for show results
      const section = this.getCurrentSection();
      if (section){
        return section.disableLeftBar;
      }
    }
    return false;
  }

  isFlagEnabledForCurrentQuestion(){
    if (this.isFlaggingEnabled()){
      const q = this.getActiveQuestionContent();
      if (q){
        return !(q.isReadingSelectionPage);
      }
    }
  }

  isCurrentQuestionFlagged(){
    const qState = this.getActiveQuestionState()
    return qState.__meta.isFlagged;
  }

  getFilledAndFlagged(){
    return {
      numUnfilled: this.countNumCurrentQuestionsUnfilled(),
      numFlagged: this.countNumberofFlaggedQuestions()
    }
  }

  isQuestionCorrect(id: number) {
    return this.questionScores.get(id) === QuestionScore.CORRECT;
  }

  isQuestionUnmarked(id: number) {
    return this.questionScores.get(id) === QuestionScore.UNMARKED;
  }

  getSubmitPreMessage(){
    const {numUnfilled, numFlagged} = this.getFilledAndFlagged();
    let preMessage = '';
    let cancelLabel = this.goBackAndReviewNoLabel();
    if (this.isFlushNavigation()){
      preMessage += this.lang.tra('alert_bc_section_submit', undefined, {numUnfilled, numFlagged}) + ' ';
    }
    // else if (this.checkIsOsslt()) {
    //   preMessage += this.lang.tra('osslt_confirm_submit')
    //   cancelLabel = this.goBackAndReviewNoLabel()
    // } 
    else if (this.checkIsOssltTools()) {
      cancelLabel = this.lang.tra('osslt_tools_message')
    }
    else{
      if (this.checkIsOsslt()) {
        cancelLabel = this.goBackAndReviewNoLabel()
      } 
      const isAnyUnfilled = numUnfilled > 0;
      const isAnyFlagged = numFlagged > 0;
      const msgUnfilledAndFlagged = this.lang.tra('alert_UNFILLED_WARN_P1') + ' '+ numUnfilled + " question(s) " + this.lang.tra('osslt_flagged') + ' ' + numFlagged + ' ' + this.lang.tra(this.getAlertUnfilledWarnP2Slug(), undefined, {QUESTION_WORD: this.lang.tra(this.getQuestionWordSlug()).toLowerCase()}) + ' ';
      const msgUnfilledAndNotFlagged = this.lang.tra(this.getAlertNumUnfilledSlug(), undefined, {questionNum: numUnfilled});
      const msgFilledAndFlagged = this.lang.tra('osslt_flagged_simple') + ' ' + numFlagged + ' '+ this.lang.tra(this.getAlertUnfilledWarnP2Slug(), undefined, {QUESTION_WORD: this.lang.tra(this.getQuestionWordSlug()).toLowerCase()}) + ' ';;
      if (isAnyUnfilled) {
        if (isAnyFlagged){
          preMessage += msgUnfilledAndFlagged;
        }
        else{
          preMessage += msgUnfilledAndNotFlagged;
        }
      } 
      else if (numUnfilled > 0 && numFlagged === 0) {
        preMessage += this.lang.tra('alert_UNFILLED_WARN_P1')+ ' ' + numUnfilled + " " + this.lang.tra(this.getAlertUnfilledWarnP2Slug(), undefined, {QUESTION_WORD: this.lang.tra(this.getQuestionWordSlug()).toLowerCase()}) + ' ';
      } 
      else if (numUnfilled === 0 && numFlagged > 0) {
        preMessage += this.lang.tra('osslt_flagged_simple') + ' ' + numFlagged + ' '+ this.lang.tra(this.getAlertUnfilledWarnP2Slug(), undefined, {QUESTION_WORD: this.lang.tra(this.getQuestionWordSlug()).toLowerCase()}) + ' ';
      }
    }
    return {preMessage, cancelLabel};
  }

  checkForUnfilledRequiredSectionQuestions(){
    let missingRequiredQuestion = false;
    this.getCurrentQuestions().forEach((qId, qIndex) => {
      const question = this.getQuestionDef(qId);
      if (question.isReqFill && !this.isQuestionFilled(qIndex)){
        missingRequiredQuestion = true;
        // console.log('missingRequiredQuestion', qId, question)
      }
    });
    return missingRequiredQuestion;
  }

  reviewAndSubmit() {
    this.overlayState.deactivate = true;
    this.isNotepadEnabled = false;
    this.showDeactivatedMode = true;
    if (this.checkForUnfilledRequiredSectionQuestions()){
      let message = 'lbl_questions_req_unfilled'
      const section = this.getCurrentSection();
      if (section.msgReqFill){
        message = section.msgReqFill
      }
      this.loginGuard.quickPopup(this.lang.tra(message))
      return;
    }
    this._saveQuestion().then(() => {
      if (this.onLastSection() && !this.checkIsOsslt()) {
        this.confirmAndSubmitTest();
      }
      else {
        this.confirmAndSubmitSection(); // checks need
      }
    })
  }

  leaveResults() {
    this.activateModal(this.lang.tra("tr_results_exit"), () => {
      return this.exitResults();
    }, this.lang.tra('lbl_yes'), this.lang.tra('lbl_no'));
  }

  onLastSection() {
    if(this.sectionsAllowed) {
      return this.testState.currentSectionIndex === this.sectionsAllowed[this.sectionsAllowed.length - 1];
    }
    return this.testState.currentSectionIndex >= this.testRunnerSections.length - 1
  }

  private getSubmitSectinoEmitMsg(){
    if (this.isFlushNavigation()){
      return '';
    }
    else if (this.checkIsOsslt() && this.onLastSection()) {
      return this.lang.tra('osslt_confirm_submit_test')
    } 
    else if (this.checkIsOsslt()) {
      return this.lang.tra('osslt_confirm_submit')
    }
    else {
      return this.lang.tra(this.getAlertKKSubmitSectionSlug())
    }
  }

  private emitSectionEndData(context: {numUnfilled:number, numFlagged:number}){
    const {numUnfilled, numFlagged} = context;
    if (numUnfilled > 0 && numFlagged > 0) {
      this.endSection.emit({hasUnfilled: true, hasFlags: true, arrayOfFlaggedQuestions: this.returnArrayOfFlaggedQuestions(), arrayOfUnfilledQuestions: this.returnArrayOfUnfilledQuestions()})
    } else if (numUnfilled > 0 && numFlagged === 0) {
      this.endSection.emit({hasUnfilled: true, hasFlags: false, arrayOfUnfilledQuestions: this.returnArrayOfUnfilledQuestions()})
    } else if (numUnfilled === 0 && numFlagged > 0) {
      this.endSection.emit({hasUnfilled: false, hasFlags: true, arrayOfFlaggedQuestions: this.returnArrayOfFlaggedQuestions()})
    } else {
      this.endSection.emit({hasUnfilled: false, hasFlags: false})
    }
  }

  private confirmAndSubmitSection(){
    const {numUnfilled, numFlagged} = this.getFilledAndFlagged();
    const {preMessage, cancelLabel} = this.getSubmitPreMessage();
    const proceed = () => {
      this.emitSectionEndData({numUnfilled, numFlagged});
      this.gotoNextSection();
    }
    const nextSectionIndex = this.getCurrentSectionIndex() + 1;
    this.ensureSectionPathFill(nextSectionIndex)
      .then(()=> {
        if (this.checkTag(KNOWN_TEST_RUNNER_TAGS.NO_SUBMIT_CONF) || (this.isFlushNavigation() && (numUnfilled === 0) && (numFlagged===0))){
          proceed();
        }
        else{
          this.activateModal(preMessage + this.getSubmitSectinoEmitMsg(), () => {
            this._saveQuestion().then(() => {
              proceed();
            });
          }, this.goBackAndReviewYesNavigation(), cancelLabel );
        }
      })
  }

  private gotoPrevSection(){
    if (this.testState.currentSectionIndex > 0 && this.isFlushNavigation()){
      this._saveQuestion().then(()=>{
        this.clearTools();
        const nextSectionIndex = this.testState.currentSectionIndex - 1;
        const nextQuestions = this.testRunnerSections[nextSectionIndex].questions;
        this.updatePosition(nextSectionIndex, nextQuestions.length - 1);
        this.isShowingSectionInfo = false;
        this.questionNavScrollToEnd()
      })
    }
  }
  getAlertNumUnfilledSlug(): string {
    return this.checkIsOsslt() ? 'osslt_alert_unfilled_questions' :'g9_alert_unfilled_questions';
  }


  private gotoNextSection(){
    sessionStorage.setItem("totalFilledBySection", JSON.stringify(this.totalFilledBySection));
    this.clearTools();
    let nextSectionIndex;
    if(this.sectionsAllowed && this.testState.currentSectionsAllowedIndex !== undefined) {
      nextSectionIndex = this.sectionsAllowed[++this.testState.currentSectionsAllowedIndex];
    } 
    else {
      nextSectionIndex = this.testState.currentSectionIndex + 1;
    }
    this.processRouting(nextSectionIndex)
      .then(()=>{
        this.updatePosition(nextSectionIndex, 0);
        try {
          this.closePassage();
        }
        catch(e){}
        this.lastFillState = this.isCurrentQuestionFilled();
        const isPreambleAvail = !!this.getCurrentSectionPreambleContent();
        if (isPreambleAvail) {
          this.isShowingSectionInfo = true;
        }
        else {
          this.isShowingSectionInfo = false;
        }
        this.initFirstQuestion();
        this.questionNavScrollToStart()
        // console.log('section', this.getCurrentSection())
      })
  }

  private processRouting(nextSectionIndex:number){
    return new Promise((resolve, reject) => {
      if (this.testFormType === TestFormConstructionMethod.MSCAT){
        this.loadNextModule();
        resolve();
      }
      else{
        this.ensureSectionPathFill(nextSectionIndex)
          .then(resolve)
          .catch(reject)
      }
    })
  }

  isDrawingToolsShown(){
    return true;//!this.isFlushNavigation();
  }

  private ensureSectionPathFill(sectionIndex:number, isConfirmationReq:boolean = true, isSilentPass:boolean = false){
    return new Promise((resolve, reject) => {
      
      const onReject = (msg:string) => {
        if (isSilentPass){
          resolve();
        }
        else{
          this.loginGuard.quickPopup(
            this.lang.tra(errorDetected.message),
          )
          reject()
        }
      }

      const sectionPathOptions = this.testRunnerPathSections.get(sectionIndex);
      if (!sectionPathOptions){
        return resolve();
      }
      const targetSection = this.testRunnerSections[sectionIndex];
      if (!targetSection || targetSection.questions.length > 0){ // do not refill
        return resolve();
      }
      let sectionToInject:{
        sectionDef: ISectionDef,
        entryState:IEntryStateMcq,
        selectionIndex: number,
      }
      let errorDetected:{message: string};
      let MESSAGE_NO_SELECTION = 'lbl_student_path_no_sel';
      if (this.asmtFmrk && this.asmtFmrk.msgPathWarnOverride){
        MESSAGE_NO_SELECTION = this.asmtFmrk.msgPathWarnOverride;
      }

      sectionPathOptions.forEach(sectionDef => {
        if (errorDetected){ return; }
        // check
        const itemId = +sectionDef.conditionOnItem;
        const deciderItemState = this.getQuestionState(itemId);
        if (!deciderItemState){
          return errorDetected = {message: MESSAGE_NO_SELECTION}
        }
        const entryStates = this.getQuestionStateEntries(deciderItemState);
        if (!entryStates){
          return errorDetected = {message: MESSAGE_NO_SELECTION}
        }
        // entryStates.forEach((entryState:IEntryStateMcq) => {
        const entryState:IEntryStateMcq = entryStates[0];
        if (!entryState || entryState.selections.length < 1){
          return errorDetected = {message: MESSAGE_NO_SELECTION}
        }
        const selection = entryState.selections[0]; // assuming its not multi select
        const selectionIndex = +selection.i;
        if (selectionIndex === +sectionDef.conditionOnOption){
          if (!deciderItemState.__meta){
            deciderItemState.__meta = {};
          }
          sectionToInject = {
            sectionDef,
            entryState,
            selectionIndex
          };
        }
      })

      if (!sectionToInject && !errorDetected){
        errorDetected = {message: MESSAGE_NO_SELECTION}
      }

      console.log('path selection', errorDetected, sectionToInject);

      if (errorDetected){
        onReject(errorDetected.message);
      }
      else if (sectionToInject){
        const sectionToFill = this.testRunnerSections[sectionIndex];
        const completeInjection = () => {
          Object.keys(sectionToInject.sectionDef).forEach(key => {
            if (key !== '__meta'){
              sectionToFill[key] = sectionToInject.sectionDef[key];
            }
          })
          sectionToInject.entryState.isPathFollowed = true;
        }
        if (isConfirmationReq){

          const asmtFmrk:any = this.asmtFmrk || {};

          const caption = this.lang.tra(asmtFmrk.msgPathCnfmOverride || 'lbl_student_path_cant_undo');
          const btnCancelCaption = this.lang.tra(asmtFmrk.msgPathCnfmCancelOverride || 'lbl_student_path_back');
          const btnProceedCaption = this.lang.tra(asmtFmrk.msgPathCnfmProceedOverride || 'lbl_student_path_proceed');
          
          return this.loginGuard.confirmationReqActivate({
            caption,
            btnCancelCaption,
            btnProceedCaption,
            confirm: ()=> {
              completeInjection()
              resolve()
            },
          })
        }
        else if (isSilentPass){
          completeInjection()
          resolve()
        }
        else{
          return resolve()
        }
      }
      else{
        onReject('Unknown error in choosing path');
      }
    })
  }

  private getItemSelectionIndex(){

  }


  getNextModuleId(currentSectionIndex, log:boolean = false){
    const currentSection:IPanelModuleDef = this.getSection(currentSectionIndex);
    const currentModuleId = currentSection.moduleId;
    let nextRoute:{module:string, minPropC:number, maxPropC};
    let panelRouting = this.currentTestDesign.panelRouting[''+currentModuleId];
    let propC = this.computePercentageCorrect(currentSectionIndex);
    let targetLevel = 0;
    try {
      if (this.currentTestDesign.isPanelRoutingByNumCorrect){
        // console.log('propC', propC)
        panelRouting.forEach(route => {
          if ( (route.minPropC === undefined) || (propC >= route.minPropC) ){
            if ( (route.maxPropC === undefined) || (propC < route.maxPropC) ){
              nextRoute = route;
            }
          }
        })
      }
      else{
        // temporary...
        const gett = (a,b) => this.currentTestDesign.panelRouting[a][b];
        if ( currentSectionIndex == 0){
          if (propC < 0.4){ targetLevel = 0; }
          else if (propC < 0.8){ targetLevel = 1; }
          else { targetLevel = 2; }
          nextRoute = gett(1,targetLevel)
        }
        if (currentSectionIndex == 1){
          targetLevel = 0;
          nextRoute = gett(2,targetLevel)
        }
        if (currentSectionIndex == 2){
          if (propC < 0.4){ targetLevel = 0; }
          else if (propC < 0.8){ targetLevel = 1; }
          else { targetLevel = 2; }
          nextRoute = gett(5,targetLevel)
        }
      }
    }
    catch(e){}

    if (!nextRoute && panelRouting){
      console.warn('Number-Correct routing failed or is not enabled for this assessment panel, you will be routed to a random module option.');
      nextRoute = randArrEntry(panelRouting);
    }
    let targetModuleId
    if(nextRoute){
     targetModuleId = +nextRoute.module;
    }
    if(log && this.testAttemptId) {
      const logData = {
        uid: this.auth.getUid(),
        test_attempt_id: this.testAttemptId,
        current_section_index: currentSectionIndex,
        current_module_id: currentModuleId,
        target_module_id: targetModuleId,
        target_level: targetLevel,
        question_states: JSON.stringify(this.getCurrentQuestionStates())
      }
      this.auth.apiCreate(this.routes.STUDENT_STAGE_SUBMISSION, logData);
    }

    return targetModuleId;
  }

  loadNextModule(){
    const currentSectionIndex = this.testState.currentSectionIndex;
    const moduleId = this.getNextModuleId(this.testState.currentSectionIndex, true)
    this.loadQuestionsForModuleId(moduleId, currentSectionIndex+1);
    this.initSection();
  }
  loadQuestionsForModuleId(moduleId:number, targetSectionIndex:number){
    // console.log('loadQuestionsForModuleId', moduleId, this.currentTestDesign.panelModules)
    let nextPanelModule;
    this.currentTestDesign.panelModules.forEach(panelModule => {
      if (+panelModule.moduleId === moduleId){
        nextPanelModule = panelModule;
      }
    })
    if (!nextPanelModule){
      nextPanelModule = this.currentTestDesign.panelModules[1]; // gross temp
    }
    this.testState.currentModuleId = +nextPanelModule.moduleId;
    this.testRunnerSections[targetSectionIndex].questions = nextPanelModule.questions;
  }


  private computePercentageCorrect(sectionIndex:number) {
    let score = 0;
    let scoreMax = 0;
    const states = this.testState.questionStates;
    this.getCurrentQuestions().forEach(qId => {
      // console.log('states', states[qId]);
      const questionState = states[qId];
      if (questionState){
        let entryScore = 0;
        let entryScoreMax = 0;
        const entries = this.getQuestionStateEntries(questionState);
        entries.forEach(entryState => {
          // console.log('entry', questionState[entryId], questionState[entryId].score);
          if (entryState && entryState.score){
            entryScore += +entryState.score;
          }
          entryScoreMax ++;
        });
        if (entryScoreMax > 0){
          score += entryScore / entryScoreMax;
        }
      }
      scoreMax ++;
    });
    if (scoreMax > 0){
      return this.roundNumeric(score / scoreMax);
    }
    return 0;
  }
  
  private roundNumeric(num: number){
    return Math.round(100*num)/100; // fixed to 2 decimal places for now
  }

  getQuestionTestLabel(question) {
    const questionObj = this.questionSrcDb.get(question)
    console.log(questionObj)
    return questionObj["testLabel"];
  }

  isSecureProxy(){
    return false;
  }

  goBackAndReviewYesNavigation(){
    const QUESTION_WORD = this.capitalizeWords('question', !this.isLang('en'));
    if (this.isFlushNavigation()){
      return this.lang.tra('alert_KK_SUBMIT_TEST_yes_bc', undefined, {QUESTION_WORD});
    }
    else {
      return this.lang.tra('alert_KK_SUBMIT_TEST_yes', undefined, {QUESTION_WORD});
    }
  }
  goBackAndReviewNoLabel() {
    const QUESTION_WORD = this.capitalizeWords('question', !this.isLang('en'));
    if (this.isFlushNavigation()){
      return this.lang.tra('alert_KK_SUBMIT_TEST_no_bc', undefined, {QUESTION_WORD});
    }
    else {
      return this.lang.tra('alert_KK_SUBMIT_TEST_no', undefined, {QUESTION_WORD});
    }
  }

  private capitalizeWords(str:string, isBlocked:boolean){
    if (isBlocked || !str){
      return str;
    }
    return str[0].toUpperCase()+str.substr(1, str.length-1)
  }

  zoomIn() {
    if (this.zoomLevel + this.zoomIncrement <=  this.maxZoomLevel) {
      this.zoomLevel += this.zoomIncrement;
    }
    this.updateDragZoomCorrection()
    this.logTool("ZOOM_IN", this.zoomLevel);
  }
  zoomOut() {
    if (this.zoomLevel - this.zoomIncrement >=  this.minZoomLevel) {
      this.zoomLevel -= this.zoomIncrement;
    }
    this.updateDragZoomCorrection()
    this.logTool("ZOOM_OUT", this.zoomLevel);
  }

  getDocumentTooltip(){
    if (this.documentItems && this.documentItems.length === 1){
      return this.documentItems[0].caption;
    }
    return this.lang.tra('lbl_documents')
  }

  ghostAdded = false;
  getPixelsPerEM() {
    const el = document.getElementById("ghost-test-runner");
    if (!el) return 0;
    if (!this.ghostAdded) {
      //document.body.appendChild(el);
      const parent = document.getElementById("left-split-container");
      parent.appendChild(el);
      this.ghostAdded = true;
    }
    const width = el.offsetWidth;
    const len = width/10;
    //document.body.removeChild(el);
    return len;
  }

  getLeftReadingPassage() {
    const element = document.getElementById("readingPassageSplit")
    return element.offsetLeft+'px'
  }

  getTopReadingPassage() {
    const element = document.getElementById("readingPassageSplit")
    return element.offsetTop+'px'
  }

  getLeftDrawingOverlayStyle() {
    const style:any={}
    const element = document.getElementById("readingPassageSplit")
    if (element) {
      style["left"] = element.offsetLeft
      style["top"] = element.offsetTop;
    }

    return element;
  }

  getNotepadSlug(){
    if (!this.isFlushNavigation()){
      return 'lbl_notepad'
    }
    else{
      return 'lbl_notepad_bc'
    }
  }

  sendIssueReport() {
    return this.auth.apiCreate(
      this.routes.TEST_TAKER_INVIGILATION_REPORT_ISSUE,
      {
        test_session_id: this.testSessionId,
        question_id: this.getActiveQuestionId(),
        message: this.issueReportMessage.value,
      }
    )
    .then(e => {
      this.isShowingReport = false;
    });
  }
  reportIssue() {
    this.isShowingReport = true;
  }
  checkTimeLeft() {
    // this.activateModal( this.lang.tra('alert_TIME_LEFT'), ()=>{} );
    if (this.checkTime) {
      this.checkTime();
    }
    this.isShowingTime = true;
  }

  getInfoCaption(){
    const section = this.getCurrentSection();
    if (section){
      return section.infoCaption;
    }
  }

  openChat() {
    if (this.checkChat) {
      this.checkChat();
    }
    this.isShowingChat = true;
  }

  closePassage() {
    // console.log("closing Reading Passage")
    this.clearReadingSelection()
    this.hyperLinkService.linkRequestSecond.next({
      readerElementId: this.currentBookmark,
      readerId: this.currentReadSelection,
    });
    this.rightPageId = 0;
    this.onResize();
  }

  toggleReadingSelections() {
    // console.log("closing toggle", this.isShowingReadingSelections)
    if (this.isShowingReadingSelections) {
      this.isShowingReadingSelections = false;
      //this.closePassage();
    } else {
      this.isShowingReadingSelections = true;
    }
  }

  toggleTestNav() {
    this.isTestNavExpanded = !this.isTestNavExpanded;
  }
  toggleToolbar() {
    this.isToolbarExpanded = !this.isToolbarExpanded;
  }
  toggleLineReader() {
    this.isLineReaderActive = !this.isLineReaderActive;
    this.logTool("LINE_READER", this.isLineReaderActive);
  }
  toggleNotepad(){
    this.isNotepadEnabled = !this.isNotepadEnabled;
    this.logTool("NOTEPAD", this.isNotepadEnabled);
  }
  toggleHiContrast() {
    this.isHighContrast = !this.isHighContrast;
    this.logTool("HI_CONTRAST", this.isHighContrast);
  }

  toggleDocuments() {
    this.isShowDocuments = !this.isShowDocuments;
    this.logTool("SHOW_DOCS", this.isShowDocuments);
  }

  toggleFormula() {
    this.isShowFormulaSheet = !this.isShowFormulaSheet;
    this.logTool("SHOW_FORMULA_SHEET", this.isShowFormulaSheet);
  }

  toggleFormulas() {
    this.isFormulasToggledOn = !this.isFormulasToggledOn;
    if (this.isFormulasToggledOn) {
      this.slowScrollToTop();
    }
    this.logTool("SHOW_FORMULAS", this.isFormulasToggledOn);
  }

  toggleCalc() {
    this.isCalcToggledOn = !this.isCalcToggledOn;
    this.calcState.emit(this.isCalcToggledOn);
    this.logTool("CALC", this.isCalcToggledOn);
    // if (this.isCalcToggledOn) {
    //   this.slowScrollToTop();
    // }
  }

  goBackToMap = () => {
    this._saveQuestion().then(() => {
      sessionStorage.setItem("totalFilledBySection", JSON.stringify(this.totalFilledBySection));
      this.backToMap.emit()
    })
  }

  goBackToMenu = () => {
    this.backToMenu.emit()
  }

  slowScrollToTop() {
    const el = this.questionDisplay.nativeElement;
    el.scrollIntoView({block: 'start'});
    setTimeout(() => {
      const elt = this.topBar.nativeElement;
      elt.scrollIntoView({behavior: 'smooth', block: 'end'});
    }, 100);
  }

  isLang(langCode: string) {
    return (langCode === this.testLang);
  }

  isShowingCalc() {
    return this.isCalcToggledOn;
  }

  getSectionTitleSlug() {
    if (this.isFlushNavigation()) {
      if (this.lang.c() === 'fr'){
        return 'Partie'
      }
      else{
        return 'Part'
      }
    }
    if (!this.checkIsOsslt()) {
      switch (this.testFormType){
        case TestFormConstructionMethod.MSCAT: return 'title_stage';
        default: return 'title_section';
      }
    } else {
      switch (this.testFormType){
        case TestFormConstructionMethod.MSCAT: return 'title_stage';
        default: return 'title_section_osslt';
      }
    }
  }

  getSectionTimeRemainingSlug() {
    switch (this.testFormType){
      case TestFormConstructionMethod.MSCAT: return 'tr_stage_time_remaining';
      default: return 'tr_section_time_remaining';
    }
  }

  getAlertUnfilledWarnP2Slug() {
    switch (this.testFormType){
      case TestFormConstructionMethod.MSCAT: return this.checkIsOsslt() ? 'alert_UNFILLED_WARN_P2_SECTION' : 'alert_UNFILLED_WARN_P2_STAGE' ;
      default: return  this.checkIsOsslt() ? 'alert_UNFILLED_WARN_P2_SECTION' : 'alert_UNFILLED_WARN_P2';
    }
  }

  getAlertKKSubmitSectionSlug() {
    switch (this.testFormType){
      case TestFormConstructionMethod.MSCAT: return 'alert_KK_SUBMIT_STAGE';
      default: return 'alert_KK_SUBMIT_SECTION';
    }
  }

  getSections(){
    if (this.currentTestDesign && this.currentTestDesign.sections){
      return this.currentTestDesign.sections;
    }
    return []
  }


  getAlertKKSubmitTestSlug() {
    if(this.isFlushNavigation()) {
      return 'alert_KK_SUBMIT_EVALUATION'
    }
    // switch (this.testFormType){
    //   case TestFormConstructionMethod.MSCAT: return 'alert_KK_SUBMIT_STAGE';
    //   default: return 'alert_KK_SUBMIT_TEST';
    // }
    if (this.testFormType === TestFormConstructionMethod.MSCAT){
      return 'alert_KK_SUBMIT_STAGE';
    }
    else{
      if (this.getSections().length > 1){
        return 'alert_KK_SUBMIT_TEST';
      }
      else{
        return 'alert_KK_SUBMIT_TEST_single_section'
      }
    }
  }

  logStudentAction(slug:string, info: any, slugPrefix? : string) {
    if(!this.auth.userIsStudent()) {
      return;
    }

    let prefix = slugPrefix || "";
    prefix += "_";
    this.auth.apiCreate( this.routes.LOG,
      {
        slug: `${prefix}${slug}`,
        data: {
          uid: this.auth.getUid(),
          session_id: this.testSessionId,
          state: {
            section_index: this.testState.currentSectionIndex,
            question_index: this.testState.currentQuestionIndex
          },
          info
        }
      }
    )
  }
  logTool(toolSlug: string, info: any) {
    this.logStudentAction(toolSlug, info, "STUDENT_ASMT_TOOL")
  }

  getProportionalQuestionScore(qId: number, round?: boolean) {
    let pScore = this.questionPScores.get(qId);
    if(pScore !== undefined) {
      if (round){
        return this.roundNumeric(pScore)
      }
      return pScore;
    } 
    else {
      const qRes = this.questionStates[qId];
      pScore = 0;
      if (qRes){
        let entries = Object.keys(qRes).filter((key) => {
          return !key.startsWith('__') && this.isAutoScorableElement(qRes[key]);
        });
        entries.forEach(entryId => {
          const entryState = qRes[entryId];
          // console.log(qId, entryId, pScore)
          pScore += entryState.score;
        })
      }
      this.questionPScores.set(qId, pScore);
      if (round){
        return this.roundNumeric(pScore)
      }
      return pScore;
    }
  }

  getQuestionScoreDisplay(qId: number){
    let score = this.getProportionalQuestionScore(qId, true);
    return score;
  }

  getQuestionScore(qId: number) {
    const correct = this.questionScores.get(qId);
    if(correct === QuestionScore.CORRECT) {
      return this.getQuestionTotalScore(qId);
    } 
    else if(correct === QuestionScore.INCORRECT) {
      return 0;
    } 
    else {
      return 0;
    }

    //all or nothing scoring. Do we award part marks?
  }

  getQuestionTotalScore(qId: number) {
    const question = this.questionSrcDb.get(qId);
    let points;
    if (question){
      points = (<IQuestionConfig>question).points;
    }
    if(!points) {
      return 0;
    }

    const tokens = points.split(/\s/);
    if(!tokens || tokens.length === 0) {
      return 0;
    }

    return +tokens[0];
  }

  scoreAllQuestions() {
    this.numSRQuestions = 0;
    this.numCRQuestions = 0;
    this.numCorrectSRQuestions = 0;
    this.correctSRScore = 0;
    this.totalSRScore = 0;
    this.totalCRScore = 0;
    const states = this.questionStates;
    this.testRunnerSections.forEach(section => {
      if(section.disableScoring){
        return;
      }
      // console.log('section.questions', section.questions)
      section.questions.forEach(qId => {
        const question = <IQuestionConfig>this.questionSrcDb.get(qId);
        if(question.isReadingSelectionPage) {
          return;
        }
        const qRes = states[qId];
        const score = this.getQuestionTotalScore(qId);
        if(this.isManuallyScored(question)) {
          this.questionScores.set(qId, QuestionScore.UNMARKED);
          this.numCRQuestions++;
          this.totalCRScore += score;
        } 
        else {
          const correct = this.isQResAllCorrect(qRes);
          this.questionScores.set(qId, correct ? QuestionScore.CORRECT : QuestionScore.INCORRECT);
          const score = this.getProportionalQuestionScore(qId, true);
          this.correctSRScore += score; // this.getQuestionScore(qId); 
          if(correct) {
            this.numCorrectSRQuestions++;
          }
          this.totalSRScore += this.getQuestionPoints(question.content);
          this.numSRQuestions++;
        }
      });
    });
    this.totalSRScore = this.roundNumeric(this.totalSRScore);
  }

  getQuestionPoints(elements: IContentElement[]){
    const entryElements = <Partial<IScoredResponse>[]> identifyQuestionResponseEntries(elements, [], true);
    let pointsTotal = 0;
    entryElements.forEach(entryElement => {
      pointsTotal += +getElementWeight(entryElement)
    });
    return pointsTotal;
  }

  private containsAutoScorableElement(elements: IContentElement[]) {
    if(!elements) {
      return false;
    }
    const entryElements = identifyQuestionResponseEntries(elements, [], true);
    return (entryElements && entryElements.length > 0) 
  }

  triggerPrintDialog() {
    window.print();
  }

  private isAutoScorableElement(element:any) : boolean {
    return checkElementIsEntry(element, true, 'type');
  } 


  private getQResPercentageCorrect(qRes) {
    if(!qRes) {
      return 0;
    }
    let entries = Object.keys(qRes).filter((key) => {
      return !key.startsWith('__') && this.isAutoScorableElement(qRes[key]);
    });

    if(!entries || entries.length === 0) {
      return 0;
    }    

    let numCorrect = 0;
    let totalPossible = 0;
    for(const entryId of entries) {
      const eRes = qRes[entryId];
      if(eRes.isCorrect) {
        numCorrect++;
      } 
      if(eRes.isCorrect !== undefined) {
        totalPossible++;
      }
    }

    if(totalPossible === 0) {
      return 0;
    }
    return numCorrect/totalPossible;
  }


  private isQResAllCorrect(qRes) {
    if(!qRes) {
      return false;
    }
    let entries = Object.keys(qRes).filter((key) => {
      return !key.startsWith('__') && this.isAutoScorableElement(qRes[key]);
    });

    if(!entries || entries.length === 0) {
      return false;
    }    

    for(const entryId of entries) {
      const eRes = qRes[entryId];
      if(!eRes.isCorrect) {
        return false;
      }
    }
    return true;
  }

  isManuallyScored(question) {
    return !this.containsAutoScorableElement(question.content);
  }

  showResultsDetailPages(){
    return !(this.asmtFmrk && this.asmtFmrk.isResultsDetailDisabled)
  }  

  exportResults() {
    this.isPrintMode = true;
  }

  exitExportResults() {
    this.isPrintMode = false;
  }

  isNotTestTaker() {
    return this.isTeacher()
    // try {
    //   return this.auth.user() && this.auth.user().value.accountType !== AccountType.TEST_TAKER
    // }
    // catch(e){
    //   return true;
    // }
  }
  
  getCustomResultsText() {
    return this.asmtFmrk.msgResultsPage;
    // const def = 'tr_results_intro_text_2'
    // const msg = this.asmtFmrk.msgResultsPage;
    
    // return !msg ? def : (msg || def);
  }

  getSubmissionText() {
    const def = this.btnReviewSubmit; //'btn_review_submit';
    const section = this.getCurrentSection();
    return !section ? def : (section.submissionText || def);
  }
  getNotepadText() {
    const section = this.getCurrentSection();
    if (section && section.notepadText){
      return section.notepadText
    }
    return null
  }

  isTeacher(){
    if (this.frameWorkTagsRef.get('IS_TEACHER_VIEW')){
      return true;
    }
    return false
  }

  writtenOn:string;
  getWritingDate(){
    if (!this.writtenOn){
      this.writtenOn = moment().format( this.lang.tra('datefmt_day_month_year_dow'));
    }
    return this.writtenOn;
  }

  getAssessmentName(){
    if (this.asmtFmrk){
      return this.asmtFmrk.assessmentName
    }
  }

  getTestTakerID(){
    return this.testTakerPEN || this.testTakerName || "____________";
  }

  downloadRubric() {
    const wasActive = this.dataGuard.isActive();
    if(wasActive) {
      this.dataGuard.deactivate();
    }
    this.rubricLinkRef.nativeElement.click();
    if(wasActive) {
      this.dataGuard.activate();
    }
  }
}
