import { Component, OnInit, Input } from '@angular/core';
import {AssetLibraryService} from '../services/asset-library.service'
import { IContentElement } from 'src/app/ui-testrunner/models';
import { EditingDisabledService } from '../editing-disabled.service';

@Component({
  selector: 'asset-library-link',
  templateUrl: './asset-library-link.component.html',
  styleUrls: ['./asset-library-link.component.scss']
})
export class AssetLibraryLinkComponent implements OnInit {

  @Input() element:IContentElement;

  constructor(
    private assetLibraryService: AssetLibraryService,
    private editingDisabled: EditingDisabledService
    ) { }

  ngOnInit(): void {
  }
    
  openAssetLibrary(){
    this.assetLibraryService.update(this.element, false);
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }

}
