import { Component, OnInit, Input, ElementRef, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { IContentElementVideo, IEntryStateVideo, QuestionState, ElementType } from '../models';
import {BrowserModule, DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'
import { LangService } from '../../core/lang.service';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { WhitelabelService } from '../../domain/whitelabel.service';


export const memo = <T>(map: Map<string, T>, key:string, generator: (key:string) => T ) => {
  if (map.has(key)){
    return map.get(key);
  }
  else{
    const val = generator(key);
    map.set(key, val);
    return val;
  }
}

@Component({
  selector: 'element-render-video',
  templateUrl: './element-render-video.component.html',
  styleUrls: ['./element-render-video.component.scss']
})
export class ElementRenderVideoComponent implements OnInit, OnDestroy {

  @Input() element:IContentElementVideo;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() questionPubSub?: QuestionPubSub;

  @ViewChild('video')  video: ElementRef
  @ViewChild('videoTooltip')  videoTooltip: ElementRef
  @ViewChild('videoPlayer') videoPlayer: ElementRef

  isTooltipVisible: boolean = false; 
  public plyrOptions = {
    controls: [
      'play-large', 
      'play', 
      'progress', 
      'current-time', 
      'mute', 
      'volume', 
      'captions', 
      'settings', 
      'fullscreen'
    ]
  }
  private sanitizedUrls:Map<string, SafeResourceUrl> = new Map();
  private videoSources:Map<string, any[]> = new Map();
  private subtitleTracks:Map<string, any[]> = new Map();


  constructor(
    private sanitizer: DomSanitizer,
    private changeDetector: ChangeDetectorRef,
    private whitelabel: WhitelabelService,
    public lang: LangService,
  ) { }

  isAutoPlay = false;
  startTimeStamp = 0;
  isLimitted:boolean;

  ngOnInit() {
    this.isPlaying = false;
    this.isLimitted = !(this.whitelabel.getSiteFlag('IS_EQAO') && !this.element.isRestricted);
    this.ensureState();
    this.initVars();
  }

  initVars() {
    const id = this.element.entryId
    if (this.questionState){
      if (this.questionState[id]) {
        const qState = this.questionState[id]
        if (!qState.seekTimes) {
          qState.seekTimes = []
        }
        if (!qState.loadTimes) {
          qState.loadTimes = []
        }
        if (!qState.pauseTimes) {
          qState.pauseTimes = []
        }
        if (!qState.endedTimes) {
          qState.endedTimes = []
        }
      }
      this.questionState[id].loadTimes.push(this.getTime())
    }
  }

  ngAfterViewInit() {
    
  }


  ngOnDestroy() {
    try{
      const el = this.getVideoElement();
      // console.log('el to destroy', el)
      el.pause();
    } catch(e) {}
  }

  canStartPlayingCalled = false;
  canStartPlaying() {
    if (!this.canStartPlayingCalled && this.questionState && this.canPlay()) {
      const id = this.element.entryId
      const el = this.getVideoElement()
      const ts = this.questionState[id].timeStamp;
      this.startTimeStamp = ts;
      if (!el) return;
      if (ts && ts>0 && ts<el["duration"]) {
        console.log("Is Autoplaying");
        this.isAutoPlay = true;
        el["currentTime"] = ts;
        el.play();
      }
      this.canStartPlayingCalled = true;
    }
    
  }

  resetter = true;
  resetFlag() {
    this.resetter = false;
    this.changeDetector.detectChanges();
    this.resetter = true;
  }

  getTime() {
    const d = new Date();
    return d.getTime();
  }

  getVideoElement() {
    // return this.video.nativeElement
    const id = this.element.entryId
    let el = null;
    
    if (this.videoPlayer && this.videoPlayer.nativeElement) {
      el = this.videoPlayer.nativeElement
    }
    return el;
  }

  ensureState() {
    if (this.questionState && !this.questionState[this.element.entryId]) {
      const state:IEntryStateVideo = {
        isStarted: true,
        isFilled: true,
        numViews: 0,
        timeStamp: 0,
        type: ElementType.VIDEO
      };
      if (!state.numViews) {
        state.numViews = 0;
      }
      this.questionState[this.element.entryId] = state;
    }
    if (this.questionState){
      return this.questionState[this.element.entryId];
    }
    else{
      return {}
    }
  }

  private isSafari(){
    const userAgent = window.navigator.userAgent;
    return userAgent.indexOf("Safari") > -1;
  }
  
  getSecRemainingStr() {
    const element = this.getVideoElement();
    if (!element) {
      return '';
    }

    const leadingZero = (n:number) => {
      if ((''+n).length < 2){
        return '0'+n
      }
      return ''+n
    }
    const convertSecToMinSec = (sec) => {
      if (!sec ){
        return '00:00'
      }
      const min = Math.floor(sec/60);
      return leadingZero(min)+':'+leadingZero(Math.floor(sec - min*60))
    }
    return convertSecToMinSec(element["currentTime"]) + ' / ' + convertSecToMinSec(element["duration"])
  }

  hasStarted() {
    return this.isPlaying
  }

  getSubtitleTracks(){
    return memo(this.subtitleTracks, this.element.subtitlesUrl, src => [{
      kind: 'captions',
      label: 'Enabled',
      // srclang: 'en',
      src,
      default: true,
    }]);
  }

  getVideoSources(){
    return memo(this.videoSources, this.element.url, src => [{ src: this.optimizeUrl(src), /*type: 'video/mp4',*/ }]);
  }

  optimizeUrl(url:string){
    const domainRaw:string = 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/';
    const domainCdn:string = 'https://d3azfb2wuqle4e.cloudfront.net/';
    return url.split(domainRaw).join(domainCdn);
  }

  getVideoUrl(){
    return memo(this.sanitizedUrls, this.element.url, rawUrl => this.sanitizer.bypassSecurityTrustResourceUrl(this.optimizeUrl(rawUrl)) );
  }

  isPlaying;
  playing() {  
    this.isPlaying = true;
    if(this.questionState && this.questionState[this.element.entryId].numViews === 0) {
      this.isTooltipVisible = true;
    }

  }

  play() {
    this.isPlaying = true;
    this.isAutoPlay = false;
    const el:HTMLMediaElement = this.getVideoElement();
    if (!el) return;
    el.play();
    this.addView();
  }

  notPlaying(logTime:boolean = true) {
    this.isPlaying = false;
    this.isAutoPlay = false;
    if (logTime) {
      if (this.questionState && this.questionState[this.element.entryId]) {
        this.questionState[this.element.entryId].pauseTimes.push(this.getTime());
      }
    }
    // console.log("pausing times", this.questionState[this.element.entryId].pauseTimes)
  }

  addView() {
    if (this.questionState && this.questionState[this.element.entryId]) {
      const state = this.questionState[this.element.entryId]
      if (!this.isAutoPlay){
        state.numViews++;
        this.resetFlag();
      }
      console.log("addView", state.numViews)
    }
  }

  ended() {
    this.notPlaying(false);
    if (this.questionState && this.questionState[this.element.entryId]) {
      this.questionState[this.element.entryId].endedTimes.push(this.getTime());
    }
    console.log("ended times", this.questionState[this.element.entryId].endedTimes)
  }

  canPlay() {
    const state = this.ensureState();
    if (state){
      if (this.element.maxViews && state.numViews >= this.element.maxViews){
        return false;
      }
      else{
        return true;
      }
    }
    return false;  
  }

  dismissTooltip() {
    this.isTooltipVisible = false;
  }

  onTimeUpdate() {
    if (this.questionState && this.questionState[this.element.entryId]) {
      const element = this.getVideoElement();
      if (!element) return;
      this.questionState[this.element.entryId].timeStamp = element["currentTime"]
    }
  }

  onSeeking() {
    if (this.questionState && this.questionState[this.element.entryId]) {
      if (!this.questionState[this.element.entryId].seekTimes) {
        this.questionState[this.element.entryId].seekTimes = []
      }
      this.questionState[this.element.entryId].seekTimes.push(this.getTime())
      console.log("seek times", this.questionState[this.element.entryId].seekTimes)
      console.log("load times", this.questionState[this.element.entryId].loadTimes)
    }
  }
}
