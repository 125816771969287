<div> 
  <div *ngIf="getUrl()">
    <div class="simple-form-container" style="margin-bottom:1em;" *ngIf="!isRequestingReupload && !isNoScale">
      <label >   Scale  <num-scrub [el]="element" prop="scale" [step]="1" [max]="100"></num-scrub></label>
    </div>
    <div [ngSwitch]="fileType">
      <div *ngSwitchCase="'image'" style="position:relative;">
        <!-- [ngStyle]="{'max-width.%': element.scale}" -->
        <img [class.no-pointer-events]="isReadOnly()" [src]="getImageUrl()"  (click)="isRequestingReupload = !isRequestingReupload" class="uploaded-image" [class.is-shrunken]="isRequestingReupload"/>
        <div class="hide-on-hover" style="position: absolute; top:0em; left:0em; margin-bottom: 0.5em; font-size:0.8em; padding:0.2em 0.5em; background-color:rgba(255,255,255,0.8); font-weight:600;">
          Image Type: {{getFileType()}}
        </div>
      </div>
      <div *ngSwitchCase="'video'">
        <video id="player" playsinline controls> 
          <source [src]="getImageUrl()" [type]="element.fileType">
        </video>
      </div>
      <div *ngSwitchCase="'audio'">
        <audio controls>
          <source [src]="getImageUrl()" [type]="element.fileType">
        </audio>
      </div>
      <div *ngSwitchCase="'Graphics'">
        <img [src]="getImageUrl()" [ngStyle]="{'max-width.%': element.scale}" (click)="isRequestingReupload = !isRequestingReupload" class="uploaded-image" [class.is-shrunken]="isRequestingReupload"/>
      </div>
      <div *ngSwitchDefault>
        <a [class.no-pointer-events]="isReadOnly()" [href]="getUrl()" target="_blank">{{getUploadedMeta().filename}}</a>
        <a [class.no-pointer-events]="isReadOnly()" (click)="clearUrl()" style="color:#f00; margin-left:0.5em; font-size:0.85em;">Delete</a>
      </div>
    </div>
    <div *ngIf="fileType === 'image' || fileType === 'Graphics'">
      <!-- <input type="text" placeholder="Alt-text for the visually impaired" class="input" [formControl]="altText"> -->
      <textarea *ngIf="!element.assetId" placeholder="Alt-text for the visually impaired" rows="1" class="textarea is-small" [formControl]="altText"></textarea>
      <textarea *ngIf="element.assetId" placeholder="Alt-text for the visually impaired" rows="1" class="textarea is-small" [formControl]="altText" disabled></textarea>
      <button *ngIf="element.assetId" (click)="editAltText()">Edit Alt Text</button>
      <button (click)="clearImg()">Clear</button>
    </div>
  </div>
    
  <div *ngIf="isWaitingForUpload() || allowReupload">
    <div *ngIf="isUploadsDisabled">
      <input type="text" placeholder="Upload URL (external)" class="input" [formControl]="url">
    </div>
    <div *ngIf="!isUploadsDisabled">
      <div *ngIf="isCondensed">
          <div class="file" >
              <label class="file-label">
                <input [disabled]="isReadOnly()" class="file-input" type="file" (change)="startUpload($event.target.files)">
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="fa fa-upload"></i>
                  </span>
                  <span class="file-label">
                    {{uploadCaption}}
                  </span>
                </span>
              </label>
            </div>
      </div>
      
      <div *ngIf="!isCondensed">
        <div [class.no-pointer-events]="isReadOnly()" class="dropzone" dropZone (hovered)="toggleHover($event)" (dropped)="startUpload($event)" [class.hovering]="isHovering">
          <p>Drag and Drop a File</p>
          <div class="file">
            <label class="file-label">
              <input [disabled]="isReadOnly()" class="file-input" type="file" (change)="startUpload($event.target.files)">
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fa fa-upload"></i>
                </span>
                <span class="file-label">
                  or choose a file…
                </span>
              </span>
            </label>
          </div>
          <div>
            <button (click)="promptImgUrl()" class="button is-small">or paste image link</button>
          </div>
        </div>
      </div>
      <div *ngIf="percentage | async as pct">
        <progress class="progress is-info" [value]="pct" max="100">
        </progress>
        {{ pct | number }}%
      </div>
    </div>
  </div>  
</div>
