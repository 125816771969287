
<!-- elementType: {{contentElement.elementType}} -->
<div [ngSwitch]="contentElement.elementType">
  <element-config-audio                  *ngSwitchCase = "ElementType.AUDIO"          [element] = "contentElement" ></element-config-audio>
  <element-config-camera                 *ngSwitchCase = "ElementType.CAMERA"         [element] = "contentElement" ></element-config-camera>
  <element-config-dnd                    *ngSwitchCase = "ElementType.DND"            [element] = "contentElement" ></element-config-dnd>
  <element-config-moveable-dnd           *ngSwitchCase = "ElementType.MOVEABLE_DND"   [element] = "contentElement" ></element-config-moveable-dnd>
  <element-config-graphics               *ngSwitchCase = "ElementType.GRAPHICS"       [element] = "contentElement" ></element-config-graphics>
  <element-config-graphing               *ngSwitchCase = "ElementType.GRAPHING"       [element] = "contentElement" ></element-config-graphing>
  <element-config-iframe                 *ngSwitchCase = "ElementType.IFRAME"         [element] = "contentElement" ></element-config-iframe>
  <element-config-frame                  *ngSwitchCase = "ElementType.FRAME"          [element] = "contentElement" ></element-config-frame>
  <element-config-image                  *ngSwitchCase = "ElementType.IMAGE"          [element] = "contentElement" [canMove]='true' ></element-config-image>
  <element-config-input                  *ngSwitchCase = "ElementType.INPUT"          [element] = "contentElement" ></element-config-input>
  <element-config-doc-link               *ngSwitchCase = "ElementType.DOC_LINK"       [element] = "contentElement" ></element-config-doc-link>
  <element-config-matching               *ngSwitchCase = "ElementType.MATCHING"       [element] = "contentElement" ></element-config-matching>
  <element-config-math                   *ngSwitchCase = "ElementType.MATH"           [element] = "contentElement" ></element-config-math>
  <element-config-mcq                    *ngSwitchCase = "ElementType.MCQ"            [element] = "contentElement" ></element-config-mcq>
  <element-config-custom-mcq             *ngSwitchCase = "ElementType.CUSTOM_MCQ"     [element] = "contentElement" ></element-config-custom-mcq>
  <element-config-mic                    *ngSwitchCase = "ElementType.MIC"            [element] = "contentElement" ></element-config-mic>
  <element-config-order                  *ngSwitchCase = "ElementType.ORDER"          [element] = "contentElement" ></element-config-order>
  <element-config-reader                 *ngSwitchCase = "ElementType.READER"         [element] = "contentElement" ></element-config-reader>
  <element-config-canvas                 *ngSwitchCase = "ElementType.CANVAS"         [element] = "contentElement" ></element-config-canvas>
  <element-config-insertion              *ngSwitchCase = "ElementType.INSERTION"      [element] = "contentElement" ></element-config-insertion>
  <element-config-sbs                    *ngSwitchCase = "ElementType.SBS"            [element] = "contentElement" ></element-config-sbs>
  <element-config-solution               *ngSwitchCase = "ElementType.SOLUTION"       [element] = "contentElement" ></element-config-solution>
  <element-config-results-print          *ngSwitchCase = "ElementType.RESULTS_PRINT"  [element] = "contentElement" ></element-config-results-print>
  <element-config-selection-table        *ngSwitchCase = "ElementType.SELECT_TABLE"   [element] = "contentElement" ></element-config-selection-table>
  <element-config-select-text            *ngSwitchCase = "ElementType.SELECT_TEXT"    [element] = "contentElement"></element-config-select-text>
  <element-config-table                  *ngSwitchCase = "ElementType.TABLE"          [element] = "contentElement" ></element-config-table>
  <element-config-text                   *ngSwitchCase = "ElementType.TEXT"           [element] = "contentElement" ></element-config-text>
  <element-config-annotation             *ngSwitchCase = "ElementType.ANNOTATION"     [element] = "contentElement" ></element-config-annotation>
  <element-config-text-link              *ngSwitchCase = "ElementType.TEXT_LINK"      [element] = "contentElement" ></element-config-text-link>
  <element-config-upload                 *ngSwitchCase = "ElementType.UPLOAD"         [element] = "contentElement" ></element-config-upload>
  <element-config-video                  *ngSwitchCase = "ElementType.VIDEO"          [element] = "contentElement" ></element-config-video>
  <element-config-grouping               *ngSwitchCase = "ElementType.GROUPING"       [element] = "contentElement"></element-config-grouping>
</div>