<div> 
  <div>
    <label class="checkbox">
      <input type="checkbox" [formControl]="isMatchingMode">
      Allow only one option per group?
    </label>
  </div>

  <div>
    <label class="checkbox">
      <input type="checkbox" [formControl]="isVerticalLayout">
      User vertical layout
    </label>
  </div>

  <!--<div>
    <label class="checkbox">
      <input type="checkbox" [(ngModel)]="element.enableProportionalScoring">
      Enable proportional scoring?
    </label>
  </div>-->

  <div>
    <label class="checkbox">
      <input type="checkbox" [formControl]="isInstructionsDisabled">
      Disable drag instruction text?
    </label>
  </div>

  <div>
    <label class="checkbox">
      <input type="checkbox" [(ngModel)]="element.isTargetsAbove">
      Place targets above?
    </label>
  </div>

  <div>
    <label class="checkbox">
      <input type="checkbox" [(ngModel)]="this.element.customTargetDim" (click)="this.updateChangeCounter()">
      Is Custom Target Dimension
    </label>
  </div>
  <div *ngIf="this.element.customTargetDim">
      Target Dimensions
      <input type="number" placeholder="Width" min="5" [(ngModel)]="this.element.targetWidth" [style.width.em]="4">
      <input type="number" placeholder="height" min="5" [(ngModel)]="this.element.targetHeight" [style.width.em]="4">
  </div>
  <div>
    Draggable Dimensions
    <input type="number" placeholder="Width"  [(ngModel)]="element.draggableWidth" [style.width.em]="4">
    <input type="number" placeholder="height"  [(ngModel)]="element.draggableHeight" [style.width.em]="4">
  </div>
  <div>
    <label class="checkbox">
      <input type="checkbox" [(ngModel)]="element.isTargetsContentsCentred" >
      Center Draggable Contents
    </label>
  </div>
  <label class="checkbox">
    Group Width
    <input type="number" min="30" [(ngModel)]="this.element.groupWidth" [style.width.em]="4">
  </label>
  <hr/>
  Layout
  <select [(ngModel)]="element.arrangementType">
    <option *ngFor="let arrangement of arrTypes; let index = index" [value]="arrangement.id">
      {{arrangement.caption}}
    </option>
  </select>

  <div class="element-group-header">
    <i class="fa fa-dot-circle-o" aria-hidden="true"></i>
    Draggable Elements
  </div>
    <div class="field" *ngIf="isShowAdvancedOptions.value">
      <color-panel [element]="this.element.draggableColourScheme"></color-panel>

    </div>
    <div 
      cdkDropList
      [cdkDropListDisabled]="isReadOnly()" 
      (cdkDropListDropped)="drop(element.draggables, $event);"
    >
      <div 
        cdkDrag 
        *ngFor="let draggableEl of element.draggables"
      >
        <div class="draggable-row" >
          <a 
            cdkDragHandle 
            class="button is-handle" 
            [class.is-info]="isSelectedPosRef(draggableEl)"
            (mousedown)="selectPosElByRef(draggableEl)"
          >
            <i class="fas fa-arrows-alt" aria-hidden="true"></i>
          </a>
          <div class="row-content">
            <capture-dnd-style 
              [element]="draggableEl" 
              (change)="throttledUpdate()"
              (idClick)="selectPosElByRef(draggableEl)"
            ></capture-dnd-style>
            <div *ngIf="element.arrangementType=='manual'">
              <label>
                <input type="number" [(ngModel)]="draggableEl.x" [style.width.em]="4" class="input is-small"> X
              </label>
              <label>
                <input type="number" [(ngModel)]="draggableEl.y" [style.width.em]="4" class="input is-small"> Y
              </label><br>
            </div>
            <element-config  [contentElement]="draggableEl.element"></element-config>
          </div>
          <a class="button is-expanded" (click)="removeElement(element.draggables, draggableEl)">
            <i class="fas fa-trash-alt" aria-hidden="true"></i>
          </a>
        </div>
        <element-config-mcq-option-info *ngIf="this.element.isShowAdvancedOptions" [mcqOption]="draggableEl"></element-config-mcq-option-info>  
      </div>
    </div>
    
    <div>
      <select-element 
        [elementTypes]="subElementTypes"
        (insert)="insertDraggableElement($event)"
        style="margin-top:1em;" 
      ></select-element>
    </div>    
    <hr/>
    <div class="element-group-header">
      <i class="fa fa-crosshairs" aria-hidden="true"></i>
      Target Elements
    </div>
    <div class="field" *ngIf="isShowAdvancedOptions.value">
      <color-panel [element]="this.element.targetColourScheme"></color-panel>
    </div>
    <div 
      cdkDropList 
      (cdkDropListDropped)="drop(element.targets, $event);"
    >
      <div 
        cdkDrag 
        *ngFor="let draggableEl of element.targets"
      >
        <div class="draggable-row">
          <a
                  cdkDragHandle
                  class="button is-handle"
                  [class.is-info]="isSelectedPosRef(draggableEl)"
                  (mousedown)="selectPosElByRef(draggableEl)"
          >
            <i class="fas fa-arrows-alt" aria-hidden="true"></i>
          </a>
          <div class="row-content">
            <capture-dnd-style
                    [element]="draggableEl"
                    (change)="throttledUpdate()"
                    (idClick)="selectPosElByRef(draggableEl)"
            ></capture-dnd-style>
            <div *ngIf="element.arrangementType=='manual'">
              <label>
                <input type="number" [(ngModel)]="draggableEl.x" [style.width.em]="4" class="input is-small"> X
              </label>
              <label>
                <input type="number" [(ngModel)]="draggableEl.y" [style.width.em]="4" class="input is-small"> Y
              </label><br>
            </div>
            <element-config  [contentElement]="draggableEl.element"></element-config>
            <div>
              Background Color
              <input type="color" [(ngModel)]="draggableEl.bgColor">
            </div>
            <div *ngIf="isShowAdvancedOptions.value">
              <div style="font-size:14px;font-weight:bold;">
                Background Image:
              </div>
              <capture-image *ngIf="draggableEl.backgroundImg" [element]="draggableEl.backgroundImg"></capture-image>
              <asset-library-link *ngIf="draggableEl.backgroundImg" [element]="draggableEl.backgroundImg"></asset-library-link>
            </div>
          </div>
          <a class="button is-expanded" (click)="removeElement(element.targets, draggableEl)">
            <i class="fas fa-trash" aria-hidden="true"></i>
          </a>
        </div>
        <element-config-mcq-option-info *ngIf="this.element.isShowAdvancedOptions" [mcqOption]="draggableEl"></element-config-mcq-option-info>
      </div>

    </div>
    
    <div>
      <select-element 
        [elementTypes]="subTargetElementTypes"
        (insert)="insertTargetElement($event)"
        style="margin-top:1em;" 
      ></select-element>
    </div>
    <hr />

    <div>
      <label class="checkbox">
        <input type="checkbox" [(ngModel)]="element.enableProportionalScoring">
        Enable proportional scoring?
      </label>
    </div>
    <div class="form-row" *ngIf="isScoreWeightEnabled()">
      <div class="form-row-label">
          Score Weight
      </div>
      <div class="form-row-input">
        <input type="text" class="input" [formControl]="scoreWeight">
      </div>
    </div>

    <div>
      <label class="checkbox">
        <input type="checkbox" [formControl]="isShowAdvancedOptions">
        Show Advanced Options
      </label>
    </div>
  </div>