import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { IContentElementMoveableDragDrop, IEntryStateMoveableDragAndDrop, IContentElementDndDraggable, IContentElementTextLink, ScoringTypes, IContentElementDndTarget, ElementType, getElementWeight } from '../models';
import { indexOf } from '../services/util';
import { HyperlinkService } from '../hyperlink.service';
import { IElementPos, renderDndElementStyle } from '../../ui-item-maker/element-config-grouping/element-config-grouping.component';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { generateDefaultElementDnd } from "../../ui-item-maker/item-set-editor/models/index"
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';



const SCORING_TYPE = ScoringTypes.AUTO;

@Component({
  selector: 'element-render-moveable-dnd',
  templateUrl: './element-render-moveable-dnd.component.html',
  styleUrls: ['./element-render-moveable-dnd.component.scss']
})
export class ElementRenderMoveableDndComponent implements OnInit, OnChanges {

  @Input() element:IContentElementMoveableDragDrop;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() changeCounter:number;
  @Input() questionState:any;
  @Input() questionPubSub?: QuestionPubSub;

  targets: {targetContext: IContentElementDndTarget, contents:IElementPos[]}[] = [];
  isDragging:boolean;

  constructor() { }

  ngOnInit() {
    this.ensureState();
  }

  ngOnChanges(changes:SimpleChanges){
    // runs on every intialization
    if (changes.changeCounter) {
      this.updateDisplayEls();
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    const source = event.previousContainer.data;
    const draggable = source[0];
    const dest = event.container.data;
    const hasContent = (event.container.data.length > 0);
    const isDestHomeTarget = this.homeTargetContents.get(dest)
    const draggableHome = this.homeTargetMap.get(draggable) || {};
    const isSelfHome = (dest === draggableHome.contents);
    const sendHome = (draggable, sendSource) => {
      const swapElement = draggable;
      const homeTarget = this.homeTargetMap.get(swapElement) || {};
      const swapTargetList = homeTarget.contents;
      console.log({
        swapElement,
        homeTarget,
        swapTargetList,
      })
      if (swapTargetList){
        transferArrayItem(
          sendSource,
          swapTargetList,
          0,
          0,
        );
      }
    }
    
    // console.log('drop', event)
    if (!event.isPointerOverContainer){
      sendHome(draggable, source);
    }
    else if (event.previousContainer === event.container) {
        //moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        return;
    }
    else {
      // console.log('will not allow drop into another draggables home slot', isDestHomeTarget, isSelfHome, dest, draggableHome.contents);
      if (isDestHomeTarget && !isSelfHome){
        sendHome(draggable, source);
      }
      else {
        if (hasContent) {
          sendHome(dest[0], dest);
        }
        transferArrayItem(
          source,
          dest,
          event.previousIndex,
          event.currentIndex,
        );
      }
    }

    this.updateState();
  }

  ensureState(){
    if (this.questionState) {
      if (!this.questionState[this.element.entryId]) {
        let entryState: IEntryStateMoveableDragAndDrop = {
          type: ElementType.MOVEABLE_DND,
          isCorrect: false,
          isStarted: false,
          isFilled: false,
          score: 0, //this.targets.find((target:any) => target.groups.length) ? getElementWeight(this.element) : 0,
          weight: getElementWeight(this.element),
          scoring_type: ScoringTypes.AUTO,
          targets: this.targets
        };
        //console.log("initializing state")
        this.questionState[this.element.entryId] = entryState;
      }
      
    }
  }

   
  updateState(){
    if (this.questionState) {
      const entryId = this.element.entryId;
      let weight = getElementWeight(this.element) || 1;
      let numCorrect = 0;
      let numTargets = 0;
      let numDraggablesPlaced = 0; // 
      let isAllFilled = true;
      let isStarted = false;
      this.targets.forEach((target)=>{
        let context = target.targetContext;
        const isHomeTarget = this.homeTargetContents.has(target.contents)
        if (!isHomeTarget){
          const draggableEl = target.contents[0];
          numTargets ++;
          if (draggableEl){
            numDraggablesPlaced ++;
            isStarted = true;
            const draggable = <IContentElementDndDraggable> draggableEl.ref;
            // console.log('real target ', target.contents.length, draggable.targetId, context.id)
            if (draggable.targetId == context.id) {
              numCorrect ++;
            }
          }
          else{
            isAllFilled = false;
          }
        }
      })

      let score = 0;
      let isCorrect:boolean = false;
      let isFilled = true;

      if (this.element.isAllTargetsToPlace || (this.element.draggables.length < this.element.targets.length)){
        isFilled = (numDraggablesPlaced >= this.element.draggables.length);
        if (numCorrect === numDraggablesPlaced){
          isCorrect = true;
        }
        if (numDraggablesPlaced > 0){
          score = weight*(numCorrect/numDraggablesPlaced);
        }
      }
      else {
        isFilled = isAllFilled;
        if (numCorrect === numTargets){
          isCorrect = true;
        }
        if (this.element.enableProportionalScoring) {
          if (numTargets > 0){
            score = weight*numCorrect/numTargets;
          }
        }
        else{
          if (isCorrect){
            score = weight;
          }
        }
      }

      // console.log(missed==0)
      let entryState: IEntryStateMoveableDragAndDrop = {
        type: ElementType.MOVEABLE_DND,
        isCorrect,
        isStarted,
        isFilled,
        score,
        weight,
        scoring_type: ScoringTypes.AUTO,
        targets: this.targets, // this.textBlocks
      };
      this.questionState[this.element.entryId] = entryState;
      // console.log('dnd entryState', entryState)
    }
  }

  homeTargetMap;
  homeTargetContents:Map<any, boolean>;

  updateDisplayEls() {
    this.homeTargetMap = new Map();
    this.homeTargetContents = new Map();
    if (this.questionState && this.questionState[this.element.entryId]) {
      this.targets = this.questionState[this.element.entryId]["targets"];
      for(let target of this.targets) {
        if(target.contents) {
          const draggable = target.contents[0];
          if (draggable){
            const homeTargetId = draggable.ref.id;
            const homeTarget = this.targets.filter(target => target.targetContext.id === homeTargetId)[0];
            this.homeTargetMap.set(draggable, homeTarget);
            this.homeTargetContents.set(homeTarget.contents, true);
          }
        }
      }
      return;
    }
    this.targets = [];
    if (this.element.draggables) {
        this.element.draggables.forEach(element => {
            const arr = []
            const targetDraggable = this.addElementToList(element, arr);
            const target = {
              targetContext: element,
              contents: [targetDraggable],
            }
            this.targets.push(target);
            this.homeTargetMap.set(targetDraggable, target);
            this.homeTargetContents.set(target.contents, true);
        });
    }
    if (this.element.targets) {
        this.element.targets.forEach(element=>{
            this.targets.push({
                targetContext: element,
                contents: []
            })
        })
    }
  }

  addElementToList(element: IContentElementDndDraggable, elementsToPosition: IElementPos[], isTarget: boolean= false) {
    let hasElement  = false;
    if ((<IContentElementDndDraggable> element).element) {
      hasElement = true;
    }
    const obj = {
        ref: element,
        originalX: element.x,
        originalY: element.y,
        isTarget,
        //style: renderDndElementStyle(element, hasElement, isTarget && this.element.customTargetDim, this.element.defaultTargetStyle),
        style: {}
      }
    elementsToPosition.push(obj);
    return obj;
  }

  getTargetStyle(target) {
    const ctx = target.targetContext;
    const content = target.contents;
    let style:any = {
      'min-width.em': ctx.width,
      'min-height.em': ctx.height,
      'left.em': ctx.x || 0,
      'top.em': ctx.y || 0,
    };

    if (!this.element.isTargetsInvisible){
      style["border"] = "dashed 2px black";
      style["background-color"] = ctx.containerBackgroundColor || "#ffffff";
    }
    else {
      style["border"] = "dashed 2px rgba(0,0,0,0)";
      style["background-color"] = "none";
    }

    if (this.element.isTargetColourSame){
      style["background-color"] = this.element.targetColour;
    }
    
    return style
  }

  getDraggableColour(drag:IContentElementDndDraggable) {
    if (!this.element.isDragsTransparent) {
      if (this.element.isDraggableColourSame) {
        return this.element.draggableColour;
      }
      if (drag.backgroundColor) return drag.backgroundColor;
      return "#ffffff"
    }
    return undefined;
  }

}