<div class="element-type-insertion">
  <div *ngIf="this.element.isShowInstructions" class="question-block-label mb-3" style="text-align: center; font-size:0.8em;">
    <tra-md *ngIf="!element.isDragBetweenWords" slug="voice_insertion_instr"></tra-md>
    <tra-md *ngIf="element.isDragBetweenWords" slug="voice_insertion_blind_instr"></tra-md>
  </div>

  <div
    #elPosContainer
    class="pos-container"
  >
    <div cdkDropListGroup class="">

      <div *ngIf="!this.element.isTargetsAbove">
        <div
                style="display: flex;
        flex-direction: row;
        flex: 1 1 0%;
        align-items: flex-start;
        flex-wrap: wrap;
        border: unset;
        height: 100%;"
                cdkDropList
                class="drag-drop-list drag-drop-source mb-3"
                [class.is-empty]="draggables.length == 0 "
                [cdkDropListData]="draggables"
                cdkDropListSortingDisabled
                (cdkDropListDropped)="drop($event, true)">
          <div
                  class="drag-el"
                  *ngFor="let elPos of draggables"
                  cdkDrag style="margin-right: 10px;"
                  [cdkDragDisabled]="isLocked"
                  (mousedown)="dragStarted(elPos)"
                  [style.max-width.em]="this.element.blankTargetSize"
                  [style.white-space]="'nowrap'"
          >
            <span [innerHtml]="this.updateRender(elPos.ref.element.caption)"></span>
            <render-audio
                    [url]="getElementAudio(elPos.ref.voiceover)"
                    [trigger]="getDragTrigger(elPos)"
                    [isTriggerDisabled]="!isVoiceoverEnabled()"
            ></render-audio>
            <!--<element-render
              *ngIf="elPos.ref.element"
              [contentElement]="elPos.ref.element"
              [isLocked]="true"
              [style.white-space]="'nowrap'"
              [questionState]="{}"
            ></element-render>-->
          </div>
        </div>
      </div>

      <div class="group-container" [class.mb-3]="this.element.isTargetsAbove" [style.min-height.em]="3">
        <div class="block-el" *ngFor="let block of blocks;" [ngSwitch]="!!block.isTarget"  [style.min-height.em]="!isDragBetweenWords() ? 1.6 : 0">
        <span *ngSwitchCase="false" [class.is-space-after]="block.isSpaceAfter && !block.isPunctNext">
          <markdown-inline [input]="block.str"></markdown-inline>
        </span>
          <div
                  *ngSwitchCase="true"
                  [class.block-drop]="!isDragBetweenWords()"
                  [class.inline-drop]="isDragBetweenWords()"
                  [class.has-no-content]="block.contents.length == 0"
                  [class.is-punct-next]="block.isPunctNext"
                  [class.align-items]="'center'"
          >
            <div
                    cdkDropList
                    [cdkDropListData]="block.contents"
                    class="drag-drop-list"
                    [style.min-width]="!isDragBetweenWords() && block.contents.length == 0 && this.element.blankTargetSize ? this.element.blankTargetSize+'em' : !isDragBetweenWords() ? '2.5em' : '0em'"
                    [class.is-punct-next]="block.isPunctNext"
                    style="flex-wrap: wrap; flex-direction: row;"
                    (cdkDropListDropped)="drop($event, false)"
            >
              <div
                      class="drag-el is-placed"
                      [style.margin-bottom.em]="0"
                      *ngFor="let assignedElement of block.contents"
                      cdkDrag
                      [cdkDragDisabled]="isLocked"
                      (mousedown)="dragStarted(assignedElement)"
              >
                <element-render
                        *ngIf="assignedElement.ref.element"
                        [contentElement]="assignedElement.ref.element"
                        [isLocked]="true"
                        [questionState]="{}"
                ></element-render>
                <render-audio
                        [url]="getElementAudio(assignedElement.ref.voiceover)"
                        [trigger]="getDragTrigger(assignedElement)"
                        [isTriggerDisabled]="!isVoiceoverEnabled()"
                ></render-audio>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="this.element.isTargetsAbove">
        <div
                style="display: flex;
        flex-direction: row;
        flex: 1 1 0%;
        align-items: flex-start;
        flex-wrap: wrap;
        border: unset;
        height: 100%;"
                cdkDropList
                class="drag-drop-list drag-drop-source mb-3"
                [class.is-empty]="draggables.length == 0 "
                [cdkDropListData]="draggables"
                cdkDropListSortingDisabled
                (cdkDropListDropped)="drop($event, true)">
          <div class="drag-el" *ngFor="let elPos of draggables"
               cdkDrag style="margin-right: 10px;"
               [cdkDragDisabled]="isLocked"
               (mousedown)="dragStarted(elPos)"
               [style.max-width.em]="this.element.blankTargetSize"
               [style.white-space]="'nowrap'"
          >
            <span [innerHtml]="this.updateRender(elPos.ref.element.caption)"></span>
            <render-audio
                    [url]="getElementAudio(elPos.ref.voiceover)"
                    [trigger]="getDragTrigger(elPos)"
                    [isTriggerDisabled]="!isVoiceoverEnabled()"
            ></render-audio>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>