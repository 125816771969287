<div 
[class.is-panel-visible]="isSidePanelVisible()" 
[class.is-panel-mobile-visible]="isMobileLeftPanel()" 
[class.is-panel-expanded]="isSidePanelExpanded()" 
style="height:100%;"
>
  <div class="mobile-overhead">
    <div class="mobile-overhead-bar">
      <div class="btn-menu" (click)=openMobileLeftPanel()>
        <i class="fa fa-bars" aria-hidden="true"></i>
      </div>
    </div>
  </div>
  <div style="height:100%;">
    <div class="page-content content" >
      <router-outlet></router-outlet>
    </div>
    <div class="confirmation-modal"></div>
    <div class="log-back-in modal-screen-cover" [class.is-hidden]="!( getLoginReq() | async)">
      <div class="modal-screen-cover-content is-boxed">
        <div class="modal-scrollpane">
          <login-form></login-form>
        </div>
        <div class="delete-container">
          <button (click)="gotoHomeScreen()" class="delete is-large"></button>
        </div>
      </div>
    </div>
    <div class="connection-display">
      <span class="tag is-danger" [class.is-hidden]="!(getDevNetFail() | async)">
        <tra slug="error_device_net"></tra>
      </span>
      <span class="tag is-warning" [class.is-hidden]="!(getApiNetFail() | async)">
        <tra slug="error_server_net"></tra>
      </span>
    </div>
    <div class="connection-display">
      <span class="tag is-danger" *ngIf="(getAutoLogout() | async)">
        <tra slug="alert_logged_out"></tra>
      </span>
      <span class="tag is-warning" *ngIf="(getAutoLogoutWarning() | async)">
        <tra slug="alert_logging_out"></tra>
        <a (click)="refreshLogin()"> <tra slug="link_logging_out"></tra> </a>
      </span>
    </div>
    <div class="device-net-fail-overlay modal-screen-cover" [class.is-hidden]="!isShowingDevNetFailOverlay">
      <div class="modal-screen-cover-content  is-boxed">
        <div class="modal-scrollpane">
          <div>
            <tra slug="offline_warning"></tra>
          </div>
          <div>
            <button (click)="isShowingDevNetFailOverlay = false" class="button is-danger">
              <tra slug="btn_dismiss_notif"></tra>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="api-net-fail-overlay modal-screen-cover" [class.is-hidden]="true"> <!-- !isShowingApiNetFailOverlay -->
      <div class="modal-screen-cover-content  is-boxed">
        <div class="modal-scrollpane">
          <div>
            <tra slug="error_server_net_long"></tra>
          </div>
          <hr/>
          <strong>Service Health</strong>
          <table class="table is-outlined is-bordered" style="margin-top: 1em;">
            <tr>
              <th>Current Status</th>
              <th>Current Details</th>
            </tr>
            <tr>
              <td>✅ Registration</td>
              <td>Service is operating normally</td>
            </tr>
            <tr>
              <td>✅ Login</td>
              <td>Service is operating normally</td>
            </tr>
            <tr>
              <td>✅ Invigilation</td>
              <td>Service is operating normally</td>
            </tr>
          </table>
          <hr/>
          <div>
            You may be receiving an error due to unusual traffic coming from your location. If you continue to experience a localized issue, please send <a target="_blank" href="https://www.google.com/search?q=what+is+my+ip&oq=what+is+my+ip">your IP address</a> to the technical support team (<a href="mailto:eqao-support@vretta.com">eqao-support@vretta.com</a>) so that the issue can be investigated.
          </div>
          <hr/>
          <div>
            <button (click)="isShowingApiNetFailOverlay = false" class="button is-warning"><tra slug="btn_dismiss_notif"></tra></button>
          </div>
        </div>
      </div>
    </div>
    <div class="support-overlay modal-screen-cover" [class.is-hidden]="!(getSupportReq() | async) ">
      <div class="modal-screen-cover-content  is-boxed is-wide">
        <div class="modal-scrollpane">
          <info-center-details [isAuthActive]="isAuthActive()" [supportCenterInfo]="getSupportReq() | async" (dismiss)="dismissSupportPopup()"></info-center-details>
        </div>
      </div>
    </div>
    <div class="confirmation-overlay modal-screen-cover" [class.is-hidden]="!currentConfirmation" style="z-index:5000">
      <div class="modal-screen-cover-content  is-boxed" [style.max-width]="setCustomWidth()">
        <div class="modal-scrollpane">
          <div class="content">
            <tra-md [slug]="getCurrentConfirmationCaption()"></tra-md>
          </div>
          <div class="modal-buttons">
            <button class="button" (click)="closeModal()" *ngIf="!showCancel()">{{confirmationCancelCaption}}</button>
            <button class="button is-info" (click)="confirmModal()">{{confirmationProceedCaption}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content-shadow" (click)="closeMobileLeftPanel()"></div>
  <main-nav class="left-panel-container hide-on-paper" [class.is-hidden]="!isSidePanelVisible()"></main-nav>
</div>
