export enum SchoolAdminView {
    DASHBOARD = 'dashboard',
    TECH_READI = 'tech_readiness',
    TEACHERS = 'educators',
    STUDENTS = 'students',
    SESSIONS = 'sessions',
    REPORTS = 'reports',
    CLASSROOMS = 'classrooms',
  }
  
export const SCHOOL_ADMIN_VIEWS = [
    {
      id:SchoolAdminView.TECH_READI, 
      caption: ('lbl_tech_readi'), //'Technical Readiness',
      imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/clipboard/1602235150476/clipboard.png',
      description: ('txt_tech_readi'), //'',
      hasIndicator: true,
    },
    {
      id:SchoolAdminView.CLASSROOMS, 
      caption: ('lbl_classes_groups'), //'Class Sections',
      imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/school/1602128112754/school.png',
      description: ('txt_sa_classrooms_info_1'), //'You can review and manage the classes as they progress with their introductory materials and administration of the assessment.',
    },
    {
      id:SchoolAdminView.TEACHERS, 
      caption: ('lbl_teachers'), //'Teachers',
      imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/work-from-home/1602128147290/work-from-home.png',
      description: ('txt_teachers'), // '0 accounts registered. Use this view to send email invitations to teachers so that they can access their account.',
    },
    {
      id:SchoolAdminView.STUDENTS, 
      caption: ('g9_students'), //'Students',
      imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/students/1602128010278/students.png',
      description: ('txt_students') // '67 students are currently enrolled. Click here to validated their information and re-assign them to different teachers.',
    },
    {
      id:SchoolAdminView.SESSIONS, 
      caption: ('g9_sessions'), //'Assessment Sessions',
      imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/calendar/1602128832706/calendar.png',
      description: ('txt_sessions') //'No assessment sessions have yet been scheduled. No assessments sessions have been run. Teachers can schedule assessments from their dashboard.',
    },
    {
      id:SchoolAdminView.REPORTS, 
      caption: ('lbl_reports'), //'Assessment Sessions',
      imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/report/1614338286078/report.png',
      description: ('txt_reports_description') //'No assessment sessions have yet been scheduled. No assessments sessions have been run. Teachers can schedule assessments from their dashboard.',
    },
]