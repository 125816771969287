import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

export interface IMenuTabConfig<T> {
  id: T;
  caption: string;
  count?: () => number | string;
  showIf?: () => boolean;
  ///
  isCountEnabled?: boolean;
  isShowIfEnabled?: boolean;
  data?: any;
}

@Component({
  selector: 'menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit, OnChanges {

  @Input() isSubMenu: boolean;
  @Input() menuTabs: IMenuTabConfig<string>[];
  @Input() tabIdInit: string;
  @Output() change = new EventEmitter();

  private activeTabId: string;

  constructor() { }

  ngOnInit(): void {
    this.menuTabs.forEach(tab => {
      tab.isCountEnabled = (typeof tab.count === 'function');
      tab.isShowIfEnabled = (typeof tab.showIf === 'function');
    });
    if (this.tabIdInit) {
      this.setActiveId(this.tabIdInit);
    }
  }

  ngOnChanges() {
    if (this.tabIdInit){
      this.setActiveId(this.tabIdInit);
    }
  }
  
  isTabHidden(tab:IMenuTabConfig<any>){
    if (tab.isShowIfEnabled){
      return tab.showIf();
    }
    return true;
  }

  isActiveId(id: string) { return this.activeTabId === id; }
  setActiveId(id: string) {
    this.activeTabId = id;
    this.change.emit(id);
  }

}
