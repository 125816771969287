<div *ngIf="element.url" class="mouse-over" style="position:relative">

  <!-- <video controls crossorigin="anonymous">
    <source [src]="getVideoUrl()" [type]="element.fileType">
  </video> -->
  <!-- <plyr  plyrTitle="" 
        [plyrPlaysInline]="false" 
        [plyrOptions]="plyrOptions"
        [plyrSources]="getVideoSources()" 
        [plyrTracks]="getSubtitleTracks()" 
        plyrCrossOrigin="anonymous"
  ></plyr> -->
    <!-- <div plyr plyrTitle="" [plyrPlaysInline]="false" [plyrCrossOrigin]="true"
   [plyrSources]="getVideoSources()" [plyrTracks]="getSubtitleTracks()" (plyrInit)="player = $event"
  (plyrPlay)="played($event)"></div> -->
  <!-- <div style="font-weight:bold;" *ngIf="resetter && this.questionState && this.questionState[element.entryId] && element.maxViews">
    <tra-md [isCondensed]="true" slug="lbl_num_views_left" [props]="{NUM_REM_VIEWS: element.maxViews - questionState[element.entryId].numViews}"></tra-md>
  </div> -->
  
  <div style="display: flex; flex-direction: row; position: relative; width: fit-content; height: fit-content; padding: 0px;">
    <video 
      #videoPlayer
      [id]="'video'+element.entryId" 
      [autoplay]="hasStarted()" 
      (play)="playing()" 
      (timeupdate)="onTimeUpdate()" 
      (ended)="ended()" 
      (pause)="notPlaying()" 
      [controls]="!isLimitted" 
      (seeked)="onSeeking()"
      width="640" 
      height="480" 
      controlsList="nodownload nofullscreen" 
      (canplay)="canStartPlaying()"
      disablePictureInPicture
    >
      <source type="video/mp4" [src]="getVideoSources()[0].src">
        <track [label]="getSubtitleTracks()[0].label" kind="getSubtitleTracks()[0].kind"  [src]="getSubtitleTracks()[0].src">
    </video> 
    <!-- <div *ngIf="element.maxViews > 0 || element.isUnPausable" #videoTooltip (click)="dismissTooltip()" class="video-tool-tip" [class.tool-tip-show]="isTooltipVisible" [class.tool-tip-hide]="!isTooltipVisible">
      <ng-container *ngIf="element.maxViews > 0">
        <p><tra-md [isCondensed]="true" slug="video_tooltip_max_views" [props]="{MAX_VIEWS: element.maxViews}"></tra-md></p>
      </ng-container>
      <ng-container *ngIf="element.isUnPausable">
        <p><tra slug="video_tooltip_unpausable"></tra></p>
      </ng-container>
      <i style="color: #a9d0ff"><tra slug="video_tooltip_dismiss"></tra></i>
    </div> -->
  </div>
  
  <div *ngIf="isLimitted" style="position:absolute; position:absolute;left:0px; right:0px; top:0px; bottom:0px; display: flex; justify-content: center; align-items: center;">
    <!--<tra-md [isCondensed]="true" slug="lbl_num_views_left" [props]="{NUM_REM_VIEWS: element.maxViews - questionState[element.entryId].numViews}"></tra-md>-->
    <button *ngIf="!isPlaying && canPlay()" (click)="play()" style="font-size:4em;">
      <i class="fas fa-play-circle"></i>
    </button>
    
    <div class="on-mouse-over" >
      <div *ngIf="!element.isAlwaysShowingTime" class="video-info-display">
        <div *ngIf="resetter && this.questionState && this.questionState[element.entryId] && element.maxViews">
          <tra-md  [isCondensed]="true" slug="lbl_num_views_left" [props]="{NUM_REM_VIEWS: element.maxViews - questionState[element.entryId].numViews}"></tra-md>
        </div>
        <div>{{getSecRemainingStr()}}</div>
      </div>
      <div *ngIf="element.maxViews > 0 || element.isUnPausable" #videoTooltip (click)="dismissTooltip()" class="video-tool-tip" [class.tool-tip-show]="isTooltipVisible" [class.tool-tip-hide]="!isTooltipVisible">
        <ng-container *ngIf="element.maxViews > 0">
          <p><tra-md [isCondensed]="true" slug="video_tooltip_max_views" [props]="{MAX_VIEWS: element.maxViews}"></tra-md></p>
        </ng-container>
        <ng-container *ngIf="element.isUnPausable">
          <p><tra slug="video_tooltip_unpausable"></tra></p>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isLimitted && element.isAlwaysShowingTime" class="video-info-display" >
  <div>
    <tra-md *ngIf="resetter && this.questionState && this.questionState[element.entryId] && element.maxViews" [isCondensed]="true" slug="lbl_num_views_left" [props]="{NUM_REM_VIEWS: element.maxViews - questionState[element.entryId].numViews}"></tra-md>
  </div>
  <div>{{getSecRemainingStr()}}</div>
</div>
