<div style="display:flex; justify-content: space-between;">
    <button (click)="openAssetLibrary()" 
            [disabled]="isReadOnly()"
    >
        Asset Library
    </button>
    <span>
        <span *ngIf="element.assetId">
            Asset ID currently in use: <b>{{element.assetId}}</b>
        </span>
    </span>
</div>