<div>
  <div style="overflow:auto;" >
    <table [style.max-width]="getTableMaxWidth()" [style.border-box]="getTableMaxWidth() == 'none' ? 'content-box' : 'border-box'">
      <tr [class.header-row]="this.element.isFirstRowHeader" *ngIf="getToprowValue()">
        <td [class.header-col]="this.element.isFirstColHeader">
          <element-render-text *ngIf="this.element.topLeftText" [element]="element.topLeftText"></element-render-text>
        </td>
        <td *ngFor="let cell of this.element.topRow; let col_i = index;" class="min-col-width selectable-cell" [style.text-align]="cell.alignText ? cell.alignText : 'left'"
          [style.max-width]="this.element.answerColumnWidth && this.element.isAnswerWidthSet ? this.element.answerColumnWidth+'em' : 'none'">
          <div class="option-container-content"
            [style.font-size]="this.element.fontSize+'em'" 
            [style.display]="'flex'" 
            [style.justify-content]="cell.alignItems ? cell.alignItems : 'flex-start'"
            [style.text-align]="cell.alignText ? cell.alignText : 'left'"
            [style.white-space]="'pre-wrap'"
            [style.max-width.em]="this.element.answerColumnWidth ? this.element.answerColumnWidth : 'none'"
          >
            <div *ngIf="!isAudio(cell)">
              <element-render 
                [contentElement]="cell.content"
                [questionPubSub]="questionPubSub"
              ></element-render>
            </div>
            <div *ngIf="isAudio(cell) && cell.audio">
              <element-render-audio [element]="cell.audio" [isLocked]="isLocked" [isShowSolution]="isShowSolution" [questionState]="questionState"></element-render-audio>
            </div>
          </div>
        </td>
      </tr>
      <tr *ngFor="let cell of this.element.leftCol; let row_i = index;" class="min-col-width selectable-cell">
        <td [style.text-align]="cell.alignText ? cell.alignText : 'left'" [class.header-col]="this.element.isFirstColHeader" 
          [style.max-width]="this.element.questionColumnWidth && this.element.isQuestionWidthSet ? this.element.questionColumnWidth+'em' : 'none'">
          <div class="option-container-content"
            [style.font-size]="this.element.fontSize+'em'" 
            [style.display]="'flex'" 
            [style.justify-content]="cell.alignItems ? cell.alignItems : 'flex-start'"
            [style.text-align]="cell.alignText ? cell.alignText : 'left'"
            [style.white-space]="'pre-wrap'"
            [style.max-width.em]="this.element.questionColumnWidth ? this.element.questionColumnWidth : 'none'"
          >
            <div *ngIf="!isAudio(cell)">
              <element-render 
                [contentElement]="cell.content"
                [questionPubSub]="questionPubSub"
              ></element-render>  
            </div>
            <div *ngIf="isAudio(cell) && cell.audio">
              <element-render-audio [element]="cell.audio" [isLocked]="isLocked" [isShowSolution]="isShowSolution" [questionState]="questionState"></element-render-audio>
            </div>
          </div>
      </td>
      <td 
        *ngFor="let c of this.element.topRow; let col_i = index;" 
        class="clickable-cell"
        (click)="checkOrUnCheckAnswer(row_i, col_i)"
        (keyup.enter)="checkOrUnCheckAnswer(row_i, col_i)"
      >
        <!--<input type="checkbox" [style.width.em]="5" [style.height.em]="5" [(ngModel)]="this.testTakerAnswers[row_i][col_i].value"/>-->
        <div *ngIf="this.testTakerAnswers[row_i] && this.testTakerAnswers[row_i][col_i]">
          <div *ngIf="this.testTakerAnswers[row_i][col_i].value === true">
            <i *ngIf="!element.isRadio" [ngClass]="['fa', 'fa-check-square', 'fa-'+this.element.checkBoxSize+'x']" tabindex="0"></i>
            <i *ngIf="element.isRadio" [ngClass]="['fas', 'fa-circle', 'fa-'+this.element.checkBoxSize+'x']" tabindex="0"></i>
          </div>
          <div *ngIf="!this.testTakerAnswers[row_i][col_i].value">
            <i *ngIf="!element.isRadio" [ngClass]="['far', 'fa-square', 'fa-'+this.element.checkBoxSize+'x']" tabindex="0"></i>
            <i *ngIf="element.isRadio" [ngClass]="['far', 'fa-circle', 'fa-'+this.element.checkBoxSize+'x']" tabindex="0"></i>
          </div>
        </div>
        <!--<img *ngIf="this.testTakerAnswers[row_i][col_i].value" src="../../../assets/ens/checkmark.jpg" [style.width.%]="100" [style.height]="'auto'" />
        <img *ngIf="!this.testTakerAnswers[row_i][col_i].value" src="../../../assets/ens/checkmark.jpg" [style.visibility]="'hidden'" [style.width.%]="100" [style.height]="'auto'" />-->
      </td>
    </tr>
  </table>
</div>
</div>

