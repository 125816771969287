import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConsoleLogger,
  DefaultActiveSpeakerPolicy,
  DefaultDeviceController,
  DefaultMeetingSession,
  DefaultModality,
  LogLevel,
  MeetingSessionConfiguration
} from 'amazon-chime-sdk-js';
import { BehaviorSubject, interval } from 'rxjs';
import { callbackify } from 'util';
import { AuthService } from '../api/auth.service';


export enum CallState {
  INACTIVE = 'INACTIVE',
  GETDEVICES = 'GETDEVICES',
  CONNECTING = 'CONNECTING',
  CONNECTED = 'CONNECTED'
}


@Injectable({
  providedIn: 'root'
})
export class MarkingCallService {

  public meetingCreated = new BehaviorSubject(null);
  public meetingDidEnd = new BehaviorSubject(false);

  logger:ConsoleLogger;
  deviceController:DefaultDeviceController;

  callState: CallState = CallState.INACTIVE;

  audioInputDevices: any;
  audioOutputDevices: any;

  
  activeCallId:any;
  activeMeetingID:string;

  meetingSession:DefaultMeetingSession;

  connectingSound:any;
  connectingInterval: any;
  connectingIntervalSubscription: any;
  joinSound: any;
  leaveSound: any;

  presentAttendeeData: any;

  callTimeNumber:number;
  callTimeString:string = "0:00";
  callTimeInterval: any;

  viewChangeDevices = false;

  me: string;

  constructor(private http: HttpClient, public auth: AuthService) {
    this.logger = new ConsoleLogger('MyLogger', LogLevel.ERROR);
    this.deviceController = new DefaultDeviceController(this.logger);

   this.loadAudio();
  }

  async configureDevicesAndConnect(meetingSession:DefaultMeetingSession) {
    
    this.audioInputDevices = await meetingSession.audioVideo.listAudioInputDevices();
    this.audioOutputDevices = await meetingSession.audioVideo.listAudioOutputDevices();
    console.log(this.audioInputDevices);
    console.log(this.audioInputDevices);

    await this.meetingSession.audioVideo.chooseAudioInputDevice(this.audioInputDevices[0].deviceId);
    await this.meetingSession.audioVideo.chooseAudioOutputDevice(this.audioInputDevices[0].deviceId);
    //this.callState = CallState.GETDEVICES;

    this.connectCall();
  }

  async changeDevices(outputID, inputID){
    await this.meetingSession.audioVideo.chooseAudioInputDevice(outputID);
    await this.meetingSession.audioVideo.chooseAudioOutputDevice(inputID);
  }

  createCall(chatId) {

    this.startConnection(chatId);
    const uid = this.getUid();
    this.http.get('https://ugwp2cdk23.execute-api.ca-central-1.amazonaws.com/production/voice?clientId=' + uid).subscribe((res) => {


      const meetingResponse = res['Meeting'];
      const attendeeResponse = res['Attendee'];
      this.me = attendeeResponse.Attendee.AttendeeId;

      const configuration = new MeetingSessionConfiguration(meetingResponse, attendeeResponse);
      
      // In the usage examples below, you will use this meetingSession object.
      this.meetingSession = new DefaultMeetingSession(
        configuration,
        this.logger,
        this.deviceController
      );

      this.activeMeetingID = meetingResponse.Meeting.MeetingId;
      this.meetingCreated.next(true)
      this.configureDevicesAndConnect(this.meetingSession);

      this.listenForAttendees();
      
    });
  }

  joinCall(chatId, meetingId) {
    this.startConnection(chatId);

    const uid = this.getUid();
    this.http.get('https://ugwp2cdk23.execute-api.ca-central-1.amazonaws.com/production/voice?clientId=' + uid + '&meetingId=' + meetingId).subscribe((res) => {


      const meetingResponse = res['Meeting'];
      const attendeeResponse = res['Attendee'];
      this.me = attendeeResponse.Attendee.AttendeeId;

      const configuration = new MeetingSessionConfiguration(meetingResponse, attendeeResponse);
      
      // In the usage examples below, you will use this meetingSession object.
      this.meetingSession = new DefaultMeetingSession(
        configuration,
        this.logger,
        this.deviceController
      );

      this.configureDevicesAndConnect(this.meetingSession);
      
      this.listenForAttendees();
      
    });
  }

  startConnection(chatId) {
    console.log("Trying to connect call!");
    this.callState = CallState.CONNECTING;
    this.activeCallId = chatId;
 
    this.connectingInterval = interval(2000);
    this.connectingIntervalSubscription = this.connectingInterval.subscribe(() => {
      this.connectingSound.play();
    });
  }

  finishConnection() {
    this.callState = CallState.CONNECTED;
    this.connectingIntervalSubscription.unsubscribe();
    this.connectingSound.pause();
    this.connectingSound.currentTime = 0;
    this.joinSound.play();
    this.startCallTime();
  }

  endCall() {
    if(this.meetingSession){
      this.meetingSession.audioVideo.stop();
      return;
    }

    this.callState = CallState.INACTIVE;
    this.connectingIntervalSubscription.unsubscribe();
    this.connectingSound.pause();
    this.connectingSound.currentTime = 0;
    this.leaveSound.play();
    this.stopCallTime();
    this.meetingDidEnd.next(true);

    
  }

  async connectCall() {
    const audioElement = <HTMLAudioElement>document.getElementById('CallAudio');
    this.meetingSession.audioVideo.bindAudioElement(audioElement);
    
    const observer = {
      audioVideoDidStart: () => {
        console.log('Started');
      },
      audioVideoDidStop: sessionStatus => {
        // See the "Stopping a session" section for details.
        console.log('Stopped with a session status code: ', sessionStatus.statusCode());
        this.callState = CallState.INACTIVE;
        this.connectingIntervalSubscription.unsubscribe();
        this.connectingSound.pause();
        this.connectingSound.currentTime = 0;
        this.leaveSound.play();
        this.stopCallTime();
        this.meetingDidEnd.next(true);
      },
      audioVideoDidStartConnecting: reconnecting => {
        if (reconnecting) {
          // e.g. the WiFi connection is dropped.
          console.log('Attempting to reconnect');
          this.callState = CallState.CONNECTING;

        }
      }
    };
    
    this.meetingSession.audioVideo.addObserver(observer);
    
    this.meetingSession.audioVideo.start();


  }



  private getUid() {
    let uid = -1;
    let user = this.auth.user().value;
    if (!user) {
      user = this.auth.user().getValue();
    }
    if (user) {
      uid = user.uid;
    } else {
      console.error('Failed to get UID');
    }
    return uid;
  }

  loadAudio() {
    this.connectingSound = new Audio();
    this.joinSound = new Audio();
    this.leaveSound = new Audio();

    this.connectingSound.src = "../../assets/sounds/connecting.mp3";
    this.joinSound.src = "../../assets/sounds/join.mp3";
    this.leaveSound.src = "../../assets/sounds/leave.mp3";

    this.connectingSound.load();
    this.joinSound.load();
    this.leaveSound.load();
  }


  listenForAttendees() {

    this.presentAttendeeData = {};
    
    this.meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(
      (presentAttendeeId, present) => {
        if (!present) {
          delete this.presentAttendeeData[presentAttendeeId];
          if(Object.keys(this.presentAttendeeData).length >= 2) {
            if(this.callState == CallState.CONNECTING) {
              this.finishConnection();
            }
          }
          else {
            if(this.callState == CallState.CONNECTED) {
              this.endCall();
            }
          }
          return;
        }
    
        this.meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(
          presentAttendeeId,
          (attendeeId, volume, muted, signalStrength) => {
            const baseAttendeeId = new DefaultModality(attendeeId).base();
            if (baseAttendeeId !== attendeeId) {
              // Optional: Do not include the content attendee (attendee-id#content) in the roster.
              // See the "Screen and content share" section for details.
              return;
            }
    
            if (this.presentAttendeeData.hasOwnProperty(attendeeId)) {
              // A null value for any field means that it has not changed.
              this.presentAttendeeData[attendeeId].volume = volume; // a fraction between 0 and 1
              this.presentAttendeeData[attendeeId].muted = muted; // A booolean
              this.presentAttendeeData[attendeeId].signalStrength = signalStrength; // 0 (no signal), 0.5 (weak), 1 (strong)
            } else {
              // Add an attendee.
              // Optional: You can fetch more data, such as attendee name,
              // from your server application and set them here.
              this.presentAttendeeData[attendeeId] = {
                attendeeId,
                volume,
                muted,
                signalStrength
              };
            }

            if(Object.keys(this.presentAttendeeData).length >= 2) {
              if(this.callState == CallState.CONNECTING) {
                this.finishConnection();
              }
            }
            else {
              if(this.callState == CallState.CONNECTED) {
                this.endCall();
              }
            }
            
          }
        );


        if(Object.keys(this.presentAttendeeData).length >= 2) {
          if(this.callState == CallState.CONNECTING) {
            this.finishConnection();
          }
        }
        else {
          if(this.callState == CallState.CONNECTED) {
            this.endCall();
          }
        }
      }
    );
    
  }

  toggleMute() {
    if(this.isMuted()) {
      this.meetingSession.audioVideo.realtimeUnmuteLocalAudio();
    }
    else {
      this.meetingSession.audioVideo.realtimeMuteLocalAudio();
    }
  }

  isMuted() {
    if(!this.meetingSession) return false;
    return this.meetingSession.audioVideo.realtimeIsLocalAudioMuted();
  }

  startCallTime() {
    this.callTimeNumber = 0;
    this.callTimeInterval = setInterval(() => {
      this.callTimeNumber++;
      this.callTimeString=this.transformCallTime(this.callTimeNumber)
    }, 1000);
  }

  transformCallTime(value: number): string {
       const minutes: number = Math.floor(value / 60);
       let seconds = (value - minutes * 60).toString();
       if(seconds.length < 2) {
        seconds = '0' + seconds;
       }
       return minutes + ':' + seconds;
  }

  stopCallTime() {
    this.callTimeNumber = 0;
    clearInterval(this.callTimeInterval);
  }

}

