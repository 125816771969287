import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ChatRowType, INewChat, INewMessage, MarkingChatService} from '../../marking/marking-chat.service';
import * as moment from 'moment-timezone';
import {FormControl, FormGroup} from '@angular/forms';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {AuthService} from '../../api/auth.service';
import { MarkingCallService } from 'src/app/marking/marking-call.service';
import { CallState } from 'src/app/marking/marking-call.service';
import { WhitelabelService } from '../../domain/whitelabel.service';
import {EMOJI_SHORTLIST} from './data/emojis'
@Component({
  selector: 'chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss']
})
export class ChatBoxComponent implements OnInit, AfterViewInit {
  SECTION_SOFT_MAX = 5;
  MAX_RECENT = 5;
  MY_UID = 1;

  @Input() accountType: string; // 'leader' or 'coordinator'

  ChatRowType = ChatRowType;

  CallState = CallState;

  deviceForm: FormGroup;

  JSON = JSON;
  Object = Object;

  outputDeviceValid: boolean = true;
  inputDeviceValid: boolean = true;

  constructor(
      public chat: MarkingChatService,
      public auth: AuthService,
      public callService: MarkingCallService,
      public whitelabelService: WhitelabelService,
  ) { 

    callService.meetingDidEnd.subscribe(val => {
      if(val) {
        this.scrollToBottom();
      }
    });
  }

  @ViewChild('textInput') textArea: CdkTextareaAutosize;
  @ViewChild('chatHistory') chatDiv: ElementRef;
  
  public isLoaded = true; // false;
  public isOpen = false;
  public isEmojiPickerShowing = false;
 // public openChat: IChat;
  public openChat: INewChat;

  public showMoreGroups = false;
  public showMorePeople = false;
  
  public emojiShortlist = EMOJI_SHORTLIST

  searchField = new FormControl();
  searchResults: INewChat[];
  chatField = new FormControl();

  ngOnInit(): void {
    this.deviceForm = new FormGroup({
      audioOutputDevices: new FormControl('default'),
      audioOutputDevicesOther: new FormControl(),
      audioInputDevices: new FormControl('default'),
      audioInputDevicesOther: new FormControl()
   });
  }
  ngAfterViewInit() {
    this.chat.elem = this.chatDiv;
  }

  getVisibleGroups() {
    if (this.showMoreGroups) {
      return this.chat.allChatData.groups.rows;
    } else {
      return this.chat.allChatData.groups.rows.slice(0, this.SECTION_SOFT_MAX);
    }

  }
  getVisiblePeople() {
    if (this.showMorePeople) {
      return this.chat.allChatData.people.rows;
    } else {
      return this.chat.allChatData.people.rows.slice(0, this.SECTION_SOFT_MAX);
    }
  }

  getChatLabel(){
    return this.whitelabelService.getSiteText('marking_chat') || 'Chat';
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }
  selectRow(row: INewChat) {
    this.openChat = row;
    this.chat.openChatId = row.id;
    this.chat.clearUnread(row);
    this.scrollToBottom();
  }
  closeRow() {
    this.openChat = null;
    this.chat.openChatId = null;
  }

  searchChange() {
    let query: string = this.searchField.value;
    if (!query) {
      this.searchResults = null;
      return;
    }
    let searchQuery = query.toLowerCase();

    const groups = this.chat.getGroups();
    const people = this.chat.getPeople();

    let searchResults: INewChat[] = [];
    groups.rows.forEach((row: INewChat) => {
      if (row.title.toLowerCase().includes(searchQuery)) {
        searchResults.push(row);
      }
    });
    people.rows.forEach((row: INewChat) => {
      if (row.title.toLowerCase().includes(searchQuery)) {
        searchResults.push(row);
      }
    });

    this.searchResults = searchResults;

  }

  textAreaKeyUp(e) {
    const isShift = e.shiftKey;
    const key = e.code;
    const isEnter = key === 'Enter';
    if (!isShift && isEnter) {
      e.preventDefault();
      this.sendMessage();
    }
  }

  sendMeetingMessage(msg) {
    const user = this.auth.user().value;
    let uid = this.MY_UID;
    let name = '';
    if (user) {
      uid = user.uid;
      const first_name = user.firstName || '';
      const last_name = user.lastName || '';
      name = first_name + ' ' + last_name;
    }

    const isBroadcast = this.openChat.chatType === ChatRowType.BROADCAST;
    const message: INewMessage = {
      isBroadcast,
      message: msg,
      senderName: name,
      senderUid: uid,
      timestamp: new Date().toString(),
      isUnread: false,
    };

    if (this.isEmojiPickerShowing) {
      this.toggleEmojis();
    }

    this.insertMessage(message);
    this.chat.sendMessage(this.openChat.id, message);
    this.chatField.setValue('');

    this.textArea.reset();
  }

  sendMessage() {
    let msg: string = this.chatField.value.trim();
    if (!msg || msg === '\n' || msg == '') {
      return;
    }

    const user = this.auth.user().value;
    let uid = this.MY_UID;
    let name = '';
    if (user) {
      uid = user.uid;
      const first_name = user.firstName || '';
      const last_name = user.lastName || '';
      name = first_name + ' ' + last_name;
    }

    const isBroadcast = this.openChat.chatType === ChatRowType.BROADCAST;
    const message: INewMessage = {
      isBroadcast,
      message: msg,
      senderName: name,
      senderUid: uid,
      timestamp: new Date().toString(),
      isUnread: false,
    };

    if (this.isEmojiPickerShowing) {
      this.toggleEmojis();
    }

    this.insertMessage(message);
    this.chat.sendMessage(this.openChat.id, message);
    this.chatField.setValue('');

    this.textArea.reset();
  }

  insertMessage(message: INewMessage) {
    this.openChat.chat.push(message);
    this.chat.updateRecent(this.openChat);
    this.scrollToBottom();
  }

  toggleEmojis() {
    this.isEmojiPickerShowing = !this.isEmojiPickerShowing;
    if (!this.isEmojiPickerShowing) {
      this.scrollToBottom();
    }
  }

  scrollToBottom() {
    let self = this;
    setTimeout(function() {
      if (self.chatDiv) {
        self.chat.elem = self.chatDiv;
        self.chatDiv.nativeElement.scrollTop = self.chatDiv.nativeElement.scrollHeight;
      } else {
        console.error('chatDiv is undefined (component)');
      }
    }, 10);
  }

  emojiHandler(emoji) {
    const raw = emoji.native || '';
    const currentTextValue = this.chatField.value || '';

    this.chatField.setValue(currentTextValue + raw);
  }

  inputHandler() {
    const val: string = this.chatField.value || '';
    if (!val) {
      this.textArea.reset();
    }
  }

  changeHandler() {
    this.textArea.reset(); // resizeToFitContent(true);
  }

  maxRows() {
    if (this.isEmojiPickerShowing) {
      return 1;
    } else {
      return 5;
    }
  }

  toggleShowMorePeople() {
    this.showMorePeople = !this.showMorePeople;
  }
  toggleShowMoreGroups() {
    this.showMoreGroups = !this.showMoreGroups;
  }

  createCall() {
    console.log(this.callService.callState);
    this.callService.createCall(this.openChat.id);
    this.callService.meetingCreated.subscribe((value: boolean) => {
      if(value) {
        let msg = {meetingId: this.callService.activeMeetingID};
        this.sendMeetingMessage(JSON.stringify(msg));
      }
      
    });

    
  }

  joinCall(meetingId) {
    this.callService.joinCall(this.openChat.id, meetingId);
  }

  toggleViewChangeDevices() {
    this.callService.viewChangeDevices = true;
  }

  submitDeviceForm(val) {
    console.log(val);

    this.outputDeviceValid = (val.audioOutputDevices == "default" || val.audioOutputDevicesOther != null);
    this.inputDeviceValid = (val.audioInputDevices == "default" || val.audioInputDevicesOther != null);

    if(this.outputDeviceValid && this.inputDeviceValid){
      let outputID;
      if(val.audioOutputDevices == "default") {
        outputID = val.audioOutputDevices;
      }
      else {
        outputID = val.audioOutputDevicesOther;
      }

      let inputID;
      if(val.audioInputDevices == "default") {
        inputID = val.audioOutputDevices;
      }
      else {
        inputID = val.audioInputDevicesOther;
      }
      this.callService.changeDevices(outputID, inputID);
      this.callService.viewChangeDevices = false;
    }
  }

  pickEmoji(emoji) {
    this.isEmojiPickerShowing = false;
    const raw = emoji || '';
    const currentTextValue = this.chatField.value || '';

    this.chatField.setValue(currentTextValue + raw);
  }
}
