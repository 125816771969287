import * as _ from 'lodash';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { IContentElementImage } from '../../ui-testrunner/models/index';
import { Observable, Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser'
import { FormControl } from '@angular/forms';
import { AuthService } from '../../api/auth.service';
import { memo } from '../../ui-testrunner/element-render-video/element-render-video.component';
import { EditingDisabledService } from '../editing-disabled.service';
import { AssetLibraryService } from '../services/asset-library.service';
import { AssetField } from '../asset-library/asset-library.component';
import { AltTextService } from '../alt-text.service';

@Component({
  selector: 'capture-image',
  templateUrl: './capture-image.component.html',
  styleUrls: ['./capture-image.component.scss']
})
export class CaptureImageComponent implements OnInit, OnDestroy {

  @Input() element:IContentElementImage;
  @Input() urlProp:string = 'url';
  @Input() fileType:string = 'image';
  @Input() isNoScale:boolean;
  @Input() isCondensed:boolean;
  @Input() allowReupload:boolean;
  @Input() uploadCaption:string = 'Select a File to Upload';
  @Output() onClear = new EventEmitter();

  percentage: Observable<number>;
  snapshot: Observable<any>;
  isHovering: boolean;
  isRequestingReupload:boolean;
  altText = new FormControl('');
  url = new FormControl('');
  isUploadsDisabled:boolean = false;

  private altTextSub:Subscription;
  private userUploadSub:Subscription;
  private storageSub:Subscription;
  private urlSub:Subscription;

  constructor(
    // private storage: AngularFireStorage,
    // private db: AngularFirestore,
    private auth: AuthService,
    private sanitizer: DomSanitizer,
    private editingDisabled: EditingDisabledService,
    private assetLibraryService: AssetLibraryService,
    private altTextService: AltTextService
  ) {}
  
  ngOnInit() {
    // this.editingDisabled.registerFormControlsArr([this.element.scale, this.altText, this.url]);
    if (!this.element.scale){
      this.element.scale = 100;
    }
    this.updateAltText();
    this.altTextService.registerFormControl(this.element.entryId, this.altText);
    this.altTextSub = this.altText.valueChanges.subscribe( e => {
      this.element.altText = this.altText.value
    })
    if (this.getUrl()){ this.url.setValue(this.getUrl()); }
    this.urlSub = this.url.valueChanges.subscribe( e => {
      this.element[this.urlProp] = this.url.value
    })
  }

  updateAltText() {
    if (this.element.altText){ this.altText.setValue(this.element.altText); }
  }

  ngOnDestroy(){
    if (this.altTextSub){ this.altTextSub.unsubscribe(); }
    if (this.userUploadSub){ this.userUploadSub.unsubscribe(); }
    if (this.storageSub){ this.storageSub.unsubscribe(); }
    if (this.urlSub){ this.urlSub.unsubscribe(); }
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  getUrl(){
    return this.element[this.urlProp];
  }
  clearUrl(){
    if (confirm('Are you sure you want to clear this upload?')){
      this.element[this.urlProp] = null;
      this.onClear.emit();
    }
  }

  promptImgUrl(){
    const url = prompt('Paste image link here (only images from within the authoring system can be used)');
    if (url){
      if (url.indexOf('https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/') === -1){
        alert('Invalid image link')
        return 
      }
      this.element[this.urlProp] = url;
    }
  }

  imageUrls = new Map();
  getImageUrl(){
    return this.getSanitizedUrl(this.getUrl());
  }
  getFileType(){
    const url = this.getUrl();
    if (url){
      const fileExt = url.split('.').pop();
      return fileExt.toUpperCase();
    }
  }

  getSanitizedUrl(url){
    return memo(this.imageUrls, url, url => {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }

  uploadedMeta = new Map();
  getUploadedMeta(){
    return memo(this.uploadedMeta, this.getUrl(), (url) => {
      if (url){
        const path = url.split('/');
        const filename = path.pop().split('?')[0];
        const ext = filename.split('.').pop();
        return {filename, ext}
      }
    })
  }
  
  isWaitingForUpload(){
    return this.isRequestingReupload || !this.getUrl();
  }

  startUpload(event: FileList) {
    let allowedFileTypes = this.fileType.split(',');
    if (this.fileType === 'audio'){
      allowedFileTypes = ['mp3', 'wav']
    }
    // alert('Image uploads are currently disabled.')
    // The File object
    const file = event.item(0);
    // Client-side validation example
    const fileTypeFull = file.type;
    const fileType = fileTypeFull.split('/')[0];
    const fileExt = file.name.split('.').pop();
    //had to add filetype override for vtt because it was not being recognized
    console.log(fileExt, allowedFileTypes)
    if (this.fileType !== 'any'){
      if (!(_.includes(allowedFileTypes, fileType) || _.includes(allowedFileTypes, fileExt) || (allowedFileTypes.indexOf('Graphics') > -1 && file.type === 'image/svg+xml'))) {  
        alert('Unsupported File Type ' + fileType);
        return;
      }
    }
    this.auth
    .uploadFile(file, file.name, 'authoring', true)
    .then(res => {
      this.element.fileType = fileTypeFull;
      this.element[this.urlProp] = res.url;
      this.isRequestingReupload = false;
      console.log('this.getUrl()', this.getUrl())
      if(this.element.assetId){
        delete(this.element.assetId)
        delete(this.element.assetVersionId)
      }
    })
  }

  // Determines if the upload task is active
  isActive(snapshot) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }

  editAltText() {
    //Open up the asset library and edit it directly
    this.assetLibraryService.update(this.element, true, AssetField.ALT_TEXT);
  }

  clearImg() {
    this.element.url = "";
  }
}
