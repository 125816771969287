import { Injectable } from '@angular/core';
import { IContentElement } from '../../ui-testrunner/models/index';
import { Subject } from 'rxjs';


export interface IAssetLibraryConfig {
  element: IContentElement,
  initEnabled: boolean,
  focusedField?: string
}

@Injectable({
  providedIn: 'root'
})
export class AssetLibraryService {

  public observer:Subject<any> = new Subject();

  constructor() {

  }

  update(contentElement:IContentElement, initEnabled: boolean, focusedField?: string){    
    const config:IAssetLibraryConfig = {
      element:contentElement,
      initEnabled,
      focusedField
    }
    this.observer.next(config);
  }
}
