import { Component, OnInit, Input, DoCheck } from '@angular/core';
import { IContentElementImage } from '../models';
import {BrowserModule, DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'
import { PrintAltTextService } from 'src/app/ui-item-maker/print-alt-text.service';
import { defaultImageScaleFactor } from '../models/index'

@Component({
  selector: 'render-image',
  templateUrl: './render-image.component.html',
  styleUrls: ['./render-image.component.scss']
})
export class RenderImageComponent implements OnInit {

  @Input() element:IContentElementImage;
  @Input() scaleWidth:number = 1;
  @Input() scaleHeight:number = 1;

  urlRaw:string;
  url:SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
    private printAltText: PrintAltTextService
  ) { }

    scaleFactor;

  ngOnInit() {
    this.updateUrl();
  }

  getScale(){
    let scaleFactor = defaultImageScaleFactor; //this.element.scaleFactor || defaultImageScaleFactor
    return (scaleFactor/100)*(this.element.scale)
  }

  updateUrl(){
    if (this.urlRaw !== this.element.url){
      this.urlRaw = this.element.url;
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlRaw);
    }
  }

  getImageUrl(){
    this.updateUrl();
    return this.url;
  }

  showAltText() {
    return this.printAltText.getAltTextVisible();
  }

}
