import { Component, OnInit, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { IContentElementMcq, IContentElementMcqOption, QuestionState, IEntryStateMcq, McqDisplay, IContentElementText, IScoredResponse, getElementWeight, ScoringTypes, ElementType } from '../models';
import { indexOf } from '../services/util';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { TextToSpeechService } from '../text-to-speech.service';
import { AuthScopeSetting } from '../../ui-item-maker/auth-scope-settings.service';
import { getRandomInt } from '../../marking/data/data';
import { IMultiSelect } from '../../ui-schooladmin/data/types';
import { StyleprofileService } from '../../core/styleprofile.service';
import { LangService } from '../../core/lang.service';
import { McqRenderer } from './element-render-mcq-functions';
import { LoginGuardService } from '../../api/login-guard.service';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { DrawingLogService } from '../drawing-log.service';



const SCORING_TYPE = ScoringTypes.AUTO;

@Component({
  selector: 'element-render-mcq',
  templateUrl: './element-render-mcq.component.html',
  styleUrls: ['./element-render-mcq.component.scss']
})
export class ElementRenderMcqComponent implements OnInit, OnChanges, OnDestroy {

  @Input() element:IContentElementMcq;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() questionPubSub?: QuestionPubSub;

  mcqRenderer: McqRenderer;
  lastTrackedQuestionState;
  alreadyScrambled:boolean;
  buttonLabels = 'ABCDEFGHIJKLMNOP'.split('');
  dropdownSelector = new FormControl();
  
  // minHeight = 3;
  constructor(
    private textToSpeech:TextToSpeechService,
    private profile: StyleprofileService,
    private lang: LangService,
    private loginGuard: LoginGuardService,
    private bufferedLog: DrawingLogService,
  ) {
    
  }

  ngOnInit() {
    this.ensureMcqRenderer();
    this.mcqRenderer.ensureState();
    this.initScrambledOrder()
    this.initDropDownSelector(); 
  }

  initScrambledOrder(){
    const entryState = this.mcqRenderer.ensureState();
    this.alreadyScrambled = entryState.alreadyScrambled;
    // if(this.questionState && !entryState.alreadyScrambled && this.element.isScrambled) {
      // this.mcqRenderer.scrambleOrder();
    // }
  }

  ensureMcqRenderer(){
    if (!this.mcqRenderer){
      this.mcqRenderer = new McqRenderer(
        this.element,
        this.questionState,
        this.getMcqContent,
        this.isShowSolution,
        this.isLocked,
        this.textToSpeech,
        this.profile,
        this.loginGuard,
        this.lang,
        this.dropdownSelector,
      );
    }
  }

  ngOnDestroy(): void {
    // this was creating an issue for dropdowns, so I suggest just saving the state immediately after creating the scramble rather than trying to catch on navigation away
    // if (!this.isDisplayStyleDropdown()){
    //   this.mcqRenderer.updateState(); //Make sure we're saving the alreadyScrambled value on changing of questions
    // } 
  }
  
  ngOnChanges(changes:SimpleChanges){
    this.ensureMcqRenderer();
    this.mcqRenderer.checkForChanges(changes)
    this.updateDropdownEnabled();
  }

  initDropDownSelector(){
    const entryState = this.mcqRenderer.ensureState();
    if (this.isDisplayStyleDropdown()) {
      if (entryState.selections.length > 0){
        const selection = entryState.selections[0]
        this.dropdownSelector.setValue(selection.i);
      }
      // else if (this.element.defaultDropdownText && this.element.defaultDropdownText.length>0) {
      //   this.dropdownSelector.setValue(0);
      // }
      else{
        this.dropdownSelector.setValue(undefined)
      }
    } 
    this.dropdownSelector.valueChanges.subscribe(change => this.updateDropdownState() );
    this.updateDropdownEnabled();
  }
  
  getOptionsContainerStyle(){
    let style:any = {};
    if (this.isDisplayStyleGrid()){
      if (this.element.gridNumColumns){
        style['width.em'] = (this.element.gridCellWidth+1+(this.element.marginRight || 0)) * this.element.gridNumColumns;
        style['justify-content'] = 'center';
      }
    }
    if (this.isDisplayStyleFreeform()){
      style['width.em'] = this.element.width;
      style['height.em'] = this.element.height;
    }
    return style;
  }

  isDisplayStyleLikert(){         return this.element.displayStyle === McqDisplay.LIKERT; }
  isDisplayStyleVertical(){       return this.element.displayStyle === McqDisplay.VERTICAL; }
  isDisplayStyleHorizontal(){     return this.element.displayStyle === McqDisplay.HORIZONTAL }
  isDisplayStyleWrapAround(){     return this.element.displayStyle === McqDisplay.WRAP }
  isDisplayStyleGrid(){           return this.element.displayStyle === McqDisplay.GRID; }
  isDisplayStyleDropdown(){       return this.element.displayStyle === McqDisplay.DROPDOWN; }
  isDisplayStyleBubble(){         return this.element.displayStyle === McqDisplay.BUBBLE; }
  isDisplayStyleFreeform(){       return this.element.displayStyle === McqDisplay.FREEFORM; }
  isColoredLikert(){   return this.element.isColoredLikert; }

  getMcqContent = (selection) =>{
    let content;
    if (selection.elementType === 'image'){
      content = selection.url;
    }
    else{
      content = selection.content;
    }
    return content
  }

  getButtonContainerStyle(optionElement){
    let style:any = {};
    if (this.isDisplayStyleFreeform()){
      style['left.em'] = optionElement.x;
      style['top.em'] = optionElement.y;
    }
    return style;
  }
  getButtonOptionStyle(optionElement){
    let style:any = {
      'margin-right.em': this.element.marginRight || 0,
      'margin-bottom.em': this.element.marginBottom || 0,
    };
    if (this.isDisplayStyleGrid()){
      style['width.em'] = this.element.gridCellWidth || 10;
    }
    if (this.element.optionHeightSet){
      if (this.element.optionHeight){
        style['min-height.em'] = this.element.optionHeight
        style['height.em'] = this.element.optionHeight
      } 
    }
    if (this.isDisplayStyleFreeform()){
      style['width.em'] = optionElement.width || this.element.gridCellWidth;
      style['height.em'] = optionElement.height || this.element.optionHeight;
    }
    const basePadding = 0.5;
    if (this.element.isPolaroidStyle){
      style['padding.em'] = 0;
    }
    else if (this.element.isRadioDisabled && this.element.isOptionLabelsDisabled) {
      style['padding-left.em'] = 0.5;
      style['padding-right.em'] = 0.5;
      // style['padding-right.em'] = basePadding - 3.5;
    } 
    else {
      style['padding-left.em'] = 2.75+basePadding;
      if (this.element.isCanLink == undefined || this.element.isCanLink == false) style['padding-right.em'] = basePadding;
    }
    return style;
  }



  isAdvTextEl(el:IContentElementText){
    if (el.paragraphStyle){
      return true;
    }
    return false;
  }

  updateDropdownEnabled(){
    if (this.mcqRenderer.isInputDisabled()){
      this.dropdownSelector.disable()
    }
    else{
      this.dropdownSelector.enable()
    }
  }
  updateDropdownState(){
    if (!this.isDisplayStyleDropdown()){
      return;
    }
    let isCorrect = false;
    let isFilled = false;
    let i = this.dropdownSelector.value;
    let option;
    if (i>=0){
      // if (this.hasDefaultDropDownText()) option = this.element.options[i-2];
      // else option = this.element.options[i]
      option = this.element.options[i]
      if (option){
        isFilled = true
        isCorrect = option.isCorrect;
      }
    }

    const weight = getElementWeight(this.element);
    const entryState:IEntryStateMcq = this.mcqRenderer.ensureState();
    entryState.isCorrect = isCorrect;
    entryState.isFilled = isFilled;
    entryState.isStarted = true;
    if (isFilled){
      entryState.selections = [{
        i: +i, 
        id: option.optionId, 
        elementType: ElementType.TEXT, 
        content: option.content,
      }]
    }
    else{
      entryState.selections = [];
    }
    entryState.score =  isCorrect ? weight : 0 ;
  }

  getDropdownOptionStyle() {
    const style:any = {};
    style['font-weight'] = this.isBold(this.element.defaultDropdownText) ? 'bold' : 'normal'
    style['font-size'] = this.isItalic(this.element.defaultDropdownText) ? 'italic' : 'normal'
    return style;
  }

  showSelectAllMessage() {
    //return this.element.isMultiSelect && this.profile.getStyleProfile()[this.lang.c()].renderStyling.mcq.showSelectAll;
  }

  isCheckbox() {
    if (this.element.isMultiSelect){
      if (this.profile.getStyleProfile()[this.lang.c()].renderStyling.mcq){
        return true;
      }
    }
    return false;
  }


  isBold(option:string) {
    if (!option) return false;
    if ((option.startsWith('**') && option.endsWith('**'))) {
      return true;
    }
    return false;
  }

  hasDefaultDropDownText() {
    const def = this.element.defaultDropdownText;
    if (!def || def==='' || def.trim() === '') return false;
    return true;
  }

  isItalic(option:string) {
    if (!option) return false;
    if ((option.startsWith('*') && option.endsWith('*') && !this.isBold(option)) || (option.startsWith("***") && option.endsWith("***"))) {
      return true;
    }
    return false;
  }

  getDefaultTextDelimiter() {
    let len = 0;
    if (this.element.defaultDropdownText) {
      len = this.element.defaultDropdownText.length;
    }
    this.element.options.forEach((option)=>{
      if (option.content && option.content.length>len) len = option.content.length;
    })
    len *= 0.64;
    if (len == 0) len = 1;
    let str = "";
    for (let i = 0;i<len;i++) str+='─';
    return str;
  }

  stopEventProp($event) {
    $event.stopPropagation();
  }
}
