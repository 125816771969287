import { Type } from "@angular/core";
import { IQPubSubPayload } from "../question-runner/pubsub/question-pubsub";

export interface ICustomTaskSet {
  __id?: string;
  __backup__id?: string;
  __backup__userDisplayName?: string;
  name?: string;
  order?: number;
  taskId?: string; // cross link to collection `tasks`
  project?: string;
  isReady?: boolean;
  isProtected?: boolean;
  labelCounter?: number;
  isArchived?: boolean;
  questions?: IQuestionConfig[] | string;
  creatorUid?: string;
  timeCreated?: any;
  timeLastSaved?: any;
  schemaVersion?: number;
  lastTouchedBy?: string;
  assessmentFrameworkId?: string;
}

export interface ICustomTaskTag {
  __id?: string;
  caption: string;
  customTaskSetId: string;
  localTaskId: number;
  createdBy: string;
  timestamp: any;
}

export interface ICustomTaskComment {
  __id?: string;
  caption: string;
  customTaskSetId: string;
  localTaskId: number;
  createdByName: string;
  createdByUid: string;
  timestamp: any;
}

export interface IQuestionConfig {
  id?: number;
  content: IContentElement[];
  label: string;
  testLabel: string;
  isReady?: boolean;
  readSel?:string;
  caption?:string;
  points?:string;
  readSelections?:string[];
  isInfoSlide?: boolean;
  isNotScored?: boolean;
  notes?: string;
  taskId?: string;
  isReadingSelectionPage?: boolean; // intended to be temporary
  isReqFill?: boolean;
  reqFillEntries?: string;
  reqFillMsg?: string;
  isReadingSelectionsAlwaysShown?: boolean;
  isStartHalf?: boolean;
  localTaskId: number;
  entryIdCounter?: number;
  entryOrder?: number[];
  quadrantFreq?: number;
  estimatedExposure?: number;
  langLink: IQuestionConfig;
  voiceover: {
    url?: string
    script?: string
    fileType?: string
  },
  __changeCounter?:number, // only used for meta changes at first
  meta: any,
  comments?: any[];
}
export interface IContentElement {
  elementType: string | ElementType;
  isShowAdvancedOptions?: boolean;
  entryId?: number;
  _changeCounter?: number;
  assetId?: number;
  assetVersionId?: number;
  colourScheme?: IContentElementColorPanel;
  voiceover?: any;
  canvasScaleWidth?: number;
  canvasScaleHeight?: number;
  _isCollapsed?:boolean;
}



export interface IContentElementMic extends IContentElement {
  setTimer?: boolean;
  isPractice?: boolean;
  time?: number;
  minDuration?: number;
  maxDuration?: number;
  onCountdownEnd?: IQPubSubPayload[];
}

export interface IScoredResponse {
  entryId?: number;
  scoreWeight?: number;
  enableProportionalScoring?: boolean;
  useDistractorAsResponseSlug?: boolean;
  isOptional?: boolean;
}
export interface IContentElementSbs extends IContentElement {
  left: IContentElement[];
  right: IContentElement[];
}

export interface IContentElementSelectionTable extends IContentElement, IScoredResponse {
  topRow: IContentElementSelectionTableCell[];
  leftCol: IContentElementSelectionTableCell[];
  fontSize: number;
  checkBoxRows: IContentElementSelectionTableBool[][];
  isMustHaveOnePerRow: boolean;
  isMustHaveOnePerCol: boolean;
  allowMultipleAnswersPerRow?: boolean;
  allowMultipleAnswersPerColumn?: boolean;
  maxCheckedPerRow?: number;
  maxCheckedPerCol?: number;
  checkBoxSize: number;
  isQuestionWidthSet?:boolean;
  isAnswerWidthSet?:boolean;
  isMaxDenoScoring?:boolean;
  questionColumnWidth?: number;
  answerColumnWidth?: number;
  isFirstRowHeader?: boolean;
  isFirstColHeader?: boolean;
  configColWidth?: number;
  configRowWidth?: number;
  showAudio?: boolean;
  isRadio?: boolean;
  topLeftText?: IContentElementText;
}

/*export interface IContentElementSelectionTableHeader {
  elementType: string;
  content: any;
}*/
export interface IContentElementSelectionTableCell {
  content: any;
  alignText?: string;
  alignItems?: string;
  audio?: IContentElementImage;
}

export interface IContentElementSelectionTableBool {
  value: boolean;
}

export interface IContentElementGraphing extends IContentElement {
  availableTools: {[key: string]: boolean};
}
export interface IContentElementGraphics extends IContentElement {
  url?: string;
}
export interface IContentElementText extends IContentElement {
  caption: string;
  paragraphStyle: TextParagraphStyle;
  simpleList?: string[];
  advancedList: IContentElement[];
  paragraphList?: TextParagraph[];
  paragraphNumberingPos?: NumberingPosition,
  paragraphNumberingMargin?: number,
  paragraphNumberingScale?: number,
  paragraphNumberingStart?: number,
  paragraphSpaceAfter?: number, // em
  link?: IContentElementTextLink;
  isAdvancedOptionsDisabled?: boolean;
  font?: FontFamily;
  alignment?: string;
  rotation?: number;
  paragraphWidth?: number; // used for paragraphs
  fontSize?: number;
  lineHeight?: number;
  isInvisible?: boolean;
  advancedInlineWidth?: number;
  colour?: string;
}

export interface TextParagraph {
  caption?: string,
  paddingRight?: number,
  paddingLeft?: number,
  paragraphSpaceAfter?: number,
  numberingOverride?: string,
}
export interface IContentElementSolution extends IContentElement {
  content: IContentElement[];
  hideFromTestTakers: boolean;
}

export interface IContentElementResultsPrint extends IContentElement {
  content: IContentElement[];
  inputEntryId?: number;
  isPlainText?: boolean;
}

export interface IContentElementTextSelection extends IContentElement {
  texts: IContentElementSelectableText[];
  hasOutline?: boolean;
  isSelectionsLimited?: boolean;
  maxSelections?: number;
}

export interface IContentElementSelectableText extends IContentElementMcqOption {
}


export interface IContentElementColorPanel {
  backgroundColor?: string;
  textColor?: string;
}

export interface IContentElementFrameStateStyle {
  backGroundImg: IContentElementDynamicImage;
  dropShadowX: number;
  dropShadowY: number;
  blurRadius: number;
  shadowColor: string;
  backgroundColor: string;
  padding: number;
}



export interface IContentElementCanvasPage {
  displayList: IContentElementCanvasDisplayElement[];
}


export interface IContentElementCanvas extends IContentElement {
  width: number;
  height: number;
  scale?: number;
  pages: IContentElementCanvasPage[];
  readerId?: string;
  caption?: string;
  showBookmarks?: boolean;
  frame?: IContentElementFrameStateStyle;
  bgImg?: IContentElementDynamicImage;
  isPaginationHidden?: boolean;
  isFrameHidden?: boolean;
}
export interface IContentElementFrame extends IContentElement {
  id?: number;
  content: IContentElement[];
  activatedStyles?:string[],
  styleRaw?:{[key:string]:string | number},
  width?:number,
  height?:number,
  isClickable?:boolean,
  isOff?:boolean,
  toggleTargetId?:number,
  toggleTargetGroupId?:string,
  linkedContent?: { // not yet implemented
    itemId: number,
    frameId: number,
  },
}
export interface IContentElementCanvasDisplayElement extends IContentElement {
  x?: number,
  y?: number,
  width?: number,
  height?: number,
  _isCollapsed?: boolean,
  element?: IContentElement
  maintainAspectRatio?: number;
  isDisabled?:boolean;
}
export interface IContentElementTable extends IContentElement {
  grid: Array<
    Array<
      IContentElementTableCell
    >
  >;
  isHeaderRow: boolean;
  isHeaderCol: boolean;
  isColWidthConst: boolean;
  isTableOfValues?: boolean;
  colWidthConst: number;
}
export interface IContentElementTableCell {
  val: string;
  elementType?: string;
  align?: string;
}

export enum TextParagraphStyle {
  HEADLINE = 'headline',
  HEADLINE_SMALL = 'headline_small',
  REGULAR = 'regular',
  SMALL = 'small',
  BULLET = 'bullet',
  NUMBERED = 'numbered',
  PARAGRAPHS = 'paragraphs',
  ADVANCED_INLINE = 'advanced-inline',
  LINK = 'link',
  ANNOTATION = 'annotation',
}

export enum InputFormat {
  NUMBER = 'number',
  FRACTION = 'fraction',
  ALGEBRA = 'algebra',
  RATIO = 'ratio',
  TEXT = 'text',
  FORM = 'form'
}

export type QuestionState = Map<number, any>;

// <option>Headline</option>
// <option>Regular</option>
// <option>Small</option>
// <option>Bullet List</option>
// <option>Numbered List</option>
// <option>Advanced Inline</option>


export interface IContentElementTextLink extends IContentElement {
  caption: string;
  readerId?: string;
  readerElementId?: string;
  itemLabel?: string;
}


export interface IContentElementMath extends IContentElement {
  latex: string;
  paragraphStyle: string;
}

export enum ImageStates {
  SELECTED = "selected",
  HOVERING = "hovering",
  DEFAULT = "default"
}

export enum NumberingPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  NONE = 'NONE',
}

export enum FontFamily {
  TIMES_NEW_ROMAN = "Times New Roman",
  BIG_CASLON = "Big Caslon",
  BODONI_MT = "Bodoni MT",
  BOOK_ANTIQUA = "Book Antiqua",
  BOOKMAN = "Bookman",
  ARIAL = "Arial",
  HELVETICA = "Helvetica",
  VERDANA = "Verdana",
  CALIBRI = "Calibiri",
  NOTO = "Noto",
  LUCIDA_SANS = "Lucida Sans",
  GILL_SANS = "Gill Sans"
}

export interface IContentElementDynamicImage extends IContentElementImage {
  images?: {[key: string]: IContentElementConditionalImage};
  subtexts?: IContentElementImageSubText[];
}

export interface IContentElementConditionalImage {
  condition: string;
  image?: IContentElementImage;
}

export interface IContentElementDocLink extends IContentElement {
  url?: string;
  fileType?: string;
  caption?: string;
}

export interface IContentElementLocAndDims extends IContentElement {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface IContentElementAnnotatedText extends IContentElementText {
  text: IContentElementText;
  annotation?: string;
  isLeftAligned?: boolean;
  isConstrainedWidth?: boolean;
}

export enum ToolTipPositions {
  TOP = 'TOP',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  BOTTOM = 'BOTTOM',
}

export interface IContentElementAudio extends IContentElement {
  url?: string;
  fileType?: string;
  altText?: string;
  numAudioPlays?: number;
  isLimitAudioPlays?: boolean;
  isAutoPlay?: boolean;
  isHidden?: boolean;
  isPermanentTooltip?: boolean;
  toolTipPosition?: ToolTipPositions;
  autoPlayDelay?: number;
  onAudioEnd?: IQPubSubPayload[];
  labelX?: number;
  labelY?: number;
  buttonScale?: number;
}


export const defaultImageScaleFactor = 20;
export interface IContentElementImage extends IContentElement {
  url?: string;
  fileType?: string;
  altText?: string;
  scale?: number;
  outline?: boolean;
  scaleFactor?: number;
  XPosition?: number;
  YPosition?: number;
  setRelative?: boolean;
  frameState?: IContentElementFrameStateStyle;
}

export interface IContentElementImageSubText {
  text: string;
  x: number;
  y: number;
  size: number;
  lineHeight?: number;
  rotation?: number;
  width: number;
  invisible: any;
  colourScheme?: IContentElementColorPanel;
  fontFamily?: string;
  alignment?: string;
}

export interface IContentElementVideo extends IContentElement {
  url: string;
  fileType?: string;
  altText?: string;
  subtitlesUrl?: string;
  maxViews?: number;
  isUnPausable?: boolean;
  isRestricted?: boolean;
  isAlwaysShowingTime?: boolean;
}
export interface IContentElementIframe extends IContentElement {
  url: string;
}
export interface IContentElementDnd extends IContentElement, IScoredResponse {
  targetType: DndTargetType;
  width?: number;
  height?: number;
  customTargetDim?: boolean;
  defaultTargetStyle: IContentElementDndSub;
  backgroundElements: Array<IContentElementDndBackground>;
  draggables: Array<IContentElementDndDraggable>;
  targets: Array<IContentElementDndTarget>;
  draggableCounter: number;
  targetCounter: number;
  groups: IDndGroup[];
}

export enum GroupingArrangementType {
  MANUAL = "manual",
  SIDE_BY_SIDE = "side",
  NORMAL = "normal"
} 

export interface IContentElementGroup extends IContentElement, IScoredResponse {
  targetType: DndTargetType;
  width?: number;
  height?: number;
  customTargetDim?: boolean;
  targetWidth?: number;
  targetHeight?: number;
  defaultTargetStyle: IContentElementDndSub;
  backgroundElements: Array<IContentElementDndBackground>;
  draggables: Array<IContentElementDndDraggable>;
  targets: Array<IContentElementGroupTarget>;
  draggableCounter: number;
  targetCounter: number;
  groups: IDndGroup[];
  isInstructionsDisabled: boolean;
  isMatchingMode: boolean;
  isVerticalLayout: boolean;
  draggableColourScheme?: IContentElementColorPanel;
  targetColourScheme?: IContentElementColorPanel;
  arrangementType?: GroupingArrangementType;
  groupWidth?: number;
  isTargetsAbove?:boolean;
  isTargetsContentsCentred?:boolean;
  draggableWidth?:number;
  draggableHeight?:number;
}

export interface IDndGroup {
  id: number;
  caption: string;
}
export enum DndTargetType {
  DRAGGABLE = 'DRAGGABLE',
  TARGET = 'TARGET',
  GROUP = 'GROUP',
}
export interface IContentElementDndSub {
  id?: number;
  x: number;
  y: number;
  width?: number;
  height?: number;
  backgroundColor?: string;
  borderColor?: string;
  borderThickness?: string;
  borderRadius?: number;
  padding?: number;
  verticalAlignment?: string;
  horizontalAlignment?: string;
  voiceover?: any;
}
export interface IContentElementDndBackground extends IContentElementDndSub {
  element: IContentElement;
}
export interface IContentElementDndDraggable extends IContentElementDndSub {
  element: IContentElement;
  targetId?: number;
  groupId?: number;
  voiceover?: any;
  containerBackgroundColor?: string;
}
export interface IContentElementDndTarget extends IContentElementDndSub {
  draggableId?: number;
  groupId?: number;
}

export interface IContentElementMoveableDragDrop extends IContentElement, IScoredResponse {
  width?: number;
  height?: number;
  draggables: IContentElementDndDraggable[];
  targets: IContentElementDndTarget[];
  isTargetsInvisible: boolean;
  draggableCounter?: number;
  isDragsTransparent?: number;
  isTargetColourSame?: boolean;
  isAllTargetsToPlace?: boolean;
  isDraggableColourSame?: boolean;
  hasFrame?: boolean;
  moveableImages?: any[];
  targetColour?: string;
  draggableColour?: string;
  backgroundImg?: IContentElementImage;
}

export interface IContentElementGroupTarget extends IContentElementDndSub {
  element?: IContentElement;
  draggableId?: number;
  groupId?: number;
  groups?: any[];
  bgColor?: string;
  backgroundImg?: IContentElementImage;
  voiceover?: any;
}

export enum OrderMode {
  TARGET = 'target',
  REORDER = 'reorder',
}

export interface IContentElementOrder extends IContentElement, IScoredResponse {
  displayStyle: McqDisplay;
  options: Array<IContentElementOrderOption>;
  scrambledOptions?: Array<IContentElementOrderOption>;
  delimeter: string;
  isScrambled: boolean;
  showDefaultText?: boolean;
  delimiter?: any;
  orderMode?: OrderMode;
  delimiterType?: string;
  delimiterImg?: IContentElementImage;
  delimiterIcon?: string;
  targetWidth?: number;
  targetHeight?: number;
  isShowDelimiter?: boolean;
  isShowLabels?: boolean;
  isCenterDraggableContent?: boolean;
  isCenterLabelContent?: boolean;
  idCount?: number;
  isTargetOrientationReversed?: boolean;
}

export interface IContentElementOption {
  voiceover?: any;
}

export interface IContentElementOrderOption extends IContentElementOption {
  optionId: number;
  elementType: string;
  content: any;
  [key:string]:any;
  isReadOnly?: boolean; //this is only for target mode.
  label?: string;
  labelImg?: IContentElementImage;
  labelType?: string;
  x?: number;
  y?: number;
  labelx?: number;
  labely?: number;
}

export interface IContentElementMcq extends IContentElement, IScoredResponse {
  displayStyle: McqDisplay;
  options: Array<IContentElementMcqOption>;
  gridCellWidth?: number;
  isMultiSelect?: boolean;
  isSelectToggle?: boolean;
  isBgClear?: boolean;
  isBgClearAlways?: boolean;
  isPolaroidStyle?: boolean;
  gridNumColumns?: number;
  isContentsVertPaddDisabled?: boolean;
  isContentsCentered?: boolean;
  isContentsCenteredVertically?: boolean;
  isScrambled?: boolean;
  isLimittedWidth?: boolean,
  isCanLink?: boolean,
  isColoredLikert?: boolean,
  isOptionLabelsDisabled?: boolean;
  isRadioDisabled?: boolean;
  selectionBorderColor?: string;
  currentSettings?: string;
  optionHeightSet?: boolean;
  isOffsetSelectionBorder?: boolean;
  isBorderColor?: boolean;
  hasFrame?: boolean;
  minHeight?: number;
  optionHeight?: number;
  defaultDropdownText?: string;
  mcqAreaHeight?: number;
  isNoThickBorder?: number;
  marginRight?: number;
  marginBottom?: number;
  maxOptions?: number;
  width?: number;
  height?: number;
}

export interface IContentElementCustomMCQ extends IContentElementMcq {
  size?: number;
}


export interface IContentElementMcqOption extends IContentElementOption {
  optionId: number;
  isCorrect?: boolean;
  elementType: string;
  content: string;
  url?: string;
  link?: IContentElementTextLink;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
}

export interface IContentElementCustomMcqOption extends IContentElementMcqOption {
  dynamicImage: IContentElementDynamicImage;
  xBubble?: number;
  yBubble?: number;
  subtext?: Array<IContentElementCustomMcqOptionSubText>;
  currentState?: ImageStates;
}

export interface IContentElementCustomMcqOptionSubText {
  text: string;
  x: number;
  y: number;
  size: number;
  colourScheme?: IContentElementColorPanel;
}

export enum McqDisplay {
  VERTICAL = 'vertical',
  GRID = 'grid',
  HORIZONTAL = 'horizontal',
  WRAP = 'wraparound',
  FREEFORM = 'freeform',
  DROPDOWN = 'dropdown',
  LIKERT = 'likert',
  BUBBLE = 'bubble',
}
export interface IRatioTermConfig {
  value: string;
}
export enum NumberTolerance {
  ALTVALUE = 'altvalue',
  DECIMALS = 'decimals',
  MINMAX = 'minmax',
  PROXPERC = 'proxperc', // proximity by percentage
}
export interface INumberTolerance {
  toleranceTypeId: NumberTolerance;
  numDecimals?: number;
  min?: number;
  max?: number;
  proxPerc?: number;
}
export interface IContentElementInput extends IContentElement, IScoredResponse {
  format: InputFormat;
  latex?: string;
  startingLatex?: string;
  tolerances?: INumberTolerance[];
  value?: string;
  prefix?: string;
  suffix?: string;
  minChars?: number;
  maxChars?: number;
  minWords?: number;
  maxWords?: number;
  alternativeValues?: IContentElementInput[];
  ratioTerms?: string[];
  isStrictLowestTerms?: boolean;
  isMixedNumber?: boolean;
  roundingTolerance?: string;
  fracWholeNumber?: string;
  fracNumerator?: string;
  fracDenominator?: string;
  isStrictSimplified?: boolean;
  isAllowEquivalent?: boolean;
  formElements?: IContentElement[];
  defaultText?: string;
  defaultTextColour?: string;
  isToolbarAutoHide?: boolean;
  isFixedHeight?: boolean;
  fixedHeight?: number;
  isAnswerNotNecessary?: boolean;
  isSetHardLimitToZero?: boolean;
  isShowingFrenchKeys?: boolean;
}

export interface IContentElementInsertion extends IContentElement, IScoredResponse {
  textBlocks: IContentElementDndDraggable[];
  draggables: IContentElementDndDraggable[];
  draggableCounter: number;
  textBlocksCounter: number;
  isDragBetweenWords: boolean;
  isShowInstructions?: boolean;
  isTargetsAbove?: boolean
  isRepeatableOptions?: boolean
  blankTargetSize?: boolean;
}

export interface IContentTextBlock {
  value: IContentElementText;
}

export interface IElementTypeDef {
  id: ElementType | string;
  caption: string;
  icon: string;
  isInteractive?: boolean;
  isDisabled?: boolean;
  feedProps?: {[key: string]: any}; // used for the dropdown creators where the block type is overloaded and the config is what differentiates it
}


export enum ElementType {
  TEXT = 'text',
  TEXT_LINK = 'text_link',
  MATH = 'math',
  IMAGE = 'image',
  DYNAMIC_IMAGE = 'dynamic_image',
  MCQ = 'mcq',
  DND = 'dnd',
  ORDER = 'order',
  GRAPHING = 'graphing',
  TABLE = 'table',
  INPUT = 'input',
  VIDEO = 'video',
  AUDIO = 'audio',
  MIC = 'mic',
  CANVAS = 'canvas',
  CANVAS_BOOKMARK = 'canvas_bookmark_element',
  CAMERA = 'camera',
  DOC_LINK = 'doc_link',
  UPLOAD = 'upload',
  HOTSPOT = 'hotspot',
  HOTTEXT = 'hottext',
  MATCHING = 'matching',
  READER = 'reader',
  IFRAME = 'iframe',
  FRAME = 'frame',
  SBS = 'sbs',
  SOLUTION = 'solution',
  SELECT_TABLE = 'select_table',
  CUSTOM_MCQ = 'custom_mcq',
  INSERTION = 'insertion',
  GRAPHICS = 'Graphics',
  GROUPING = 'Grouping',
  SELECT_TEXT = 'select_text',
  ANNOTATION = 'annotation',
  MOVEABLE_DND = 'moveable_dnd',
  RESULTS_PRINT = 'results_print'
}

export const ElementTypeDefs = {
  RESULTS_PRINT: {id: ElementType.RESULTS_PRINT, caption: 'Results Print', icon: 'fa-print'},
  SOLUTION:     {id: ElementType.SOLUTION,     caption: 'Solution',   icon: 'fa-columns'},
  ANNOTATION: {id: ElementType.ANNOTATION, caption: 'Annotation', icon: 'fa-link'},
  SELECT_TABLE:  {id: ElementType.SELECT_TABLE, caption: 'Selection Table', icon: 'fa-table'},
  CUSTOM_MCQ: {id: ElementType.CUSTOM_MCQ, caption: 'Custom MCQ', icon: 'fa-bars'},
  MOVEABLE_DND: {id: ElementType.MOVEABLE_DND, caption: 'Drag & Drop', icon: 'fa-hand-pointer'},
  INSERTION: {id: ElementType.INSERTION, caption: 'Insertion', isDisabled: false, icon: 'fas fa-sign-in-alt fa-rotate-90'},
  TEXT:    {id: ElementType.TEXT,    caption: 'Text',  icon: 'fa-font'},
  TEXT_LINK:{id: ElementType.TEXT_LINK,    caption: 'Reader Link',  icon: 'fa-link'},
  FRAME:   {id: ElementType.FRAME,    caption: 'Frame',  icon: 'fa-crop-alt'},
  CANVAS:  {id: ElementType.CANVAS,  caption: 'Canvas',  isDisabled: false, icon: 'fa-sticky-note'},
  CANVAS_BOOKMARK: {id: ElementType.CANVAS_BOOKMARK,  caption: 'Bookmark Target',  isDisabled: true, icon: 'fa-sticky-note'},
  SELECT_TEXT:  {id: ElementType.SELECT_TEXT, caption: 'Text Selection', icon: 'fa-font'},
  TABLE:   {id: ElementType.TABLE,   caption: 'Table', icon: 'fa-table'},
  MATH:    {id: ElementType.MATH,    caption: 'Math',  icon: 'fa-pencil-alt'},
  IMAGE:   {id: ElementType.IMAGE,   caption: 'Image', icon: 'fa-image'},
  VIDEO:   {id: ElementType.VIDEO,   caption: 'Video', icon: 'fa-file-video'},
  AUDIO:   {id: ElementType.AUDIO,   caption: 'Audio', isDisabled: false,  icon: 'fa-file-audio'},
  DOC_LINK:{id: ElementType.DOC_LINK,caption: 'Document', isDisabled: false,  icon: 'fa-file-pdf'},
  INPUT:   {id: ElementType.INPUT,   caption: 'Keyboard Input',   isInteractive: true, isDisabled: false, icon: 'fa-keyboard'},
  MCQ:     {id: ElementType.MCQ,     caption: 'Multiple Choice',  isInteractive: true, icon: 'fa-bars'},
  ORDER:   {id: ElementType.ORDER,   caption: 'Ordering',         isInteractive: true, icon: 'fa-sort'},
  GRAPHING: {id: ElementType.GRAPHING, caption: 'Geometry',         isInteractive: true, isDisabled: false, icon: 'fa-paint-brush'},
  DND:     {id: ElementType.DND,     caption: 'Drag & Drop',      isInteractive: true, isDisabled: true, icon: 'fa-hand-pointer-o'},
  HOTSPOT: {id: ElementType.HOTSPOT, caption: 'Hot Spot',         isInteractive: true, isDisabled: true, icon: 'fa-asterisk'},
  HOTTEXT: {id: ElementType.HOTTEXT, caption: 'Hot Text',         isInteractive: true, isDisabled: true, icon: 'fa-commenting'}, // include features such as: click any word to highlight, include instructions above (which use the highlighting styling), click any word to capitalize it, drag in a comma (into any open space), semi-colon or other punctuation, drag out a punctuation to throw it away.
  MATCHING: {id: ElementType.MATCHING, caption: 'Matching',         isInteractive: true, isDisabled: false,     icon: 'fa-compress'}, // like equation attack ... https://h5p.org/image-pairing
  READER:  {id: ElementType.READER,  caption: 'Reader',           isDisabled: true,    icon: 'fa-bars'}, // like the liseuse in French
  MIC:     {id: ElementType.MIC,     caption: 'Microphone',       isInteractive: true, isDisabled: false, icon: 'fa-microphone'},
  CAMERA:  {id: ElementType.CAMERA,  caption: 'Camera',           isInteractive: true, isDisabled: false, icon: 'fa-camera'},
  UPLOAD:  {id: ElementType.UPLOAD,  caption: 'File Upload',      isInteractive: true, isDisabled: false, icon: 'fa-upload'},
  GRAPHICS: {id: ElementType.GRAPHICS, caption: 'Graphics',         isInteractive: true, isDisabled: true, icon: 'fa-image'},
  GROUPING: { id: ElementType.GROUPING, caption: 'Grouping', isInteractive: true, isDisabled: false, icon: 'fa-object-group' },
  PAINTING: {id: ElementType.TEXT,    caption: 'Painting',  icon: 'fa-font'},
  SCULPTING: {id: ElementType.TEXT,    caption: 'Sculpting',  icon: 'fa-font'},
 };

export const elementTypes: IElementTypeDef[] = [
  ElementTypeDefs.TEXT,
  ElementTypeDefs.CANVAS,
  ElementTypeDefs.FRAME,
  ElementTypeDefs.TABLE,
  ElementTypeDefs.MATH,
  ElementTypeDefs.INSERTION,
  ElementTypeDefs.IMAGE,
  ElementTypeDefs.GRAPHICS,
  ElementTypeDefs.MOVEABLE_DND,
  ElementTypeDefs.VIDEO,
  ElementTypeDefs.AUDIO,
  ElementTypeDefs.DOC_LINK,
  ElementTypeDefs.INPUT,
  ElementTypeDefs.MCQ,
  ElementTypeDefs.ORDER,
  ElementTypeDefs.GRAPHING,
  ElementTypeDefs.SELECT_TEXT,
  // ElementTypeDefs.DND,
  // ElementTypeDefs.MATCHING,
  ElementTypeDefs.GROUPING,
  // ElementTypeDefs.HOTSPOT,
  // ElementTypeDefs.HOTTEXT,
  ElementTypeDefs.SELECT_TABLE,
  ElementTypeDefs.CUSTOM_MCQ,
  ElementTypeDefs.MIC,
  ElementTypeDefs.CAMERA,
  ElementTypeDefs.UPLOAD,
  // ElementTypeDefs.READER,
  ElementTypeDefs.SOLUTION,
  ElementTypeDefs.RESULTS_PRINT
];

export enum Direction {
  UP="up",
  DOWN="down",
  LEFT="left",
  RIGHT="right",
}

export interface IEntryState {
  type: string;
  isCustomGrading?: boolean;
  isCorrect?: boolean;
  isStarted: boolean;
  isFilled: boolean;

}

export const getElementWeight = (element: IScoredResponse) => {
  const weight = element.scoreWeight;
  if (weight === 0) { return weight; }
  if (!weight) { return 1; }
  return +weight;
};

export const shuffle = (arr:any[]) => {
  for (let i = 0;i<arr.length;i++) {
    const j = Math.floor(Math.random()*(i+1));
    const temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
  }
  return arr;
}

export enum ScoringTypes {
  AUTO= 'AUTO',
  REVIEW= 'REVIEW',
  MANUAL= 'MANUAL'
}
export interface IEntryStateScored extends IEntryState {
  score: number;
  weight: number;
  scoring_type: ScoringTypes;
}

export interface IEntryStateMcq extends IEntryStateScored {
  selections: Array<{
    i: number,
    id: number,
    elementType: string,
    content: any
  }>;

  isPathFollowed?:boolean,
  alreadyScrambled?: boolean;
}

export interface IEntryStateOrder extends IEntryStateScored {
  answers: IContentElementOrderOption[][];
  options: IContentElementOrderOption[];
}

export interface IEntryStateSelectionTable extends IEntryStateScored {
  checkMarks: {value: boolean}[][];
}


export interface IEntryStateGroup extends IEntryStateScored {
  draggables: any[];
  targets: any[];
}

export interface IEntryStateVideo extends IEntryState {
  numViews?: number;
  timeStamp?: number;
  seekTimes?: number[];
  loadTimes?: number[];
  pauseTimes?: number[];
  endedTimes?: number[];
}

export interface IEntryStateInsertion extends IEntryStateScored {
  draggables: any[];
  targets: any[];
}

export interface IEntryStateMoveableDragAndDrop extends IEntryStateScored {
  targets: any[];
}

export interface IEntryStateCamera extends IEntryStateScored {
  url?: string;
}
export interface IEntryStateMic extends IEntryStateScored {
  fileType?: string;
  url?: string;
}

export interface IEntryStateInputNumber extends IEntryStateScored {
  value: string;
}
export interface IEntryStateInputMath extends IEntryStateScored {
  latex: string;
}
export interface IEntryStateInputText extends IEntryStateScored {
  str: string;
}
export interface IEntryStateInputFraction extends IEntryStateScored {
  wholenumber: number;
  numerator: number;
  denominator: number;
  reducedValue?: number;
}
export interface IEntryStateInputRatio extends IEntryStateScored {
  terms: number[];
}

export interface IEntryStateGraphing extends IEntryStateScored {
  state: any;
}

export interface IEntryStateGraphics extends IEntryState {
  state: any;
}
