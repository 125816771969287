import { Component, OnInit, Input } from '@angular/core';
import { IContentElementFrame, TextParagraphStyle, QuestionState, ImageStates } from '../models';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { PubSubTypes } from './pubsub/types';



@Component({
  selector: 'element-render-frame',
  templateUrl: './element-render-frame.component.html',
  styleUrls: ['./element-render-frame.component.scss']
})
export class ElementRenderFrameComponent implements OnInit {

  @Input() element:IContentElementFrame;
  @Input() isLocked: boolean;
  @Input() questionState: any;
  @Input() isShowSolution?: boolean;
  @Input() questionPubSub?: QuestionPubSub;

  constructor() { }

  isToggledOn:boolean = true;

  ngOnInit() {
    if (this.element.isOff){
      if (!this.isLocked){
        this.isToggledOn = false;
      }
    }
    this.initElementSub();  
  }

  initElementSub(){
    const elementSub = this.questionPubSub.initElementSub(this.element.entryId);
    elementSub.subscribe(payload => {
      switch(payload.type){
        case PubSubTypes.TOGGLE:     return this.handleToggle(!this.isToggledOn);
        case PubSubTypes.TOGGLE_OFF: return this.handleToggle(false);
        case PubSubTypes.TOGGLE_ON:  return this.handleToggle(true);
      }
    });
  }

  handleToggle(toggleState:boolean){
    this.isToggledOn = toggleState;
  }

  onClick(){
    if (this.element.isClickable){
      this.questionPubSub.elementPub(
        this.element.toggleTargetId,
        PubSubTypes.TOGGLE
      )
    }
  }

}
