import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Subject, Subscription} from 'rxjs';

@Component({
  selector: 'mictest',
  templateUrl: './mictest.component.html',
  styleUrls: ['./mictest.component.scss']
})
export class MictestComponent implements OnInit {

  @Input()  startRec:Subject<boolean>;
  @Input()  stopRec: Subject<boolean>;
  @Output() onUrlGenerated = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.startRec.subscribe(flag => {
      if (flag){
        this.startRecording();
      }
    })
    this.stopRec.subscribe(flag => {
      if (flag){
        this.stopRecording();
      }
    })
  }

  rec: any
  gumStream: any
  audioContext: any
  input: any

  stopRecording() {
    console.log("stopButton clicked");
    this.rec.stop();
    this.gumStream.getAudioTracks()[0].stop();
    this.rec.exportWAV(blob => this.createDownloadLink(blob));
  }

  startRecording() {
    console.log("recordButton clicked");
    var constraints = { audio: true, video: false }
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      console.log("getUserMedia() success, stream created, initializing Recorder.js ...");
      this.audioContext = new (window.AudioContext || window['webkitAudioContext'])();
      this.gumStream = stream;
      this.input = this.audioContext.createMediaStreamSource(stream);
      this.rec = new window['Recorder'](this.input, { numChannels: 1 })
      this.rec.record()
      console.log("Recording started");
    }).catch(function (err) {
      //enable the record button if getUserMedia() fails
    });
  }

  createDownloadLink(blob) {
    var url = (window.URL || window.webkitURL).createObjectURL(blob);
    this.onUrlGenerated.emit(url);
    
    // const recordingsList = document.getElementById('recordingsList') as HTMLElement;;
    // var au = document.createElement('audio');
    // var li = document.createElement('div');
    // var link = document.createElement('a');

    // //name of .wav file to use during upload and download (without extendion)
    // var filename = new Date().toISOString();

    // //add controls to the <audio> element
    // au.controls = true;
    // au.src = url;


    // //save to disk link
    // // link.href = url;
    // // link.download = filename + ".wav"; //download forces the browser to donwload the file using the  filename
    // // link.innerHTML = "Save to disk";

    // //add the new audio element to li
    // li.appendChild(au);

    //add the filename to the li
    // li.appendChild(document.createTextNode(filename + ".wav "))

    //add the save to disk link to li
    // li.appendChild(link);
    // li.appendChild(document.createTextNode(" "))//add a space in between

    //add the li element to the ol
    // recordingsList.appendChild(li);
  }

}
