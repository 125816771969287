<div *ngIf="testState && !testState.isSubmitted" class="screen-warning">
    <tra slug="error_small_screen"></tra>
</div>

<div *ngIf="!testState.isSubmitted && !isPrintMode" class="question-content-frame" [class.results-intro]="pageMode===PageMode.RESULTS_INTRO" [class.is-hi-contrast]="isHighContrast">    

    <div *ngIf="!isTestNavExpanded">
        <button class="button" (click)="toggleTestNav()">
            <i class="fa fa-angle-double-right" aria-hidden="true"></i>
        </button>
    </div>
    <div class="test-nav" 
        [style.font-size.em]="(getZoomValue()/defaultZoomLevel)/screenShrink"
        [class.is-expanded]="isTestNavExpanded" 
        *ngIf="!isLineReaderActive && !isLeftBarDisabled()"
    >
        <div>
            <!-- <div class="collapser">
                <button class="button" (click)="toggleTestNav()">
                    <i class="fa fa-angle-double-left" aria-hidden="true"></i>
                </button>
            </div> -->
            <div class="support" *ngIf="false && isIssueReportingEnabled">
                <button (click)="reportIssue()">
                    <img src="https://mathproficiencytest.ca/img/red-flag.png">
                    <tra slug="btn_report_issue"></tra>
                </button>
            </div>
            
            <div *ngIf="isExitEnabled">
                <button class="button is-small is-fullwidth  is-danger has-icon" (click)="exit.emit()">
                    <span class="icon"><i class="fa fa-times"></i></span>
                    <span>Exit Preview</span>
                </button>
            </div>
            
            <div class="identity">
                <div class="space-between">
                    <div class="identity-name">{{testTakerName}}</div>
                    <button class="button" (click)="toggleTestNav()" *ngIf="">
                        <i class="fa fa-angle-double-left" aria-hidden="true"></i>
                    </button>
                </div>
                <div *ngIf="attemptKey" class="identity-key">
                <tra slug="txt_dummy_attempt_key"></tra>: {{attemptKey}}</div>
            </div>

            <div *ngIf="getLogo()" class="logo-container">
                <img [src]="getLogoSafe()" [alt]="lang.tra('lbl_eqao_logo')">
            </div>

            <!-- <div class="test-title">
                <tra slug="brand_long"></tra>
            </div> -->
            
            <div class="test-section">
                <div *ngIf="testRunnerSections.length > 1 && !isShowingResults" [ngSwitch]="!!useSectionCaptions()">
                    <span *ngSwitchCase="true">
                        {{getCurrentSectionCaption()}}
                    </span>
                    <span *ngSwitchCase="false">
                        <tra [slug]="getSectionTitleSlug()"></tra>
                        {{(getCurrentSectionIndex() + 1)}}
                        <tra slug="of_enum"></tra>
                        {{testRunnerSections.length}}
                    </span>
                </div>
                <div *ngIf="isShowingResults">
                    <tra slug="tr_results_page"></tra>
                </div>
                <div *ngIf="getCurrentSectionPreambleContent()">
                    <a *ngIf="isFlushNavigation() && !isShowingResults" (click)="showSectionInfo()">
                        <!--<img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/Essential-question-Unselected/1611767079590/Essential-question-Unselected.png">-->
                        <div class="button-back" *ngIf="!isShowingSectionInfo"><div class="image-container"><img [src]="getCurrentSection().preambleThumbnail"></div><div class="button-text">{{getCurrentSection().preambleThumbnailText}}</div></div>
                        <div class="button-back is-active" *ngIf="isShowingSectionInfo"><div class="image-container"><img [src]="getCurrentSection().preambleThumbnailSelected"></div><div class="button-text">{{getCurrentSection().preambleThumbnailText}}</div></div>
                    </a>
                    <button *ngIf="!isFlushNavigation()" (click)="showSectionInfo()" class="button is-small is-fullwidth" [class.is-info]="isShowingSectionInfo">
                        <i *ngIf="!getInfoCaption()" class="fa fa-info"></i>
                        <span *ngIf="getInfoCaption()">{{getInfoCaption()}}</span>
                    </button>
                </div>
                <div *ngIf="getCurrentSection().isTimeLimit && !isFlushNavigation()" style="font-size: 0.85em; font-weight: 400;">
                    <tra [slug]="getSectionTimeRemainingSlug()"></tra>: {{sectionTimeRemaining}}
                </div>
            </div>
        </div>
        <div class="test-questions" [class.hide-flags]="!isAnyFlaggedQuestions()">
            <ng-container *ngIf="isShowingResults">
                <ng-container *ngFor="let mode of pageModeFlow; let i = index">
                    <button 
                        *ngIf="mode.caption && (showResultsDetailPages() || !mode.isDetail)"  
                        [class.is-active]="pageMode === mode.slug" 
                        class="question" 
                        (click)="selectPageMode(i)"
                    >
                        <tra [slug]="mode.caption"></tra>
                    </button>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!isShowingResults || showResultsDetailPages()">
                <button *ngFor="let question of getCurrentQuestions(); let qIndex = index;" class="question test-question--entry" tabindex="0" (click)="selectQuestion(qIndex)" [class.is-active]="!isShowingSectionInfo && getCurrentQuestionIndex() === qIndex && pageMode === PageMode.TEST_RUNNER">
                    <img *ngIf="isQuestionFlagged(qIndex)" class="coloured-flag" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/flag (coloured)/1607961301596/flag (coloured).svg" alt=""/>
                    <span *ngIf="!isQuestionFlagged(qIndex)" class="coloured-flag invisible-flag"></span>
                    <span class="icon">
                        <ng-container *ngIf="!isShowingResults">
                            <i *ngIf="!isQuestionFilled(qIndex)" class="far fa-circle" [tooltip]="lang.tra('lbl_not_filled')" placement="left" aria-hidden="true"></i>
                            <i *ngIf="isQuestionFilled(qIndex)" class="fas fa-circle" [tooltip]="lang.tra('lbl_filled')" placement="left" aria-hidden="true"></i>
                        </ng-container>
                        <ng-container *ngIf="isShowingResults">
                            <ng-container *ngIf="!isQuestionUnmarked(question)">
                                <img *ngIf="isQuestionCorrect(question)" style="width: 15px" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6276/authoring/correctcircle/1612215146148/correctcircle.png">
                                <img *ngIf="!isQuestionCorrect(question)" style="width: 15px" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6276/authoring/incorrectcircle/1612215224414/incorrectcircle.png">
                            </ng-container>
                            <img *ngIf="isQuestionUnmarked(question)" style="width: 15px" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6276/authoring/yellowclock/1612215246552/yellowclock.png">
                        </ng-container>
                    </span>
                    <span>
                        {{getQuestionTitleFromId(question)}}
                    </span>
                </button>
            </ng-container>
            <button *ngIf="!isShowingResults" class="question" (click)="reviewAndSubmit()">
                <tra [slug]="getSubmissionText()"></tra>
            </button>
            <button *ngIf="isShowingResults" class="question" (click)="leaveResults()">
                <tra slug="tr_btn_exit_results"></tra>
            </button>
        </div>
        <div class='test-timing back-to-map' *ngIf="checkIsOsslt()" (click)="goBackToMap()">
            <i class="fas fa-angle-left"></i> <tra slug="osslt_back_to_map"></tra>
        </div>
        <div class='test-timing back-to-map' *ngIf="checkIsOssltTools()" (click)="goBackToMenu()">
            <i class="fas fa-angle-left"></i> <tra slug="osslt_back_to_menu"></tra>
        </div>
        <div class="test-timing" *ngIf="!isNavFooterDisabled">
            <button (click)="openChat()" *ngIf="isChatEnabled">
                <i class="far fa-comments" aria-hidden="true"></i>
            </button>
            <button *ngIf="chatService.newMessage" (click)="openChat()">
                <a><i class="fa fa-envelope" aria-hidden="true"></i></a>
            </button>
        </div>
    </div>

    <ng-container [ngSwitch]="pageMode">
        <ng-container *ngSwitchCase="PageMode.RESULTS_INTRO">
            <div class="results-page " [ngStyle]="{'font-size.em': getZoomValue()}">
                <div class="centered-div results-intro-div">
                    <div>
                        <tra-md [isCondensed]="true" slug="tr_results_intro_text_1" [props]="{ASSESSMENT_NAME: getAssessmentName()}"></tra-md>
                    </div>
                    <div *ngIf="getCustomResultsText()" style="text-align:left;">
                        <!--<tra-md [isCondensed]="true" slug="tr_results_intro_text_2"></tra-md>-->
                        <tra-md [isCondensed]="true" [slug]="getCustomResultsText()"></tra-md>
                    </div>
                    <div *ngIf="showResultsDetailPages()">
                        <!--<tra-md [isCondensed]="true" slug="tr_results_intro_text_2"></tra-md>-->
                        <tra-md [isCondensed]="true" slug="tr_results_intro_text_2"></tra-md>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="PageMode.RESULTS_SUMMARY">
            <div class="results-page results-summary" [ngStyle]="{'font-size.em': getZoomValue()}">
                <div class="centered-div">
                    
                    <div style="font-size:2.1em; font-weight:600; line-height: 1.2em;"><tra slug="tr_results_summary_title"></tra></div>


                    <results-summary-sr style="width:100%"
                        [numCorrectSRQuestions]="numCorrectSRQuestions"
                        [numSRQuestions]="numSRQuestions"
                        [correctSRScore]="correctSRScore" 
                        [totalSRScore]="totalSRScore">
                    </results-summary-sr>

                    <results-summary-cr style="width:100%"
                        [totalCRScore]="totalCRScore"
                        [numCRQuestions]="numCRQuestions"
                    >
                    </results-summary-cr>
                    <div><tra-md [isCondensed]="true" slug="tr_results_summary_text_1"></tra-md></div>
                    <button class="button results-export-button" (click)="exportResults()"> <i style="margin-right: 0.2em" class="fas fa-arrow-circle-down"></i><tra slug="test_auth_export"></tra></button>
                    <ng-container *ngIf="isNotTestTaker() && rubricDownloadLink">
                        <div><tra-md [isCondensed]="true" slug="tr_results_summary_text_rubric"></tra-md></div>
                        <button (click)="downloadRubric()" class="button results-export-button"><i style="margin-right: 0.2em" class="fas fa-arrow-circle-down"></i><tra slug="tr_results_view_rubric"></tra></button>
                        <a #rubricLink [href]="rubricDownloadLink"></a>
                    </ng-container>
                    <div><tra-md [isCondensed]="true" slug="tr_results_summary_text_2"></tra-md></div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="PageMode.RESULTS_INSTRUCTIONS">
            <div class="results-page results-instructions" [ngStyle]="{'font-size.em': getZoomValue()}">
                <div class="centered-div">
                    <div></div>
                    <div style="font-size:2.1em; font-weight:600; line-height: 1.2em;"><tra slug="tr_results_instr_title"></tra></div>
                    <div><tra-md [isCondensed]="true" slug="tr_results_legend_text_1"></tra-md></div>
                    <div><tra-md [isCondensed]="true" slug="tr_results_legend_text_2"></tra-md></div>
                    <results-legend [initExpand]="true" style="width: 20em"></results-legend>
                    <div><tra-md [isCondensed]="true" slug="tr_results_legend_text_3"></tra-md></div>
                    <div><tra-md [isCondensed]="true" slug="tr_results_legend_text_4"></tra-md></div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="PageMode.TEST_RUNNER">
            <div class="question-content" id="quest-cont" [class.is-split-view]="getActiveQuestionReadSel()">
            <!--<button *ngIf="false" tabindex="1" aria-label="Keyboard Instructions" style="display: none;background-color: white; position: absolute; left: 0; color: #3298dc; border-radius: 5px; padding: 0.75em; border: 2px solid #3298dc">Keyboard Instructions</button>-->
            <div class="helper-tools" [style.font-size.em]="getZoomValue()">
                <div 
                    #questionDisplay 
                    class="question-content-constrainer no-padding" 
                    [class.needs-padding]="getActiveQuestionReadSel()" 
                    [style.width]="getActiveQuestionReadSel() ? '100%' : null" 
                >

                    <ng-container>
                        <!-- *ngIf="questionResetter" -->
                        <div class="split-view-container" >
                            <div cdkScrollable class="split-view-left" *ngIf="isShowingLeft()" [class.is-solo]="!isShowingRight()" [class.is-saving]="isSavingResponse && !isQuietlySavingResponse">
                                <div id="ghost-split-left" style="position:absolute; visibility: hidden; width: 10em; height:0.01em"></div>

                                <div *ngIf="isProgressBarEnabled()" class="progress-bar-container">
                                    <div class="question-loc-sm">
                                        <tra slug="title_stage"></tra> {{testState.currentSectionIndex+1}},
                                        {{getQuestionTitle(testState.currentQuestionIndex)}}
                                    </div>
                                    <div  style="position:relative" #topBar [class.is-hidden]="isLineReaderActive" [ngSwitch]="!!checkTag(KNOWN_TEST_RUNNER_TAGS.QUESTIONS_FILLED_PROGRESS_BAR) || checkIsOsslt()">
                                        <ng-container *ngSwitchCase="false || undefined">
                                            <progress  class="progress is-info" style="margin-bottom:0px;" [value]="getCurrentProgressLoc(true)" max="100">{{getCurrentProgressLoc()}}</progress>
                                            <div *ngFor="let section of testRunnerSections; let isLast = last" class="section-progress-marker" [ngStyle]="{left: section.__meta.markLoc}" [class.is-hidden]="isLast"> </div>
                                        </ng-container>
                                        <ng-container  *ngSwitchCase="true"> <!--  -->
                                            <progress class="progress is-info" style="margin-bottom:0px;" [value]="getCurrentProgressBySession(currentSession, 0)" max="100"></progress>
                                        </ng-container>
                                    </div>
                                </div>
                                
                                <div *ngIf="isHelpOverlay" class="question-content question-content-help" style="height: auto;">
                                    <div class="question-content-constrainer" style="margin-bottom:2em; height: auto;;" >
                                        <question-runner 
                                            [currentQuestion]="getHelpScreenLayout()" 
                                            [containingWidth]="getQuestionRunnerWidth('main')"
                                            [containingWidthSpill]="getQuestionRunnerSpillover('main')"
                                            [questionState]="helpPageState"
                                        ></question-runner>
                                    </div>
                                </div>

                                <!-- Display Preamble: -->
                                <ng-container *ngIf="isShowingSectionInfo && !isHelpOverlay">
                                    <div *ngIf="getCurrentSectionPreambleContent()" style="position: relative;" class="question-runner-padder">
                                        <div *ngIf="isInfoIconEnabled()" style="font-size: 2em; color: #3298dc; position:absolute; left:-1em; top:0.1em;">
                                            <i class="fa fa-info"></i>
                                        </div>
                                        <question-runner 
                                            [currentQuestion]="getCurrentSectionPreambleContent()" 
                                            [containingWidth]="getQuestionRunnerWidth('main')"
                                            [containingWidthSpill]="getQuestionRunnerSpillover('main')"
                                        ></question-runner>
                                    </div>
                                    <div class="next-button-container" *ngIf="!isFlushNavigation()">
                                        <button (click)="hideSectionInfo()" id="btn-submit-question" class="button is-info" [class.is-large]="!isFlushNavigation()" >
                                            <tra-md [slug]="getNextPageSlug()" [isCondensed]="true" [props]="{QUESTION_WORD: getQuestionWord()}" ></tra-md>
                                        </button>
                                    </div>
                                </ng-container>

                                <!-- Display Current Question:    -->
                                <ng-container *ngIf="!isShowingSectionInfo && !isHelpOverlay">
                                    <div  *ngIf="isFlushNavigation()" class="top-bar is-in-frame">
                                        <div style="display: flex; flex-direction: column">
                                            <div class="align-center">
                                                <!-- <button class="button" *ngIf="!isTestNavExpanded" (click)="toggleTestNav()">
                                                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                                </button> -->
                                                <span style="font-weight:600" [style.color]="isHighContrast ? '#fff' : '#000'">{{getCurrentQuestionTitle()}}</span>
                                                <span *ngIf="getCurrentPoints()" class="points-indicator">({{getCurrentPoints()}})</span>
                                            </div>
                                            <div *ngIf="isFlagEnabledForCurrentQuestion() && !isShowingResults" style="font-size: 0.7em; margin-top:0.8em;" class="flag-button-container">
                                                <!-- class="button is-small is-info is-outlined" -->
                                                <a (keypress)="flagKeyPress($event)" style="font-weight: 400;" tabindex="0" (click)="flagCurrentQuestion()">
                                                    <img class="flag" style="width:1em; max-width:1em; position:relative; top:0.2em; margin-right:0.5em;" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/flag%20(coloured)/1607961301596/flag%20(coloured).svg" alt=""/> 
                                                    <tra [slug]="getFlagSlug()" *ngIf="!isCurrentQuestionFlagged()"></tra> 
                                                    <tra [slug]="getFlagSlug(true)" style="color: red;" *ngIf="isCurrentQuestionFlagged()"></tra>
                                                </a>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="!isShowingResults">
                                            <div *ngIf="hasExactlyOneReadingSelection()" class="reading-selections-container">
                                                <div class="reading-selections" style="margin-top:0.5em;">
                                                    <element-render-text-link [element]="convertReadSelToTextLink(readerInfo[0])" ></element-render-text-link>
                                                </div>
                                            </div>
                                            <div *ngIf="hasReadingSelections()" class="reading-selections-container">
                                                <div class="reading-selections">
                                                    <div *ngIf="isReadingSelectionListToggle()">
                                                        <a  (click)="toggleReadingSelections()" tabindex="0" [ngSwitch]="!!isShowingReadingSelections">
                                                            <i *ngSwitchCase="false" class="icon fas fa-caret-right"></i>
                                                            <i *ngSwitchCase="true" class="icon fas fa-caret-down"></i>
                                                            <tra [slug]="getOpenReadSelSlug(isShowingReadingSelections)"></tra>
                                                        </a>
                                                        <div *ngIf="isShowingReadingSelections" class="reading-selection-options">
                                                            <div class="reading-selection-option" *ngFor="let readSel of readerInfo; let i = index">
                                                                <element-render-text-link  [element]="convertReadSelToTextLink(readSel)" ></element-render-text-link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="!isReadingSelectionListToggle()">
                                                        <div class="reading-selection-options">
                                                            <div class="reading-selection-option" *ngFor="let readSel of readerInfo; let i = index">
                                                                <element-render-text-link  [element]="convertReadSelToTextLink(readSel)" ></element-render-text-link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div style="position:relative;">
                                        <div class="question-runner-padder">
                                            <question-runner 
                                                [currentQuestion]="getActiveQuestionContent()" 
                                                [questionState]="getActiveQuestionState()"
                                                [containingWidth]="getQuestionRunnerWidth('split-left')-2"
                                                [containingWidthSpill]="getQuestionRunnerSpillover('split-left')"
                                                [isSubmitted]="isShowingResults"
                                            > <!-- style="padding-right: 16em;" -->
                                            </question-runner>
                                        </div>
                                        <div *ngIf="isShowOverlay && resetter && questionResetter" class="question-content__overlay" [ngClass]="{'deactivated':showDeactivatedMode}" id="draw-overlay-split">
                                            <element-render-drawing  
                                                id="test-runner" 
                                                [parent]="getDrawingDisplayMode()" 
                                                [currId]="getCurrentLeftItemId()" 
                                                [activatedState]="!showDeactivatedMode" 
                                                [useEraser]="showEraser" 
                                                [useHighlighter]="showHighlight" 
                                                [useLine]="showLine" 
                                                [zoomLevel]="getZoomValue()" 
                                                [isZoomIn]="isZoomIn"
                                                [isFrameEnabled]="false"
                                                [isToolbarEnabled]="false"
                                                [drawingCtx]="loadDrawing()"
                                                [pageIndexTracker]="drawingPageIndexTracker"
                                                [section]="getCurrentSectionIndex()"
                                                (savedCtx)="saveDrawing($event)"
                                            ></element-render-drawing>
                                        </div>
                                        <div 
                                            class="question-content__faux-overlay" 
                                            [class.animateDown]="isShowOverlay && !showDeactivatedMode"
                                            [class.animateUp]="showDeactivatedMode"
                                        ></div>
                                    </div>
                                </ng-container>

                                <div  *ngIf="!isFlushNavigation()" [ngSwitch]="!!isOnLastQuestion()" class="next-button-container test-question" style="display:block; flex-direction: column;" [class.is-hidden]="isShowingSectionInfo">
                                    <button *ngSwitchCase="false" (click)="gotoNextQuestion()" id="btn-submit-question" [disabled]="!isCurrentQuestionFilled() && !isCurrentQuestionFlagged() && !areTestQuestionsSkippable() && !forceQuestionsSkippable"   class="button is-large is-info">
                                        <tra-md [slug]="getNextPageSlug()" [isCondensed]="true" [props]="{QUESTION_WORD: getQuestionWord()}" ></tra-md>
                                    </button>
                                    <button *ngSwitchCase="true" (click)="reviewAndSubmit()" id="btn-submit-question" [disabled]="!isCurrentQuestionFilled() && !isCurrentQuestionFlagged()"  class="button is-large is-info">
                                        <tra [slug]="getSubmissionText()"></tra>
                                    </button>
                                    <div *ngIf="isFlagEnabledForCurrentQuestion()" class="flag-button-container">
                                        <!-- class="button is-small is-info is-outlined" -->
                                        <a (keypress)="flagKeyPress($event)" tabindex="0" (click)="flagCurrentQuestion()">
                                            <img class="flag" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/flag%20(coloured)/1607961301596/flag%20(coloured).svg" alt=""/> 
                                            <tra [slug]="getFlagSlug(false)" *ngIf="!isCurrentQuestionFlagged()"></tra> 
                                            <tra [slug]="getFlagSlug(true)" style="color: red;" *ngIf="isCurrentQuestionFlagged()"></tra>
                                        </a>
                                    </div>
                                </div>
            
                                <div style="height:6em;"></div>

                            </div>
                            <div cdkScrollable class="split-view-right" [class.is-solo]="!isShowingLeft()" id="readingPassageSplit" *ngIf="isShowingRight() && getActiveQuestionReadSel() &&!isShowingSectionInfo">
                                <!-- Reading selection -->
                                <div style="position:relative; margin-bottom: 8em;" id="left-split-container" >
                                    <div id="ghost-split-right" style="position:absolute; visibility: hidden; width: 10em; height:0.01em"></div>
                                    <div class="question-runner-padder-disabled">
                                        <question-runner *ngIf="activeReadingSelection" id="questionRunnerInReadingPassage" 
                                            [currentQuestion]="activeReadingSelection"
                                            [containingWidth]="getQuestionRunnerWidth('split-right')"
                                            [containingWidthSpill]="getQuestionRunnerSpillover('split-right')"
                                            [questionState]="getReadingSelectionState()"
                                            [isSubmitted]="isShowingResults">
                                        </question-runner>
                                    </div>
                                    <div *ngIf="isShowOverlay && resetter && questionResetter" class="question-content__overlay" [ngClass]="{'deactivated':showDeactivatedMode}" id="draw-overlay-split">
                                        <element-render-drawing  
                                            id="test-runner" 
                                            [parent]="getDrawingDisplayMode()" 
                                            [currId]="getCurrentRightItemId()" 
                                            [activatedState]="!showDeactivatedMode" 
                                            [useEraser]="showEraser" 
                                            [useHighlighter]="showHighlight" 
                                            [useLine]="showLine" 
                                            [zoomLevel]="getZoomValue()" 
                                            [isZoomIn]="isZoomIn"
                                            [isFrameEnabled]="false"
                                            [isToolbarEnabled]="false"
                                            [drawingCtx]="loadDrawing()"
                                            [pageIndexTracker]="drawingPageIndexTracker"
                                            [section]="getCurrentSectionIndex()"
                                            (savedCtx)="saveDrawing($event)"
                                        ></element-render-drawing>
                                    </div>
                                    <div 
                                        class="question-content__faux-overlay" 
                                        [class.animateDown]="isShowOverlay && !showDeactivatedMode"
                                        [class.animateUp]="showDeactivatedMode"
                                    ></div>
                                    
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        </ng-container>
    </ng-container>


    <div class="toolbar-surrogate" ></div>
    <!-- *ngIf="pageMode === PageMode.TEST_RUNNER"  -->

</div>
<div *ngIf="!testState.isSubmitted && !isPrintMode" class="test-runner-overlays">

    <!-- [style.max-width.px]="this.screenWidth" -->

    <div *ngIf="isLineReaderActive">
        <div class="line-reader-container" id="quest-reader" [ngStyle]="{'font-size.em': getZoomValue()}">
            <div cdkDrag class="line-reader">
                <div class="line-reader-top"></div>
                <div class="line-reader-left"></div>
                <div class="line-reader-right"></div>
                <div class="line-reader-bottom"></div>
            </div>
        </div>
    </div>
    <div *ngIf="isNotepadEnabled" [ngStyle]="{'font-size.em': getZoomValue()}">
        <div cdkDrag class="notepad-container" >
            <div style="padding-bottom:0.5em;">
                <tra [slug]="getNotepadSlug()"></tra>
            </div>
            <div *ngIf="getNotepadText()" style="    padding-bottom: 0.6em;
            border-bottom: 1px solid #fff;
            margin-bottom: 0.5em;
            line-height: 1.1em;
            font-weight: 500;
            font-size: 0.7em;;">
                {{getNotepadText()}}
            </div>
            <ng-container [ngSwitch]="!!(isFlushNavigation() || checkIsOsslt())">
                <textarea *ngSwitchCase="true" class="textarea" style="font-size:1em;" spellcheck="false" (mousedown)="$event.stopPropagation()"  [(ngModel)]="testState.notes"></textarea>
                <ng-container *ngSwitchCase="false">
                    <ng-container [ngSwitch]="!!isUsingTemporaryNotes()">
                        <textarea *ngSwitchCase="false" class="textarea" spellcheck="false" (mousedown)="$event.stopPropagation()" oncopy="return false" [(ngModel)]="getActiveQuestionState().__meta.notes"></textarea>
                        <textarea *ngSwitchCase="true" class="textarea"  spellcheck="false" (mousedown)="$event.stopPropagation()" oncopy="return false" [(ngModel)]="testState.notes"></textarea>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div *ngIf="isCalcAvailable()" style="z-index: 999;" id="calculator-widget">
        <widget-calculator [zoom]="getZoomValue()" *ngIf="isShowingCalc()"></widget-calculator>
    </div>

    <div *ngIf="isShowFormulaSheet" class="formula-container">
    <div class="formula-container__sheet">
        <div class="formula-container__sheet-header">
            <p>Formula Sheet</p>
            <button (click)="toggleFormula()"> <i class="fas fa-times"></i></button>
            
        </div>
    </div>
    </div>

    <div class="toolbar" [class.is-expanded]="isToolbarExpanded">
            <div class="tools-top">
                <button class="button collapser" (click)="toggleToolbar()">
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                </button>
                <button class="toolbar-icon tool-help" [class.is-activated]="isHelpOverlay" (click)="toggleHelpScreen()" [tooltip]="lang.tra('btn_help')" 
                        placement="left"  *ngIf="isHelpEnabled && !isFlushNavigation()" [attr.aria-label]="lang.tra('btn_help')">
                    <!-- <img src="https://mathproficiencytest.ca/img/text-to-speech.png"> -->
                    <i class="fa fa-question-circle" style="font-size: 2em;" aria-hidden="true"></i>
                </button>
                <!-- Text to speech by Trevor Dsouza from the Noun Project -->
                <button class="toolbar-icon" (click)="toggleTextToSpeech()" [class.is-active]="isTextToSpeechActive()" 
                        *ngIf="isText2SpeechEnabled && !isFlushNavigation()" [tooltip]="lang.tra('btn_listen')" placement="left"
                        [attr.aria-label]="lang.tra('btn_listen')">
                    <img aria-hidden="true" src="https://mathproficiencytest.ca/img/text-to-speech.png">
                </button>
                <button class="toolbar-icon" (click)="zoomIn()" [tooltip]="lang.tra('btn_zoom_in')" placement="left" [attr.aria-label]="lang.tra('btn_zoom_in')">
                    <img aria-hidden="true" src="https://mathproficiencytest.ca/img/zoom-in.png">
                </button>
                <div style="color:#fff">{{getCurrentZoomDisplay()}}</div>
                <button class="toolbar-icon" (click)="zoomOut()" [tooltip]="lang.tra('btn_zoom_out')" placement="left" [attr.aria-label]="lang.tra('btn_zoom_out')">
                    <img aria-hidden="true" src="https://mathproficiencytest.ca/img/zoom-out.png">
                </button>
                <button class="toolbar-icon" (click)="toggleLineReader()" [class.is-active]="isLineReaderActive" [tooltip]="lang.tra(getLineReaderCaption())" 
                        placement="left" [attr.aria-label]="lang.tra(getLineReaderCaption())">
                    <img aria-hidden="true" src="https://www.mathproficiencytest.ca/img/line_reader.png">
                </button>
                <button *ngIf="isDrawingToolsShown()" class="toolbar-icon" (click)="toggleHiContrast()" [class.is-active]="isHighContrast" 
                        [tooltip]="lang.tra('btn_hi_contrast')" placement="left" [attr.aria-label]="lang.tra('btn_hi_contrast')">
                    <!-- hi contrast -->
                    <img aria-hidden="true" src="https://mathproficiencytest.ca/img/hi-contrast.png">
                </button>
                <button *ngIf="!isShowingResults && isDrawingToolsShown()" class="toolbar-icon" (click)="setLine()" 
                        [class.is-active]="isShowOverlay && !showDeactivatedMode && showLine" [class.is-inactive]="isShowingSectionInfo" 
                        [tooltip]="lang.tra('btn_toggle_editor')" placement="left" [attr.aria-label]="lang.tra('btn_toggle_editor')">
                    <i class="fas fa-edit" style="font-size: 2em; color:white;" aria-hidden="true"></i>
                </button>
                <button *ngIf="!isShowingResults && isDrawingToolsShown()" class="toolbar-icon" (click)="setHighlighter()" 
                        [class.is-active]="isShowOverlay && !showDeactivatedMode && showHighlight"  [class.is-inactive]="isShowingSectionInfo" 
                        [tooltip]="lang.tra('el_draw_highlighter')" placement="left" [attr.aria-label]="lang.tra('el_draw_highlighter')">
                    <i class="fas fa-highlighter" style="font-size: 2em; color:white;" aria-hidden="true"></i>
                </button>
                <button *ngIf="!isShowingResults && isDrawingToolsShown()" class="toolbar-icon" (click)="setEraser()" 
                        [class.is-active]="isShowOverlay &&!showDeactivatedMode && showEraser" [class.is-inactive]="isShowingSectionInfo" 
                        [tooltip]="lang.tra('btn_eraser')" placement="left" [attr.aria-label]="lang.tra('btn_eraser')">
                    <i class="fas fa-eraser" style="font-size: 2em; color:white;" aria-hidden="true"></i>
                </button>
                <button *ngIf="!isShowingResults && checkIsOsslt()" class="toolbar-icon" (click)="toggleNotepad()" [class.is-active]="isNotepadEnabled" 
                        [tooltip]="lang.tra(getNotepadSlug())" placement="left" [attr.aria-label]="lang.tra(getNotepadSlug())">
                    <i class="fas fa-sticky-note" style="font-size: 2em; color:white;" aria-hidden="true"></i>
                </button>
                
            </div>
            <div class="tools-bottom">
            <button *ngIf="documentItems && documentItems.length && !checkIsOsslt()" 
                (click)="toggleDocuments()" 
                class="toolbar-icon" 
                [class.is-active]="isShowDocuments" 
                [tooltip]="getDocumentTooltip()" placement="left"
                [attr.aria-label]="getDocumentTooltip()"
                >
                <img aria-hidden="true" src="https://mathproficiencytest.ca/img/reference-book.png">
            </button>
            <!-- <button *ngIf="isFormulasAvailable()" (click)="toggleFormulas()" class="toolbar-icon" [class.is-active]="isFormulasToggledOn" title="{{lang.tra('btn_formulas')}}">
                <img src="https://mathproficiencytest.ca/img/reference-book.png">
            </button> -->
            <button *ngIf="isCalcAvailable()" (click)="toggleCalc()" class="toolbar-icon" [class.is-active]="isShowingCalc()" 
                    [tooltip]="lang.tra('btn_calculator')" [attr.aria-label]="lang.tra('btn_calculator')" placement="left">
                <img aria-hidden="true" src="https://mathproficiencytest.ca/img/calculator.png">
            </button>
        </div>
    </div>

    <div class="info-overlay" *ngIf="isShowingReport">
        <div class="info-box">
            <strong><tra slug="btn_report_issue"></tra></strong>
            <hr/>
            <textarea class="textarea" placeholder="Please describe the issue that you experienced in the box below" [formControl]="issueReportMessage" rows=6></textarea>
            <hr/>
            <div class="buttons">
                <button class="button" (click)="sendIssueReport()">
                    Report Issue
                </button>
                <button class="button is-danger is-outlined" (click)="isShowingReport = false">
                    Cancel
                </button>
            </div>
        </div>
    </div>

    <div class="info-overlay" *ngIf="isShowDocuments">
        <div class="info-box is-maximized documents-container">
            <div [ngStyle]="{'font-size.em': getZoomValue()}">
                <div style="display:flex; flex-direction:row; justify-content: space-between;">
                    <menu-bar style="flex-grow:1" [menuTabs]="documentViews" (change)="selectDocumentView($event)" [tabIdInit]="selectedDocumentId"></menu-bar>
                    <a style="font-size:1.4em;" (click)="isShowDocuments = !isShowDocuments">&times;</a>
                </div>
                <div *ngIf="activeDocument">
                    <question-runner 
                        [currentQuestion]="activeDocument" 
                        [containingWidth]="getQuestionRunnerWidth('main')"
                        [containingWidthSpill]="getQuestionRunnerSpillover('main')"
                    ></question-runner>
                </div>
            </div>
        </div>
    </div>

    <div class="info-overlay" *ngIf="isShowingTime">
        <div class="info-box">
            <small>T<tra slug="test_time_message"></tra></small>
            <hr/>
            <div> <strong><tra slug="test_time_remaining"></tra></strong> </div>
            <div style="font-size:1.4em;">{{regularTimeRemaining}}</div>
            <!-- <hr/> -->
            <!-- <div> <strong>Extra Time Remaining:</strong> </div> -->
            <!-- <div>02:00</div> -->
            <hr/>
            <div>
                <button class="button is-fullwidth" (click)="isShowingTime=false">
                    OK
                </button>
            </div>
        </div>
    </div>

    <div class="chat-overlay" *ngIf="isShowingChat">
        <div class="info-box">
            <div>
                <button class="button is-fullwidth" (click)="isShowingChat=false">
                    Close
                </button>
            </div>
            <hr/>
            <div>
                <test-taker [uid]="auth.user().value.uid" [instit_group_id]="instit_group_id" [markingPoolId]="testSessionId" [chatService]="chatService"></test-taker>
            </div>
        </div>
    </div>

    <div class="save-overlay" [class.is-activated]="isSavingResponse && !isQuietlySavingResponse">
        <div class="overlay-bg"></div>
        <div class="overlay-text">
            <span class="tag is-warning">Saving...</span>
        </div>
    </div>

    <!-- <div style="top:0em; left:0em;" *ngIf="!isTestNavExpanded">
        <button class="button" (click)="toggleTestNav()">
            <i class="fa fa-angle-double-right" aria-hidden="true"></i>
        </button>
    </div> -->

    <div class="expander" style="top:0em; right:0em;" *ngIf="!isToolbarExpanded">
        <button class="button" (click)="toggleToolbar()">
            <i class="fa fa-angle-double-left" aria-hidden="true"></i>
        </button>
    </div>

    <div class="navigation-panel-container"  *ngIf="!showDeactivatedMode && isShowOverlay">
        <button 
            class="annotation-tool-close"
            (click)="disableOverlay()"
        >
            <tra [slug]="getCloseDrawingSlug()"></tra>
        </button>
    </div>
    <!-- *ngIf="isFlushNavigation()" -->
    <div class="navigation-panel-container" [class.is-wider]="lang.c() === 'fr'" >
        <div class="reading-text-controls" *ngIf="getActiveQuestionReadSel() && !isShowingResults && isShowingRight()">
            <button class="button has-icon" (click)="readSelViewClose()">
                <span class="icon"><i class="fas fa-caret-square-right"></i></span>
                <span><tra slug="btn_readsel_close"></tra></span>
            </button>
            <button class="button has-icon" (click)="readSelViewHalf()">
                <span class="icon"><i class="fas fa-columns"></i></span>
                <span><tra slug="btn_readsel_half"></tra></span>
            </button>
            <button class="button has-icon" (click)="readSelViewFull()">
                <span class="icon"><i class="far fa-square"></i></span>
                <span><tra slug="btn_readsel_full"></tra></span>
            </button>
        </div>
        <button 
            *ngIf="!showDeactivatedMode && isShowOverlay" 
            class="annotation-tool-close"
            (click)="disableOverlay()"
        >
            <tra [slug]="getCloseDrawingSlug()"></tra>
        </button>
        <div class="navigation-panel" *ngIf="showPrevNext()  && isFlushNavigation()">
            <button class="toolbar-icon navigation-icon left-nav-button" (click)="gotoPrev()">
                <div class="nav-button-div">
                    <i class="fas fa-arrow-left"></i> 
                    <tra slug="btn_prev_bc"></tra>
                </div>
            </button>
            <button class="toolbar-icon navigation-icon" (click)="gotoNext()">
                <div class="nav-button-div">
                    <i class="fas fa-arrow-right"></i> 
                    <tra slug="btn_next_bc"></tra>
                </div>
            </button>
        </div>
    </div>

</div>

<div *ngIf="isPrintMode && !isShowingResults" class="printed-test-form">
    <div>
        <strong> Test Form {{testFormId}}</strong>
        <!-- <div>The following sheets of paper must be shredded after digitization.</div> -->
    </div>
    <!-- section.isShuffleDisabled &&  !isShowQuestionLabel-->
    <div *ngFor="let section of testRunnerSections; let index = index" >
        <div class="section-block">
            <h1><tra [slug]="getSectionTitleSlug()"></tra> {{index+1}}</h1>
            <question-runner 
                [currentQuestion]="getSectionPreambleContent(section)" 
                [containingWidth]="getQuestionRunnerWidth('main')"
                [containingWidthSpill]="getQuestionRunnerSpillover('main')"
            ></question-runner>
        </div>
        <div class="question-block" *ngFor="let question of section.questions; let qindex = index">
            <div style="border-top: 0.3em solid #666; padding:0.2em 0em;  font-weight:600; margin-top:1em;">
                <tra [slug]="getSectionTitleSlug()"></tra> {{index+1}}, Question {{qindex+1}}
                <span *ngIf="isShowQuestionLabel">[{{getQuestionLabel(question)}}]</span>
            </div>
            <question-runner 
                [currentQuestion]="getQuestionDef(question)" 
                [containingWidth]="getQuestionRunnerWidth('main')"
                [containingWidthSpill]="getQuestionRunnerSpillover('main')"
            ></question-runner>
        </div>
    </div>
</div>

<div *ngIf="isPrintMode && isShowingResults" class="printed-page" style="padding-left:0.5em; color:#000">

    <div class="dont-print" style="margin-top:1em;margin-bottom:1.5em;">
        <button class="button" (click)="exitExportResults()"> 
            <i style="margin-right:0.5em" class="fas fa-arrow-left"></i>
            <tra slug="btn_back"></tra>
        </button>
        <button class="button" (click)="triggerPrintDialog()"> 
            <i style="margin-right:0.5em" class="fas fa-print"></i>
            <tra slug="btn_print"></tra>
        </button>
    </div>

    <div>
        <div style="font-size:1.7em; line-height:1.3em; margin-bottom:0.5em;">
            <div >{{getAssessmentName()}}</div>
            <div><strong><tra slug="lbl_results_export"></tra></strong></div>
        </div>
        <div style="margin-bottom:0.8em;">
            <strong><tra slug="tr_student_pen"></tra> </strong>
            {{getTestTakerID()}}
        </div>
        <div style="margin-bottom:0.8em;">
            <tra slug="lbl_completed_on"></tra><tra slug="txt_colon"></tra>
            {{getWritingDate()}}
        </div>
    </div>

    <div style="border: 1px solid #000; margin-bottom:0.8em">
        <div style="    font-size: 1.2em; font-weight: 600; background-color: rgb(163, 188, 235); color: #000; padding: 0.2em;">
            <tra slug="tr_results_sr_questions"></tra> (Points)
        </div>
        <div style="display: flex; flex-direction: row; flex-wrap: wrap;;">
            <ng-container *ngFor="let questionId of resultQs">
                <div *ngIf="!isQuestionUnmarked(questionId)" [class.is-green]="getProportionalQuestionScore(questionId) == getQuestionTotalScore(questionId)" style="display: flex; flex-grow: 1; flex-direction:row; width:12em;  justify-content: space-between; border:1px solid #000;    padding: 0.2em; margin-right: -1px;">
                    <div style="max-width:8em; overflow: hidden; text-overflow: ellipsis;">{{getQuestionTitleFromId(questionId)}}</div>
                    <div>({{getProportionalQuestionScore(questionId, true) }}/{{getQuestionTotalScore(questionId)}})</div>
                </div>
            </ng-container>
        </div>
        <div style="padding: 0.2em">
            <tra slug="tr_results_score_total"></tra>: {{correctSRScore}}/{{totalSRScore}}
        </div>
    </div>
    

    <div style="border: 1px solid #000; margin-bottom:0.8em">
        <div style="    font-size: 1.2em; font-weight: 600; background-color: rgb(163, 188, 235); color: #000; padding: 0.2em;">
            <tra slug="tr_results_cr_questions"></tra>
        </div>
        <div style="padding: 0.2em">
            <tra slug="lbl_num_responses"></tra><tra slug="txt_colon"></tra> 
            {{numCRQuestions}}
        </div>
    </div>
    
    <div class="question-block" *ngFor="let question of resultQs" >
        <ng-container *ngIf="isQuestionUnmarked(question)">
            <!-- <div style="border-top: 0.3em solid #666; padding:0.2em 0em;  font-weight:600; margin-top:1em; display: flex; flex-direction: column;"> -->
                <!-- <span>{{getQuestionTitleFromId(question)}}</span> -->
                <!-- <span *ngIf="!isQuestionUnmarked(question)"> -->
                    <!-- <tra slug="tr_results_score"></tra> {{getQuestionScore(question)}}/{{getQuestionTotalScore(question)}} -->
                <!-- </span> -->
                <!-- <span *ngIf="isQuestionUnmarked(question)"> -->
                    <!-- <tra slug="tr_results_score_pending"></tra> -->
                <!-- </span> -->
            <!-- </div> -->
            <div style="margin-bottom:0.8em; border:1px solid #ccc;">
                <question-runner
                    [currentQuestion]="getQuestionDef(question)"
                    [questionState]="getQuestionState(question)"
                    [containingWidth]="getQuestionRunnerWidth('main')"
                    [containingWidthSpill]="getQuestionRunnerSpillover('main')"
                    [isSubmitted]="true"
                    [isPrintMode]="true"
                ></question-runner>
                <div style="font-size: 0.8em; text-align: right; padding: 0.5em; font-style: italic;">
                    <tra slug="lbl_submitted_by"></tra>  {{getTestTakerID()}} 
                    (<tra slug="lbl_student_pen"></tra>) 
                    <tra slug="snippet_for"></tra> {{getAssessmentName()}}
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div *ngIf="isShowingResults && pageMode === PageMode.TEST_RUNNER" class="score-legend-box">
    <div  class="score-box">
        <div><tra slug="tr_results_score"></tra></div>
        <div *ngIf="!isQuestionUnmarked(getActiveQuestionId())">{{getQuestionScoreDisplay(getActiveQuestionId())}}/{{getQuestionTotalScore(getActiveQuestionId())}}</div>
        <img *ngIf="isQuestionUnmarked(getActiveQuestionId())" style="width: 1.1em" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6276/authoring/yellowclock/1612215246552/yellowclock.png">
    </div>

    <results-legend>
    </results-legend>
</div>

