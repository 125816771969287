<table class="legend-table">
    <th (click)="toggleLegendExpand()">
        <div>
            <div style="visibility:hidden; width: auto">
                <i *ngIf="!isExpanded" class="fas fa-chevron-right legend-arrow"></i>
                <i *ngIf="isExpanded" class="fas fa-chevron-down legend-arrow"></i>
            </div>
            <span><tra slug="tr_results_legend_title"></tra></span>
            <i *ngIf="!isExpanded" class="fas fa-chevron-right legend-arrow"></i>
            <i *ngIf="isExpanded" class="fas fa-chevron-down legend-arrow"></i>
        </div>
    </th>
    <ng-container *ngIf="isExpanded">
        <tr>
            <td>
                <div>
                    <img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6276/authoring/correctcircle/1612215146148/correctcircle.png">
                    <span><tra slug="tr_results_legend_check"></tra></span>
                </div>
            </td>
        </tr>
        <tr>
            <td>
                <div>
                    <img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6276/authoring/incorrectcircle/1612215224414/incorrectcircle.png">
                    <span><tra slug="tr_results_legend_circle"></tra></span>
                </div>
            </td>
        </tr>
        <tr>
            <td>
                <div>
                    <img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6276/authoring/yellowclock/1612215246552/yellowclock.png">
                    <span><tra slug="tr_results_legend_clock"></tra></span>
                </div>
            </td>
        </tr>
    </ng-container>
</table>