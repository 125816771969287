<test-runner
  *ngIf="testForm"
  [testFormType]="asmtFmrk.testFormType"
  [testFormId]="-1"
  [currentTestDesign]="testForm.currentTestDesign"
  [frameWorkTags]="asmtFmrk.tags"
  [questionSrcDb]="testForm.questionSrcDb"
  [questionStates]="testForm.questionStates"
  [testLang]="testForm.testLang"
  [testTakerName]="testTakerName"
  [sectionIndexInit]="0"
  [isTimeEnabled]="!asmtFmrk.isTimerDisabled"
  [documentItems]="asmtFmrk.referenceDocumentPages"
  [helpPageItem]="asmtFmrk.helpPageId"
  [rubricDownloadLink]="asmtFmrk.rubricDownloadLink"
  [asmtFmrk]="asmtFmrk"
  [questionIndexInit]="0"
  [regularTimeRemaining]="999"
  [isToolExploration] = isToolExploration
  [testSessionId]="-1"
  [saveQuestion]="saveQuestionResponse"
  [submitTest]="showAnswers"
  [exitResults]="goHome"
  [isShowingResults]="isShowingEmbeddedResults()"
  (questionTitles)="questionTitleMap = $event"
  [forceQuestionsSkippable]="true"
  [btnReviewSubmit]="isToolExploration ? 'exit_tool_exploration' : 'btn_review_submit'"
  [noTestConfirmationRequired]="isToolExploration"
></test-runner>

<div *ngIf="!testForm" style="display:flex; justify-content: center; align-items: center; height:100vh;"> 
  <div *ngIf="!errorMessage">Loading...</div>
  <div *ngIf="errorMessage">{{errorMessage}}</div>
</div>

<div *ngIf="testForm && isShowingExternalResults()" class="sample-question-review">

  <div style=" display:flex; flex-direction:row; justify-content: space-between; align-items: flex-start;">
    <div>
      <h2><tra-md slug="txt_g9_results_title"></tra-md></h2>
      <tra-md slug="txt_g9_results_body"></tra-md>
      <!-- <h2>Results Page</h2>
      <div>
        <button>Export Results</button>
      </div> -->
    </div>
    <div class="eqao-logo-container" *ngIf="isEqaoWhitelabel()"> 
      <img *ngIf="isEnglish()"  src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/EQAO_Logo_SVG/1609173725758/EQAO_Logo_SVG.svg">
      <img *ngIf="!isEnglish()" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/OQRE_Logo_SVG/1609173780871/OQRE_Logo_SVG.svg">
    </div>
  </div>
  
  <hr/>

  <!-- <div class="question-review-viewport">
    <div class="section-question-preview" #questionReviewRunner>
      <div style="margin-bottom:0.5em;"> <strong>
        <tra [slug]="getSectionTitleSlug()"></tra>
        {{testState.currentSectionIndex+1}}, 
        {{getQuestionTitleFromIndex(testState.currentSectionIndex, testState.currentQuestionIndex)}}
      </strong> </div>
      <question-runner
        [currentQuestion]="getActiveQuestionContent()"
        [currentQuestionIndex]="0"
        [totalQuestions]="4"
        [isSubmitted]="true"
        [questionState]="getActiveQuestionState()"
      ></question-runner>
    </div>
    <div class="section-question-list">
      <div *ngFor="let section of testForm.currentTestDesign.sections; let sectionIndex = index" >
        <div class="question-summary-section" >
          <tra [slug]="getSectionTitleSlug()"></tra> 
          {{sectionIndex+1}} 
          <tra slug="of_enum"></tra> 
          {{testForm.currentTestDesign.sections.length}} 
          : <tra [slug]="section.caption"></tra>

        </div>
        <div class="question-row-container">
          <ng-container *ngFor="let questionId of section.questions; let qIndex = index;">
            <div *ngIf="!isReadingSelection(questionId)" class="question-row">
              <button class="button" 
                [class.is-text]="questionId != getActiveQuestionId()" 
                [class.is-info]="questionId == getActiveQuestionId()" 
                (click)="selectSectionAndQuestion(sectionIndex, qIndex)"
              >
                <span class="validation-icon">
                  <i *ngIf="getQuestionScore(questionId)" class="is-correct fa fa-check-circle-o" title="Correct" aria-hidden="true"></i>
                  <i *ngIf="!getQuestionScore(questionId)" class="is-incorrect fa fa-times-circle-o" title="Incorrect" aria-hidden="true"></i>
                </span>
                {{getQuestionTitle(questionId)}}
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div> -->

</div>

