<div class="asset-library-panels" [class.is-split-view]="getElementUrl()">
  <div class="menu">
    <ul class="menu-list">
      <li *ngFor="let mode of availableModes" 
          [class.no-pointer-events]="isReadOnly()" 
          [class.is-hidden]="!showModeOption(mode.id)"
      >
        <a (click)="activeMode = mode.id" 
           [class.is-active]="activeMode == mode.id"
        >
          {{mode.caption}}
        </a>
      </li>
    </ul>
  </div>
  <div class="editing-panel">
    <div>{{createStatus}}</div>
    <div [ngSwitch]="activeMode">
      <div *ngSwitchCase="Modes.TEMP" class="upload-area">
        <button [disabled]="isReadOnly()" (click)="uploadTemp()"> Use This Asset </button> 
        <div> alt text <input type="text" class="input" [formControl]="altText"> </div>
        <div>{{getElementUrl()}}</div>
      </div>
      <div *ngSwitchCase="Modes.NEW">
        <div  class="upload-area">
          <div> 
            Type 
            <select [formControl]="selectedAssetType">
              <option *ngFor="let assetType of availableAssetTypes" [value]="assetType.id"> {{assetType.caption}} </option>
            </select> 
          </div>
          <div> 
            <capture-image *ngIf="!activeElement" [element]="standAloneElement"></capture-image>
            <capture-image *ngIf="activeElement && !activeElement.url" [fileType]="getElementType(activeElement.elementType)" [element]="activeElement"></capture-image>
            <div>{{getElementUrl()}}</div>
          </div>
          <div> alt text <input type="text" class="input" [formControl]="altText"> </div>
          <div> voice captions <input type="text" class="input" [formControl]="voiceCaptions"> </div>
          <div> description <input type="text" class="input" [formControl]="description"> </div>
          <div> tags <input type="text" class="input" [formControl]="tags"> </div>
          <div> license holder <input type="text" class="input" [formControl]="licenseHolder"> </div>    
          <div> license infromation <input type="text" class="input" [formControl]="licenseInformation"> </div>             
          <div> license start date <input type="date" class="input" [formControl]="licenseDateStart"> </div>
          <div> license expiry date <input type="date" class="input" [formControl]="licenseDateEnd"> </div>
          <div> maximum impression <input type="number" class="input" [formControl]="maxImpressions"> </div>   
        </div>
        <div class="new-asset-area">
          select library to put this into
          <div> 
            Library:  
            <select [formControl]="selectedLibrary">
              <option *ngFor="let library of assetLibraries" [value]="library.id"> {{library.name}} </option>
            </select> 
          </div>
        </div>

        <div style="margin-top:1em;">
          <button 
            class="button is-success"
            [disabled]="isReadOnly() || !((activeElement && activeElement.url) || (standAloneElement && standAloneElement.url))" 
            (click)="createSingleAsset()"
          > 
            Create New Asset
          </button> 
        </div>

        <hr>

        

        <h4><a (click)="isBatchCreationExpanded = !isBatchCreationExpanded">Batch Asset Creation</a></h4>
        <div *ngIf="isBatchCreationExpanded">
          <textarea [formControl]="batchCreationJson"
                    class="textarea is-small" 
                    placeholder="JSON should be in the form for example, `{'asset_library_id':1 'asset_type':'IMAGE', 'url':'http://____'}` 'tags':'year_2012 org_project dib_00000'}"
          ></textarea>
          <div style="margin-top:1em;">
            <button 
              class="button is-success"
              [disabled]="isReadOnly()" 
              (click)="batchCreateAssets()"
            > 
              Create New Assets
            </button>   
          </div>
        </div>

        <div>{{createStatus}}</div>

      </div>
      <div *ngSwitchCase="Modes.SEARCH">
        <div class="asset-search">

          <button *ngIf="canUseSelectedItem()" [disabled]="isReadOnly()" (click)="useLibraryAsset()" >
            Use Selected Item
            <i class="fa fa-arrow-up" aria-hidden="true"></i>
          </button>
    
          <div class="preview-search-element">
            <div class="notification" style="word-wrap: break-word;" *ngIf="selectedAsset">
              You have selected this asset: {{selectedAsset.description}}
              <div>
                {{selectedAsset.url}}
              </div>
              <div *ngIf="editLibraryAsset.isEditing()" (keypress)="editAssetKeyPress($event)">
                <div>File info, copyright info</div>
                <button *ngIf="!showNewUpload" [disabled]="isReadOnly()" (click)="showNewUpload = true">Upload New File</button>
                <div *ngIf="showNewUpload">
                  <capture-image [element]="newUploadElement"></capture-image>
                  <button [disabled]="isReadOnly()" (click)="showNewUpload = false">Cancel</button>
                </div>
                <div> alt text <input #altTextId type="text" class="input" [(ngModel)]="selectedAsset.alt_text" [formControl]="altText"> </div>
                <div> voice captions <input type="text" class="input" [(ngModel)]="selectedAsset.voice_captions" [formControl]="voiceCaptions"> </div>
                <div> description <input type="text" class="input" [(ngModel)]="selectedAsset.description" [formControl]="description"> </div>
                <div> tags <input type="text" class="input" [(ngModel)]="selectedAsset.tags" [formControl]="tags"> </div>
                <div> license holder <input type="text" class="input" [(ngModel)]="selectedAsset.license_holder" [formControl]="licenseHolder"> </div>  
                <div> license information <input type="text" class="input" [(ngModel)]="selectedAsset.license_information" [formControl]="licenseInformation"> </div>           
                <div> license start date <input type="datetime" class="input" [(ngModel)]="selectedAsset.license_start" [formControl]="licenseDateStart"> </div>
                <div> license expiry date <input type="datetime" class="input" [(ngModel)]="selectedAsset.license_end" [formControl]="licenseDateEnd"> </div>
                <div> maximum impression <input type="number" class="input" [(ngModel)]="selectedAsset.max_impressions" [formControl]="maxImpressions"> </div>   
                <div> copyright checked <input type="checkbox" class="checkbox" [(ngModel)]="selectedAsset.is_copyright_checked" [formControl]="isCopyrightChecked"> </div>
                <div> plagiarism checked <input type="checkbox" class="checkbox" [(ngModel)]="selectedAsset.is_plagiarism_checked" [formControl]="isPlagiarismChecked"> </div>
                <div> archived <input type="checkbox" class="checkbox" [(ngModel)]="selectedAsset.is_archived" [formControl]="isArchived"> </div>
              </div>
              <div style="margin-top:1em;">
                <edit-save [initEditing]="initEditing" [isDisabled]="isReadOnly()" [formlet]="editLibraryAsset"></edit-save>
              </div>
            </div>
          </div>
          <paginator [model]="libraryAssetsTable.getPaginatorCtrl()" [page]="libraryAssetsTable.getPage()" [numEntries]="libraryAssetsTable.numEntries()"></paginator>
          <table class="table is-hoverable">
            <tr>
              <th></th>
              <th class="flush" style="width:12em"> <table-header id = "url"                   caption = "Preview"               [ctrl] = "libraryAssetsTable" [isSortEnabled]="false"></table-header> </th>
              <th class="flush"> <table-header id = "type"                  caption = "Type"                  [ctrl] = "libraryAssetsTable" [isSortEnabled]="true"></table-header> </th>
              <th class="flush"> <table-header id = "tags"      caption = "Description and Tags"  [ctrl] = "libraryAssetsTable" (filter)="storeTagFilter()" [isSortEnabled]="true"></table-header> </th>
              <th class="flush"> <table-header id = "updated_on"            caption = "Last Update"           [ctrl] = "libraryAssetsTable" [isSortEnabled]="true"></table-header> </th>
              <th class="flush"> <table-header id = "library"               caption = "Library"               [ctrl] = "libraryAssetsTable" [isSortEnabled]="true"></table-header> </th>
              <th *ngIf="false" class="flush"> <table-header id = "is_copyright_checked"  caption = "Copyright Checked"     [ctrl] = "libraryAssetsTable" [isSortEnabled]="true"></table-header> </th>
              <th *ngIf="false" class="flush"> <table-header id = "is_plagiarism_checked" caption = "Plagiarism Checked"     [ctrl] = "libraryAssetsTable" [isSortEnabled]="true"></table-header> </th>
              <th *ngIf="false" class="flush"> <table-header id = "used_in_items"         caption = "Items Using"               [ctrl] = "libraryAssetsTable" [isSortEnabled]="false"></table-header> </th>
            </tr>
            <!-- <tr *ngFor="let asset of loadedAssets"> -->
            <tr *ngFor="let asset of libraryAssetsTable.getCurrentPageData()">
              <td> 
                <button *ngIf="!selectedAsset || !asset || selectedAsset.asset_id !== asset.asset_id" (click)="selectLibraryAsset(asset)">Select</button>
                <span *ngIf="selectedAsset && asset && selectedAsset.asset_id === asset.asset_id">Selected</span>
                <button  *ngIf="isElementConnected()" [disabled]="isReadOnly()" (click)="swapInAsset(asset)">Swap In</button>
              </td>
              <td [ngSwitch]="asset.type">
                <div *ngSwitchCase="AssetTypes.IMAGE"> 
                  <img [src]="renderUrl(asset.url)" 
                       [class.is-constrained-image]="!asset.isExpanded"
                       [class.is-expanded-image]="asset.isExpanded"
                       (click)="asset.isExpanded = !asset.isExpanded"
                  > 
                </div>
                <div *ngSwitchCase="AssetTypes.GRAPHIC"> <img [src]="renderUrl(asset.url)"> </div>
                <div *ngSwitchCase="AssetTypes.VIDEO"> <video [src]="asset.url"></video> </div>
                <div *ngSwitchCase="AssetTypes.AUDIO"> 
                  <audio controls>
                    <source [src]="asset.url">
                  </audio> 
                </div>
              </td>
              <td> {{asset.type}} </td>
              <td>
                <div>{{asset.description}}</div>
                <div  *ngIf="asset._tags" class="tags">
                  <span class="tag" *ngFor="let tag of asset._tags">{{tag}}</span>
                </div>
              </td>
              <td> {{renderDate(asset.updated_on)}} </td>
              <td> {{asset.library_name}} </td>
              <td *ngIf="false"> {{asset.is_copyright_checked}} </td>
              <td *ngIf="false"> {{asset.is_plagiarism_checked}} </td>
              <td *ngIf="false"><a>{{asset.used_in_items}}</a></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    
    
  </div>
  <div class="comparison-panel" *ngIf="getElementUrl()">
    <div style="margin-bottom:1em; font-size:0.8em;">
      Image to be replaced:
    </div>
    <img [src]="renderUrl(getElementUrl())">
  </div>
</div>