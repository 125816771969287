<table class="results-table" [ngStyle]="getStyle()">
    <tr>
        <th colspan="3"><tra slug="tr_results_sr_questions"></tra></th>
    </tr>
    <tr>
        <td>
            <tra slug="tr_results_score"></tra>
        </td>
        <td>
            {{getCorrectSRScore()}}/{{totalSRScore}}
        </td>
        <td>
            ({{getSRPercentage()}}%)
        </td>
    </tr>
    <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
    <tr>
        <td>
            <tra slug="tr_results_correct_ans"></tra>
        </td>
        <td>
            {{numCorrectSRQuestions}}
        </td>
        <td>
        </td>
    </tr>
    <tr>
        <td>
            <tra slug="tr_results_incorrect_ans"></tra>
        </td>
        <td>
            {{getNumIncorrectSRQuestions()}}
        </td>
        <td>
        </td>
    </tr>
</table>