import { Component, OnInit, Input, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { IContentElementImage, QuestionState, ElementType, IEntryStateVideo, IContentElementAudio, ToolTipPositions } from '../models';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { LangService } from '../../core/lang.service';
import { RenderAudioComponent } from '../render-audio/render-audio.component';

@Component({
  selector: 'element-render-audio',
  templateUrl: './element-render-audio.component.html',
  styleUrls: ['./element-render-audio.component.scss']
})
export class ElementRenderAudioComponent implements OnInit, OnDestroy {

  @Input() element:IContentElementAudio;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState; // not used
  @Input() questionPubSub?: QuestionPubSub;

  @ViewChild(RenderAudioComponent) audioPlayer: RenderAudioComponent

  constructor(
    private changeDetector: ChangeDetectorRef,
    public lang: LangService,

  ) { }

  trigger:Subject<boolean> = new Subject();
  ToolTipPositions = ToolTipPositions

  isAutoPlay;
  ngOnInit() {
    // console.log('element', this.element)
    this.isPlaying = false;
    this.ensureState();
    if (this.element.isAutoPlay){
      this.initAudioCountdown()
    }
  }

  ngAfterViewInit() {
    
    
  }

  hasCheckedCanStart
  canStartPlaying() {
    if (!this.hasCheckedCanStart && this.questionState && this.canPlay()) {
      const id = this.element.entryId
      const el = this.getAudioElement()
      if (this.questionState && this.questionState[id]) {
        const ts = this.questionState[id].timeStamp;
        // console.log(this.questionState[id], el);
        if (!el) return;
        if (ts && ts>0 && ts<el["duration"]) {
          this.isAutoPlay = true;
          el["currentTime"] = ts;
          // console.log(el["currentTime"])
          el.play();
        }
      }
      this.hasCheckedCanStart = true;
    }
  }
  
  bufferVal = 5;
  isPlaying = false;
  ngOnDestroy(){
    this.isCountdownInProgress = false;
    const id = this.element.entryId
    if (id && this.questionState && this.questionState[id]) {
      const qState = this.questionState[id]
    }
    try{
      const el = this.getAudioElement();
      // console.log('el to destroy', el)
      el.pause();
    } catch(e) {}
  }

  ensureState() {
    if (this.questionState && !this.questionState[this.element.entryId]) {
      const state:IEntryStateVideo = {
        isStarted: true,
        isFilled: true,
        numViews: 0,
        timeStamp: 0,
        type: ElementType.AUDIO
      };
      if (!state.numViews) {
        state.numViews = 0;
      }
      this.questionState[this.element.entryId] = state;
    }
    if (this.questionState){
      return this.questionState[this.element.entryId];
    }
    else{
      return {};
    }
  }

  private isSafari(){
    const userAgent = window.navigator.userAgent;
    return userAgent.indexOf("Safari") > -1;
  }

  isCountdownInProgress:boolean;
  countdownInterval:any;
  countdownTimeRemaining:number;
  initAudioCountdown() {
    // console.log('init audio countdown')
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval)
    }
    this.isCountdownInProgress = true;
    this.countdownTimeRemaining = this.element.autoPlayDelay;  
    this.countdownInterval = setInterval(
      () => {
        if (!this.isCountdownInProgress){
          return;
        }
        if (this.countdownTimeRemaining>0) {
          this.countdownTimeRemaining--
          // console.log('audio countdown', this.countdownTimeRemaining)
        };
        if (this.countdownTimeRemaining<=0) {
          this.isCountdownInProgress = false;
          // play audio
          // console.log('trigger audio')
          this.trigger.next(true);
        } 
      },
      1000
    );
  }

  hasControls() {
    if (this.element.isHidden) {
      return false;
    }
    return true;
  }

  onPlay(){
    console.log('on play')
    this.addView();
  }

  play() {
    this.isCountdownInProgress = false;
    this.isPlaying = true;
    console.log("in play")
  }
  

  notPlaying() {
    this.isPlaying = false;
    this.isAutoPlay = false;
  }

  addView() {
    // console.log(this.questionState, this.element.entryId)
    if (this.questionState && this.questionState[this.element.entryId]) {
      const state = this.questionState[this.element.entryId]
      // console.log("addView", this.isAutoPlay)
      if (!this.isAutoPlay){
        this.isAutoPlay = true;
        state.numViews++;
        this.resetFlag();
      }
      // console.log("addView", state.numViews)
    }
  }

  isEnabled(){
    const state = this.ensureState();
    if (state){
      if (this.element.isLimitAudioPlays && this.element.numAudioPlays <= state.numViews) {
        return false;
      }
      return true
    }
    return false;
  }

  playEnd() {
    this.onAudioEnd();
    this.notPlaying();
  }

  onAudioEnd(){
    if (this.element.onAudioEnd){
      this.element.onAudioEnd.forEach(pub => {
        this.questionPubSub.elementPub(+pub.entryId, pub.type, pub.data);
      })
    }
  }


  resetter = true;
  resetFlag() {
    this.resetter = false;
    this.changeDetector.detectChanges();
    this.resetter = true;
  }

  getNumViews() {
    if (this.questionState && this.questionState[this.element.entryId] && this.questionState[this.element.entryId].numViews) {
      return this.questionState[this.element.entryId].numViews
    }
    return 0;
  }

  canPlay() {
    return this.isEnabled()
  }

  getAudioElement() {
    const id = this.element.entryId
    let el = null;
    

    if (this.audioPlayer && this.audioPlayer.audioPlayer) {
      el = this.audioPlayer.audioPlayer.nativeElement   
    }
    /*if (el==null) {
      el = <HTMLMediaElement>(document.getElementById("audio"+id))
      console.log("View Child failed")
    }*/
    return el;
  }

  getStartTime() {
    if (!this.questionState || !this.questionState[this.element.entryId]) {
      return 0;
    }
    return this.questionState[this.element.entryId].timeStamp;
  }

  getSecRemainingStr() {
    const element = this.getAudioElement();
    if (!element) {
      return '';
    }

    const leadingZero = (n:number) => {
      if ((''+n).length < 2){
        return '0'+n
      }
      return ''+n
    }
    const convertSecToMinSec = (sec) => {
      if (!sec ){
        return '00:00'
      }
      const min = Math.floor(sec/60);
      return leadingZero(min)+':'+leadingZero(Math.floor(sec - min*60))
    }
    return convertSecToMinSec(element["currentTime"]) + ' / ' + convertSecToMinSec(element["duration"])
  }

  onTimeUpdate() {
    if (this.questionState && this.questionState[this.element.entryId]) {
      const element = this.getAudioElement();
      if (!element) return;
      this.questionState[this.element.entryId].timeStamp = element["currentTime"]
    }
  }
}

