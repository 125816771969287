<div class="element-type-ordering">
  <div *ngIf="element.showDefaultText==undefined || element.showDefaultText" class="question-block-label mb-3" style="text-align: center; font-size:0.8em;margin-bottom: 4em;">
    <tra-md slug="txt_default_order_instr"></tra-md>
  </div>

  <div cdkDropListGroup
       [style.display]="'flex'"
       [style.flex-direction]="isVertical() ? (element.isTargetOrientationReversed ? 'row-reverse' : 'row') : (element.isTargetOrientationReversed ? 'column-reverse' : 'column')"
       [style.margin-left.em]="isVertical() && isTargetMode()? 4 : 0"
  >
    <div
            class="option-container"
            cdkDropList
            (cdkDropListDropped)="(!isTargetMode()) ? drop(options, $event) : dropTargetMode($event, null, true)"
            [cdkDropListSortingDisabled]="isTargetMode()"
            [cdkDropListData]="options"
            [cdkDropListOrientation]="isHorizontal() ? 'horizontal' : 'vertical'"
            [class.is-vertical]="isVertical()"
            [class.is-horizontal]="isHorizontal()"
            [class.is-target-mode-source]="isTargetMode()"


    >
      <div cdkDrag [cdkDragDisabled]="isLocked" (mousedown)="dragStarted(optionElement)" *ngFor="let optionElement of options; let optionIndex = index;">
        <div
                class="inner-option-container"
                [class.is-vertical]="isVertical()"
                [class.is-horizontal]="isHorizontal()"
                [style.height]="'inherit'"
                [style.max-height]="'inherit'"
                style="display:flex;flex-grow:1;"
                *ngIf="!optionElement.isReadOnly || !isTargetMode()"
        >
          <div *ngIf="optionIndex !== 0 && !isTargetMode()" style="padding:0.3em; flex-grow:0;">
            <div *ngIf="!element.delimiterType || element.delimiterType=='text'" [style.line-height]="'1em'" [innerHTML]="element.delimiter ? processThisText(element.delimiter) : ''">
              <!--<markdown-inline [input]="element.delimiter"></markdown-inline>-->
            </div>
            <div *ngIf="element.delimiterType=='image'">
              <element-render-image *ngIf="element.delimiterImg" [element]="element.delimiterImg"></element-render-image>
            </div>
            <div *ngIf="element.delimiterType=='icon'">
              <i *ngIf="element.delimiterImg" [class]="element.delimiterIcon"></i>
            </div>
          </div>
          <div
                  [ngSwitch]="optionElement.elementType" class="option not-in-target"
                  [style.color]="element.colourScheme.textColor ? element.colourScheme.textColor : ''"
                  [style.backgroundColor]="element.colourScheme.backgroundColor ? element.colourScheme.backgroundColor : ''"
                  [ngStyle]="isTargetMode() ? getDims(false) : {}"
                  [style.justify-content]="element.isCenterDraggableContent ? 'center' : 'left'"
                  [style.align-items]="element.isCenterDraggableContent ? 'center' : 'left'"
          >
            <div *ngSwitchCase="'text'" [style.line-height]="'1em'" [style.text-align]="element.isCenterDraggableContent ? 'center' : 'left'" style="white-space: pre-wrap;" [innerHTML]="optionElement.content ? processThisText(optionElement.content) : ''">
              <!--<markdown-inline [input]="optionElement.content"></markdown-inline>-->
            </div>
            <div *ngSwitchCase="'image'">
              <element-render-image [element]="optionElement"></element-render-image>
            </div>
            <div *ngSwitchCase="'math'">
              <render-math [obj]="optionElement" [prop]="'content'"></render-math>
            </div>
          </div>
        </div>
        <render-audio
                [url]="getElementAudio(optionElement.voiceover)"
                [trigger]="getDragTrigger(optionElement)"
                [isTriggerDisabled]="!isVoiceoverEnabled()"
        ></render-audio>
      </div>
    </div>
    <div
            *ngIf="isTargetMode()"
            class="option-container answers"
            [class.is-vertical]="isVertical()"
            [class.is-horizontal]="isHorizontal()"
            [style.margin-right.em]="isVertical() && element.isTargetOrientationReversed ? 4 : 0"
            [style.margin-left.em]="isVertical() && !element.isTargetOrientationReversed ? 4 : 0"
            [style.margin-top.em]="isHorizontal() && !element.isTargetOrientationReversed ? 4 : 0"
            [style.margin-bottom.em]="isHorizontal() && element.isTargetOrientationReversed ? 4 : 0"
    >
      <div *ngFor="let optionElement of answers; let i = index;">
        <div
                class="inner-option-container"
                [class.is-vertical]="isVertical()"
                [class.is-horizontal]="isHorizontal()"
                [style.left.em]="element.options[i] && element.options[i].x ? element.options[i].x : 0"
                [style.top.em]="element.options[i] && element.options[i].y ? element.options[i].y : 0"
        >
          <div [ngStyle]="{padding: '0.3em', 'flex-grow': 0, 'align-self': 'center', visibility: i !== 0 ? 'visible': 'hidden'}">
            <div *ngIf="!element.delimiterType || element.delimiterType=='text'" [style.line-height]="'1em'" [innerHTML]="element.delimiter ? processThisText(element.delimiter) : ''">
            </div>
            <div *ngIf="element.delimiterType=='image'">
              <element-render-image *ngIf="element.delimiterImg" [element]="element.delimiterImg"></element-render-image>
            </div>
            <div *ngIf="element.delimiterType=='icon'">
              <i *ngIf="element.delimiterImg" [class]="element.delimiterIcon"></i>
            </div>
          </div>
          <div style="display:flex; flex-wrap: nowrap;align-items: center;" [style.flex-grow]="1" [style.flex-direction]="'column'">
            <div [style.color]="element.colourScheme.textColor"
                 [style.backgroundColor]="element.colourScheme.backgroundColor"
                 [ngStyle]="isTargetMode() ? getDims(true) : {}"
                 [style.border-style]="'solid'"
                 [style.border-width.em]="0.1"
                 [style.border-color]="'#000000'"
                 style="display:flex;flex-direction:column;justify-content: flex-start;align-items: center;"
                 cdkDropList [cdkDropListData]="optionElement" (cdkDropListDropped)="canBeDropped(optionElement) ? dropTargetMode($event, this.element.options[i], false) : '';"
                 [cdkDropListEnterPredicate]="canBeDroppedPredicate"
            >

              <div style="display:flex;flex-direction: column;white-space:pre;align-self:stretch">
                <div [style.text-align]="element.isCenterLabelContent ? 'center' : 'left'"
                     [style.line-height]="'1em'"
                     [style.align-self]="'stretch'"
                     [style.border-bottom]="element.options[i].label && element.options[i].label != '' ? '2px solid' : 'none'"
                     *ngIf="!element.options[i].labelType || element.options[i].labelType=='text' || element.options[i].labelType=='simple_text'"
                     [innerHTML]="element.options[i].label ? processThisText(element.options[i].label) : ''">
                  <!--<markdown-inline [input]="element.options[i].label ? processThisText(element.options[i].label) : ''"></markdown-inline>-->
                </div>
                <div style="display:flex;flex-direction: row;"
                     [style.justify-content]="element.isCenterLabelContent ? 'center' : 'flex-start'"
                     [style.border-bottom]="hasDefaultImage(element.options[i].labelImg) ? '2px solid' : 'none'"
                     *ngIf="element.options[i].labelType=='image'" >
                  <element-render-image [style.align-items]="element.isCenterLabelContent ? 'center' : 'left'" [element]="element.options[i].labelImg"></element-render-image>
                </div>

              </div>


              <div *ngFor="let ans of optionElement" cdkDrag [cdkDragDisabled]="ans.isReadOnly || isLocked" class="option"
                   (mousedown)="dragStarted(ans)"
                   [class.is-fixed]="ans.isReadOnly"
                   [style.justify-content]="element.isCenterDraggableContent ? 'center' : 'left'"
                   [style.align-items]="element.isCenterDraggableContent ? 'center' : 'left'"
                   [ngStyle]="isTargetMode() ? getDims(false) : {}">
                <div [ngSwitch]="ans.elementType">
                  <div *ngSwitchCase="'text'" [style.line-height]="'1em'" [style.text-align]="element.isCenterDraggableContent ? 'center' : 'left'" style="white-space: pre-wrap;" [innerHTML]="ans.content ? processThisText(ans.content) : ''">
                    <!--<markdown-inline [input]="ans.content"></markdown-inline>-->
                  </div>
                  <div *ngSwitchCase="'image'">
                    <element-render-image [element]="ans"></element-render-image>
                  </div>
                  <div *ngSwitchCase="'math'">
                    <render-math [obj]="ans" [prop]="'content'"></render-math>
                  </div>
                </div>
              </div>
              <render-audio
                      [url]="getElementAudio(this.element.options[i].label_voiceover)"
                      [trigger]="getDropTrigger(this.element.options[i])"
                      [isTriggerDisabled]="!isVoiceoverEnabled()"
              ></render-audio>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>