import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { IContentElementImage, ElementType } from '../../ui-testrunner/models';
import { AuthService } from '../../api/auth.service';
import { RoutesService } from 'src/app/api/routes.service';
import { MyInstitutionService } from 'src/app/ui-testadmin/my-institution.service';
import { FormControl } from '@angular/forms';
import { EditSaveFormlet, EditSaveComponent } from '../../ui-partial/edit-save/edit-save.component';
import { MemDataPaginated } from '../../ui-partial/paginator/helpers/mem-data-paginated';
import { randInt } from '../../ui-testadmin/demo-data.service';
import { DATETIME } from 'src/app/api/models/db-types';
import { createDefaultElement } from '../item-set-editor/models';
import { DomSanitizer } from '@angular/platform-browser';
import { memo } from '../../ui-testrunner/element-render-video/element-render-video.component';
import { EditingDisabledService } from '../editing-disabled.service';
import { AuthScopeSetting, AuthScopeSettingsService } from '../auth-scope-settings.service';
import { AltTextService } from '../alt-text.service';

enum AssetTypes {
  IMAGE  = 'IMAGE',
  VIDEO  = 'VIDEO',
  AUDIO  = 'AUDIO',
  GRAPHIC = 'GRAPHIC',
  TEXT   = 'TEXT',
  SUBTITLE = 'SUBTITLE'
}

enum Modes {
  TEMP       = 'TEMP',
  NEW        = 'NEW',
  BATCH_NEW  = 'BATCH_NEW',
  SEARCH     = 'SEARCH',
}

export enum AssetField {
  ALT_TEXT = 'ALT_TEXT'
}

interface ILibraryAssetSummary {
  asset_id: number;
  current_version_id: number;
  url: string;
  alt_text?: string;
  description?: string;
  group_id: number;
  id: number;
  library_id: number;
  library_name: string;
  license_end: DATETIME;
  license_holder: string;
  license_information: string;
  license_start: DATETIME;
  max_impressions: number;
  tags: string;
  type: string;
  uid: number;
  updated_on: DATETIME;
  is_archived?: boolean;
  is_plagiarism_checked?: boolean;
  is_copyright_checked?: boolean;
  voice_captions?: any;
}

@Component({
  selector: 'asset-library',
  templateUrl: './asset-library.component.html',
  styleUrls: ['./asset-library.component.scss']
})
export class AssetLibraryComponent implements OnInit, OnChanges, AfterViewInit {
  constructor(
    private auth: AuthService,
    private routes:RoutesService,
    private myInst: MyInstitutionService,
    private sanitizer: DomSanitizer,
    private editingDisabled: EditingDisabledService,
    private authScope: AuthScopeSettingsService,
    private altTextService: AltTextService
  ) { }

  
  
  @ViewChild('altTextId') altTextElementRef:ElementRef;
  @ViewChild(EditSaveComponent) editSaveComponent:EditSaveComponent;

  @Input() activeElement:IContentElementImage;
  @Input() assetType:AssetTypes;
  @Input() initEditing:boolean;
  @Input() focusedField:AssetField;

  @Output() close = new EventEmitter();

  availableAssetTypes = [
    {id: AssetTypes.IMAGE,  caption: 'Image'},
    {id: AssetTypes.VIDEO,  caption: 'Video'},
    {id: AssetTypes.AUDIO,  caption: 'Audio'},
    {id: AssetTypes.GRAPHIC, caption: 'Graphic'},
    {id: AssetTypes.TEXT,   caption: 'Text'},
    {id: AssetTypes.SUBTITLE,   caption: 'Subtitle'},
  ];

  availableModes = [
    {id: Modes.TEMP, caption: 'Temporary Asset'},
    {id: Modes.NEW, caption: 'New Asset'},
    // {id: Modes.BATCH_NEW, caption: 'Batch Asset Creation'},
    {id: Modes.SEARCH, caption: 'Asset Library'},
  ];

  isBatchCreationExpanded:boolean;
  batchCreationJson = new FormControl()

  loadedAssets = [];

  Modes = Modes;
  AssetTypes = AssetTypes;
  activeMode:Modes;
  selectedAsset:ILibraryAssetSummary;
  libraryAssetsTable = new MemDataPaginated<ILibraryAssetSummary>({
    data: [],
    pageSize: 20,
    sortSettings: {},
    filterSettings: { },
  });
  
  assetLibraries:any[] = [];
  
  selectedAssetType = new FormControl();  
  selectedLibrary = new FormControl();
  altText = new FormControl();  
  voiceCaptions = new FormControl();
  description = new FormControl();
  tags = new FormControl();
  licenseHolder = new FormControl();
  licenseDateStart = new FormControl();
  licenseDateEnd = new FormControl();
  licenseInformation = new FormControl();
  maxImpressions = new FormControl();
  isCopyrightChecked = new FormControl();
  isPlagiarismChecked = new FormControl();
  isArchived = new FormControl();
  newUploadElement:IContentElementImage;
  showNewUpload:boolean = false;
  standAloneElement:IContentElementImage;

  editLibraryAsset:EditSaveFormlet;
  createStatus:string;

  santiziedUrls = new Map();

  // 

  ngOnInit(): void {
    this.selectDefaultMode();
    this.getAssetLibraries();
    this.loadAllAssetData();
    this.retrieveTagFilter();

    this.altText.valueChanges.subscribe((value) => {
      if(this.activeElement) {
        if (value){
          this.activeElement.altText = value;
        }
        else{
          value = this.activeElement.altText;
          this.selectedAsset.alt_text = value;
        }
        this.altTextService.updateAltText(this.activeElement.entryId, value);
      }
    });

    const formControls = {
      selectedAssetType:this.selectedAssetType,  
      selectedLibrary:this.selectedLibrary,
      altText:this.altText,  
      voiceCaptions:this.voiceCaptions,
      description:this.description,
      tags:this.tags,
      licenseHolder:this.licenseHolder,
      licenseDateStart:this.licenseDateStart,
      licenseDateEnd:this.licenseDateEnd,
      licenseInformation:this.licenseInformation,
      maxImpressions:this.maxImpressions,      
      isCopyrightChecked:this.isCopyrightChecked,
      isPlagiarismChecked:this.isPlagiarismChecked,
      isArchived:this.isArchived,
    };

    this.editingDisabled.registerFormControlsObj(formControls);

    this.editLibraryAsset = new EditSaveFormlet(formControls, this.updateAsset )

    if(!this.activeElement){
      this.standAloneElement = createDefaultElement(ElementType.IMAGE)
    }

    this.newUploadElement = _.cloneDeep(this.activeElement);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if(this.editLibraryAsset.isEditing()) {
        const assetFieldToElement = {
          [AssetField.ALT_TEXT]: this.altTextElementRef
        }
        assetFieldToElement[this.focusedField].nativeElement.focus();
      }
    }, 400);
  }

  getElementUrl(){
    if (this.activeElement && this.activeElement.url){
      return this.activeElement.url
    }
    if (this.standAloneElement && this.standAloneElement.url){
      return this.standAloneElement.url
    }
    return null;
  }

  storeTagFilter(){
    this.authScope.setSetting(AuthScopeSetting.ASSET_LIB_TAG_FILTER, this.libraryAssetsTable.activeFilters.tags)
  }
  retrieveTagFilter(){
    this.libraryAssetsTable.activeFilters.tags = this.authScope.getSetting(AuthScopeSetting.ASSET_LIB_TAG_FILTER)
  }

  getElementType(elType){
    if(elType === 'reader'){
      return 'text'
    }
    return elType;
  }

  showModeOption(modeOption:Modes){
    if ((modeOption === Modes.TEMP) && !this.isElementConnected()){
      return false;
    }
    return true;
  }

  ngOnChanges(changes:SimpleChanges){
    this.selectDefaultMode();
  }

  selectDefaultMode(){
    this.activeMode = Modes.SEARCH; // temp: should have a toggle to allow people to stay in this mode
    // if (this.activeElement){
    //   this.activeMode = Modes.TEMP;
    // }
    // else{
    //   this.activeMode = Modes.SEARCH;
    // }
  }

  selectLibraryAsset(asset:ILibraryAssetSummary){
    this.selectedAsset = asset;
  }

  canUseSelectedItem(){
    return (this.selectedAsset && this.isElementConnected())
  }

  swapInAsset(asset:ILibraryAssetSummary){
    this.selectLibraryAsset(asset);
    this.useLibraryAsset();
  }

  useLibraryAsset(){
    this.activeElement.assetId = this.selectedAsset.asset_id;
    this.activeElement.assetVersionId = this.selectedAsset.current_version_id;
    if(this.showNewUpload){
      this.activeElement.url = this.newUploadElement.url;
    }else{
      this.activeElement.url = this.selectedAsset.url;
    }
    this.close.emit();
  }

  loadAllAssetData(){
    this.loadedAssets = []
    this.auth.apiFind(this.routes.TEST_AUTH_ASSET, {})
    .then((data)=>{
      // this.loadedAssets = data.data.slice(0,100);
      this.loadedAssets = data.data;
      this.loadedAssets.forEach(asset => {
        if (asset.tags){
          asset._tags = asset.tags.replace(/\s\s+/g,' ').split(' ');
        }
        else{
          asset._tags = [];
        }
      });
      this.libraryAssetsTable.injestNewData(data.data);
      if(this.activeElement && this.activeElement.assetId){
        this.selectedAsset = _.find(data.data, {asset_id: this.activeElement.assetId})
        this.activeMode = Modes.SEARCH;
      }
    })
  }

  isUploadActive(){
    return this.isElementConnected() || (this.activeMode === Modes.NEW);
  }
  isNewActive(){
    return (this.activeMode === Modes.NEW);
  }
  isSearchActive(){
    return (this.activeMode === Modes.SEARCH);
  }
  isTempActive(){
    return (this.activeMode === Modes.TEMP);
  }


  isElementConnected(){
    return !!this.activeElement;
  }

  getAssetLibraries(){
    this.auth.apiFind(this.routes.TEST_AUTH_ASSET_LIBRARIES, {})
    .then((data)=>{
      this.assetLibraries = data;
    })
  }

  updateAsset=()=>{
    return this.auth.apiPatch(
      this.routes.TEST_AUTH_ASSET,   
      this.selectedAsset.asset_id,    
      {
        alt_text: this.altText.value,
        voice_captions: this.voiceCaptions.value,
        description: this.description.value,
        tags: this.tags.value,
        licenseHolder: this.licenseHolder.value,
        licenseDateStart: this.licenseDateStart.value,
        licenseDateEnd: this.licenseDateEnd.value,
        licenseInformation:this.licenseInformation.value,
        maxImpressions: this.maxImpressions.value,
        url: this.showNewUpload ? this.newUploadElement.url : this.selectedAsset.url,        
        isCopyrightChecked:this.isCopyrightChecked.value,
        isPlagiarismChecked:this.isPlagiarismChecked.value,
        isArchived:this.isArchived.value,
      },
      this.myInst.constructPermissionsParams()
    ).then(()=>{
      this.loadAllAssetData()      
    })
  }

  batchCreateAssets(){
    this.createStatus = 'uploading'
    const records = JSON.parse(this.batchCreationJson.value);
    Promise.all(records.map(record => {
      this.auth.apiCreate(
        this.routes.TEST_AUTH_ASSET,       
        { ... record },
        this.myInst.constructPermissionsParams()
      )
    }))
    .then( ()=>{
      console.log('created : ', records);
      this.createStatus = 'success';
      this.loadAllAssetData()
    })
  }

  createSingleAsset(){
    if(this.activeMode === 'NEW'){
      if(!this.selectedLibrary.value || !this.selectedAssetType.value || !this.getElementUrl()){
        alert('Please enter required parameters')
      }
      this.createStatus = 'uploading'
      this.auth.apiCreate(
        this.routes.TEST_AUTH_ASSET,       
        {
          asset_library_id: this.selectedLibrary.value,
          asset_type: this.selectedAssetType.value,
          url: this.getElementUrl(),
          alt_text: this.altText.value,
          voice_captions: this.voiceCaptions.value,
          description: this.description.value,
          tags: this.tags.value,
          licenseHolder: this.licenseHolder.value,
          licenseDateStart: this.licenseDateStart.value,
          licenseDateEnd: this.licenseDateEnd.value,
          licenseInformation:this.licenseInformation.value,
          maxImpressions: this.maxImpressions.value        
        },
        this.myInst.constructPermissionsParams()
      )
      .then((data)=>{
        console.log('created : ', data)
        this.createStatus = 'success';
        this.loadAllAssetData()
        if(this.activeElement){
          const asset = data[0].item_asset;
          const asset_version = data[0].item_asset_version;
          this.activeElement.assetId = asset.id;
          this.activeElement.assetVersionId = asset_version.id;
          this.activeElement.url = asset_version.url;          
        }
      })
      .catch((err)=>{
        this.createStatus = 'failed'
      })
    }
  }
  renderUrl(url:string){
    return memo(this.santiziedUrls, url, url  => this.sanitizer.bypassSecurityTrustResourceUrl(url) );
  }

  renderDate(dateStr){
    return moment(dateStr).fromNow();
  }

  uploadTemp(){
    this.activeElement.altText = this.altText.value;
    
    delete(this.activeElement.assetId);
    delete(this.activeElement.assetVersionId);

    this.activeElement.url = this.getElementUrl();
    this.close.emit();
  }

  editAssetKeyPress(event) {
    if(event.key === 'Enter') {
      this.editSaveComponent.editSave();
      this.close.emit();
    }
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly()
  }

}
