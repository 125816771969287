import { Component, OnInit, Input } from '@angular/core';
import { IContentElement, ElementType } from '../models';
import { Subscription, Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';

@Component({
  selector: 'element-render',
  templateUrl: './element-render.component.html',
  styleUrls: ['./element-render.component.scss']
})
export class ElementRenderComponent implements OnInit {

  @Input() contentElement: IContentElement;
  @Input() isLocked: boolean;
  @Input() questionState: any;
  @Input() isShowSolution?: boolean;
  @Input() questionPubSub?: QuestionPubSub;
  
  ElementType = ElementType;

  constructor() { }

  ngOnInit() {
  }
}
